import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { AdwContext } from "../../../context/AdwContext";
import * as MsalAuthService from "../../../services/MsalAuthService"
import styles from "./CalendarEventView.module.scss";
import appStyles from "../../../../src/App.module.scss";
import moment from "moment";
import AdwCalendarEventType from "../../../types/CalendarTypes/AdwCalendarEventType";
import GraphService from "../../../services/GraphService";
import DateUtils from "../../../utils/DateUtils";
import { TimeUnits } from "../../../types/CachingTypes/TimeUnits";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import LoadingOverlay from '@ronchalant/react-loading-overlay';
import "./calendar.css";
import calendarIcon from "../../../assets/images/eventscalendar-icon.svg";
import buttonIcon from "../../../assets/images/button-icon.svg";
import momenttimezone from 'moment-timezone';
import { SetSpListContents } from "../../../context/ActionTypes";
import { EventContentArg, EventInput } from "@fullcalendar/core";
import AdwFcEvent from "../../../types/CalendarTypes/AdwFcEvent";
import CalendarEventPopUp from "../../molecules/CalendarEventPopUp/CalendarEventPopUp";
import searchStyles from "../../molecules/SearchBar/MindbreezeAutoCompleteWithSearch.module.scss";  

const CalendarEvents: React.FC = () => {
  document.title = "InSide - Events Calendar";
  const [appState, dispatch] = useContext(AdwContext);
  const fcCompanyEvent: AdwFcEvent[] = appState && appState.lists && appState.lists.fcCompanyEvent ? appState.lists.fcCompanyEvent : [];
  //ALL event types
  const eventTypes: AdwCalendarEventType[] =
    appState && appState.lists && appState.lists.companyEventType
      && appState.lists.companyEventType.length ? appState.lists.companyEventType : [];

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [dayMaxEvents, setDayMaxEvents] = useState<any>(2);
  const [currView, setCurrView] = useState<string>('listMonth');
  const [loading, setLoading] = useState<boolean>(true);
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [popUpData, setPopUpData] = useState(null);
  const [eventInputData, setEventInputData] = useState<EventInput[]>([]);
  const [lastFocusedButton, setLastFocusedButton] = useState(null);
  const modalRef = useRef(null);
  const [isNvdaReaderOn, setIsNvdaReaderOn] = useState(false);

  /* get user's timezone */
  const localtz = momenttimezone.tz.guess();
  useLayoutEffect(() => {
    if (appState?.graphUser.id) {
      getEventsAndPaydays(0);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [appState?.graphUser])

  useEffect(() => {
    const searchBar = document.getElementById(searchStyles.search);

    if (searchBar) {
      searchBar.style.display = 'none';
    }

    return () => {
      if (searchBar) {
        searchBar.style.display = 'block';
      }
    }
  }, [])


  const getEventsAndPaydays = (
    numberOfTries: number = 0,
    startsDate: string = startDate,
    endsDate: string = endDate
  ): void => { // NOSONAR
    if (numberOfTries < 5) {
      if (MsalAuthService.hasActiveMsalSession() && appState?.graphUser?.id) {
        if (startsDate === "") {
          if (currView === "day") {
            startsDate = (moment(new Date(), 'MM-DD-YYYY')).format('MM/DD/YYYY');
          } else if (currView === "dayGridWeek") {
            startsDate = moment().startOf('week').format('MM-DD-YYYY');
          }
          else if (currView === "listMonth") {
            startsDate = moment().startOf('month').format('MM-DD-YYYY');
          }
          else {
            startsDate = moment().startOf('month').format('MM/DD/YYYY');
          }
        }
        if (endsDate === "") {
          if (currView === "day") {
            endsDate = (moment(new Date(), 'MM-DD-YYYY')).format('MM/DD/YYYY');
          } else if (currView === "dayGridWeek") {
            endsDate = moment().endOf('week').format('MM-DD-YYYY')
          } else if (currView === "listMonth") {
            endsDate = moment().endOf('month').format('MM/DD/YYYY')
          } else {
            endsDate = moment().endOf('month').format('MM/DD/YYYY')
          }
        }
        setEventInputData([]);
        //get sharepoint list - events
        GraphService.getCompanyCalendarEvents(startsDate, endsDate, eventTypes, 0, TimeUnits.days).then((events) => {
          setEventInputData(Object.assign(events));
          if (events) {
            dispatch(SetSpListContents("company event", sortEventsByEventDate(events)));
          }
          setLoading(false);
          setStartDate(startsDate);
        }); // Graph - Events
      } //else
    }//numberOfTries
  }; //getEventsAndPaydays

  const handleResize = () => {
    dispatch(SetSpListContents("company event", determineHowManyToShow(fcCompanyEvent)));
  };

  const togglePop = () => {
    setOpenPopUp(!openPopUp)
    if (lastFocusedButton) {
      lastFocusedButton.focus();
    }
  };

  const openPopUpNow = (info: any) => {
    setPopUpData(info.event);
    setOpenPopUp(true);
  }

  const sortEventsByStartDate = (eventArray: AdwFcEvent[]): AdwFcEvent[] => {
    if (eventArray) {
      return eventArray.sort((a, b) => moment(a.date).diff(moment(b.date)));
    }
  }

  const sortedEventArrayByDate = (eventArray: AdwFcEvent[]) => {
    return eventArray.sort((a, b) => {
      const dateA = moment(a.date).format('LLL');
      const dateB = moment(b.date).format('LLL');
      return moment(dateA).diff(dateB);
    });
  }

  const sortEventsByEventDate = (eventArray: AdwFcEvent[]): AdwFcEvent[] => {
    if (eventArray) {
      let tempEventArray = sortedEventArrayByDate(eventArray);

      tempEventArray = tempEventArray.sort((a, b) => moment(a.start).diff(moment(b.start)));
      return tempEventArray;
    }
  }

  const determineHowManyToShow = (eventArray: AdwFcEvent[]): AdwFcEvent[] => {
    if (eventArray) {
      eventArray = sortEventsByStartDate(eventArray);
    }
    return eventArray;
  }

  const handleOnClick = (e: any) => {
    if (isNvdaReaderOn) {
      return
    }

    setLastFocusedButton(e.target);
  };

  const renderEvent = (e: EventContentArg) => {
    let shortTitle = e.event.title;
    {window['newrelic'].interaction().end()}  

    if (currView === 'listMonth') {
      return (
        <button
          className={`calendarEventDiv ${styles.calendarEventButton}`}
          onClick={handleOnClick}
          ref={modalRef}
        >
          <div className="calendarEventDot">
            <span className={`${(currView === 'listMonth' || currView === 'timeGridDay') ? styles.dotListView : styles.dot}`} style={{ backgroundColor: e.event.extendedProps.colorTag }}></span>
          </div>
          <div className="calendarEventTitle">
            <span className='timeSpan'>
              {(currView === 'listMonth' || currView === 'timeGridDay') ? "" : formatCalendarDisplay(e.event.extendedProps.startTimeVar, e.event.allDay)}
            </span>
            <span className={`${(currView === 'listMonth' || currView === 'timeGridDay') ? styles.eventSpanList : styles.eventSpan}`} >
              {(currView === 'listMonth' || currView === 'timeGridDay') ? e.event.title : shortTitle}
            </span>
          </div>
        </button>
      )
    }
    return (
      <div className={`calendarEventDiv`}
        ref={modalRef}
      >
        <div className="calendarEventDot">
          <span className={`${(currView === 'listMonth' || currView === 'timeGridDay') ? styles.dotListView : styles.dot}`} style={{ backgroundColor: e.event.extendedProps.colorTag }}></span>
        </div>
        <div className="calendarEventTitle">
          <span className='timeSpan'>
            {(currView === 'listMonth' || currView === 'timeGridDay') ? "" : formatCalendarDisplay(e.event.extendedProps.startTimeVar, e.event.allDay)}
          </span>
          <span className={`${(currView === 'listMonth' || currView === 'timeGridDay') ? styles.eventSpanList : styles.eventSpan}`} >
            {(currView === 'listMonth' || currView === 'timeGridDay') ? e.event.title : shortTitle}
          </span>
        </div>
      </div>
    )
  }

  const formatCalendarDisplay = (val, allDay) => {
    if (allDay) {
      return 'All Day';
    } else {
      let dec = moment(val);
      return dec.tz(localtz).format('h:mma');
    }
  }

  //call getEventsAndPaydays() when view is changed
  const handleDatesSet = (dateInfo) => {
    let prevDate = DateUtils.formatDateCustom(new Date(startDate), "MM-DD-YYYY");
    let currDate = DateUtils.formatDateCustom(new Date(dateInfo.startStr), "MM-DD-YYYY");
    setStartDate(dateInfo.startStr);
    setEndDate(dateInfo.endStr);
    //first load
    if (currView !== dateInfo.view.type) {
      setCurrView(dateInfo.view.type);
      getEventsAndPaydays(0, dateInfo.startStr, dateInfo.endStr);
    } else {
      if ((prevDate !== currDate)) {
        getEventsAndPaydays(0, dateInfo.startStr, dateInfo.endStr);
      }
    }
    if (dateInfo.view.type === 'dayGridMonth') {
      setDayMaxEvents(2);
    } else {
      setDayMaxEvents(true);
    }
  }//handleDatesSet


  return (<div className={appStyles.padded} style={{ paddingBottom: 35 }}>
    <div className='calendar-event'>
      <div className="fc fc-media-screen fc-theme-standard">
        <div className="fc-header-toolbar fc-toolbar">
          <a className="alignLeft"
            title="Printable Payroll Calendar" href="https://onyourside.sharepoint.com/sites/Compensation-and-Pay/SitePages/Pay-Calendars.aspx"
            target="_blank" rel="noopener noreferrer">
            <img className="calendarIcons" alt="" src={calendarIcon} />
            Printable Payroll Calendar</a>

          <a className="alignRight"
            title="Help" href="https://onyourside.sharepoint.com/sites/InSide-Resources/SitePages/About-the-InSide-Events-Calendar.aspx"
            target="_blank" rel="noopener noreferrer">
            <img className="calendarIcons" alt="" src={buttonIcon} />
            Calendar Help</a>
        </div>
      </div>
    </div>
    {loading ?
      <>
        <LoadingOverlay
          active={loading}
          spinner
          text='Loading your calendar...'
        >
          <div className='emptyDiv'></div>
        </LoadingOverlay>
      </>
      :
      <>
        <FullCalendar
          initialView={currView}
          eventContent={() => renderEvent}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
          datesSet={handleDatesSet}
          displayEventTime={true}
          eventTimeFormat={
            {
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: true,
              meridiem: 'narrow'
            }
          }
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,dayGridWeek,timeGridDay,listMonth'
          }}
          buttonText={{
            today: 'Today',
            month: 'Month',
            week: 'Week',
            day: 'Day',
            list: 'List'
          }}
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={dayMaxEvents}
          events={eventInputData}
          timeZone={localtz}
          eventClick={(e) => {
            openPopUpNow(e);

            const targetElement = e.jsEvent.target as HTMLElement;
            const calendarEventTitle = targetElement.closest('.calendarEventTitle');

            if (!calendarEventTitle) {
              setIsNvdaReaderOn(false)
              setLastFocusedButton(e.jsEvent.target);
            } else {
              setIsNvdaReaderOn(true)

              if (currView == 'listMonth' || currView == 'timeGridDay') {
                lastFocusedButton.removeAttribute('tabindex');
                targetElement.parentElement.setAttribute("tabindex", "0");
                setLastFocusedButton(targetElement.parentElement);

                return
              }

              if (currView == 'dayGridMonth' || currView == 'dayGridWeek') {
                const closestFcEvent = targetElement.closest('.fc-event');
                if (closestFcEvent) {
                  setLastFocusedButton(closestFcEvent);
                }
              }
            }
          }
      }
          fixedWeekCount={false}
          weekends={true}
        />
        <div>
          {openPopUp && <CalendarEventPopUp isOpen={openPopUp} toggle={togglePop} data={popUpData} />}
        </div>
      </>
    }
  </div>)

}

export default CalendarEvents;