import "core-js/features/array/find";
import "core-js/features/array/includes";
import { InsideUserData } from "../InsideUserData";
import { SpCommonTool } from "./SpCommonTool";

class AdwCommonTool {
  public Created: string = "";
  public DefaultTool: boolean = true;
  public defaultOrPreferredTool: boolean = true;
  public DefaultOrRequiredTool: boolean = true;
  public ID: number = 99;
  public ImagePath: string;
  public ToolHoverImagePath: string;
  public Link: { Description: string; Url: string } = {
    Description: "Empty space",
    Url: "/#",
  };
  public PreferredTool: boolean = true;
  public RequiredTool: boolean = false;
  public Title: string = "Empty space";
  public TooltipText: string = "Please select a new tool";
  public WindowTarget: string = "_self";
  public NewTool: boolean = false;

  constructor(spCommonTool: SpCommonTool, insideUser?: InsideUserData) {
    if (spCommonTool !== null && insideUser !== null) {
      this.Created = spCommonTool.fields.Created;
      this.DefaultTool = spCommonTool.fields.DefaultTool;
      this.ID = Number(spCommonTool.fields.id);
      this.ImagePath = spCommonTool.fields.ImagePath;
      this.ToolHoverImagePath = spCommonTool.fields.ToolHoverImagePath;
      this.Link = spCommonTool.fields.Link;
      this.RequiredTool = spCommonTool.fields.RequiredTool;
      this.Title = spCommonTool.fields.Title;
      this.TooltipText = spCommonTool.fields.TooltipText;
      this.WindowTarget = spCommonTool.fields.WindowTarget;
      this.DefaultOrRequiredTool = AdwCommonTool.isDefaultOrRequiredTool(spCommonTool);
      this.PreferredTool = AdwCommonTool.isPreferredTool(spCommonTool, insideUser);
      this.defaultOrPreferredTool = AdwCommonTool.isDefaultOrPreferredTool(spCommonTool, insideUser);
    }
  }

  private static isDefaultOrRequiredTool = (tool: SpCommonTool): boolean => {
    if (tool.fields.DefaultTool || tool.fields.RequiredTool) {
      return true;
    } else {
      return false;
    }
  };

  private static isDefaultOrPreferredTool = (tool: SpCommonTool, insideUser: InsideUserData): boolean => {
    let isDefaultOrPreferredTool: boolean = false;
    let preferredToolsIdString: string = "";
    if (insideUser && insideUser.preferredCommonTools) {
      preferredToolsIdString = insideUser.preferredCommonTools;
    }

    if (preferredToolsIdString) {
      const preferredTools: number[] = AdwCommonTool.convertPreferredToolsToArray(preferredToolsIdString);

      isDefaultOrPreferredTool = preferredTools.includes(tool.fields.id);
    } else {
      isDefaultOrPreferredTool = tool.fields.DefaultTool;
    }

    return isDefaultOrPreferredTool;
  };

  private static isPreferredTool = (tool: SpCommonTool, insideUser: InsideUserData): boolean => {
    let preferredTools: string = "";
    if (insideUser && insideUser.preferredCommonTools) {
      preferredTools = insideUser.preferredCommonTools;
    }
    const preferredToolsArray: number[] = AdwCommonTool.convertPreferredToolsToArray(preferredTools);
    return preferredToolsArray &&
      preferredToolsArray.some(preferredTool => {
        return preferredTool === tool.fields.id;
      })
      ? true
      : false;
  };

  public static convertPreferredToolsToArray = (preferredTools: string): number[] => {
    let numberArray: number[] = [];
    if (preferredTools) {
      const preferredToolsArray: string[] = preferredTools.split(", ");
      numberArray = preferredToolsArray.map(preferredTool => parseInt(preferredTool, 0));
    }
    return numberArray;
  };

  public static getNumberOfNewTools = (tools: AdwCommonTool[], user: InsideUserData): number => {
    let numNewTools: number = 0;
    const lastToolVisit: Date = new Date(user && user.lastToolsVisit ? user.lastToolsVisit : "1/01/2019, 2:10:52 PM");
    tools.forEach((tool) => {
      if (new Date(tool.Created) > lastToolVisit) {
        numNewTools++;
      }
    })
    return numNewTools;
  };

  public static getPrefferedTools(prefferedToolIdString: string, adwCommonTools: AdwCommonTool[]): AdwCommonTool[] {
    let userPrefferedTools: AdwCommonTool[] = [];
    const preferredToolsArray: number[] = AdwCommonTool.convertPreferredToolsToArray(prefferedToolIdString);

    userPrefferedTools = preferredToolsArray.map(preferredToolId => adwCommonTools.find(tool => tool.ID === preferredToolId));

    userPrefferedTools = userPrefferedTools.filter(tool => tool !== undefined);
    return userPrefferedTools;
  }
}

export default AdwCommonTool;
