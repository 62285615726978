import React, { useContext, useEffect, useState } from "react";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AdwCommonTool from "../../../types/CommonToolsTypes/AdwCommonTool";
import styles from "./MyTools.module.scss";
import Link from "../../atoms/Link/Link";
import {ReactComponent as GearIcon} from "../../../assets/images/Gear.svg";
import { useNavigate } from "react-router-dom";
import { randomAlphaNumeric } from "../../../utils/ArrayUtils";

export interface IMyToolsProps {
  defaultOrPreferredTools: AdwCommonTool[];
  onViewAllClicked:any;
}

const MyToolsView: React.FC<IMyToolsProps> = ({
  defaultOrPreferredTools,onViewAllClicked
}) => {
  
  const navigate = useNavigate();
  const [appState]: AdwContextType = useContext(AdwContext);
const [numberOfNewTools,setNumberOfNewTools] = useState(0);

useEffect(()=>{
  if(appState.lists && appState.lists.commonTools && appState.user){
    setNumberOfNewTools(AdwCommonTool.getNumberOfNewTools(
      appState.lists.commonTools,
      appState.user
    ));
  }
},[appState.lists, appState.user])

  return ( 
    <div className={styles.CommonToolsWrapper} id="Common-Tools-Wrapper">
      <div className={styles.toolsHeading}>
        <a href="/customizetools" target="_blank" id={`all-tools-link`}>
        <h4 className={styles.title} id="Common-Tools-Title">
          <strong>My tools</strong>
        </h4>
       </a>
          <div className={styles.newToolsOrArrows}>
            {numberOfNewTools > 0 && (
              
               <Link url={'/customizetools'} id={`new-tool-link`}>
                <span className={styles.newTools}>
                  {numberOfNewTools} new available
                </span>
               </Link>
            )}
          </div>
          <div className={styles.viewAllLink}>
          <button className={styles.viewAllToolsLink}  onClick={(e)=>{
             e.preventDefault();
             e.stopPropagation();
             onViewAllClicked();
             navigate('/customizetools');
          }} id={`view-all-tools-link`}>
            <GearIcon className={styles.gearIconSvg}/>
                <span className={styles.viewAllLinkText}>
                   View all
                </span>
               </button>
          </div>
      </div>
    
      <ul className={styles.toolsDisplay}>
        {defaultOrPreferredTools && 
           defaultOrPreferredTools.map(
              (tool, index) => (
                <li className={styles.myToolLink} key={randomAlphaNumeric(index+1)}>
                <Link linkClasses={styles.linkClass} url={tool.Link.Url} target={tool.WindowTarget} id={`custom-tool-link-${index}`}>
                <div><span id={`custom-tool-title-${index}`} className={styles.toolTitleText}>{tool.Title}</span></div>
              </Link>
              </li>
              )
            )
          }
      </ul>
    </div>
  
  );
};

export default MyToolsView;
