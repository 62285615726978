import React, { useEffect, useState } from "react";
import moment from "moment";
import useFocusTrap from "./useFocusTrap";
import styles from "./EventPopUp.module.scss";
import momenttimezone from 'moment-timezone';

const EventPopUp = ({isOpen, toggle, data }) => {
  let localtz = momenttimezone.tz.guess();
  const modalRef = useFocusTrap(isOpen);
  const [colorTag, setColorTag] = useState(null);
  const [eventTime, setEventTime] = useState(null);
  const [eventtype, setEventtype] = useState(null);
  const [locations, setLocations] = useState(null);
  const [description, setDescription] = useState(null);
  const [title, setTitle] = useState(null);
  const [allDayEvent, setAllDayEvent] = useState(false);
  const [displayStartDate, setDisplayStartDate] = useState(null);
  const [displayEndDate, setDisplayEndDate] = useState(null);

  useEffect(() => {
    setColorTag(data.colorTag);
    setEventTime(data.eventTime);
    setEventtype(data.eventtype);
    setLocations(data.location);
    setDescription(formatDescription(data.description));
    setTitle(data.title);
    setAllDayEvent(data.allDay);
    setDisplayStartDate(data.start);
    setDisplayEndDate(data.end);
    if (data.allDay) {
      setDisplayStartDate(data.start);
      setDisplayEndDate(moment(data.end)
        .add(1, "day")
        .format("YYYY-MM-DD hh:mm A"))
    }
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        toggle();
      }})
  }, [data]);

  const formatStartEndTimeForEvent = (startTime, endTime) => {
    let startTimeOnly = moment(startTime).tz(localtz).format(
      "h:mm A"
    );
    let endTimeOnly = moment(new Date(endTime)).tz(localtz).format(
      "h:mm A"
    );
    return startTimeOnly + ' - ' + endTimeOnly;
  }

  const formatDate = (input, allDay, type) => {
    if (allDay) {
      if (type == 'start') {
        return moment(new Date(input), "YYYYMMDD[T]HHmmss").add(1, "day").format(
          "YYYYMMDD"
        );
      } else {
        return moment(new Date(input), "YYYYMMDD[T]HHmmss").format(
          "YYYYMMDD"
        );
      }
    } else {
      return moment(new Date(input), "YYYYMMDD[T]HHmmss").format(
        "YYYYMMDD[T]HHmmss"
      );
    }
  };

  const formatDescription =(eventDescription)=>{
          //Sharepoint automatically convert absolute URL to relative URL
          if (eventDescription !== undefined && eventDescription !== null) {
            if (
              eventDescription.length > 0 &&
              eventDescription.indexOf('<a href="/') > 0
            ) {
              eventDescription = eventDescription.replaceAll(
                '<a href="/',
                '​<a href="https://onyourside.sharepoint.com/'
              );
            }
          } else {
            eventDescription = "";
          }
          return eventDescription;
  }
  const getEventLocation = (locations) => {
    if (locations) {
      return locations;
    } else {
      return '';
    }
  }

  return (
    <div id="eventModal" className={styles.event_modal} ref={modalRef} tabIndex={-1}>
      <div className={styles.modal_content}>
        <div className={styles.close_div_button}>
          <button className={styles.close_button} onClick={() => toggle()}>
            <div className={styles.image_close_div_button}>
              <img
                src={require("../../../assets/images/close-event-icon.png")}
                alt="close"
              />
            </div>
          </button>
        </div>
        {data ? <>
          <div
            className={styles.header_color}
            style={{ backgroundColor: colorTag }}
          ></div>
          <div className={styles.model_description_content}>
            <div className={styles.popup_titlebar}></div>
            <div className={styles.popup_header}>
              <div className={styles.header_title}>
                <div
                  className={styles.date_header}
                  style={{ backgroundColor: colorTag }}
                >
                  {moment.utc(eventTime).format("MMM DD")}
                </div>
                <h2 className={styles.header_title_text}>{title}</h2>
              </div>
              <div className={styles.location_desc_content}>
                <div>
                  <p className={styles.eventStartDate}>
                    <img
                      src={require("../../../assets/images/event-time-icon.png")}
                      className={styles.event_time_image}
                      alt="Time"
                    />
                    {moment.utc(eventTime).format("ddd [,] MMM DD [,] YYYY")}{" "}
                    at {allDayEvent ? 'All Day' : formatStartEndTimeForEvent(displayStartDate, displayEndDate)}
                  </p>
                  <p className={styles.eventStartDate}>
                    <img
                      src={require("../../../assets/images/location-icon.png")}
                      className={styles.loc_image}
                      alt="Location"
                    />
                    {locations}
                  </p>
                  <p className={styles.eventStartDate}>
                    <img
                      src={require("../../../assets/images/event-type-icon.png")}
                      className={styles.event_type_image}
                      alt="Eventtype"
                    />
                    {eventtype}
                  </p>
                </div>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </div>
          </div>
          <div className={styles.modal_footer}>
            <div className={styles.modal_footer_wrapper}>
              <div className={styles.right_div_buttons}>
                <button
                  className={styles.footer_add_to_calendar_button}
                  onClick={() => {
                    let iCalString =
                      "BEGIN:VCALENDAR\n" +
                      "PRODID:-//Microsoft Corporation//Outlook 16.0 MIMEDIR//EN\n" +
                      "VERSION:2.0\n" +
                      //"METHOD:PUBLISH\n" + vTimeZone + "\n" +
                      "BEGIN:VEVENT\n" +
                      "CLASS:PUBLIC\n" +
                      "CREATED:20170811T124152Z\n" +
                      "DESCRIPTION:" +
                      description +
                      "\\n\n" +
                      "DTEND:" +
                      formatDate(displayEndDate, allDayEvent, 'end') +
                      "\n" +
                      "DTSTAMP:20170811T124152Z\n" +
                      "DTSTART:" +
                      formatDate(displayStartDate, allDayEvent, 'start') +
                      "\n" +
                      "LAST-MODIFIED:20170811T124152Z\n" +
                      "LOCATION:" +
                      getEventLocation(locations) +
                      "\n" +
                      "PRIORITY:5\n" +
                      "SEQUENCE:0\n" +
                      "SUMMARY;LANGUAGE=en-us:" +
                      title +
                      "\n" +
                      'X-ALT-DESC;FMTTYPE=text/html: <html xmlns:v="urn:schemas-microsoft-com:vml" \n' +
                      ' xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:w="urn:schemas-microsoft-com:office:word\n' +
                      ' xmlns:m="http://schemas.microsoft.com/office/2004/12/omml\n' +
                      ' xmlns="http://www.w3.org/TR/REC-html40"><head></head><body>' +
                      description +
                      "\\n\n" +
                      "</body></html>\n" +
                      "TRANSP:OPAQUE\n" +
                      "X-MICROSOFT-CDO-BUSYSTATUS:BUSY\n" +
                      "X-MICROSOFT-CDO-IMPORTANCE:1\n" +
                      "X-MICROSOFT-DISALLOW-COUNTER:FALSE\n" +
                      "X-MS-OLK-AUTOFILLLOCATION:FALSE\n" +
                      "X-MS-OLK-CONFTYPE:0\n" +
                      "BEGIN:VALARM\n" +
                      "TRIGGER:-PT15M\n" +
                      "ACTION:DISPLAY\n" +
                      "DESCRIPTION:Reminder\n" +
                      "END:VALARM\n" +
                      "END:VEVENT\n" +
                      "END:VCALENDAR";

                    const blob = new Blob([iCalString]);
                    const link = document.createElement("a");
                    if (link.download !== undefined) {
                      const fileUrl = URL.createObjectURL(blob);
                      link.setAttribute("href", fileUrl);
                      link.setAttribute("download", "calendar.ics");
                      link.style.visibility = "hidden";
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                      URL.revokeObjectURL(fileUrl);
                    }
                  }}
                >
                  Add to Calendar
                </button>
              </div>
            </div>
          </div>
        </>
          :
          <><p>Unable to fetch event information!</p></>
        }
      </div>
    </div>


  );
};

export default EventPopUp;
