import React from "react";
import 'react-loading-skeleton/dist/skeleton.css'
import HomepageSkeleton from "../SkeletonScreen/HomePageSkeleton";

const LoadingSpinner: React.FC<{}> = () => {
  
  /* calendar page will not use Skeleton screen */
  if (window.location.pathname === '/') {
    return <HomepageSkeleton />;
  }
};

export default LoadingSpinner;
