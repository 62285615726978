import React from "react";
import styles from "./FilterTab.module.scss";


const FilterTab = ({ activeLetter, activeFilter, label, onPress, count }) => {

    return (
        <div className={(activeFilter === label && !activeLetter) ? styles.tabSelected : styles.tab} onClick={onPress}>
            <a href="#" className={activeFilter === label ? styles.tabLabelSelected : styles.tabLabel}>{label} {count > 0 ? '(' + count + ')' : ''}</a>
        </div>
    )
}

export default FilterTab;