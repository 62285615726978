import { useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import InsideLogo from '../../../assets/images/InSide.png';
import './HomePageSkeleton.css';
import 'react-loading-skeleton/dist/skeleton.css'

const HomepageSkeleton: React.FC = () => {

  useEffect(() => {
    const body = document.querySelector('body');

    if (body) {
      body.style.overflow = 'hidden';
    }

    return () => {
      if (body) {
        body.style.removeProperty('overflow');
      }
    }
  }, [])

  return (
    <>
      <SkeletonTheme baseColor="#1F74DB" highlightColor="#1c68c5">
      <div className="header-placeholder">
        <div className="skeleton-header">
          <div className="profile-placeholder">
            <div>
              <Skeleton circle height={37} width={37} />
            </div>
            <div>
              <Skeleton width={100} height={18} />
              <Skeleton width={200} height={18} />
            </div>
          </div>
          <div className="header-logo">
            <img src={InsideLogo} alt="InSide logo" />
          </div>
          <div className="date-placeholder">
            <Skeleton width={300} height={18} />
          </div>
        </div>
      </div>
      </SkeletonTheme>
      <SkeletonTheme baseColor="#f2f2f2" highlightColor="#dadada">
      <div className="megamenu-container">
        <div className="megamenu-placeholder">
          <div className="megamenu-categories-placeholder">
            <Skeleton width={85} height={18} />
            <Skeleton width={85} height={18} />
            <Skeleton width={85} height={18} />
            <Skeleton width={85} height={18} />
            <Skeleton width={85} height={18} />
          </div>
          <div className="tools-placeholder">
            <Skeleton width={110} height={60} />
          </div>
        </div>
      </div>
      <div className="content">
        <div className="searchbar-placeholder">
          <Skeleton height={50} />
        </div>
        <div className="news-placeholder">
          {window.innerWidth > 400 ? (
            <div>
              <Skeleton width={384} height={226} />
              <div style={{ marginTop: '10px' }}>
                <Skeleton width={384} height={18} />
                <Skeleton width={292} height={18} />
              </div>
              <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Skeleton width={18} height={18} />
                  <Skeleton width={83} height={12} />
                </div>
                <Skeleton width={346} height={12} />
                <Skeleton width={308} height={12} />
                <Skeleton width={346} height={12} />
                <Skeleton width={308} height={12} />
              </div>
            </div>
          ) : (
            <div>
              <Skeleton width={360} height={226} />
              <div style={{ marginTop: '10px' }}>
                <Skeleton width={360} height={18} />
                <Skeleton width={292} height={18} />
              </div>
              <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Skeleton width={18} height={18} />
                  <Skeleton width={83} height={12} />
                </div>
                <Skeleton width={346} height={12} />
                <Skeleton width={308} height={12} />
                <Skeleton width={346} height={12} />
                <Skeleton width={308} height={12} />
              </div>
            </div>
          )}
          <div className="skeleton-news">
              {(window.innerWidth > 1024 || window.innerWidth < 667) && (
              <>
                <div className="skeleton-snapshot">
                  <Skeleton width={107} height={76} />
                  <div>
                    <Skeleton width={222} height={18} />
                    <Skeleton width={137} height={18} />
                    <div className="skeleton-snapshot-more">
                      <Skeleton height={18} width={18} />
                      <Skeleton width={83} height={12} />
                    </div>
                  </div>
                </div>
                <div className="skeleton-snapshot">
                  <Skeleton width={107} height={76} />
                  <div>
                    <Skeleton width={222} height={18} />
                    <Skeleton width={137} height={18} />
                    <div className="skeleton-snapshot-more">
                      <Skeleton height={18} width={18} />
                      <Skeleton width={83} height={12} />
                    </div>
                  </div>
                </div>
                <div className="skeleton-snapshot">
                  <Skeleton width={107} height={76} />
                  <div>
                    <Skeleton width={222} height={18} />
                    <Skeleton width={137} height={18} />
                    <div className="skeleton-snapshot-more">
                      <Skeleton height={18} width={18} />
                      <Skeleton width={83} height={12} />
                    </div>
                  </div>
                </div>
                <div className="skeleton-snapshot">
                  <Skeleton width={107} height={76} />
                  <div>
                    <Skeleton width={222} height={18} />
                    <Skeleton width={137} height={18} />
                    <div className="skeleton-snapshot-more">
                      <Skeleton height={18} width={18} />
                      <Skeleton width={83} height={12} />
                    </div>
                  </div>
                </div></>
            )}
              {window.innerWidth <= 1024 && window.innerWidth >= 667 && (
              <>
                <div className="skeleton-snapshot">
                  <Skeleton width={107} height={76} />
                  <div>
                    <Skeleton width={180} height={18} />
                    <Skeleton width={137} height={18} />
                    <div className="skeleton-snapshot-more">
                      <Skeleton height={18} width={18} />
                      <Skeleton width={83} height={12} />
                    </div>
                  </div>
                </div>
                <div className="skeleton-snapshot">
                  <Skeleton width={107} height={76} />
                  <div>
                    <Skeleton width={180} height={18} />
                    <Skeleton width={137} height={18} />
                    <div className="skeleton-snapshot-more">
                      <Skeleton height={18} width={18} />
                      <Skeleton width={83} height={12} />
                    </div>
                  </div>
                </div>
                <div className="skeleton-snapshot">
                  <Skeleton width={107} height={76} />
                  <div>
                    <Skeleton width={180} height={18} />
                    <Skeleton width={137} height={18} />
                    <div className="skeleton-snapshot-more">
                      <Skeleton height={18} width={18} />
                      <Skeleton width={83} height={12} />
                    </div>
                  </div>
                </div>
                <div className="skeleton-snapshot">
                  <Skeleton width={107} height={76} />
                  <div>
                    <Skeleton width={180} height={18} />
                    <Skeleton width={137} height={18} />
                    <div className="skeleton-snapshot-more">
                      <Skeleton height={18} width={18} />
                      <Skeleton width={83} height={12} />
                    </div>
                  </div>
                </div>
              </>
            )}
            <Skeleton width={107} height={18} />
          </div>
          <div className="announcements-placeholder">
            <div className="announcement-placeholder">
              <Skeleton width={278} height={29} />
            </div>
            <div className="announcement-placeholder">
              <div>
                <Skeleton width={272} height={18} />
                <Skeleton width={322} height={18} />
              </div>
              <div>
                <Skeleton width={22} height={22} />
              </div>
            </div>
            <div className="announcement-placeholder">
              <div>
                <Skeleton width={272} height={18} />
                <Skeleton width={322} height={18} />
                <Skeleton width={88} height={18} />
              </div>
              <div>
                <Skeleton width={22} height={22} />
              </div>
            </div>
            <div className="announcement-placeholder">
              <div>
                <Skeleton width={272} height={18} />
                <Skeleton width={322} height={18} />
              </div>
              <div>
                <Skeleton width={22} height={22} />
              </div>
            </div>
            <div className="announcement-placeholder">
              <div>
                <Skeleton width={229} height={18} />
              </div>
              <div>
                <Skeleton width={22} height={22} />
              </div>
            </div>
            <div className="announcement-placeholder">
              <div>
                <Skeleton width={229} height={18} />
              </div>
              <div>
                <Skeleton width={22} height={22} />
              </div>
            </div>
            <div style={{ borderBottom: 'none' }} className="announcement-placeholder">
              <div>
                <Skeleton width={229} height={18} />
              </div>
              <div>
                <Skeleton width={22} height={22} />
              </div>
            </div>
            <div style={{ marginTop: '8px' }}>
              <Skeleton width={237} height={18} />
            </div>
          </div>
        </div>
        <div className="events-otherNews-placeholder">
          <div className="events-placeholder">
            <div className="event-placeholder">
              <Skeleton width={256} height={28} />
            </div>
            <div className="event-placeholder">
              <div>
                <Skeleton width={292} height={18} />
                <Skeleton width={292} height={18} />
              </div>
              <Skeleton width={174} height={18} />
            </div>
            <div className="event-placeholder">
              <div>
                <Skeleton width={292} height={18} />
                <Skeleton width={292} height={18} />
              </div>
              <Skeleton width={174} height={18} />
            </div>
            <div className="event-placeholder">
              <div>
                <Skeleton width={292} height={18} />
                <Skeleton width={292} height={18} />
              </div>
              <Skeleton width={174} height={18} />
            </div>
            <div className="event-placeholder">
              <div>
                <Skeleton width={292} height={18} />
                <Skeleton width={292} height={18} />
              </div>
              <Skeleton width={174} height={18} />
            </div>
          </div>
          <div className="otherNews-placeholder">
            <div className="otherNews-header">
              <Skeleton width={256} height={28} />
              <Skeleton width={174} height={18} />
            </div>
            <div className="otherNews-snapshot">
              <Skeleton width={108} height={76} />
              <div>
                <Skeleton width={292} height={18} />
                <Skeleton width={405} height={18} />
              </div>
            </div>
            <div className="otherNews-snapshot">
              <Skeleton width={108} height={76} />
              <div>
                <Skeleton width={292} height={18} />
                <Skeleton width={405} height={18} />
              </div>
            </div>
            <div className="otherNews-snapshot">
              <Skeleton width={108} height={76} />
              <div>
                <Skeleton width={292} height={18} />
                <Skeleton width={405} height={18} />
              </div>
            </div>
            <div className="otherNews-snapshot">
              <Skeleton width={108} height={76} />
              <div>
                <Skeleton width={292} height={18} />
                <Skeleton width={405} height={18} />
              </div>
            </div>
          </div>
        </div>
        </div>
    </SkeletonTheme>
    </>
)};
export default HomepageSkeleton;