import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import styles from "./WhatsChangedPopUp.module.scss";
import {ReactComponent as CloseIcon} from "../../../assets/images/Close-button-icon.svg";
import {ReactComponent as PrevIcon} from "../../../assets/images/Arrow-left.svg";
import {ReactComponent as NextIcon} from "../../../assets/images/Arrow-right.svg";
import { SetUserPreferences } from "../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import { InsideUserData } from "../../../types/InsideUserData";
import AwsS3ImageNoDefault from "../../atoms/AwsS3Image/AwsS3ImageNoDefault";

const WhatsChangedPopUp = ({ toggle, data,disableRemindMeLater }) => {
  const [appState, dispatch]: AdwContextType = useContext(AdwContext);
  const [numberOfNewFeature,setNumberOfNewFeature]=useState(0);
  const [featureSlidesToDisplay,setFeatureSlidesToDisplay]=useState(data);
  const [currentSlide,setCurrentSlide]=useState(0);
  const [hasPrev,setHasPrev]=useState(false);
  const [hasNext,setHasNext]=useState(false);
  
  useEffect(() => {
    setFeatureSlidesToDisplay(data);
    setNumberOfNewFeature(data.length);
  }, [data]);

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        onCloseButtonPressed();
      }
    })
    return ()=>{
      document.removeEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          onCloseButtonPressed();
        }
      })
    }
  }, [data]);

  useLayoutEffect(()=>{
    if(currentSlide > 0){
      setHasPrev(true);
    }else{
      setHasPrev(false);
    }
    if(currentSlide < numberOfNewFeature-1){
    setHasNext(true);
    }else{
      setHasNext(false);
    }
  },[currentSlide,numberOfNewFeature])

  const onNextPress =()=>{
    if(currentSlide < numberOfNewFeature){
      setCurrentSlide(currentSlide+1);
    }
  }
  const onPrevPress =()=>{
    if(currentSlide > 0){
      setCurrentSlide(currentSlide-1);
    }
  }

  const onCloseButtonPressed = (): void => {
    const insideUserData: InsideUserData = Object.assign({}, appState.user);
    let todayDate = new Date();
    let closeNowData = "close, "+todayDate.toLocaleString();
    insideUserData.whatsChangedStatus = closeNowData;
    dispatch(SetUserPreferences(insideUserData));
    toggle();
  };


  const onRemindMeLater = (): void => {
    const insideUserData: InsideUserData = Object.assign({}, appState.user);
    let todayDate = new Date();
    let remindmelaterData = "remindmelater, "+todayDate.toLocaleString();
    insideUserData.whatsChangedStatus = remindmelaterData;
    dispatch(SetUserPreferences(insideUserData, false));
    toggle();
  };

  return (
    <>{(featureSlidesToDisplay && numberOfNewFeature > 0) &&
    <div id="whatChangedModal" className={styles.whatschanged_modal}>
      <div id="modal_content" className={styles.modal_content}>
        <div className={styles.modal_header}>
          <button autoFocus aria-label="Close What's new on Inside"  tabIndex={0} className={styles.close_button_wrapper} onClick={onCloseButtonPressed}>
            <CloseIcon className={styles.close_button} />
          </button>
        </div>
        <div className={styles.modal_body}>
          <div className={styles.modal_main_body}>
            <div className={styles.modal_prev_arrow_wrapper}>
              <button aria-label="Previous Slide" className={styles.prev_button} onClick={() => { onPrevPress()}} disabled={currentSlide === 0}>
                <PrevIcon className={hasPrev ? styles.prev_button_arrow_active : styles.prev_button_arrow}/>
              </button>
            </div>
            <div className={styles.modal_main_content_wrapper} aria-live="assertive">
              <div className={styles.title_wrapper}>
                <h2 aria-label="What's new on InSide">What's new on InSide</h2>
              </div>
              <div className={styles.description_wrapper}>
                <div className={styles.description_text} 
                  dangerouslySetInnerHTML={{ __html: featureSlidesToDisplay[currentSlide]?.Description}}>
                </div>
              </div>
              <div className={styles.image_wrapper}>
                {featureSlidesToDisplay[currentSlide].ImagePath &&
                  <AwsS3ImageNoDefault src={featureSlidesToDisplay[currentSlide].ImagePath} 
                    id={`whatsChanged-aws-img-main-${featureSlidesToDisplay[currentSlide].id}`} 
                    alt={featureSlidesToDisplay[currentSlide].ImageAltText}
                    className={styles.feature_image}
                  />
                }
              </div>
              <div className={styles.button_wrapper}>
                {featureSlidesToDisplay[currentSlide]?.ButtonLink &&
                <a className={styles.button} aria-label="Learn more about what's new on InSide page provides details about all current and past updates to the InSide home page." href={featureSlidesToDisplay[currentSlide].ButtonLink.Url} target="_blank">
                    {featureSlidesToDisplay[currentSlide].ButtonLink.Description}
                </a>
                }
                {!disableRemindMeLater &&
                <button onClick={()=>onRemindMeLater()} className={styles.remind_me_later}>Remind me later</button>
                }
              </div>
              {featureSlidesToDisplay.length > 1 &&
              <div className={styles.slide_count_wrapper}>
                {featureSlidesToDisplay.map((slide,index)=>(
                  <div key={slide.id} aria-hidden="true"
                  className={ index === currentSlide ? styles.slide_bubble_active : styles.slide_bubble} >
                  </div>
                ))
                }
              </div>
              }
            </div>
            <div className={styles.modal_next_arrow_wrapper}>
              <div className={styles.next_button_wrapper}>
                <button aria-label="Next Slide" className={styles.next_button} onClick={() => {onNextPress() }}  disabled={currentSlide === (featureSlidesToDisplay.length-1)}>
                  <NextIcon className={hasNext ? styles.next_button_arrow_active : styles.next_button_arrow} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
}
</>
  );
};

export default WhatsChangedPopUp;
