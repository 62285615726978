import React from "react";

export interface IListProps {
  listClasses?: string;
  listData?: number | string;
  style?: React.CSSProperties;
  children?:React.ReactNode;
}

const List: React.FC<IListProps> = ({ children, listClasses, listData, style }) => {
  return (
    <ul data-category-list={listData} className={listClasses} style={style}>
      {children}
    </ul>
  );
};

export default List;
