import { SpPopularTool } from "./SpPopularTool";

class AdwPopularTool {
  public Name: string;
  public Enabled: boolean;
  public Position: number;
  public AppUrl: string;
  public SiteTarget: string;
  public AvailableTo: string;
  public id: number;
  
  constructor(spPopularTool: SpPopularTool) {
    if (spPopularTool !== null) {
      this.id = spPopularTool.fields.id; //check API call data
      this.Name = spPopularTool.fields.Title;
      this.Enabled = spPopularTool.fields.Enabled;
      this.Position = spPopularTool.fields.Position;
      this.AppUrl = spPopularTool.fields.AppUrl;
      this.SiteTarget = spPopularTool.fields.SiteTarget;
      this.AvailableTo = spPopularTool.fields.AvailableTo;
    }
  }

}

export default AdwPopularTool;