export enum TimeUnits {
  milliseconds = "milliseconds",
  second = "second",
  seconds = "seconds",
  minute = "minute",
  minutes = "minutes",
  hour = "hour",
  hours = "hours",
  day = "day",
  days = "days",
  year = "year",
  years = "years",
}
