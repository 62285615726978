import React from "react";
import { AdwContextState, DispatchAction } from "./AdwContextTypes";


/**
 * Entire application context type. The context is the return value of the
 * React useReducer hook. To learn more about React hooks and the useReducer hook
 * see here: https://reactjs.org/docs/hooks-intro.html
 * React useReducer returns a state value and a dispatcher.
 */
export type AdwContextType = [AdwContextState, React.Dispatch<DispatchAction>];

/**
 * This is where we create the AdwContext that we'll be using throughout the entire application.
 * We initialize it with an empty object and a null value for the reducer.
 * Later, in the context provider, we will provide the initial application state and the
 * reducer that is used to update application state.
 */
export const AdwContext = React.createContext<AdwContextType>([{} as AdwContextState, null]);