import React, { useEffect } from "react";
import usePrevious from "../../../assets/hooks/usePrevious";
import { useLocation } from "react-router-dom";
export interface IScrollIntoViewProps {
  children?:React.ReactNode;
}
const ScrollIntoView: React.FC<IScrollIntoViewProps> = ({ children }) => {
  let location = useLocation();
  const prevLocation: any = usePrevious(location);
 
  useEffect(() => {
    if (location !== prevLocation) {
      window.scroll(0, 0);
    }
  }, [location, prevLocation]);

  return <>{children}</>;
};

export default ScrollIntoView;
