import React, { useState } from "react";
import styles from "./Dropdown.module.scss";
import SelectLetter from "../SelectLetter/SelectLetter";

const Dropdown = ({ selected, setSelected, options, sites, handleLetterClick }) => {

  const handleClick = (letter) => {
    handleLetterClick(letter)
    setSelected(letter)
    setIsActive(false)
  }


  const [isActive, setIsActive] = useState(false);

  window.onclick = function (event) {
    if (!event.target.matches('.dropdownContent')) {
      if (event.target.matches('.dropdownBtn')) {
        setIsActive(!isActive);
      }
    }
  }

  return (
    <div className={styles.dropdown}>
      <a href="#" className={styles.dropdownBtn}
        onClick={() => setIsActive(!isActive)}>
        {selected ?
          <span className={styles.selectedAlphabet}>{selected}</span>
          :
          <span className={styles.selectText}>Select</span>
        }
        <span className={styles.arrowCaret}></span>
      </a>

      {isActive && (
        <div className={styles.dropdownContent}>
          {options.map((letter) => letter &&
            <SelectLetter key={letter} letter={letter} sites={sites} handleLetterClick={handleClick} />
          )}
        </div>
      )}

    </div>
  );
}

export default Dropdown;
