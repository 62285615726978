import React, { useState, useEffect } from "react";

import Announcements from "../Announcements/Announcements";
import GenericAnnouncementCard from "../../molecules/GenericAnnouncementCard/GenericAnnouncementCard";
import CommonToolsView from "../CommonTools/CommonToolsView";

import useFeatureSwitch from "../../../hooks/useFeatureSwitch";

import styles from './AnnouncementsSpace.module.scss';

const AnnouncementSpace = ({ announcementsGrid, toolsToDisplay }) => {
  const { arrayOfFeaturesEnabled, isLoaded } = useFeatureSwitch(['Announcements Widget', 'Component Moved']);

  const [showCommonToolsMovedEnabled, setShowCommonToolsMovedEnabled] = useState(null);
  const [showAnnouncementsWidget, setShowAnnouncementsWidget] = useState(null);
  const [showCommonTools, setShowCommonTools] = useState(null);

  useEffect(() => {
    if (!isLoaded) return;

    let tempShowCommonToolsMovedEnabled = null;
    let tempShowAnnouncementsWidget = null;

    const [announcementsWidget, componentMoved] = arrayOfFeaturesEnabled;

    tempShowAnnouncementsWidget = announcementsWidget;

    if (componentMoved?.featureSettings?.length > 0) {
      componentMoved.featureSettings.forEach((setting) => {
        if (setting.component === "MYTOOL" && setting.enabled && RegExp(setting.allowRegex).exec(setting.nwieID)) {
          tempShowCommonToolsMovedEnabled = setting;
        }
      });
    }

    if (tempShowAnnouncementsWidget) {
      setShowAnnouncementsWidget(tempShowAnnouncementsWidget);
    } else if (tempShowCommonToolsMovedEnabled) {
      setShowCommonToolsMovedEnabled(tempShowCommonToolsMovedEnabled);
    } else {
      setShowCommonTools(true);
    }
  }, [isLoaded, arrayOfFeaturesEnabled]);

  return (
    <>
      {!isLoaded && <Announcements announcementsGrid={`${styles.commonTools} ${announcementsGrid}`} />}
      {isLoaded&&showAnnouncementsWidget && <Announcements announcementsGrid={announcementsGrid} />}
      {isLoaded&&showCommonToolsMovedEnabled && <GenericAnnouncementCard
        className={`${announcementsGrid} ${styles.GenericAnnouncementCard}`}
        title={showCommonToolsMovedEnabled.title}
        description={showCommonToolsMovedEnabled.description}
        icon={showCommonToolsMovedEnabled.icon}
      />}
      {isLoaded&&showCommonTools && <CommonToolsView
        commonToolsStyles={`${styles.commonTools} ${announcementsGrid}`}
        showEditingTools={false}
        defaultOrPreferredTools={toolsToDisplay}
      />}
    </>
  );
};

export default AnnouncementSpace;