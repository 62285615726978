import React, { type Dispatch, type SetStateAction, useContext, useEffect, useState } from "react";
import styles from "./Office365Image.module.scss";
import linkStyles from "../Link/Link.module.scss";
import GraphService from "../../../services/GraphService";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import { UpdateViewUserDetailNwie } from "../../../context/ActionTypes";
import * as MsalAuthService from "../../../services/MsalAuthService";
import { useNavigate } from "react-router-dom";

interface Props {
  openDropdown: boolean;
  setOpenDropdown: Dispatch<SetStateAction<boolean>>
}

const Office365Image: React.FC<Props> = ({ openDropdown, setOpenDropdown }) => {
  const navigate = useNavigate();
  const [appState, dispatch]: AdwContextType = useContext(AdwContext);
  const [profilePicture, setProfilePicture] = useState<string>("");
  const hasActiveMsalSession: boolean = MsalAuthService.hasActiveMsalSession();

  useEffect(() => {
    if (hasActiveMsalSession) {
      GraphService.getProfilePicture().then((image) => {
        setProfilePicture(image);
      });
    }
  }, [hasActiveMsalSession]);

  const openAssociateProfile = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const nwie: string = appState && appState.user && appState.user.nwieID ? appState.user.nwieID : "";
    if (nwie) {
      dispatch(UpdateViewUserDetailNwie(nwie));
      navigate("/employeeDetail");
    }
  };

  return profilePicture ? (
    <div className={`${linkStyles.link}`} id="YourAssociateDetails">
      <img
        src={profilePicture}
        className={styles.office365Images}
        onClick={openAssociateProfile}
        alt="Your associate details"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.code === 'Enter' ) {
            openAssociateProfile(e);
          }
        }}
        onFocus={() => {
          if (openDropdown) {
            setOpenDropdown(false)
          }
        }}
      />
    </div>
  ) : null;
};

export default Office365Image;
