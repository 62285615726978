import React, { useState, useEffect } from "react";
import styles from "./HeaderUserError.module.scss";
import AdwDateTimeService from "../../../services/AdwDateTimeService";

const insideMainLogo: string = require("../../../assets/images/InSide.png");

const HeaderUserError: React.FC = () => {
  const [isOnMobile, setIsOnMobile] = useState<boolean>(false);
  const [formattedDate,setFormattedDate]=useState<string>(null);
  const today: Date = new Date();
  const monthName: string = AdwDateTimeService.getMonthName(today.getMonth());
  const dayName: string = AdwDateTimeService.getDayName(today.getDay());
 

  const checkIfMobile = () => {
    if (window.innerWidth <= 767 || window.screen.width <= 767) {
      setIsOnMobile(true);
   }
    else {
      setIsOnMobile(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', checkIfMobile);
    checkIfMobile();
    setFormattedDate(`${dayName}, ${monthName} ${today.getDate()}, ${today.getFullYear()}`);
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    }
 });

  return (<>
    { (!isOnMobile ) &&
    <header className={styles.officeBarContainer} id="headerRowOne">
      <a className={styles.insideHeaderSkip}  title="skip to main content" href="#adw-app-container" >Skip to main content</a>
      <div className={styles.insideMainLogoWrapper}>
          <img
            src={insideMainLogo}
            className={styles.insideMainLogo}
            alt="inside logo"
          />
      </div>
      <div className={styles.dateMainWrapper}>
        <div className={styles.dateWrapper}>
          <span>{formattedDate}</span>
        </div>
      </div>
  </header>
    }
    </>
  );
};

export default HeaderUserError;
