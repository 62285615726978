import React, { useContext, useEffect } from "react";
import { SetHrAwsCounselor, SetHrAwsNurse } from "../../../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../../../context/AdwContext";
import AuthService from "../../../../../services/AuthService";
import CallupService from "../../../../../services/CallupService";
import HRConnectService from "../../../../../services/HRConnectService";
import AdwEmployee from "../../../../../types/CallupTypes/AdwEmployee";
import { AdwHrConnectSwitch } from "../../../../../types/HRConnectTypes/AdwHrConnectSwitch";
import ComingSoon from "../ComingSoon/ComingSoon";
import widgetStyles from "../HRConnectWidgets.module.scss";
import styles from "./AssociateWellbeingSafety.module.scss"

const AssociateWellBeingSafety: React.FC = () => { // NOSONAR

  const [appState, dispatch]: AdwContextType = useContext(AdwContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const [hasDataError, setHasDataError] = React.useState(false);
  const hasActiveSession: boolean = AuthService.hasActiveSession();
  const nwieID: string = (appState && appState.user && appState.user.nwieID);
  const switches: AdwHrConnectSwitch[] = (appState && appState.lists && appState.lists.hrConnectSwitches) || []

  useEffect(() => { // NOSONAR
    if (nwieID && hasActiveSession) {

      CallupService.getEmployeeByShortName(nwieID).then((user: AdwEmployee) => {
        let userMailDrop = user.mailDrop;
        //Maildrop Ex: "mailDrop": "MI-61-TEMP"
        let index = userMailDrop.indexOf("-");
        
        //check the format, use dash as qualifier
        if(index === 1){
          let tempChar = userMailDrop.substring(0,1);

          //check if numeric 
          if(!isNaN(parseFloat(tempChar))){

            //if numeric, add leading "0"
            userMailDrop = "0" + userMailDrop;

            //increment because we pad leading "0"
            index++; 
          }
        }

        const state: string = user && userMailDrop.substring(0,index)+','+user.state;
        const homeState: string = user && user.city+'_'+user.state;
        
        setIsLoading(true);
            
        // Set to null to make shouldFetch return false above and prevent future calls to API
        dispatch(SetHrAwsNurse(null, null, null));
        dispatch(SetHrAwsCounselor(null, null));
        
        // Retrieve both sets of data before displaying results
        if(user.state === ''){
          setHasDataError(true)
        }else{
          Promise.all([
            //nurse
            HRConnectService.getAWSNurse(nwieID, state)
            .then(response => {
              dispatch(SetHrAwsNurse(response.nurse.nurseName, response.nurse.nursePhone, response.nurse.nurseEmail));
              if (response.hasError) {
                setHasDataError(true)
              }
              setIsLoading(false);
            }),
          
            //counselor
            HRConnectService.getAWSCounselor(nwieID, homeState)
            .then(response => {
              dispatch(SetHrAwsCounselor(response.counselor.counselorName, response.counselor.counselorPhone));
              if (response.hasError) {
                setHasDataError(true)
              }
              setIsLoading(false);
            })
        ]).then(() => {
          setIsLoading(false)
        })
        }
      });
      
    }
  }, [nwieID, hasActiveSession, dispatch])

  useEffect(() => {
    if (nwieID && switches && switches.length > 0) {
      switches.every((switchOption => {
        if (switchOption.widget === 'AssociateWellbeingSafety' && switchOption.displayWidget && nwieID.match(switchOption.allowRegex)) {
          setIsVisible(true);
          return false;
        }
        return true;
      }))
    }
  }, [nwieID, switches])

  const associateWellbeingSafety: any = isLoading ? null : appState && appState.hrConnect && (appState.hrConnect.associateWellbeingSafety || null);
  
  return (
    <div className={widgetStyles.widget}>
      <div className={widgetStyles.header}><h3>Associate Wellbeing & Safety</h3></div>
      {
      !isVisible || hasDataError ?
        <ComingSoon unavailable={hasDataError} /> 
        :
        <>
        {
        associateWellbeingSafety &&
          <>
          <div className={widgetStyles.section}>
            <div className={styles.subSectionHeader}>My Nationwide Nurse</div>
            <div className={`${styles.subSectionContents} ${styles.showRow}`}>
              <span className="">{associateWellbeingSafety.nurseName} : {associateWellbeingSafety.nursePhone}</span>
            </div>
            <div className={`col-sm-12 ${styles.associateWellbeingSafetyRow}`}>
              <span className="">{associateWellbeingSafety.nurseEmail}</span>
            </div>
          </div>
          <div className={widgetStyles.divider} />
          <div className={widgetStyles.section}>
            <div className={widgetStyles.subSectionHeader}>My AAP Counselor</div>
            <div className={styles.subSectionContents}>
              <span className="">{associateWellbeingSafety.counselorName} : {associateWellbeingSafety.counselorPhone}</span>
            </div>
          </div>
          </>
        }
        </>
      }
    <div className={widgetStyles.buttonRow}>
        <a
          href="https://onyourside.sharepoint.com/sites/AssociateWellbeingandSafety"
          target="_blank"
          rel="noopener noreferrer"
          className={widgetStyles.associateButtonLink}
        >
          Visit AWS
        </a>
      </div>
    </div>      
  );
}

export default AssociateWellBeingSafety;