import "core-js/features/array/find";
import { MegaMenuCategory } from "../types/MegaMenuTypes/MegaMenuCategory";
import AdwMegaMenuItem from "../types/MegaMenuTypes/AdwMegaMenuItem";
import { MegaMenuSubCategory } from "../types/MegaMenuTypes/MegaMenuSubCategory";

class AdwMegaMenuService {

  public static buildMenuStructure = (adwMenuItems: AdwMegaMenuItem[]): MegaMenuCategory[] => {
    if (!adwMenuItems || !adwMenuItems.length){ 
      return []
    };
    let wronglyOrderedItems: AdwMegaMenuItem[] = [];

    const megaMenu: MegaMenuCategory[] = adwMenuItems.reduce((megaMenu, item) => {
      // access specific category via categoryOrder since that is the order that they are in the array or return an empty object
      if(!item) {
        return megaMenu;
      }
      const category: MegaMenuCategory = megaMenu[item.categoryOrder] || {
        categoryOrder: item.categoryOrder,
        categoryTitle: item.category,
        subCategories: []
      };
      // get subcategory via subCategoryOrder or set to empty object if it doesn't exist
      const subCategory: MegaMenuSubCategory = category.subCategories[item.subCategoryOrder] || {
        categoryTitle: item.category,
        subCategoryTitle:item.subCategory,
        subCategoryOrder: item.subCategoryOrder,
        helpful: item.special,
        subCategoryItems: []
      } as MegaMenuSubCategory;

      
      AdwMegaMenuService.addItemToSubCategory(item, subCategory, megaMenu, wronglyOrderedItems);

      // add the subcategory to the subcategories array using the subcategoryorder as the index
      category.subCategories[subCategory.subCategoryOrder] = subCategory;

      // add the category to the MegaMenuCategory array using the categoryOrder as the index
      megaMenu[category.categoryOrder] = category;

      return megaMenu;
    }, []);

    AdwMegaMenuService.concatWronglyOrderedLinkToSubcategory(megaMenu, wronglyOrderedItems);

    return megaMenu;
  };

  private static concatWronglyOrderedLinkToSubcategory = (megaMenu: MegaMenuCategory[], wronglyOrderedItems: AdwMegaMenuItem[]): void => {
    if (wronglyOrderedItems.length > 0) {
      wronglyOrderedItems.forEach(item => {
        const subCategory: MegaMenuSubCategory = AdwMegaMenuService.getCorrectSubCategory(item, megaMenu);
        subCategory.subCategoryItems.push(item);
      });
    }
  };

  private static addItemToSubCategory = (
    item: AdwMegaMenuItem,
    subCategory: MegaMenuSubCategory,
    megaMenu: MegaMenuCategory[],
    wronglyOrderedItems: AdwMegaMenuItem[],
  ): void => {
    if (AdwMegaMenuService.isCorrectSubCategory(item, subCategory)) {
        AdwMegaMenuService.checkIfItemExists(item, subCategory, wronglyOrderedItems);
    } else {
      const correctSubCategory: MegaMenuSubCategory = AdwMegaMenuService.getCorrectSubCategory(item, megaMenu);
      AdwMegaMenuService.checkIfItemExists(item, correctSubCategory, wronglyOrderedItems);
    }
  };

  private static checkIfItemExists = (item: AdwMegaMenuItem, subCategory: MegaMenuSubCategory, wronglyOrderedItems: AdwMegaMenuItem[]): void => {
    if (!item || !subCategory || !wronglyOrderedItems || !subCategory.subCategoryItems) {
      return 
    }
    if (!subCategory.subCategoryItems[item.linkOrder]) {
      subCategory.subCategoryItems[item.linkOrder] = item;
    } else {
      wronglyOrderedItems.push(item);
    }
  };

  private static isCorrectSubCategory = (item: AdwMegaMenuItem, subCategory: MegaMenuSubCategory): boolean => {
    return item && subCategory && item.subCategory === subCategory.subCategoryTitle &&
      item.subCategoryOrder === subCategory.subCategoryOrder &&
      item.category === subCategory.categoryTitle;
  };

  private static getCorrectSubCategory = (item: AdwMegaMenuItem, megaMenu: MegaMenuCategory[]): MegaMenuSubCategory => {
    const correctCategory: MegaMenuCategory = AdwMegaMenuService.getCorrectCategory(item, megaMenu);
    if (!item || !megaMenu) {
      return { subCategoryItems: [] } as MegaMenuSubCategory;
    };

    return correctCategory.subCategories
      .filter(subCategory => subCategory !== null && subCategory !== undefined) // filter out empty array elements
      .find(subCategory => subCategory.subCategoryTitle === item.subCategory) || { subCategoryItems: [] } as MegaMenuSubCategory;
  };

  private static getCorrectCategory = (item: AdwMegaMenuItem, megaMenu: MegaMenuCategory[]): MegaMenuCategory => {
    if (!item || !megaMenu) {
      return { subCategories: [] } as MegaMenuCategory
    };

    return megaMenu
      .filter(category => category !== null && category !== undefined) // filter out empty array elements
      .find(category => category.categoryTitle === item.category) || { subCategories: [] } as MegaMenuCategory
  };
}

export default AdwMegaMenuService;
