import React from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';

import OrgChartListContentsSkeleton from '../OrgChartListContents/OrgChartListContentsSkeleton';

import styles from "./OrgChart.module.scss";

const OrgChartSkeleton: React.FC = () => {
  return (
    <div className={styles.orgChart}>
      <div
        className={classNames(styles.orgTitleWrapper, styles.orgTitleWrapperSkeleton)}
      >
        <div className={styles.title}>
          <Skeleton width={155} height={18} />
        </div>
      </div>

      <div className={styles.viewLinksContainer}>
        <div className={styles.viewWorkGroupLink}>
          <Skeleton width={155} height={18} baseColor="#ffffff"/>
        </div>

        <OrgChartListContentsSkeleton />

      </div>

    </div>
  );
};

export default OrgChartSkeleton;