import React, { useEffect, useState } from "react";
import AdwAtoZView from "../../../types/AtoZViewTypes/AdwAtoZView";
import styles from "./Letter.module.scss";

export interface ILetterProps {
    activeFilter: string;
    letter: string;
    activeLetter: boolean;
    sites: AdwAtoZView[];
    handleLetterClick: Function
}

const Letter: React.FC<ILetterProps> = (props: ILetterProps) => {
    const { activeFilter, letter, sites, handleLetterClick, activeLetter } = props;
    const [hasSites, setHasSites] = useState(false);

    useEffect(() => {
        if (sites != null) {
            sites.forEach((element) => {
                if (activeFilter === "All") {
                    if (element.SiteName.toLowerCase().indexOf(letter) === 0) {
                        setHasSites(true);
                    }
                } else {
                    setHasSites(false);
                }
            })
        }
    }, [sites, activeFilter, letter])

    const handleClick = (letter) => {
        handleLetterClick(letter)
    }

    return (
        <>
                {hasSites ?
                    <a className={styles.letter} aria-disabled="false" href="#" onClick={() => hasSites && handleClick(letter)}><span className={activeLetter ? styles.activeLetter : styles.enabled} >{letter}</span></a>
                    :
                    <a className={styles.letter} aria-disabled="true" href="#" onClick={() => { return; }} style={{ cursor: 'default' }}><span className={styles.disabled}>{letter}</span></a>
                }
        </>
    );
}

export default Letter;
