import React, { useContext, useRef, useState, useEffect } from "react";
import { UpdateViewUserDetailNwie } from "../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import "./NewSearch.css";
import PeopleUtils from "../../../utils/PeopleUtils";
import AuthService from "../../../services/AuthService";
import ErrorReportingService from "../../../services/ErrorReportingService";
import { toInteger } from "lodash";
import { useNavigate } from "react-router-dom";
import defaultStyles from '../../layouts/DefaultLayout/DefaultLayout.module.scss';
const magnifyingGlass: string = require("../../../assets/images/MagnifyingGlass_searchBar_icon.svg").default;
const peopleResultDefault: string = require("../../../assets/images/PeopleSearch_Avatar.png");
const defaultResultImage: string = require("../../../assets/images/HTML-page-icon@2x.png");
const videoResultImage: string = require("../../../assets/images/videoResultImage.png")
const mail: string = require("../../../assets/images/Mail.svg").default;

function numberOfPeopleResults() {
	if (window.innerWidth > 1023) {
		if (window.innerWidth > 1095 && window.innerWidth < 1525) {
			return 3;
		} else {
			return 4;
		}
	} else {
		return 2;
	}
}


export interface IAssociateClickObj {
	userId: string;
}

const NewSearch: React.FC = () => {
	document.title = "InSide - Search Results";
	const navigate = useNavigate();
	const [appState, dispatch] = useContext<AdwContextType>(AdwContext);
	const [userName, setUserName] = useState(
		appState && appState.user && appState.user.nwieID
	);

	useEffect(() => {
		const searchBox: HTMLElement = document.querySelector('.' + defaultStyles.searchBoxDefault);
		const app: HTMLElement = document.querySelector('.' + defaultStyles.App);
		let backToTopButtonElement: any = document.getElementById("search-back-to-top");
		if (backToTopButtonElement) {
			backToTopButtonElement.addEventListener('click', () => {
				window.scrollTo({
					top: 0,
					behavior: "auto",
					/* you can also use 'auto' behaviour
						 in place of 'smooth' */
				});
			})
		}

		if (searchBox) {
			searchBox.classList.add(defaultStyles.searchPageFixed);

			app.style.marginTop = searchBox.clientHeight + 'px';
		}

		return () => {
			if (searchBox) {
				searchBox.classList.remove(defaultStyles.searchPageFixed);

				app.style.marginTop = '30px';
			}
		}
	}, [])

	useEffect(() => {
		const interval = setInterval(() => {
			// @ts-ignore
			if (window.Mindbreeze) {
				scriptLoaded();
				clearInterval(interval);
			}
		}, 1000);
		return () => {
			window.addEventListener("resize", handleResize)
		}
	}, [userName]);

	const appRootElRef = useRef();

	const template = {
		__html: `
		<link href="https://nationwide.mindbreeze.com/search/apps/css/v2/adapted.css" rel="stylesheet">
			<main data-template="view" data-count="4" data-queryspelling-upto-results="50" data-constraint='ALL NOT (fqcategory:"BestBets:Default") AND NOT (fqcategory:"DataIntegration:EmployeeCSVData")'>
			<span id="my-username" style="display:none!important"></span>
			<div class="mb-component-container container nw-container" id="mb-component-container">
				<div class="row-fluid">
					<div class="mb-query span9">
					<div class="mb-progress-bar" style="max-width: 1200px !important; margin: auto !important" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
						<form id="search-form" class="search-field" data-template="searchform" data-requires-user-input="true">
							<input data-template="suggest" data-property-constraint='NOT (fqcategory:"Microsoft SharePoint Online:Enterprise")' data-placeholder="Search People and Content" class="mb-query only-desktop" name="query" type="search" data-aria-label="search" data-source-id-pattern="document_property"/>
							<button onClick="window['newrelic'].addPageAction('search', {'query':this.parentElement.getElementsByTagName('input')[0].value})" class="btn btn-link mb-no-print only-desktop" type="submit" tabindex="-1"><img src="${magnifyingGlass}" alt="search"/></button>
						</form>
						
						<div class="row-fluid">
							<div class="span9" id="mb-search-navigation">
								<div class="navbar mb-role navbar-sourcetabs">
									<div class="navbar-inner no-wrap custom-tabs">
										<div class="container">
											<ul class="nav mb-role sourceTabs" data-template="tabs" data-model="tabs" data-datasourcetabs="true">
												<script type="text/x-mustache-template" data-tabconfig="true" data-name="All" data-constraint='NOT category:"Microsoft Graph"'></script>
												<script type="text/x-mustache-template" data-tabconfig="true" data-name="People" data-constraint='category:"Microsoft Graph"'></script>
												<script type="text/x-mustache-template" data-tabconfig="true" data-name="News" data-constraint='site:"InSide-News"'></script>
												<script type="text/x-mustache-template" data-tabconfig="true" data-name="Learning" data-constraint='site:"FOWC"'></script>
												<script type="text/x-mustache-template" data-tabconfig="true" data-name="Policies" data-constraint='site:"NationwidePolicyGuide"'></script>
												<script type="text/x-mustache-template" data-tag-name="a" data-attr-role="tab" data-attr-class="action tab-{{name}}" data-attr-disable="{{#disabled?}}true{{/disabled?}}{{^disabled?}}false{{/disabled?}}" data-attr-aria-selected="{{#selected?}}true{{/selected?}}{{^selected?}}false{{/selected?}}" data-attr-data-action-object="{ &quot;changeView&quot;: { &quot;constraints.view&quot;: {{^constraint?}}null{{/constraint?}}{{{constraint}}} }, &quot;handleTabSelect&quot;: { &quot;name&quot;: &quot;{{name}}&quot; } }" aria-hidden="true">
													{{#editable?}}
														<span data-event-mask="dblclick|taphold" data-edit data-action-object="{&quot;inplaceEdit&quot;: { &quot;deletable&quot;: true }}">
													{{/editable?}}
													{{name}}
													{{#editable?}}
														</span>
													{{/editable?}}
												</script>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="newsearch-main-div">
			<div id="results-container" class="container mb-role mb-stack-results-container" id="main" data-aria-describedby="results-description">
				<div class="mb-stack" data-template="stack" data-model="stack">
					<div class="mb-stack-content mb-stack-results" id="filter-column-nav">
						<div class="row-fluid">
							<div id="filters-column" class="span2 mb-filters">
							<input type="hidden" id="searchKey" value="true">
							<div id="search-homepage-id" aria-live="assertive">
								<script type="text/x-mustache-template" data-attr-role="status" data-attr-class="{{^estimated_count?}}hide{{/estimated_count?}}" data-attr-tabindex="-1" aria-hidden="true">
									{{^status_messages.no_results?}}
										<h2 id="search-homepage-id-h2">
											{{#estimated_count?}}
											{{#is_about}}{{i18n.client_resultset_About}}{{/is_about}}
											{{estimated_count}}
											{{^singular}}{{i18n.editor_result_title}}{{/singular}}
											{{#singular}}{{i18n.editor_singular_result_title}}{{/singular}}
											{{/estimated_count?}}
										</h2>
									{{/status_messages.no_results?}}
								</script>
							</div>
							<div class="feedbackClick">
								<div data-template="feedbackbutton" class="mb-feedbackbutton" role="button" tabindex="0">Feedback</div>
                            </div>
						<div data-template="savedsearches" data-serversaved="false" class="mb-saved-searches" aria-controls="main" role="region" aria-labelledby="savedsearches-title-view164"></div>

						<br />
						<div id="sources-div" data-template="filteredfacet" data-name="source_filter" data-title="Sources" data-collapsible="true" data-user-input="disabled"  style="display: block;">
							<script type="text/x-mustache-template" data-tag-name="span">
								<span id="sources" class="{{#showAddAnd?}}mb-add-and-available{{/showAddAnd?}} mb-tooltip">{{{html}}}{{#showAddAnd?}} <a class="action mb-add-and"><i class="icon-plus-sign"></i></a>{{/showAddAnd?}}</span>
								<span id="sources" class="pull-right" title="{{count}}">  {{#count?}}&nbsp;{{count}}{{/count?}}{{#excluded}}&ndash;{{/excluded}}</span>
							</script>
						</div>

						<div id="date-parent">
								<div data-template="filteredfacet" data-name="mes:date" data-title="Date" data-collapsible="true" data-user-input-format="MM/DD/YYYY" style="display: block;" id="date-filter">
									<script type="text/x-mustache-template" data-tag-name="span">
										<span class="{{#showAddAnd?}}mb-add-and-available{{/showAddAnd?}} mb-tooltip">{{{html}}}{{#showAddAnd?}} <a class="action mb-add-and"><i class="icon-plus-sign"></i></a>{{/showAddAnd?}}</span>
										<span class="pull-right" title="{{count}}">{{#count?}}&nbsp;{{count}}{{/count?}}{{#excluded}}&ndash;{{/excluded}}</span>
									</script>
								</div>
						</div>
						<div data-template="filteredfacet" data-name="File Type" data-title="File Type" data-collapsible="true" data-user-input="disabled" style="display: block;">
							<script type="text/x-mustache-template" data-tag-name="span">
								<span class="{{#showAddAnd?}}mb-add-and-available{{/showAddAnd?}} mb-tooltip">{{{html}}}{{#showAddAnd?}} <a class="action mb-add-and"><i class="icon-plus-sign"></i></a>{{/showAddAnd?}}</span>
								<span class="pull-right" title="{{count}}">{{#count?}}&nbsp;{{count}}{{/count?}}{{#excluded}}&ndash;{{/excluded}}</span>
							</script>
						</div>
						<div data-template="filteredfacet" data-name="kb_category.display_value" data-collapsible="true" data-user-input="disabled" style="display: block;">
							<script type="text/x-mustache-template" data-tag-name="span">
								<span class="{{#showAddAnd?}}mb-add-and-available{{/showAddAnd?}} mb-tooltip">{{{html}}}{{#showAddAnd?}} <a class="action mb-add-and"><i class="icon-plus-sign"></i></a>{{/showAddAnd?}}</span>
								<span class="pull-right" title="{{count}}">{{#count?}}&nbsp;{{count}}{{/count?}}{{#excluded}}&ndash;{{/excluded}}</span>
							</script>
						</div>
						<div data-template="filteredfacet" data-name="datasource/modificationDate" data-title="Updated On" data-collapsible="true" data-user-input-format="MM/DD/YYYY" style="display: block;">
							<script type="text/x-mustache-template" data-tag-name="span">
								<span class="{{#showAddAnd?}}mb-add-and-available{{/showAddAnd?}} mb-tooltip">{{{html}}}{{#showAddAnd?}} <a class="action mb-add-and"><i class="icon-plus-sign"></i></a>{{/showAddAnd?}}</span>
								<span class="pull-right" title="{{count}}">{{#count?}}&nbsp;{{count}}{{/count?}}{{#excluded}}&ndash;{{/excluded}}</span>
							</script>
						</div>
						<div data-template="filteredfacet" data-name="mgru_department" data-title="Department" data-collapsible="true" style="display: block;">
							<script type="text/x-mustache-template" data-tag-name="span">
									<span class="{{#showAddAnd?}}mb-add-and-available{{/showAddAnd?}} mb-tooltip">{{{html}}}{{#showAddAnd?}} <a class="action mb-add-and"><i class="icon-plus-sign"></i></a>{{/showAddAnd?}}</span>
									<span class="pull-right" title="{{count}}">{{#count?}}&nbsp;{{count}}{{/count?}}{{#excluded}}&ndash;{{/excluded}}</span>
							</script>
						</div>
						<div data-template="filteredfacet" data-name="mgru_jobTitle" data-title="Job Title" data-collapsible="true" style="display: block;">
							<script type="text/x-mustache-template" data-tag-name="span">
								<span class="{{#showAddAnd?}}mb-add-and-available{{/showAddAnd?}} mb-tooltip">{{{html}}}{{#showAddAnd?}} <a class="action mb-add-and"><i class="icon-plus-sign"></i></a>{{/showAddAnd?}}</span>
								<span class="pull-right" title="{{count}}">{{#count?}}&nbsp;{{count}}{{/count?}}{{#excluded}}&ndash;{{/excluded}}</span>
							</script>
						</div>
						<div data-template="filteredfacet" data-name="mb_city" data-title="City" data-collapsible="true" style="display: block;">
							<script type="text/x-mustache-template" data-tag-name="span">
									<span class="{{#showAddAnd?}}mb-add-and-available{{/showAddAnd?}} mb-tooltip">{{{html}}}{{#showAddAnd?}} <a class="action mb-add-and"><i class="icon-plus-sign"></i></a>{{/showAddAnd?}}</span>
									<span class="pull-right" title="{{count}}">{{#count?}}&nbsp;{{count}}{{/count?}}{{#excluded}}&ndash;{{/excluded}}</span>
							</script>
						</div>
						<div data-template="filteredfacet" data-name="mb_state" data-title="State" data-collapsible="true" style="display: block;">
							<script type="text/x-mustache-template" data-tag-name="span">
									<span class="{{#showAddAnd?}}mb-add-and-available{{/showAddAnd?}} mb-tooltip">{{{html}}}{{#showAddAnd?}} <a class="action mb-add-and"><i class="icon-plus-sign"></i></a>{{/showAddAnd?}}</span>
									<span class="pull-right" title="{{count}}">{{#count?}}&nbsp;{{count}}{{/count?}}{{#excluded}}&ndash;{{/excluded}}</span>
							</script>
						</div>
					</div>
					<!-- ALL "People result" -->
					<div class="span10" id="search-results-container">
						<div class="mb-component-container container-fluid" id="people-search-results-container" style="display: none">
							<div id="results-title" class="row-fluid">
								<div class="content-title-left">People results</div>
								<div class="content-title-right">
									<a data-action-object="{ &quot;selectPeopleTab&quot;: {} }" class="people-search"  href="javascript:void(0);">Show more people results</a>
								</div>
							</div>
							<div style="width:100%"><hr class="title-hr"/></div>
							<div data-template="search" data-name="People-Search" data-template-refid="show-people" data-enabled-views="All"></div>

							<!-- HR -->
							<div class="content-title-advanced" style="display:none">
								<a data-action-object="{ &quot;selectPeopleTab&quot;: {} }" class="people-search"  href="javascript:void(0);">Show more people results</a>
							</div>
						</div>
						<div class="mb-component-container container-fluid" id="all-results-container" style="display: none">
							<div class="results-title" class="row-fluid" id="contentDiv">
									<span class="content-title">
										Content results
									</span>
									<div class="offset7"></div>
									<div style="width:100%"><hr class="title-hr"/></div>
							</div>
								<!-- future cleanup item
								<div id="advanced-people-search-title-container" class="row-fluid">
										<h3 id="all-results-header" class="content-title">
											Search results
										</h3>
										<div id="advanced-people-search-title" class="offset7">
											<a href="https://inside.nwie.net/inside-web/advancedCallupSearch/index">
												<p class="people-search">Advanced People Search</p>
											</a>
										</div>
									</div-->
							
												<div class="no-results-view" data-template="searchinfo"></div>
												<div data-template="results" data-enable-voting="true" data-appendonscroll="true" class="results-more-people" >
													<script type="text/x-mustache-template" data-attr-role="group" data-attr-aria-labelledby="result_{{id}}" data-class-name="mb-clickable-phone" data-attr-data-action-object="{ &quot;toggleOpen&quot;: { &quot;enabledSelector&quot;: &quot;.visible-phone&quot; }}">
													{{#categoryinstance.Graph?}}
													<button class="span3 box-width people" data-action-object='{"handleAssociateClick": { "userId": "{{mgru_onPremisesSamAccountName}}" }}'>
														<div class="media center people">
															<!-- <div class="row"> -->
																<div class="column-left-more-people">
																	<span class="media-object-icon" aira-hiddent="true">
																		{{#mes:thumbnail?}}
																			<img src="{{mes:thumbnail.href}}">
																		{{/mes:thumbnail?}}
																		{{^mes:thumbnail?}}
																			<img id="people-default-photo" src="${peopleResultDefault}" width="70px">
																		{{/mes:thumbnail?}}
																	</span>
																</div>
																<div class="column-right">
																	<div id="search-preferred-name">{{{preferred_firstname}}} {{{preferred_lastname}}}</div>
																	<div id="search-job-title">{{{mgru_jobTitle}}}</div>
																	<div id="search-employee-type" style="display:none">{{{mgru_employeeType}}}</div>
																	{{#mgru_businessPhones?}}
																		<div class="us-phone">{{{mgru_businessPhones}}}</div>
																	{{/mgru_businessPhones?}}
																	<div><p></p></div>
																	<div id="search-login-id">{{{mgru_onPremisesSamAccountName}}}
																	{{#mgru_mail?}}
																	<a tabindex="0" data-action-object='{"handleEmailClick": { "email": "{{mgru_mail}}" }}'><img src="${mail}" alt="email this person clickable link"/></a>
																	{{/mgru_mail?}}
																	</div>
																	<!-- <a data-action-object='{"handleEmailClick": { "email": "{{mgru_mail}}" }}'>
																		 <small id="wrap">{{{mgru_mail}}}</small>?
																	 </a> -->
																</div>
															<!-- </div> row-->
														</div>
													</button>
												{{/categoryinstance.Graph?}}
												{{^categoryinstance.Graph?}}
												<div class="media">
													<span class="pull-left media-object {{^mes:thumbnail}}media-object-icon{{/mes:thumbnail}}" aria-hidden="true">
															{{#actions.data[0].value.href?}}<a href="{{actions.data[0].value.href}}" data-disabled-selector=".visible-phone" tabindex="-1">{{/actions.data[0].value.href?}}
															{{#mes:thumbnail?}}
																{{#extension.mp4?}}
																	<img id="video-result-img" src="${videoResultImage}"">
																{{/extension.mp4?}} 
																{{^extension.mp4?}}
																	<img src="{{mes:thumbnail.href}}">
																{{/extension.mp4?}}
															{{/mes:thumbnail?}}
															{{^mes:thumbnail?}}
																{{#extension.mp4?}}
																	<img id="video-result-img" src="${videoResultImage}"">
																{{/extension.mp4?}} 
																{{^extension.mp4?}}
																	<img id="result-img" src="${defaultResultImage}" width="100px">
																{{/extension.mp4?}}
															{{/mes:thumbnail?}}
															
															{{#actions.data[0].value.href?}}</a>{{/actions.data[0].value.href?}}
														</span>
														<div class="media-body">
															<h3 class="{{categoryinstance}}-title media-heading" id="result_{{id}}">
																{{#actions.data[0].value.href?}}<a href="{{actions.data[0].value.href}}" data-disabled-selector=".visible-phone">{{/actions.data[0].value.href?}}
																	{{#sp_Title?}}
																		{{{sp_Title}}}
																	{{/sp_Title?}}
																	{{^sp_Title?}}
																		{{{title}}}
																	{{/sp_Title?}}
																{{#actions.data[0].value.href?}}</a>{{/actions.data[0].value.href?}}
															</h3>

															{{#categoryinstance.bestbets?}}
																<ul class="mb-actions mb-separated hidden-phone mb-visible-open mb-no-print">															
																	<li class="no-wrap res-source-{{results_source_tag}}">
																		{{{results_source_tag}}}
																	</li>
																	{{#actions.data[0]}} <li class="nowrap">{{{html}}}</li>{{/actions.data[0]}}
																	{{#actions.data[1]}} <li class="nowrap">{{{html}}}</li>{{/actions.data[1]}}
																	{{#actions.data[2]}} <li class="nowrap">{{{html}}}</li>{{/actions.data[2]}}
																	<li class="voting-confirmation">Thanks for your feedback!</li>
																</ul>
															{{/categoryinstance.bestbets?}}
															{{^categoryinstance.bestbets?}}
																<ul class="mb-actions mb-separated hidden-phone mb-visible-open mb-no-print">															
																	<li class="no-wrap res-source-{{results_source_tag}}">
																		{{{results_source_tag}}}
																	</li>

																	{{#categoryinstance.Enterprise?}}
																		{{#actions.data[0]}} <li class="nowrap">{{{html}}}</li>{{/actions.data[0]}}
																		{{#actions.data[1]}} <li class="nowrap">{{{html}}}</li>{{/actions.data[1]}}
																		{{#actions.data[3]}} <li class="nowrap">{{{html}}}</li>{{/actions.data[3]}}
																		{{#actions.data[4]}} <li class="nowrap">{{{html}}}</li>{{/actions.data[4]}}
																	{{/categoryinstance.Enterprise?}}
																	{{^categoryinstance.Enterprise?}}
																		{{#categoryinstance.Inside?}}
																			{{#actions.data[0]}} <li class="nowrap">{{{html}}}</li>{{/actions.data[0]}}
																			{{#actions.data[1]}} <li class="nowrap">{{{html}}}</li>{{/actions.data[1]}}
																			{{#actions.data[3]}} <li class="nowrap">{{{html}}}</li>{{/actions.data[3]}}
																			{{#actions.data[4]}} <li class="nowrap">{{{html}}}</li>{{/actions.data[4]}}
																		{{/categoryinstance.Inside?}}
																		{{^categoryinstance.Inside?}}
																			{{#categoryinstance.ServiceNow?}}
																				{{#actions.data[0]}} <li class="nowrap">{{{html}}}</li>{{/actions.data[0]}}
																				{{#actions.data[2]}} <li class="nowrap">{{{html}}}</li>{{/actions.data[2]}}
																				{{#actions.data[3]}} <li class="nowrap">{{{html}}}</li>{{/actions.data[3]}}
																			{{/categoryinstance.ServiceNow?}}
																		{{/categoryinstance.Inside?}}
																	{{/categoryinstance.Enterprise?}}

																	<li class="voting-confirmation">Thanks for your feedback!</li>
																</ul>															
															{{/categoryinstance.bestbets?}}

															<dl class="mb-metadata mb-comma-separated-values mb-horizontal mb-separated hidden-phone mb-visible-open mb-small">
																<dt class="mb-acc">{{i18n.date}}</dt><dd>{{{mes:date}}}</dd><dd id="result-url">{{url}}</dd>

																{{#categoryinstance.ServiceNow?}}
																	{{#mes:summary}}
																		{{^name['Created by']?}}
																			{{^name['Updated by']?}}
																				<dt>{{name}}</dt>{{#data}}<dd>{{#actions?}}
																				<span class="mb-dropdown-toggle">
																				<a data-action-object="{&quot;openMenu&quot;: {&quot;propertyId&quot;: &quot;{{property:id}}&quot;, &quot;position&quot;: {{data:position}} }}" class="nowrap">
																				{{/actions?}}
																					{{{html}}}
																				{{#actions?}}
																				<span class="caret"></span></a></span> {{/actions?}}</span></dd>{{/data}}
																			{{/name['Updated by']?}}
																		{{/name['Created by']?}}
																	{{/mes:summary}}
																{{/categoryinstance.ServiceNow?}}
																
															</dl>

															{{#content}}
																<p class="mb-content">{{{.}}}</p>

															{{/content}}

															{{#categoryinstance.bestbets?}}

                                                                {{#Description.data}}
                                                                    <p class="mb-content">{{{html}}}</p>
                                                                {{/Description.data}}			
                                                            {{/categoryinstance.bestbets?}}
															{{#mes:nested.data?}}
															<ul class="mb-nested">
																{{#mes:nested}}
																<div class="media mb-nested" data-action-object="{ &quot;toggleOpen&quot;: { &quot;enabledSelector&quot;: &quot;.visible-phone&quot; }}">
																	{{#actions.data[0].value.href?}}
																	<a href="{{actions.data[0].value.href}}" data-disabled-selector=".visible-phone">
																	{{/actions.data[0].value.href?}}
																	<b>{{{title}}}</b>
																	{{#actions.data[0].value.href?}}
																	</a>
																	{{/actions.data[0].value.href?}}
																	<ul class="mb-actions mb-separated hidden-phone mb-visible-submenu-open mb-no-print">
																	{{#actions.data}} <li class="nowrap"><small>{{{html}}}</small></li>{{/actions.data}}
																	</ul>

																	<dl class="mb-comma-separated-values mb-separated mb-summarized-description-list mb-small">
																		<dd>{{{mes:date}}}</dd>
																	</dl>

																</div>
																{{/mes:nested}}
															</ul>
															{{/mes:nested.data?}}
															<span class="clearfix"></span>
														</div>
														<div style="display:none">
                                                            {{#is_section?}}{{/is_section?}}
                                                            {{#sp_ParentList_Name?}}{{/sp_ParentList_Name?}}
															{{#sp_Id?}}{{/sp_Id?}}
															{{#mb_knowledge_base?}}{{/mb_knowledge_base?}}
															{{#['number.value']?}}{{/['number.value']?}}
                                                        </div>
														</div>
														{{/categoryinstance.Graph?}}
													</script>
												</div>
											</div>
										
							
							</div>
						</div>
					</div>
				</div>
			</div>
			<div data-template="preview" id="mb_preview"></div>
			</div> 
			<div data-mb-exclude="true" style="display:none">
				<div id="show-people">
						<div class="no-people-view" data-template="searchinfo"></div>
						<script type="text/x-mustache-template">
							{{#alternatives.query_spelling.entries[0]?}}
								<p class="pDidYouMean">
									{{{i18n.client_did_you_mean}}}:
									<a href="#" class="action" data-action-object="{&quot;setDidYouMean&quot;: {&quot;query&quot;: {{alternatives.query_spelling.entries[0].query_expr}} }}">
										{{{alternatives.query_spelling.entries[0].html}}}
									</a>
								</p>
							{{/alternatives.query_spelling.entries[0]?}}
						</script>
					<div data-template="view" data-count="${numberOfPeopleResults()}" data-constraint='category:"Microsoft Graph"' data-queryspelling-upto-results="2">
						<div data-template="results" class="row-fluid results-more-people">
							<script type="text/x-mustache-template">
							<div id="people-container">
							<button class="span3 box-width card" data-action-object='{"handleAssociateClick": { "userId": "{{mgru_onPremisesSamAccountName}}" }}'>
								<div class="media center all">
										<!-- <div class="row"> -->
											<div class="column-left">
													<span class="media-object-icon" aira-hiddent="true">
														{{#mes:thumbnail?}}
															<img src="{{mes:thumbnail.href}}">
														{{/mes:thumbnail?}}
														{{^mes:thumbnail?}}
															<img id="people-default-photo" src="${peopleResultDefault}" width="70px">
														{{/mes:thumbnail?}}
													</span>
												</div>
												<div class="column-right">
													<div id="search-preferred-name">{{{preferred_firstname}}} {{{preferred_lastname}}}</div>
													<div id="search-job-title">{{{mgru_jobTitle}}}</div>
													<div id="search-employee-type" style="display:none">{{{mgru_employeeType}}}</div>																					
													<!-- {{#mgru_businessPhones?}}
													 	<div class="us-phone">{{{mgru_businessPhones}}}</div>
													 {{/mgru_businessPhones?}}
													-->
													<div><p></p></div>
													<div id="search-login-id">{{{mgru_onPremisesSamAccountName}}}
													{{#mgru_mail?}}
													<a tabindex="0" data-action-object='{"handleEmailClick": { "email": "{{mgru_mail}}" }}'><img src="${mail}" alt="email this person clickable link"/></a>
													{{/mgru_mail?}}
													</div>
													<!-- <a data-action-object='{"handleEmailClick": { "email": "{{mgru_mail}}" }}'>
														 <small id="wrap">{{{mgru_mail}}}</small>?
													 </a> -->
												</div>
											</div>
										<!-- </div> -->
										</div>
								</div>
							</button>
						</script>
						</div>
					</div>
				</div>
			</div>
			<div data-mb-exclude="true" id="best-bets" aria-hidden="true" style="display:none"> <!-- new -->
	
		<a href="#" id="search-back-to-top"  class="backToTop" tabindex="-1"> 
		<span class="backToTopText">
			Back to top
		</span>
	  </a>
		</main>
    `, // NOSONAR
	};

	/* handle "Show more people result" when window is resized */
	const handleResize = () => {
		if (window.innerWidth < 1023) {
			Array.from(document.getElementsByClassName('content-title-right') as HTMLCollectionOf<HTMLElement>)[0].style.display = "none";
		} else {

			let noPeopleView = Array.from(document.getElementsByClassName('no-people-view') as HTMLCollectionOf<HTMLElement>)[0];
			let noPeopleViewStyle = "block";
			if (window.getComputedStyle(noPeopleView).display !== "none") {
				noPeopleViewStyle = "none";
			}

			Array.from(document.getElementsByClassName('content-title-right') as HTMLCollectionOf<HTMLElement>)[0].style.display = noPeopleViewStyle;
			Array.from(document.getElementsByClassName('people-search') as HTMLCollectionOf<HTMLElement>)[0].style.display = noPeopleViewStyle;

		}
	};


	const getUsername = (appState, username): void => {
		if (!username) {
			setUserName(appState && appState.user && appState.user.nwieID);
		}
	};

	const scriptLoaded = () => { // NOSONAR
		const rootEl = appRootElRef.current;
		getUsername(appState, userName);
		let tab = "";

		let myusername = localStorage.getItem("currentUser");
		let hidePeople = false;

		// @ts-ignore
		window.Mindbreeze.require(
			["client/application", "jquery", "component!stack", "component!clientnotification", "client/template"],
			function (Application, $) {
				let computedInitialQuery = false;
				let Myapplication = Application.extend({
					//	 Uncomment below code snippet to enable JWT in Production;
					// initialize: function () {
					// 	console.log('called init');
					// 	let token = AuthService.getAuthorizationToken();
					// 	this.options.ajaxOptions = {
					// 		requestHeaders: function (channelURL) {
					// 			if (token) {
					// 				return {
					// 					Authorization: token
					// 				}
					// 			}

					// 		}
					// 	}
					// 	Application.prototype.initialize.apply(this, arguments);
					// },


					handleAssociateClick: function (clickObj: IAssociateClickObj) {
						if (clickObj && clickObj.userId) {
							let userId: string = clickObj.userId;
							// Handle emails
							if (userId.indexOf("@") > 0) {
								userId = userId.substring(0, userId.indexOf("@"));
							}
							userId = userId.toLowerCase();
							dispatch(UpdateViewUserDetailNwie(userId));
							navigate("/employeeDetail");
						}
					},
					handleEmailClick: function (email) {
						PeopleUtils.openEmail(email.email);
					},
					selectPeopleTab: function () {
						$(".tab-People").click();
					},
					handleTabSelect: function (options) {
						if (!computedInitialQuery) {
							return;
						}

						window["newrelic"].addPageAction(`SearchTab - ${options.name}`);
						tab = options.name;
						let displayValue = "block";
						if (hidePeople) {
							displayValue = "none";
						}

						if (options.name === "All") {
							$("#people-search-results-container").attr(
								"style",
								"display: " + displayValue + " !important"
							);

							$("#all-results-container").attr(
								"style",
								"display: block !important"
							);
							$("#all-results-header").text("Search Results");
							$("#advanced-people-search-title").attr(
								"style",
								"display: none !important"
							);
							$("#date-parent").attr("style", "display: block !important");
							$("span.content-title").html("Content results");
							$(".results-title").attr("style", "padding-top: 10px !important");

						} else {
							$("#people-search-results-container").attr(
								"style",
								"display: none !important"
							);
							if (options.name === "People") {
								$("#tipsId").remove();
								$("#all-results-header").text("People");
								$("#advanced-people-search-title-container").attr(
									"style",
									"height: 30px;"
								);
								$("#advanced-people-search-title").attr(
									"style",
									"display: !important"
								);
								$("#date-parent").attr("style", "display: none !important");
								$("span.content-title").html("People results");
								$(".results-title").attr(
									"style",
									"padding-top: 10px !important"
								);
								$("#all-results-container").attr(
									"style",
									"display: " + displayValue + " !important"
								);
							} else {
								$("#all-results-container").attr(
									"style",
									"display: block !important"
								);
								$("#all-results-header").text("Search Results");
								$("#advanced-people-search-title").attr(
									"style",
									"display: none !important"
								);
								$("span.content-title").html("Content results");
								$("#date-parent").attr("style", "display: block !important");
							}
						}
					},
					vote: function (weight) {
						if (weight.weight === "up") {
							weight.event.delegateTarget.getElementsByClassName('icon-thumbs-up')[0].setAttribute("style", "color: #6eceb2");
							weight.event.delegateTarget.getElementsByClassName('icon-thumbs-down')[0].setAttribute("style", "color: #D0D3D4");
							weight.event.delegateTarget.getElementsByClassName('voting-confirmation')[0].setAttribute("style", "visibility: visible !important");
							weight.event.delegateTarget.getElementsByClassName('voting-confirmation')[0].classList.add("voting-confirmation-fade");
							$('.voting-confirmation-fade').delay(3000).fadeOut(1000, "linear");
						} else if (weight.weight === "down") {
							weight.event.delegateTarget.getElementsByClassName('icon-thumbs-up')[0].setAttribute("style", "color: #D0D3D4");
							weight.event.delegateTarget.getElementsByClassName('icon-thumbs-down')[0].setAttribute("style", "color: #6eceb2");
							weight.event.delegateTarget.getElementsByClassName('voting-confirmation')[0].setAttribute("style", "visibility: visible !important");
							weight.event.delegateTarget.getElementsByClassName('voting-confirmation')[0].classList.add("voting-confirmation-fade");
							$('.voting-confirmation-fade').delay(3000).fadeOut(1000, "linear");
						}


					}
				});

				const app = new Myapplication({
					rootEl: rootEl,
					loginAsError: true,
					disableClientNotifications: true,
					ajaxOptions: {
						//Below is a workaround for open PDF preview: get idToken to sessionStorage for NewSearch.
						//Please update below section when the long term solution is available.
						requestHeaders: function (channelURL) {
							let token = sessionStorage.getItem("idToken.for.search");
							if (token) {
								return { Authorization: token };
							} else {
								AuthService.getMindbreezeHeadersWithForceRefresh();
								let newToken = sessionStorage.getItem("idToken.for.search");
								console.log(
									"NewSearch >>> scriptLoaded : new id token with forceRefresh "
								);
								return { Authorization: newToken };
							}
						},
						onRequestFailed: function (error) {
							console.log(
								"NewSearch >>> scriptLoaded : error.status : ",
								error.status
							);
							console.log(
								"NewSearch >>> scriptLoaded : error.reason : ",
								error.reason
							);
						},
					},
					callback: function (application) {
						$("#my-username").text(myusername);
						if (myusername === null) {
							ErrorReportingService.reportLogsWithMessage(
								"Sending search logs No User Id",
								`NewSearch.ts -> requestAccessToken -> Handling Search/Feedback Logs`,
								`Data UserID: ${myusername}`
							);
							console.log("NewSearch >>> Value Missing UserID: ", myusername);
						} else {
							ErrorReportingService.reportLogsWithMessage(
								"Sending search logs with userName",
								`NewSearch.ts -> requestAccessToken -> Handling Search/Feedback Logs`,
								`Data UserID: ${myusername}`
							);
							console.log(
								"NewSearch >>> reportLogsWithMessage : Value Missing UserID: ",
								myusername
							);
						}

						/* click People tab and change title - See all people link (main page) */
						if (appState.search.searchPage) {
							$(".tab-People").click();
							tab = "People";
							$("span.content-title").html("People results");
						}

						// @ts-ignore
						let peopleSearchView = application.models[(Object.keys(application.models)
							.filter(function (name) {
								return name.indexOf("search_view") === 0;
							})).filter(function (name) {
								return application.models[name].input.attributes.name === "People-Search";
							}
							)];

						peopleSearchView.on("computed", function () {
							let query = application.getUnparsedUserQuery();
							let displayVal = "block";
							if (query !== undefined) {
								if ((query !== '') && query.toUpperCase().indexOf('ASSOCIATE', 0) > -1) {
									displayVal = "none";
								}
							}

							$("#people-search-results-container").attr(
								"style",
								"display: " + displayVal + " !important"
							);

							$("#advanced-people-search-title").attr(
								"style",
								"display: none !important"
							);

							let peopleMessage = "";

							/* get people result */
							let resultsPeople = peopleSearchView.output.attributes.resultset;

							/* hide not found message if there's a result */
							if (resultsPeople && resultsPeople.results && resultsPeople.results.length > 0) {
								/* convert Contractor jobTitle to 'Non-Employee' based on employeeType */
								resultsPeople.results.forEach(result => {
									if(result.properties.mgru_employeeType.data[0].html === 'N'){
										result.properties.mgru_jobTitle.data[0].html = 'Non-Employee';
									}
								});

								peopleMessage = ""; // NOSONAR
								if (window.innerWidth > 1023) {
									$(".content-title-right").show();
									$(".people-search").show();
								}
								$(".no-people-view").hide();

								/* show not found message if there's no result */
							} else {
								$(".content-title-right").hide();
								$(".no-people-view").show();

								peopleMessage = "Sorry, we couldn't find any people who matched your search for " + query + ".";

								/* did you mean people content */
								let didYouMeanPeople = $("p.pDidYouMean").html();

								if (didYouMeanPeople !== "" && didYouMeanPeople !== undefined) {
									didYouMeanPeople = didYouMeanPeople.replace(/\>\s+\</g, "><");
									$("p.pDidYouMean").html(didYouMeanPeople.trim());
								}

								$("p.pDidYouMean").append("?");
								$(".no-people-view").html("<p> " + peopleMessage + " </p>");

								/* when user clicks 'did you mean' term - execute new search */
								$("div.mb-dont-serialize p a:first").click(function () {
									let didYouMeanPeopleTerm = $("p.pDidYouMean")
										.find("a")
										.text()
										.trim();
									$("#query").val(didYouMeanPeopleTerm);
									$("#bMagnifying").click();
								});
							}

						});

						application.models.search.on("computed", function () {
							let trackingAttribute = "result-tracking-added";
							let results = document.getElementsByClassName("mb-clickable-phone");
							for (let result of results) {
								if (
									!result.hasAttribute(trackingAttribute) ||
									result.getAttribute(trackingAttribute) !== "true"
								) {
									result.setAttribute(trackingAttribute, "true");
									result.addEventListener("click", (e) => {
										let element = $(e.target).closest("div.mb-clickable-phone");
										let resultTitle = element
											?.find(".media-body")
											?.find(".media-heading")[0]
											?.textContent?.trim();
										let index = toInteger(element.index()) + 1;
										window["newrelic"].addPageAction("Search Result Click", {
											query: application.getUnparsedUserQuery(),
											index: index,
											result: resultTitle,
										});
									});
								}
							}
							if (!computedInitialQuery && application.getUnparsedUserQuery()) {
								computedInitialQuery = true;
								$("#all-results-container").removeAttr("style");
							}

							$("#tipsId").remove();
							$("#cDidYouMean").remove();
							let contentMessage = "",
								noContentTips = "";

							/* get content result */
							let resultsContent =
								document.getElementsByClassName("mb-clickable-phone");

							/* did you mean content */
							let didYouMeanContent = $(".no-results-view")
								.find(".mb-dont-serialize")
								.find("p")
								.html();

							if (didYouMeanContent !== "" && didYouMeanContent !== undefined) {
								didYouMeanContent = didYouMeanContent.replace(/\>\s+\</g, "><");
							}

							/* did you mean term */
							let didYouMeanContentTerm = $(".no-results-view")
								.find(".mb-dont-serialize")
								.find("p")
								.find("a")
								.text()
								.trim();

							/* search term */
							let query = application.getUnparsedUserQuery();
							let displayValue = "block"; // NOSONAR
							if (query !== undefined) {
								if ((query !== '') && query.toUpperCase().indexOf('ASSOCIATE', 0) > -1) {
									hidePeople = true;
								} else {
									hidePeople = false;
								}
							}

							if (!hidePeople) {
								/* All & People tab - not 'associate' - SHOW PEOPLE results */
								if (tab === "" || tab === "All" || tab === "People") {
									displayValue = "block"; // NOSONAR

									/* News, Learning, Policies tabs - HIDE PEOPLE results */
								} else {
									displayValue = "none";
								}

							} else {
								/* All, People, News, Learning, Policies tabs - 'associate' HIDE PEOPLE results */
								displayValue = "none";
							}

							/* hide not found message if there's a result */
							if (resultsContent.length > 0) {
								$(".content-title-advanced > .people-search").show();
								contentMessage = ""; // NOSONAR
								$(".no-results-view").hide();
								if (tab === "People") {
									for (let i = 0; i < resultsContent.length; i++) {
										let tempEmpType = resultsContent.item(i);
										/* convert Contractor jobTitle to 'Non-Employee' based on employeetype - People tab */
										if($(tempEmpType).find('div#search-employee-type').html().toUpperCase() === 'N'){
											$(tempEmpType).find("div.media.center.people").find("div.column-right")
											.find("div#search-job-title")
											.html("Non-Employee");
										}
									}
									$("#all-results-container").attr(
										"style",
										"display: " + displayValue + " !important"
									);
									/* ALWAYS hide when tab = People */
									$("#people-search-results-container").attr(
										"style",
										"display: none !important"
									);

								} else {
									$("#people-search-results-container").attr(
										"style",
										"display: " + displayValue + " !important"
									);
								}

								/* show not found message */
							} else {
								if (tab === "People") {
									$("#all-results-container").attr(
										"style",
										"display: " + displayValue + " !important"
									);
									/* ALWAYS hide when tab = People */
									$("#people-search-results-container").attr(
										"style",
										"display: none !important"
									);
								} else {
									$("#people-search-results-container").attr(
										"style",
										"display: " + displayValue + " !important"
									);
								}
								$(".people-search").hide();
								$(".no-results-view").show();
								/* not found messages */
								if (tab === "People") {
									contentMessage = "Sorry, we couldn't find " + query + ".";
									$("#people-search-results-container").hide();
								} else {
									contentMessage =
										"Sorry, we couldn't find any results for your search of " +
										query +
										".";
								}

								$(".no-results-view")
									.find(".mb-dont-serialize")
									.html("<p> " + contentMessage + " </p>");

								if (
									didYouMeanContent !== undefined &&
									didYouMeanContent !== ""
								) {
									$(".no-results-view")
										.find(".mb-dont-serialize")
										.append(
											'<p id="cDidYouMean">' +
											didYouMeanContent.trim() +
											"?</p>"
										);
								}

								/* show these tips if no results in "All" tab */
								if (tab === "" || tab === "All") {
									let pathName = window.location.href;
									if (pathName.toLowerCase().indexOf("searchresults") > 0) {
										pathName = pathName.replace("/SearchResults", "");
									}

									noContentTips = `<div id = 'tipsId'>
																	<p class='tips'><b>Search tips:</b></p>
																	<p class='tips'>Check your spelling and try again</p>
																	<p class='tips'>Search for similar but different terms, like car instead of auto</p>
																	<p class='tips'>Try using fewer search terms or simpler words</p>
																	<p class='tips'>Browse for content on <a href=${pathName}>InSide</a></p>
																	<p class='tips'>If all else fails, <a href="https://forms.office.com/r/YtiNwKBT2J" target="_blank">contact the InSide team</a></p>
																	</div>`;
									$(".no-results-view").append(noContentTips);
								}

								/* when user click 'did you mean' term - execute new search */
								$("#cDidYouMean a").click(function () {
									$("#query").val(didYouMeanContentTerm);
									$("#bMagnifying").click();
								});
							}
						});
						application.views.on(
							"afterRender:Result",
							function (application, view, resultModel) {
								let resultProperties = resultModel.output.properties;
								let category = resultProperties.category.data[0].value;
								let newUrl = "";

								if (category === "ServiceNow") {
									let mbKnowledgeBase =
										resultProperties["mb_knowledge_base"].properties[0].data[0]
											.value;

									if (mbKnowledgeBase === "End User Self Service Knowledge") {
										let kbNumber =
											resultProperties["number.value"].properties[0].data[0]
												.value;
										newUrl =
											"https://nwproduction.service-now.com/csm/?id=kb_article_view&sysparm_article=" +
											kbNumber;
									}
								}

								if (newUrl) {
									let aTags = view.$el.find("a");
									if (aTags.length >= 3) {
										aTags[0].setAttribute("href", newUrl);
										aTags[1].setAttribute("href", newUrl);
										aTags[2].setAttribute("href", newUrl);
									}
								}

								function formatPhoneNumber(number) {
									let cleaned = ("" + number).replace(/\D/g, "");
									let allZeroes = /^0+$/;
									if (
										cleaned.match(allZeroes) ||
										cleaned === undefined ||
										cleaned === ""
									) {
										return "";
									} else {
										let match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
										if (match) {
											let intlCode = match[1] ? "+1 " : "";
											return [
												intlCode,
												"(",
												match[2],
												") ",
												match[3],
												"-",
												match[4],
											].join("");
										}
									}
								}

								let allNums = $("div.us-phone");
								Array.from(allNums).forEach(function (num) {
									// @ts-ignore
									let old = num.innerHTML;
									let formatted = formatPhoneNumber(old);
									// @ts-ignore
									num.innerHTML = formatted;
								});
							}
						);
					},
				});

				// @ts-ignore
				app.execute(function (application) {
					application.setUnparsedUserQuery(appState.search.query);
				});
			}
		);
	};

	return (
		<div className="newsearch-main-div" ref={appRootElRef} dangerouslySetInnerHTML={template} ></div>
	);
};


export default NewSearch;