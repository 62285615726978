import React, { useContext, useEffect, useState } from "react";
import GraphService from "../../../services/GraphService";
import { AdwContext } from "../../../context/AdwContext";
import styles from "./NwOrgChart.module.scss"
import { GraphUserData } from "../../../types/GraphTypes/GraphUser";
import PeopleUtils from "../../../utils/PeopleUtils";

export interface INwOrgChartProps {
  managerMail: string,
  selectedAssociateMail: string,
  onCardClick: any
}

const NwDirectReports: React.FC<INwOrgChartProps> = ({ managerMail, selectedAssociateMail, onCardClick }) => {
  const [appState] = useContext(AdwContext);
  const [managerDirectReport, setManagerDirectReport] = useState<GraphUserData[]>([]);

  //Get Direct Report Data
  useEffect(() => {
    if (appState.user && managerMail) {
      GraphService.getDirectReports(managerMail).then((user) => {
        setManagerDirectReport(user);
      });
    }

  }, [managerMail])

  return (<>
    {managerDirectReport && managerDirectReport.map((levelThree, i) => {
      return (levelThree.displayName.includes(",") &&
        <div id="peers" key={levelThree?.onPremisesSamAccountName} className={styles.levelThree} >
          <button onClick={() => onCardClick(levelThree?.userPrincipalName?.toLowerCase())} className={selectedAssociateMail?.toLowerCase() == levelThree?.userPrincipalName?.toLowerCase() ? styles.selectedName : styles.name}>{PeopleUtils.formatAssociateDisplayName(levelThree?.displayName)}</button>
          <p>{PeopleUtils.formatJobTitle(levelThree?.employeeType, levelThree?.jobTitle)}</p>
          <p>{levelThree?.onPremisesSamAccountName?.toLowerCase()}</p>
        </div>)
    })}

  </>)
};

export default NwDirectReports;
