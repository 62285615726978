import React from 'react';

import DirectReportSkeleton from '../DirectReport/DirectReportSkeleton';

import styles from "./OrgChartListContents.module.scss";

const OrgChartListContentsSkeleton: React.FC = () => {
  return (
    <ul className={styles.workGroup}>
      <DirectReportSkeleton/>
      <DirectReportSkeleton/>
      <DirectReportSkeleton/>
    </ul>
  );
};

export default OrgChartListContentsSkeleton;