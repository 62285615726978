import React, { useState } from "react";
import styles from "./AtoZMobileNav.module.scss";
import Dropdown from "../../atoms/Dropdown/Dropdown";

const AtoZMobileNav = ({
  activeFilter,
  alphabet,
  sites,
  handleLetterClick,
  backToTopClick,
}) => {
  const handleBackToTopClick = () => {
    backToTopClick();
    setSelected("Select");
  };

  const [selected, setSelected] = useState("");
  return (
    <div className={styles.mobileFilterNavBar}>
      <div className={styles.jumpToContainer}>
        <a href="#" className={styles.jumpToText}>
          Jump to:
        </a>
      </div>
      <div className={styles.selectContainer}>
        <Dropdown
          selected={selected}
          setSelected={setSelected}
          options={alphabet}
          sites={sites}
          handleLetterClick={handleLetterClick}
        />
      </div>
      <div className={styles.backToTopContainer}>
        <a
          href="#"
          onClick={(e) => handleBackToTopClick()}
          className={styles.backToTopText}
        >
          Back to top
        </a>
      </div>
    </div>
  );
};

export default AtoZMobileNav;
