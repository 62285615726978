import React from "react";
import styles from "./Link.module.scss";

export interface ILinkProps {
  linkClasses?: string;
  linkText?: string;
  url: string;
  target?: string;
  id: string;
  style?: React.CSSProperties;
  children?:React.ReactNode;
  onKeyDown?:(e)=>void;
  role?:string;
  title?: string;
}

const Link: React.FC<ILinkProps> = ({ linkClasses, linkText, url, target, id,onKeyDown, role, title, ...otherProps }) => {
  const linkTarget: string = getLinkTarget(target);
  linkClasses = getLinkClasses(linkClasses);

  if (linkText) {
    return (
      <a role={role} onKeyDown={(e)=>{onKeyDown &&
        onKeyDown(e)}} title={title} {...otherProps} href={url} target={linkTarget} className={linkClasses} id={id} onClick={() => onLinkClick(linkText)}>
        {linkText}
        {getScreenReaderText(linkTarget)}
      </a>
    );
  } else {
    return (
      <a {...otherProps} href={url} target={linkTarget} className={linkClasses} id={id} onClick={() => onLinkClickWithChildren(otherProps.children)}>
        {/* calling a method here, results in no images being displayed */}
      </a>
    );
  }
};

function isIterable(object) {
  if (object == null) {
    return false;
  }
  return typeof object[Symbol.iterator] === 'function';
}

function onLinkClick(linkText: string): void {
  window['newrelic'].addPageAction(linkText);
}

function onLinkClickWithChildren(children): void {
  let text = getLinkTextFromChild(children);
  if (text) {
    window['newrelic'].addPageAction(text);
  }
}

function getLinkTextFromChild(child): string {
  if (typeof child === "string") {
    return child;
  }
  else if (isIterable(child)) {
    for (let value of child) {
      let text = getLinkTextFromChild(value.props.children);
      if (text) {
        return text;
      }
    }
  }
  else if (typeof child === 'object') {
    let text = getLinkTextFromChild(child.props.children);
    if (text) {
      return text;
    }
  }
  return null;
}

function getLinkClasses(linkClasses: string): string {
  if (linkClasses) {
    linkClasses = `${linkClasses} ${styles.link}`;
  } else {
    linkClasses = styles.link;
  }
  return linkClasses;
}

function getLinkTarget(target: string): string {
  let windowTarget: string = "_self";

  if (!target) {
    target = "_blank";
  }

  target = target.toLowerCase();

  if (target === "new" || target === "_blank") {
    windowTarget = "_blank";
  }

  return windowTarget;
}

/*
ADA compliance, we will add a DIV for the screen reader to indicate
if the link wil lopen in a new window
*/
function getScreenReaderText(linkTarget: string): JSX.Element {
  let returnElement: JSX.Element = <span />;
  if (linkTarget === "_blank") {
    returnElement = <span className={styles.screenReaderOnly}>This link will open in a new window</span>;
  }
  return returnElement;
}

export default Link;

/*
ADA notes:
- The purpose of each link can be determined from the link text.
- Links should have descriptive names rather than "click here" or "read more"
- hide additional content off screen using css
- links should be underlined
- links should avoid text such as "click the link to the right"
- On focus - indicate when links will open in a new window
- the new window indicator, does not need to be visible ONLY available in the screen reader
- Provide the ALT attribute for IMG element
*/
