import * as React from "react";
import { SignOutButton } from '../../../components/atoms/Signout/Signout';
import style from '../UserErrorMessage/UserErrorMessage.module.scss';
import HeaderUserError from "../HeaderUserErrorMessage/HeaderUserError";

export const mainMsg: string = "Sorry, something is amiss."
export const msgDetails: string = "We are working to correct the issue. Go get some coffee, and check back later.";
export const linkMsg: string = "Please use the links below to access these sites:"

/** 
 * NOTE - This component intentionally uses inline styles and duplicates some functionality
 * (ex. getting the formatted date) so that it has no dependencies on other portions of the
 * app. If we are having issues with the app, leaving this component with no dependencies
 * reduces the risk of an error happening in this component. The only import used 
 */
const MainErrorMessage: React.FC = () => {
  return (
    <>
    <HeaderUserError />
    <div className={style.errorStyles}>
      <div className={style.actionMessage} style={{ paddingTop: '15%' }}>
        <p>We're having trouble logging you in to InSide. Please refresh this page.</p>
        <p>If you continue to receive this error, try clearing your cache or <SignOutButton/> of your account and reloading InSide.</p>
      </div>
    </div>
    </>
  );
}

export default MainErrorMessage;