import React, { useContext, useEffect, useState } from "react";
import { AdwContext } from "../../../context/AdwContext";
import styles from "./DirectReport.module.scss";
import GraphService from "../../../services/GraphService";
import { UpdateViewUserDetailNwie} from "../../../context/ActionTypes";
import { GraphAvatarData } from "../../../types/GraphTypes/GraphUser";
import PeopleUtils from "../../../utils/PeopleUtils";

export interface IDirectReportProps {
  avatarData: GraphAvatarData;
  showOrgChart: boolean;
}

export interface IDirectReportState {
  imageSource: string;
}

const DirectReport: React.FC<IDirectReportProps> = ({ avatarData, showOrgChart }) => {

  const [appState, dispatch] = useContext(AdwContext);
  const [imageSource, setImageSource] = useState<string>(require(`../../../assets/images/NoPhoto.png`));
  const [currentUser, setCurrentUser] = useState<string>('');
  const [tabOnceStyles, setTabOnceStyles] = useState<string>('');
  const [wrapperClass, setWrapperClass] = useState<string>('');
  const [greyConnectingLine, setGreyConnectingLine] = useState<string>('');
  const [blueConnectingLine, setBlueConnectingLine] = useState<string>('');
  const [directReportWrapper, setDirectReportWrapper] = useState<string>('');

  useEffect(() => {
    setCurrentUser(appState.user && appState.viewUserDetails ? appState.viewUserDetails.toLowerCase() : "");
    return () => {
      setCurrentUser("");
    }
  }, [appState.user, appState.viewUserDetails])
  useEffect(() => {
    setTabOnceStyles(showOrgChart && currentUser !== "walkerk" ? `${styles.tabOnce} ${styles.blueBorder}` : styles.tabOnce);
    setGreyConnectingLine(isGreyConnectingLine(showOrgChart, currentUser));
    return () => {
      setTabOnceStyles("");
      setGreyConnectingLine("");
    }
  }, [showOrgChart, currentUser]);

  useEffect(() => {
    if (avatarData && avatarData.userPrincipalName && avatarData.userPrincipalName !== " ") {
      getUserImage(avatarData.userPrincipalName);
    }
  }, [avatarData]);

  useEffect(() => {
    setWrapperClass(isWrapperClass(tabOnceStyles, avatarData));
    return () => {
      setWrapperClass("");
    }
  }, [avatarData, tabOnceStyles]);

  useEffect(() => {
    setBlueConnectingLine(isBlueConnectingLine(showOrgChart, avatarData, currentUser))
    return () => {
      setBlueConnectingLine("");
    }
  }, [avatarData, showOrgChart, currentUser]);

  useEffect(() => {
    setDirectReportWrapper((!avatarData.tabOnce && !avatarData.tabTwice) ? `${styles.directReportWrapperBoss} ${styles.directReportWrapper}` : styles.directReportWrapper);
    return () => {
      setDirectReportWrapper("");
    }
  }, [avatarData]);

  const getUserImage = (nwie: string): void => {
    Promise.resolve(GraphService.getUsersPhoto(nwie)).then((image) => {
      setImageSource(image);
    });
  }

  const isGreyConnectingLine = (showOrgChart, currentUser) => {
    let greyLine;
    if (showOrgChart) {
      if (avatarData.tabTwice || avatarData.tabOnce || currentUser === "walkerk") {
        greyLine = null;
      } else {
        greyLine = styles.connectingLine;
      }
      return greyLine;
    }
  }

  const isBlueConnectingLine = (showOrgChart, avatarData, currentUser) => {
    let blueLine;
    if (avatarData.tabOnce && showOrgChart && currentUser !== "walkerk") {
      blueLine = styles.blueLine;
    } else if (avatarData.tabTwice) {
      blueLine = styles.fixedheight;
    } else {
      blueLine = null;
    }
    return blueLine;
  }

  const isWrapperClass = (tabOnceStyles, avatarData) => {
    let wrapper = null;
    if (avatarData.tabOnce) {
      wrapper = tabOnceStyles;
    } else if (avatarData.tabTwice) {
      wrapper = styles.tabTwice;
    } else {
      wrapper = styles.boss;
    }
    return wrapper;
  }

  const onFocusChange = (): void => {
    setTimeout(() => {
      document.getElementById("fancy-name").focus();
    }, 500)
  }

  const onClickEmployee = (avatarOnPremisesSamAccountName): void => {
    dispatch(UpdateViewUserDetailNwie(avatarOnPremisesSamAccountName));
    window.scrollTo(0, 0);
    onFocusChange();
  }

  return (
    <div>
      <div className={blueConnectingLine} />
      <div className={wrapperClass}
        onClick={() => {
          onClickEmployee(avatarData.onPremisesSamAccountName);
          document.getElementById("fancy-name").style.outline = "0";
        }}
        onKeyDown={(e: any) => {
          if (e.code === 'Enter') {
            onClickEmployee(avatarData.onPremisesSamAccountName);
          }
        }}
      >
        <div className={directReportWrapper}>
          <div className={styles.pictureWrapper}>
            <div className={styles.pictureWhiteBorder}>
              <img
                className={styles.employeeImage}
                id={avatarData.id}
                src={imageSource}
                alt={`${avatarData.displayName} `}
              />
            </div>
            <div className={greyConnectingLine} />
          </div>
          <div className={styles.nameTitleWrapper} tabIndex={0} role="group">
            <div className={styles.employeeFullName}>{PeopleUtils.formatAssociateDisplayName(avatarData.displayName)}</div> 
            <div className={styles.employeeTitle}>{avatarData.jobTitle ? `${PeopleUtils.formatJobTitle(avatarData.employeeType, avatarData.jobTitle)}` : "Non-Employee"}</div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default DirectReport;