import equal from "fast-deep-equal";
import { Observable, Observer } from "rxjs";
import CacheObject from "../types/CachingTypes/CacheObject";
import { TimeUnits } from "../types/CachingTypes/TimeUnits";
import EnvironmentService from "./EnvironmentService";

class CachingService {

  /**
   * Remove an item from local storage, by key
   * Key is your listName or ObjectName
   * @param key Key the name of the ( object or list ) you want to delete from local storage
   * @returns void
   */
  public static removeValueFromCache = (key: string): void => {
    const environmentKey: string = CachingService.buildStorageKeyByEnvironment(
      key
    );
    localStorage.removeItem(environmentKey);
  };

  /**
   * Save an item to local storage by key for the supplied duration
   * @param apiObject Object that is to be saved
   * @param key Key the name of the ( object or list ) you want, with the envrionment info
   * @param cacheDuration Number of minutes before the cached value will expire
   * @param timeUnit Unit of time that will be added and set as an expiration
   */
  public static saveValueToCache = <T>(
    apiObject: T,
    key: string,
    duration: number,
    timeUnit: TimeUnits
  ): void => {
    const cacheObject: CacheObject<T> = new CacheObject(
      apiObject,
      duration,
      timeUnit
    );
    CachingService.setValueToLocalStorage<T>(key, cacheObject);
  };

  /**
   * Update an item to local storage by key for the supplied duration
   * @param apiObject Object that is to be saved
   * @param key Key the name of the ( object or list ) you want, with the envrionment info
   * @param cacheDuration Number of minutes before the cached value will expire
   * @param timeUnit Unit of time that will be added and set as an expiration
   */
  public static updateValueInCache = <T>(
    apiObject: T,
    key: string,
    duration: number,
    timeUnit: TimeUnits
  ): void => {
    const cacheObject: CacheObject<T> = new CacheObject(
      apiObject,
      duration,
      timeUnit
    );
    CachingService.setValueToLocalStorage<T>(key, cacheObject);
  };

  private static setValueToLocalStorage = <T>(
    key: string,
    cacheObject: CacheObject<T>
  ): void => {
    const environmentKey: string = CachingService.buildStorageKeyByEnvironment(
      key
    );
    localStorage.setItem(environmentKey, JSON.stringify(cacheObject));
  };

  public static getLocalObjectByKey<T>(key: string, returnExpiredValue: boolean = true): [T, boolean] {
    const environmentKey: string = CachingService.buildStorageKeyByEnvironment(
      key
    );
    const cachedObject: CacheObject<T> = JSON.parse(
      localStorage.getItem(environmentKey)
    );
    const isNotFoundOrExpired: boolean = CacheObject.isCacheObjectNotFoundOrExpired(cachedObject);
    return [cachedObject ? cachedObject.value : null, isNotFoundOrExpired];
  }

  /**
   * Get Object by key
   * This will return the Object from local storage OR the value from the API call
   * @param callback API callback to get items if they're expired or not in the localStorage
   * @param key Key the name of the ( object or list ) you want
   * @param cacheDuration Number of minutes before the cached value will expire
   * @param timeUnit Unit of time that will be added and set as an expiration, example TimeUnits.minutes
   * @returns A promise with the Object requested by the provided key
   */
  public static getObjectByKey = async <T>(
    key: string,
    callback: () => Promise<T>,
    cacheDuration: number,
    timeUnit: TimeUnits
  ): Promise<T> => {
    const environmentKey: string = CachingService.buildStorageKeyByEnvironment(
      key
    );
    const cachedObject: CacheObject<T> = JSON.parse(
      localStorage.getItem(environmentKey)
    );
    return CachingService.getObjectFromAPIAndSaveToCache(
      cachedObject,
      callback,
      key,
      cacheDuration,
      timeUnit
    );
  };

  public static getObjectByKeyAndObserve = <T>(
    key: string,
    callback: () => Promise<T>,
    cacheDuration: number,
    timeUnit: TimeUnits
  ): Observable<T> => {
    return Observable.create((observer: Observer<T>) => {
      const environmentKey: string = CachingService.buildStorageKeyByEnvironment(
        key
      );
      const cachedObject: CacheObject<T> = JSON.parse(
        localStorage.getItem(environmentKey)
      );
      CachingService.passObjectsToObserversAndSaveToCache(
        key,
        observer,
        cachedObject,
        callback,
        cacheDuration,
        timeUnit
      );
    });
  };

  private static passObjectsToObserversAndSaveToCache = <T>(
    key: string,
    observer: Observer<T>,
    cachedObject: CacheObject<T>,
    callback: () => Promise<T>,
    cacheDuration: number,
    timeUnit: TimeUnits
  ): void => {
    if (cachedObject && cachedObject.value) {
      observer.next(cachedObject.value);
    }

    if (CacheObject.isCacheObjectNotFoundOrExpired(cachedObject)) {
      callback().then(apiObject => {
        if (!cachedObject) {
          observer.next(apiObject);
          CachingService.saveValueToCache(
            apiObject,
            key,
            cacheDuration,
            timeUnit
          );
        } else if (!equal(cachedObject.value, apiObject)) {
          observer.next(apiObject);
          CachingService.updateValueInCache(
            apiObject,
            key,
            cacheDuration,
            timeUnit
          );
        }
      });
    }
  };

  private static getObjectFromAPIAndSaveToCache = async <T>(
    cachedObject: CacheObject<T>,
    callback: () => Promise<T>,
    key: string,
    cacheDuration: number,
    timeUnit: TimeUnits
  ): Promise<T> => {
    if (CacheObject.isCacheObjectNotFoundOrExpired(cachedObject)) {
      return callback().then((apiObject: T) => {
        CachingService.saveValueToCache(
          apiObject,
          key,
          cacheDuration,
          timeUnit
        );
        return apiObject;
      });
    } else {
      return cachedObject.value;
    }
  };

  public static clearLocalStorageAndRefresh = (): void => {
    localStorage.clear();
    const reload: boolean = window.confirm(
      "Your local storage has been cleared. Click ok to refresh your page or click cancel to keep your page as is"
    );
    if (reload) {
      window.location.reload();
    } else {
      return;
    }
  };

  public static clearLocalStorageAndRefreshNoPrompt = (): void => {
    localStorage.clear();
    window.location.reload();
  };

  /**
   * Returns the true keyName for local storage
   * the local storage keyName is the ( object or list ) name + environment name
   * @param key Key the name of the ( object or list ) you want a local key for
   * @returns string local storage key name by environment
   */
  public static buildStorageKeyByEnvironment(key: string): string {
    return `${key}-${EnvironmentService.getEnvironment()}`;
  }
}

export default CachingService;
