import React, { useRef } from "react";
import styles from "./ShowHide.module.scss";

export interface IShowHideProps {
  showing: boolean;
  toggle: () => void;
  className?: string;
  featureTitle?:string;
  ariaLabel?:string;
}
const showIcon = require("../../../../assets/images/icon-show.svg").default;
const hideIcon = require("../../../../assets/images/icon-hide.svg").default;

const ShowHide: React.FC<IShowHideProps> = ({featureTitle, showing, toggle, className = "",ariaLabel="" }: IShowHideProps) => {

  const showHideButton = useRef(null);

  const icon = showing ? showIcon : hideIcon;
  const text = showing ? "Hide" : "Show"

  function handleClick(): void {
    if (showHideButton && showHideButton.current) {
      showHideButton.current.blur();
    }
    toggle();
  }

  function handleKeyPress(e: React.KeyboardEvent<HTMLSpanElement>): void {
    if (e.key === 'Enter' || e.code === 'Enter') {
      toggle()
    }
  }

  return (<button
    style={{background:'none',border:'none'}}
    ref={showHideButton} onClick={handleClick} onKeyDown={handleKeyPress} 
  className={`${styles.showHide} ${className}`}
    aria-label={ariaLabel}
  >
    <img src={icon} alt="" /><br />
    <span>{text}</span>
    <span className={styles.screenReaderOnly}>{featureTitle}</span>
  </button>);
}

export default ShowHide;