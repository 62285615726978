import * as MsalAuthService from "../../../services/MsalAuthService";
import React from "react";
import styles from "./Signout.module.scss"
import BrowserUtils from "../../../utils/BrowserUtils";
import TimingUtils from "../../../utils/TimingUtils";

/**
 * Renders a sign out button 
 */
export const SignOutButton = () => {

  const handleLogout = () => {
    MsalAuthService.myMSALObj.logoutRedirect({
      postLogoutRedirectUri: '/',
      });
  };

  return (
    <>
      {!BrowserUtils.isIframe() && TimingUtils.registerComponentLoad('Signout')}
      <a href="#" className={styles.linkStyle} onClick={() => handleLogout()}>signing out</a>
    </>
  );
};