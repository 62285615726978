import React, { useLayoutEffect } from "react";
import AuthService from "../../../services/AuthService";
import AmazonWebService from "../../../services/AmazonWebService";

export interface IImageProps {
  id: string;
  src: string;
  alt: string;
  className?: string;
  imageClasses?: string;
  onMouseOver?: any;
  onMouseOut?: any;
  onClick?: () => void;
  onError?:any;
}

const AwsS3ImageNoDefault: React.FC<IImageProps> = ({ id,src,imageClasses,className,onError, ...otherProps }) => {
  let hasActiveMsalSession = AuthService.hasActiveSession();

  const getImage = async(src:string)=>{
    if(hasActiveMsalSession){
      let imageUrl = await AmazonWebService.getImageFromS3Bucket(src);
      const imgElement = document.getElementById(id) as HTMLImageElement;
      if (imgElement) {
        imgElement.src = imageUrl;
      }
    }
  }

  useLayoutEffect( ()=>{
    getImage(src);
  },[hasActiveMsalSession,src]);

  return <img {...otherProps} id={id} alt='' className={className} />;
};

export default AwsS3ImageNoDefault;