import React from "react";
import styles from "./ArrowButton.module.scss";

export enum Direction {
  UP, DOWN, LEFT, RIGHT
}

export interface IArrowButtonProps {
  arrowDirection: Direction;
  chevronClasses?: string;
  buttonClasses?: string;
  id?: string;
}

const ArrowButton: React.FC<IArrowButtonProps> = ({ arrowDirection, buttonClasses, chevronClasses, ...otherProps }) => {
  const chevronContainer: string = `${styles.nwChevron} ${buttonClasses}`;
  let arrowClass: string = "";
  switch (arrowDirection) {
    case Direction.UP:
      arrowClass = `${chevronClasses} ${styles.chevronArrowUp}`;
      break;
    case Direction.DOWN:
      arrowClass = `${chevronClasses} ${styles.chevronArrowDown}`;
      break;
    case Direction.RIGHT:
      arrowClass = `${chevronClasses} ${styles.chevronArrowRight}`;
      break;
    case Direction.LEFT:
      arrowClass = `${chevronClasses} ${styles.chevronArrowLeft}`;
      break;
  }

  return (
    <div className={chevronContainer}>
      <div {...otherProps} className={arrowClass} />
    </div>
  );
};

export default ArrowButton;
