import { TimeUnits } from "./TimeUnits";
import moment from "moment";

class CacheObject<T> {
  public value: T;
  public timestamp: number;

  /**
   * Create an Object, to be stored by the CachingService
   * @param value The JavaScript value or object to be cached
   * @param timestamp Number of minutes before the cached value will expire
   * @param timeUnit Unit of time that will be added and set as an expiration
   * @returns a CacheObject, with a timestamp and time unit
   */
  constructor(value: T, duration: number, timeUnit: TimeUnits) {
    this.value = value;
    this.timestamp = CacheObject.buildTimeStamp(duration, timeUnit);
  }

  /**
   * Get the timestamp
   * @param duration Number of time units before the cache item expires, defaulted to 0
   * @param timeUnit unit by which to measure time, defalted to minuites
   * @returns a number representing a timestamp
   */
  public static buildTimeStamp = (duration: number = 0, timeUnit: TimeUnits = TimeUnits.minutes): number => {
    let timestamp: number = 0;
    timestamp = moment()
      .add(duration, timeUnit)
      .toDate()
      .getTime();
    return timestamp;
  };

  /**
   * Determine if the object exists, or the timestamp has expired
   * @param cachedObject the object you want to know if it exists, or has expired
   * @returns a number representing a timestamp
   */
  public static isCacheObjectNotFoundOrExpired = <T>(cachedObject: CacheObject<T>): boolean => {
    return !cachedObject || !cachedObject.value || !cachedObject.timestamp || cachedObject.timestamp < Date.now();
  };
}

export default CacheObject;
