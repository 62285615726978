import { SpHrConnectSwitch } from "./SpHrConnectSwitch";

export class AdwHrConnectSwitch {
  public widget: string;
  public allowRegex: string;
  public startDate: string;
  public endDate: string;
  public endTime: string;
  public position: number;
  public displayWidget: boolean;
  public title: string;
  public description: string;

  constructor(spSwitch: SpHrConnectSwitch) {
    this.widget = spSwitch.fields.Widget;
    this.startDate = spSwitch.fields.StartDate;
    this.endDate = spSwitch.fields.EndDate;
    this.endTime = spSwitch.fields.EndTime;
    this.allowRegex = spSwitch.fields.AllowRegex;
    this.position = spSwitch.fields.Position;
    this.displayWidget = spSwitch.fields.DisplayWidget;
    this.title = spSwitch.fields.Title;
    this.description = spSwitch.fields.Description;
  }
}