import React, { useEffect, useRef, useState } from "react";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import EnvironmentService from "../../../services/EnvironmentService";
import styles from "../../organisms/MegaMenu/MegaMenuView.module.scss";
import MegaMenuButton from "../MegaMenuButton/MegaMenuButton";
import MegaMenuDropdown from "../MegaMenuDropdown/MegaMenuDropdown";
import { MegaMenuCategory } from "../../../types/MegaMenuTypes/MegaMenuCategory";
import { useNavigate } from "react-router-dom";
import ToolsDropdown from "../ToolsDropdown/ToolsDropdown";
import defaultLayoutStyles from "../../layouts/DefaultLayout/DefaultLayout.module.scss";
import { ReactComponent as SearchIcon } from "../../../assets/images/MagnifyingGlass_searchBar_icon.svg";
import searchStyles from '../SearchBar/MindbreezeAutoCompleteWithSearch.module.scss'
import alertStyles from "../../organisms/Alert/Alert.module.scss";
import { isMobile, isTablet } from "react-device-detect";

export interface IMegaMenuCategoryProps {
  handleButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleSubCategoryClick: () => void;
  mobileCollapsed: boolean;
  closeMobileMegaMenu: () => void;
  setMobileCollapsed: (i) => void;
}

export function isSubcategoryNull(category: MegaMenuCategory): boolean {
  return category.subCategories[0].subCategoryTitle === "null";
}

const MegaMenuCategories: React.FC<IMegaMenuCategoryProps> = ({
  handleButtonClick,
  handleSubCategoryClick,
  mobileCollapsed,
  closeMobileMegaMenu,
  setMobileCollapsed
}) => {
  
  const navigate = useNavigate();
  const initialRender = useRef(true);
  const searchButtonRef = useRef<HTMLButtonElement>(null)
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const [appState]: AdwContextType = React.useContext(AdwContext);
  const listItems: MegaMenuCategory[] = appState.lists.siteNavigation;
 
    
  const megaMenuClasses: string = mobileCollapsed
    ? `${styles.megaMenuCategories} ${styles.hideMobileMenu}`
    : `${styles.megaMenuCategories} ${styles.showMobileMenu}`;


    useEffect(() => {
      if (initialRender.current) {
        initialRender.current = false;
      } 
    }, [appState]);

    useEffect(() => {
      const { searchBoxEl } = queryDOMContent();

      window.addEventListener('scroll', handleScroll);
      window.addEventListener('keydown', (e) => handleKeyDown(e))
      searchBoxEl.addEventListener('mouseleave', handleMouseLeave)

      return () => {
        window.removeEventListener('scroll', handleScroll)
        window.addEventListener('keydown', (e) => handleKeyDown(e))
        searchBoxEl.removeEventListener('mouseleave', handleMouseLeave)
      };

    }, [])
    
    useEffect(() => {
      const { searchBoxElClassList, alertBox } = queryDOMContent();
      let showIcon = false;

      searchBoxElClassList.remove(defaultLayoutStyles.searchBoxFixed);
      
      if (alertBox) {
        alertBox.style.display = 'block';
      }

      if (window.location.pathname !== '/' && window.location.pathname !== '/SearchResults') {
        showIcon = true;
      }

      setShowSearchIcon(showIcon);
    }, [window.location.pathname])

  function handleSubcategoryNull(category: MegaMenuCategory): void {
    const siteUrl: string = EnvironmentService.getSharepointSiteUrl();
    let url: string = category.subCategories[0].subCategoryItems[0].link.url;
    let ind: number = url.indexOf(siteUrl);
    if (ind >= 0 && ind + siteUrl.length === url.length) {
      url = url.substring(ind + siteUrl.length);
    }
    if (url.length === 0) {
      url = "/";
    }
    if (window.location.pathname === url) {
      window.location.reload();
    }
    closeMobileMegaMenu();
    navigate(url);
  }

  function handleSearchIconClick () {
    const {alertBox, isOpen, searchBoxElClassList, searchBar, toolsDropdown, toolsDropdownButton} = queryDOMContent();
    const pathname = window.location.pathname;

    // Hide tools dropdown in case it's open
    toolsDropdown.style.display = 'none';
    toolsDropdownButton.ariaExpanded = 'false';

    if (isOpen) {
      searchBoxElClassList.remove(defaultLayoutStyles.searchBoxFixed)
      searchButtonRef.current.ariaExpanded = 'false';
      if (alertBox) {
        alertBox.style.display = 'block';
      }
      if (pathname !== '/') {
        searchBar.style.display = 'none';
      }
    } else {
      searchBar.style.display = 'block';
      searchBoxElClassList.add(defaultLayoutStyles.searchBoxFixed)

      const searchInput = document.getElementById('query') as HTMLInputElement;
      searchInput.focus();

      searchButtonRef.current.ariaExpanded = 'true';

      if (alertBox) {
        alertBox.style.display = 'none';
      }
    }

  }

  function handleKeyDown (e: KeyboardEvent) {
    const {alertBox, isOpen, searchBar, searchBoxElClassList} = queryDOMContent();
    const pathname = window.location.pathname;

    if (isOpen && e.key === 'Escape') {
      searchBoxElClassList.remove(defaultLayoutStyles.searchBoxFixed);
      searchButtonRef.current.ariaExpanded = 'false'
      if (alertBox) {
        alertBox.style.display = 'block';
      }

      if (pathname !== '/') {
        searchBar.style.display = 'none';
      }
    }
  }

  function handleScroll () {
    const pathname = window.location.pathname;
    if (pathname !== '/') return;

    const {alertBox, isOpen, searchBoxElClassList, searchBoxElHeight} = queryDOMContent();
    let showButton = window.scrollY > searchBoxElHeight;

    if (window.scrollY < searchBoxElHeight && isOpen) {
      showButton = false;
      searchBoxElClassList.remove(defaultLayoutStyles.searchBoxFixed);
      if (searchButtonRef.current) {
        searchButtonRef.current.ariaExpanded = 'false'
      }
      if (alertBox) {
        alertBox.style.display = 'block';
      }
    }

    setShowSearchIcon(showButton);
  }

  function handleMouseLeave () {
    const {alertBox, isOpen, searchBar, searchBoxElClassList} = queryDOMContent();
    const pathname = window.location.pathname;
  
    if (isOpen) {
      
      searchBoxElClassList.remove(defaultLayoutStyles.searchBoxFixed)
      
      const searchInput = document.getElementById('query') as HTMLInputElement;
      searchInput.value = '';
      
      const suggestDropDown = document.querySelector('[id^="ui-id-"]').id;
      if (suggestDropDown) {
        document.getElementById(suggestDropDown).style.display = "none";
      }
      
      if (pathname !== '/') {
        searchBar.style.display = 'none';
      }

      if (alertBox) {
        alertBox.style.display = 'block';
      }
    }

    if (searchButtonRef.current) searchButtonRef.current.ariaExpanded = 'false'
  }

  function queryDOMContent () {
    const toolsDropdown: HTMLElement = document.getElementById('toolsDropDownContent');
    const toolsDropdownButton: HTMLElement = document.getElementById('tools_dropdown_button');
    const searchBar: HTMLElement = document.getElementById(searchStyles.search);
    const searchBoxEl: HTMLElement = document.querySelector('.' + defaultLayoutStyles.searchBoxDefault);
    const alertBox: HTMLElement = document.querySelector('.' + alertStyles.alertWrapper);
    const searchBoxElClassList = searchBoxEl.classList;
    const searchBoxElHeight = searchBoxEl.clientHeight;
    const isOpen = searchBoxElClassList.contains(defaultLayoutStyles.searchBoxFixed);


    return {
      toolsDropdown,
      toolsDropdownButton,
      searchBar,
      searchBoxEl,
      alertBox,
      searchBoxElClassList,
      searchBoxElHeight,
      isOpen
    }
  }

  return (
    <div className={styles.mainNavigationWrapper}  >
    <nav className={megaMenuClasses } id="megaMenuCategories" 
              role="navigation">
      {listItems &&
        listItems.map((category, index) => {
          return (
            <div
              className={styles.megaMenuCategory} 
              key={category.categoryTitle}
              id={`nav-button-${index.toString()}`}
            >
              <MegaMenuButton
              menuIndex={index}
                buttonClasses={styles.megaMenuButton}
                category={category}
                handleButtonClick={
                  isSubcategoryNull(category)
                    ? (e: any) => {
                        e.currentTarget.blur();
                        handleSubcategoryNull(category);
                      }
                    : handleButtonClick
                }
                onKeyPress={
                  isSubcategoryNull(category)
                    ? (e: React.KeyboardEvent) => {
                        if (e.code === 'Enter') {
                          handleSubcategoryNull(category);
                        }
                      }
                    : null
                }
                buttonData={index + 1}
              />
              {!isSubcategoryNull(category) && (
                <MegaMenuDropdown labelledby={`mm-megaMenuButton-${index.toString()}`}
                  dropdownClasses={styles.megaMenuDropdown}
                  mobileCollapsed={mobileCollapsed}
                  setMobileCollapsed={(e)=>setMobileCollapsed(e)}
                  category={category}
                  dropdownData={index + 1}
              menuIndex={index}
                  onSubCategoryClick={handleSubCategoryClick}    />
              )}
            </div>
          );
        })}
      
      {
        showSearchIcon && !isMobile && !isTablet
        ?
        <div className={`${styles.searchIconContainer} ${styles.megaMenuCategory}`}>
          <button 
            ref={searchButtonRef}
            className={styles.searchIconButton} 
            aria-expanded={false}
            aria-haspopup={true}
            onClick={handleSearchIconClick}
          >
            <SearchIcon className={searchStyles.searchIcon}/>
          </button>
          <ToolsDropdown mobileCollapsed={mobileCollapsed}/>
        </div>
        :
        <ToolsDropdown mobileCollapsed={mobileCollapsed}/>
        }
        </nav>
    </div>
  );
};

export default MegaMenuCategories;
