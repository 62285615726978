import { SpMegaMenuItem } from "./SpMegaMenuItem";

class AdwMegaMenuItem {
  public id: number;
  public category: string;
  public categoryOrder: number;
  public subCategory: string;
  public subCategoryOrder: number;
  public link: { description: string; url: string };
  public linkOrder: number;
  public special: string;
  public windowTarget: string;

  constructor(spMegaMenuItem: SpMegaMenuItem) {
    this.id = spMegaMenuItem.fields.id;
    this.category = spMegaMenuItem.fields.Category;
    this.categoryOrder = spMegaMenuItem.fields.CategoryOrder;
    this.subCategory = spMegaMenuItem.fields.SubCategory;
    this.subCategoryOrder = spMegaMenuItem.fields.SubCategoryOrder;
    this.link = {
      description: spMegaMenuItem.fields.Link !== null ? spMegaMenuItem.fields.Link.Description : "Please enter link data",
      url: spMegaMenuItem.fields.Link !== null ? spMegaMenuItem.fields.Link.Url : "Please enter link data",
    };
    this.linkOrder = spMegaMenuItem.fields.ItemOrder !== undefined ? spMegaMenuItem.fields.ItemOrder : spMegaMenuItem.fields.LinkOrder;
    this.special = spMegaMenuItem.fields.Special;
    this.windowTarget = spMegaMenuItem.fields.WindowTarget;
  }
}

export default AdwMegaMenuItem;
