import React, { type TouchEvent, useContext, useState, useRef } from "react";
import { InsideUserData } from "../../../types/InsideUserData";
import styles from './Announcement.module.scss'
import { AdwContext } from "../../../context/AdwContext";
import { SetUserPreferences } from "../../../context/ActionTypes";
import Link from "../../atoms/Link/Link";

const Close = require('../../../assets/images/Dismiss_button.svg').default;
const Bullhorn = require('../../../assets/images/Bullhorn-icon.svg').default;

interface IAnnouncementProps {
  announcementText: string;
  pinned: boolean;
  id: number;
  link: {description: string; url: string;} | null;
}

const SWIPE_THRESHOLD = 75; //Threshold to decide if the user swiped long enough to dismiss

const Announcement = ({announcementText, pinned, id, link}: IAnnouncementProps) => {

  const [appState, dispatch] = useContext(AdwContext);
  const [isAnimating, setIsAnimating] = useState(false);
  const [deltaX, setDeltaX] = useState(0);
  const [startX, setStartX] = useState(0);
  const articleEl = useRef<HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleDismiss = () => {
    if (pinned) return;

    buttonRef.current.disabled = true;

    const newUserPreferences: InsideUserData = {...appState.user};
    if (!newUserPreferences.dismissedAnnouncements) {
      newUserPreferences.dismissedAnnouncements = id.toString();
    } else {
      newUserPreferences.dismissedAnnouncements += `,${id}`;
    }

    dispatch(SetUserPreferences(newUserPreferences));
  }

  const startDrag = (e: TouchEvent<HTMLElement>) => {
    if (isAnimating || pinned) return;

    const startX = e.touches[0].pageX;

    setStartX(startX);
  }

  const onMove = (e: TouchEvent<HTMLElement>) => {
    if (pinned) return;

    const currentX = e.touches[0].pageX;
    const deltaX = currentX - startX;

    if (deltaX === 0) return;

    articleEl.current.style.transform = `translateX(${deltaX}px)`;
    articleEl.current.style.opacity = '0.5'; 

    setIsAnimating(true);
    setDeltaX(deltaX);
  }

  const onEnd = () => {
    if (pinned) return;

    const isDismissed = Math.abs(deltaX) >= SWIPE_THRESHOLD;

    if (isDismissed) {
      handleDismiss();
    } else {
      articleEl.current.style.transform = `translateX(0)`;
      articleEl.current.style.opacity = '1'; 
    }

    articleEl.current.removeAttribute('style');
    setIsAnimating(false);
    setDeltaX(0);
    setStartX(0);
  }

  return (
    <article 
      className={styles.announcement} 
      ref={articleEl}
      onTouchStart={startDrag}
      onTouchMove={onMove}
      onTouchEnd={onEnd}
      >
        {link 
          ? 
          <Link id={`announcement-${id}`} url={link.url} target="_blank" title={announcementText}  linkText={announcementText} linkClasses={`${styles.text} ${styles.link}`} />
          : 
          <p className={styles.text} title={announcementText}>{announcementText}</p>}
        {
          pinned 
          ? (
          <img src={Bullhorn} alt='Bullhorn icon' /> 
          )
          : (
            <button className={styles.closeButton} ref={buttonRef} onClick={handleDismiss} title='Dismiss announcement'>
            <img src={Close} alt='Dismiss announcement' />
          </button>
          )
        }
    </article>
)
}

export default Announcement;