import AdwDateTimeService from "../../services/AdwDateTimeService";
import { SpAnnouncements } from "./SpAnnouncements";

class AdwAnnouncement {
  public id: number;
  public announcementText: string;
  public targetAudience: string;
  public pinned: boolean;
  public startDate: string;
  public endDate: string;
  public link: {description: string; url: string;};

  constructor(spAnnouncements: SpAnnouncements) {
    this.buildAdwAnnouncement(spAnnouncements);
  }

  public buildAdwAnnouncement(spAnnouncements: SpAnnouncements): void {
    this.id = spAnnouncements.fields.id;
    this.announcementText = spAnnouncements.fields.Title;
    this.targetAudience = spAnnouncements.fields.TargetAudience;
    this.pinned = spAnnouncements.fields.Pinned;
    this.startDate = this.setDate(spAnnouncements.fields.StartDate);
    this.endDate = this.setDate(spAnnouncements.fields.EndDate);
    this.link = spAnnouncements.fields.Link ? {
      description: spAnnouncements.fields.Link.Description,
      url: spAnnouncements.fields.Link.Url
    } : null;
  }

  public setDate = (dateString: string) => {
    const startDate: Date = new Date(dateString);
    return `${AdwDateTimeService.getMonthName(startDate.getMonth())} ${startDate.getDate()}, ${startDate.getFullYear()}`;
  };
}

export default AdwAnnouncement;
