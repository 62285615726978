import React, { useEffect, useState } from "react";
import styles from "./OrgChartListContents.module.scss";
import OrgWorkGroup from "../OrgWorkGroup/OrgWorkGroup";
import OrgChartWidget from "../OrgChartWidget/OrgChartWidget";
import { GraphAvatarData } from "../../../types/GraphTypes/GraphUser";

export interface IOrgChartListContentsProps {
  avatarUser: GraphAvatarData;
  showOrgChart: boolean;
  workGroup: GraphAvatarData[];
  orgChart: GraphAvatarData[];
  cEOShortName:string;
}

const OrgChartListContents: React.FC<IOrgChartListContentsProps> = ({avatarUser,showOrgChart,workGroup,orgChart,cEOShortName}) => { // NOSONAR
  const [isCEO,setIsCEO]=useState<boolean>(false);
  useEffect(()=>{
    if(avatarUser && avatarUser.onPremisesSamAccountName && avatarUser.onPremisesSamAccountName.toLowerCase() === cEOShortName){
      setIsCEO(true);
    }
    return()=>{
      setIsCEO(false);
    }
  },[cEOShortName,avatarUser])

  return(
    (!showOrgChart || isCEO) && workGroup ?  // NOSONAR
      <ul id={`work-group-list`} className={styles.workGroup}>
      <OrgWorkGroup workGroup={workGroup} showOrgChart={showOrgChart}/>
      </ul>
:
showOrgChart && orgChart ?  // NOSONAR
<ul id={`org-chart-list`} className={styles.workGroup}>
<OrgChartWidget orgChart={orgChart} showOrgChart={showOrgChart} />
</ul>
:
<div></div> // NOSONAR
  )  // NOSONAR
};

export default OrgChartListContents;
