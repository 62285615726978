import AdwEmployee from "../types/CallupTypes/AdwEmployee";
import { GetContactCollectionResponse } from "../types/CallupTypes/GetContactCollectionResponse";
import AuthService from "./AuthService";
import EnvironmentService from "./EnvironmentService";
import ErrorReportingService from "./ErrorReportingService";
import { checkOKResponse } from "./GraphService";

export default class CallupService {
  /**
   * Get an employee detail record by shortname
   * @param shortName : string : must be the shortname like chine1, or naile2
   */
  public static getEmployeeByShortName(shortName: string): Promise<AdwEmployee> {
    if (!shortName || !shortName.trim()) {
      return Promise.resolve(new AdwEmployee(null, true));
    }
    let callupApiUrl = `${EnvironmentService.getApigeeConfig().domain}/hr-management/callup/v3/contacts`;
    const messageId: string = "ADW-details-123456";

    return fetch(callupApiUrl, {
      method: "post",
      headers: {
        client_id: EnvironmentService.getApigeeConfig().clientId,
        "Content-Type": `application/json`,
        "X-NW-Message-ID": `${messageId}`,
        Authorization: AuthService.getAuthorizationHeader(),
      },
      body: JSON.stringify({
        shortName: shortName,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          ErrorReportingService.reportErrorWithResponse(
            response,
            "CallupService.ts -> getEmployeeByShortName",
            `Attempted to retrieve: ${response.url}`
          );
        }
        return response;
      })
      .then(checkOKResponse)
      .then(callupEmployeeData => callupEmployeeData.json())
      .then(employee => (employee && employee.contacts ? new AdwEmployee(employee.contacts[0]) : new AdwEmployee()))
      .catch(error => {
        console.error(error);
        return new AdwEmployee(null, true);
      });
  }

  /**
   *  Retrieve a Contact's direct reports (list)
   *  @param managerShortName : string : must be the shortname like chine1, or naile2
   */
  public static getDirectReportsList = (managerShortName: string): Promise<AdwEmployee[]> => {
    if (!managerShortName || !managerShortName.trim()) {
      return Promise.resolve([]);
    }

    let callupApiUrl = `${EnvironmentService.getApigeeConfig().domain}/hr-management/callup/v3/contacts/reports/current`;
    const messageId: string = "ADW-direct-reports-123456";

    return fetch(callupApiUrl, {
      method: "post",
      headers: {
        client_id: EnvironmentService.getApigeeConfig().clientId,
        "Content-Type": `application/json`,
        "X-NW-Message-ID": `${messageId}`,
        Authorization: AuthService.getAuthorizationHeader(),
      },
      body: JSON.stringify({
        shortName: managerShortName,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          ErrorReportingService.reportErrorWithResponse(
            response,
            "CallupService.ts -> getDirectReportsList",
            `Attempted to retrieve: ${response.url}`
          );
        }
        return response;
      })
      .then(checkOKResponse)
      .then(response => {
        return response.json();
      })
      .then((data: GetContactCollectionResponse) => {
        return data && data.contacts ? data.contacts : [];
      })
      .then((callupContacts) => {
        return callupContacts.map(employee => new AdwEmployee(employee));
      })
      .catch(error => {
        console.error(error);
        return [];
      });
  };

  /**
   *  Retrieve a Contact's admin (list)
   *  @param managerShortName : string : must be the shortname like farleys, or naile2
   */
  public static getCallupAdminList = (managerShortName: string): Promise<AdwEmployee[]> => {
    if (!managerShortName || !managerShortName.trim()) {
      return Promise.resolve([]);
    }
    let callupApiUrl = `${EnvironmentService.getApigeeConfig().domain}/hr-management/callup/v3/contacts/admin/current`;
    const messageId: string = "ADW-direct-reports-123456";

    return fetch(callupApiUrl, {
      method: "post",
      headers: {
        client_id: EnvironmentService.getApigeeConfig().clientId,
        "Content-Type": `application/json`,
        "X-NW-Message-ID": `${messageId}`,
        Authorization: AuthService.getAuthorizationHeader(),
      },
      body: JSON.stringify({
        shortName: managerShortName,
      })
    })
      .then((response) => {
        if (!response.ok) {
          ErrorReportingService.reportErrorWithResponse(
            response,
            "CallUpService.ts -> getCallupAdminList",
            `Attempted to retrieve: ${response.url}`
          );
        }
        return response;
      })
      .then(checkOKResponse)
      .then(callupDirectReports => {
        return callupDirectReports.json();
      })
      .then((data: GetContactCollectionResponse) => {
        return data && data.contacts ? data.contacts : [];
      })
      .then((callupContacts) => {
        return callupContacts.map(employee => new AdwEmployee(employee));
      })
      .catch(error => {
        console.error(error);
        return [];
      });
  };
}
