import React from "react";
import styles from "../../organisms/MegaMenu/MegaMenuView.module.scss";
import HamburgerButton from "../HamburgerButton/HamburgerButton";
import Link from "../../atoms/Link/Link";
import Image from "../../atoms/Image/Image";

export interface IMobileMegaMenuHeaderProps {
  handleButtonClick: () => void;
  logoSrc: string;
  mobileCollapsed: boolean;
}

const MobileMegaMenuHeader: React.FC<IMobileMegaMenuHeaderProps> = ({ handleButtonClick, logoSrc, mobileCollapsed }) => {
  return (
    <div className={styles.mobileMegaMenuHeader}>
      <HamburgerButton collapsed={mobileCollapsed} handleButtonClick={handleButtonClick} />
      <Link url={'/'} target={"_self"} linkClasses={styles.insideImage} id="mobile-mega-menu-inside-link">
        <Image alt="Welcome to Associate Digital Workspace" src={logoSrc} id="mobile-mega-menu-inside-image" />
      </Link>
    </div>
  );
};

export default MobileMegaMenuHeader;
