import { ErrorBoundary } from 'react-error-boundary';
import MainErrorMessage from "./components/organisms/MainErrorMessage/MainErrorMessage";
import ErrorReportingService from "./services/ErrorReportingService";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ScrollIntoView from "./components/atoms/ScrollIntoView/ScrollIntoView";
import LoadingSpinner from "./components/atoms/LoadingSpinner/LoadingSpinner";
import React, { Suspense, useContext } from "react";
import DefaultLayout from "./components/layouts/DefaultLayout/DefaultLayout";
import NwCustomizeCommonToolsView from "./components/organisms/NwCommonTools/NwCommonToolsView";
import CallupErrorMessage from "./components/organisms/CallupErrorMessage/CallupErrorMessage";
import AtoZView from "./components/organisms/AtoZView/AtoZView";
import CalendarEvents from "./components/organisms/CalendarEventView/CalendarEvents";
import NwOrgChart from "./components/organisms/NwOrgChart/NwOrgChart";
import NewSearch from "./components/organisms/NewSearch/NewSearch";
import EmployeeDetailView from "./components/organisms/EmployeeDetailView/EmployeeDetailView";
import SeeAllAnnouncementsView from "./components/organisms/SeeAllAnnouncements/SeeAllAnnouncementsView";
import HomePage from "./components/pages/home/Home";
import styles from "./App.module.scss";
import { AdwContext, AdwContextType } from "./context/AdwContext"; // Adjust the import path as necessary

const NonPrimaryApp: React.FC<{}> = () => {

  const [appState]: AdwContextType = useContext(AdwContext);

  return (
    <ErrorBoundary FallbackComponent={MainErrorMessage} onError={(error, info) => {
        ErrorReportingService.reportErrorWithMessage(
          error.toString(),
          "ErrorBoundary.tsx -> MainErrorMessage -> BrowserRouter App.tsx",
          appState.user.nwieID
        )
      }}>
        <BrowserRouter>
          <ScrollIntoView>
            <ErrorBoundary FallbackComponent={MainErrorMessage} onError={(error, info) => {
              ErrorReportingService.reportErrorWithMessage(
                error.toString(),
                "ErrorBoundary.tsx -> MainErrorMessage -> Routes",
                appState.user.nwieID
              )
            }}>
              <Suspense fallback={<LoadingSpinner/>}>
                <Routes>
                  <Route
                    path="/"
                    element={<DefaultLayout />}
                  >
                    <Route
                      path="/"
                      element={<HomePage className={styles.padded} />}
                    />
                    <Route
                      path="/customizetools"
                      element={
                        <NwCustomizeCommonToolsView />
                      }
                    />
                    <Route
                      path="/SeeAllAnnouncements"
                      element={
                        <SeeAllAnnouncementsView />
                      }
                    />
                    <Route
                      path="/employeeDetail"
                      element={<ErrorBoundary FallbackComponent={CallupErrorMessage} onError={(error, info) => {
                        ErrorReportingService.reportErrorWithMessage(
                          error.toString(),
                          "ErrorBoundary.tsx -> CallupErrorMessage -> EmployeeDetailView",
                          appState.user.nwieID
                        )
                      }}>
                        <EmployeeDetailView />
                      </ErrorBoundary>
                      }
                    />
                    <Route
                      path="/SearchResults"
                      element={<NewSearch />}
                    />
                    <Route
                      path="/AtoZ"
                      element={
                        <AtoZView />
                      }
                    />
                    <Route
                      path="/event-calendar"
                      element={
                        <CalendarEvents />
                      }
                    />
                    <Route
                      path="/org-chart"
                      element={<NwOrgChart currentAuthenticatedUser={appState && appState.user} />
                      }
                    />
                  </Route>
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </ScrollIntoView>
        </BrowserRouter>
      </ErrorBoundary>
  );
}
export default NonPrimaryApp;