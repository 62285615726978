import React from "react";
import AuthService from "../../../services/AuthService";

const handleAuthMessage = (event: MessageEvent): void => {
  if (!AuthService.hasActiveSession() && event.data.refreshSession) {
    AuthService.startAuthentication();
  }
}

class AuthRedirect extends React.Component {

  public componentDidMount() {
    window.addEventListener("message", handleAuthMessage);
  }

  public componentWillUnmount() {
    window.removeEventListener("message", handleAuthMessage);
  }

  private static getIdTokenFromUrl(): string | boolean {
    let idToken;
    let stateMatches: boolean = false;
    window.location.hash
      .substring(1) // Remove '#' character
      .split("&") // Divide into key/value pairs
      .forEach((hashPart: string) => {
        const [key, value] = hashPart.split("=");
        if (key) {
          if (key.toLowerCase() === "id_token") {
            idToken = value;
          } else if (key.toLowerCase() === "state") {
            stateMatches = AuthService.getState() === value;
          }
        }
      });
    return stateMatches && idToken;
  }

  public render() {
    const idToken: string | boolean = AuthRedirect.getIdTokenFromUrl();
    if (idToken) {
      AuthService.manageAuthentication(idToken as string);
    } else if (AuthService.hasActiveSession()) {
      AuthService.manageAuthentication();
    } else {
      AuthService.startAuthentication();
    }
    return null;
  }
}

export default AuthRedirect;
