import React, { useContext, useEffect, useState } from "react";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AdwCommonTool from "../../../types/CommonToolsTypes/AdwCommonTool";
import styles from "./AtoZFavorites.module.scss";
import { useNavigate } from "react-router-dom";
import Link from "../../atoms/Link/Link";
import NoResults from "../../molecules/NoResults/NoResults";
import AdwAtoZView from "../../../types/AtoZViewTypes/AdwAtoZView";
import {ReactComponent as GearIcon} from "../../../assets/images/Gear.svg";

export interface IAtoZFavoritesProps {
  onViewAllClicked:any
}

export interface IAtoZFavoritesState {
  pageOfItems: AdwCommonTool[];
  isToggleDisable: boolean;
  pageNumber: number;
  toolsToDisplay: AdwCommonTool[];
}

const AtoZFavoritesView: React.FC<IAtoZFavoritesProps> = ({onViewAllClicked}) => {
  const navigate = useNavigate();
  const [appState]: AdwContextType = useContext(AdwContext);

  const [sitesAToZFavorites, setSitesAToZFavorites] = useState([]);

  let aToZLinks: AdwAtoZView[] =
    (appState && appState.lists && appState.lists.aToZView) || [];
  let favSite: string =
    (appState && appState.user && appState.user.sitesAToZFavorites) || "";

    useEffect(() => {
      let sites = AdwAtoZView.getFavoriteSite(favSite, aToZLinks);
      setSitesAToZFavorites(sites);
    }, [favSite, aToZLinks]);
    
    const showResultsOrMessage = (siteList: AdwAtoZView[]): JSX.Element | JSX.Element[] => {
      return (<>
        {siteList && siteList.length > 0 ? (
          <>
            {siteList.map(
                  (link, index) => ( 
                    link && (
                    <li className={styles.myToolLink} id={`favorite-site-${link.AtoZId}`}
                    key={`favorite-site-${link.AtoZId}`}>
                    <Link linkClasses={styles.linkClass} id={`sites-a-z-link-${index}`} url={link.Url} target={link.Target} >
                    <div ><span id={`sites-a-z-link-${index}`}  className={styles.toolTitleText}>{link.SiteName ? link.SiteName : ""}</span></div>
                  </Link>
                  </li>
                  )
                  )
                )
              }
              </>)
              :(
              <NoResults
              imagePath={require("../../../assets/images/star.png")}
              textForDisplay="You haven't favorited any items in A-Z yet. Visit A-Z and try clicking the star next to some of your favorites!"
              id="AtoZ-NoResults"
            />)
    }
    </>
      )
    }  
  return ( 
    <div className={styles.AtoZFavWrapper} id="AtoZ-Links-Wrapper">
      <div className={styles.toolsHeading}>
        
      <a href="/atoz" target="_blank" id={`all-atoz-link`}>
        <h4 className={styles.title} id="AtoZ-Link-Title">
          <strong>A-Z favorites</strong>
        </h4>
       </a>
         
        <div className={styles.viewAllLink}>
          <button className={styles.viewAllToolsLink} onClick={(e)=>{
             e.preventDefault();
             e.stopPropagation();
             onViewAllClicked();
             navigate('/atoz');
          }}  id={`view-all-atoz-link`}>
          <GearIcon className={styles.gearIconSvg}/>
                <span className={styles.viewAllLinkText}>
                   View all
                </span>
               </button>
          </div>
      </div>
    
      <ul className={styles.toolsDisplay}>
   {showResultsOrMessage(sitesAToZFavorites)}
      </ul>

      <div id="toolsDropdown"></div>
    </div>
  
  );
};

export default AtoZFavoritesView;
