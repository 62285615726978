import AdwAtoZLink from "../types/AtoZtypes/AdwAtoZLink";

export type InsideUserData = {
  adwLoginId: string;
  nwieID: string;
  employeeNumber: string;
  emailAddress: string;
  telephoneNumber: string;
  title: string;
  employeetype: string;
  givenName: string;
  preferredCommonTools: string;
  hrPreferredWidgets: string;
  lastToolsVisit: string;
  checkedReminders: string;
  weatherZip: string;
  aToZFavorites: AdwAtoZLink[];
  preferredName: string;
  sitesAToZFavorites: string;
  recentlyVisitedSites: string;
  postOfficeBox: string;
  department: string;
  whatsChangedStatus: string;
  dismissedAnnouncements: string;
};

/**
 * In the event, that our insideUSerData object is falsey, we build one
 * @param adwLoginId this is the id the user is logged into sharepoint with
 * @returns InsideUserData object for consumption
 */
export function buildInsideUserData(adwLoginId: string): InsideUserData {
  let insideUser: InsideUserData;
  insideUser = {
    aToZFavorites: null,
    adwLoginId: adwLoginId,
    checkedReminders: "0",
    emailAddress: `${adwLoginId}@nationwide.com`,
    employeeNumber: "1",
    employeetype: "E",
    givenName: null,
    lastToolsVisit: "2/26/2019, 2:10:52 PM",
    nwieID: null,
    preferredCommonTools: null,
    hrPreferredWidgets: null,
    preferredName: null,
    telephoneNumber: "6148675309",
    title: "Development",
    weatherZip: "43215",
    sitesAToZFavorites: null,
    recentlyVisitedSites: null,
    postOfficeBox: null,
    department: null,
    whatsChangedStatus:null,
    dismissedAnnouncements: '', 
  };
  return insideUser;
}
