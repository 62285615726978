import React from "react";
import styles from "./PopularToolsButton.module.scss";
import Link from "../Link/Link";
import AdwPopularTool from "../../../types/PopularToolsTypes/AdwPopularTool";

export interface ICircleProps {
  tool:AdwPopularTool
}
const PopularToolsButton: React.FC<ICircleProps> = ({
  tool
}) => {
  return (
    <li className={styles.popularToolsItem}>
    <Link linkClasses={styles.popularToolsLink} target={tool.SiteTarget}
    url={tool.AppUrl} 
    id={`tool-${tool.Name}`}>
    {tool.Name}
    </Link>
    </li>
  );
};

export default PopularToolsButton;
