import AdwDateTimeService from "../../services/AdwDateTimeService";
import { SpNewsStory } from "./SpNewsStory";

export const DEFAULT_NEWS_IMAGE = require("../../assets/images/NandEagle_white_News_image.png");

class AdwNewsStory {
  public id: number;
  public title: string;
  public publishDate: string;
  public publishDateLong: string;
  public storySummary: string;
  public storyLink: string;
  public windowTarget: string;
  public imagePath: string;
  public imageAlternativeText: string;
  public publishMonth: any;
  public publishYear: number;
  public tags: string[];
  public contentTime: string;
  public contentAction: string;

  constructor(spNewsStory: SpNewsStory) {
    this.buildAdwNewsStory(spNewsStory);
  }

  public buildAdwNewsStory(spNewsStory: SpNewsStory): void {
    this.id = spNewsStory.fields.id;
    this.title = spNewsStory.fields.Title;
    this.publishDate = this.setPublishDate(spNewsStory.fields.PublishDate);
    this.storySummary = spNewsStory.fields.StorySummary;
    this.storyLink = spNewsStory.fields.Link ? spNewsStory.fields.Link.Url : null;
    this.windowTarget = spNewsStory.fields.WindowTarget;
    this.imageAlternativeText = spNewsStory.fields.ImageAlternateText;
    this.imagePath = this.setImagePath(spNewsStory);
    this.publishDateLong = spNewsStory.fields.PublishDate;
    this.publishMonth = this.setPublishMonth(spNewsStory.fields.PublishDate);
    this.publishYear = this.setPublishYear(spNewsStory.fields.PublishDate);
    this.tags = spNewsStory.fields.Tags;
    this.contentTime = spNewsStory.fields.ContentTime;
    this.contentAction = spNewsStory.fields.ContentAction;
  }

  public setPublishDate = (dateString: string) => {
    const publishDate: Date = new Date(dateString);
    return `${AdwDateTimeService.getMonthName(publishDate.getMonth())} ${publishDate.getDate()}, ${publishDate.getFullYear()}`;
  };

  public setPublishMonth = (dateString: string) => {
    const publishDate: Date = new Date(dateString);
    return `${AdwDateTimeService.getMonthName(publishDate.getMonth())} ${publishDate.getFullYear()}`;
  }

  public setPublishYear = (dateString: string) => {
    const publishDate: Date = new Date(dateString);
    return publishDate.getFullYear();
  }

  public setImagePath = (spNewsStory: SpNewsStory): string => {
    return spNewsStory.fields.ImagePath
      ? spNewsStory.fields.ImagePath
      : DEFAULT_NEWS_IMAGE;
  };
}

export default AdwNewsStory;
