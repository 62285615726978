import React from "react";
import classNames from 'classnames';

import AdwNewsStory from "../../../types/CompanyNewsTypes/AdwNewsStory";

import styles from './ContentTime.module.scss';

import videoPlayIcon from "../../../assets/images/video_play_icon.svg";
import linkIcon from "../../../assets/images/Link_icon.svg";
import documentIcon from "../../../assets/images/Document-icon.svg";

type ContentTimeType = Pick<AdwNewsStory, 'contentAction' | 'contentTime'>;

interface IContentType {
  icon: string;
  alt: "Document" | "Video Play" | "Link" | "Listen Audio";
  time: string;
}

const ContentTime: React.FC<ContentTimeType> = ({ contentAction = "Newsroom", contentTime = "2" }: ContentTimeType) => {

  const contentTypes: Record<string, IContentType> = {
    read: { icon: documentIcon, alt: "Document", time: contentTime, },
    watch: { icon: videoPlayIcon, alt: "Video Play", time: contentTime },
    listen: { icon: videoPlayIcon, alt: "Listen Audio", time: contentTime },
    default: { icon: linkIcon, alt: "Link", time: "" },
  };

  const getContentType = (value: string): IContentType => {
    const contentTypeKey = Object.keys(contentTypes).find(key => value.includes(key));
    return contentTypes[contentTypeKey] || contentTypes.default;
  };

  const { icon: contentIcon, alt, time } = getContentType(contentAction);

  return (
    <div className={styles.contentTime}>
      <img
        src={contentIcon}
        className={classNames({
          [styles.readIcon]: alt === 'Document',
          [styles.watchIcon]: alt === 'Video Play',
          [styles.listenIcon]: alt === 'Listen Audio',
          [styles.defaultIcon]: alt === 'Link',
        })}
        alt={`${alt} icon`}
      />
      <span>{`${time} ${contentAction}`}</span>
    </div>
  );
};

export default ContentTime;