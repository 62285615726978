import React, { useEffect, useState, useContext } from "react";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import EnvironmentService from "../../../services/EnvironmentService";
import styles from "./AtoZView.module.scss";
import AdwAtoZView from "../../../types/AtoZViewTypes/AdwAtoZView";
import Image from "../../atoms/Image/Image";
import AtoZLink from "../../atoms/AtoZLink/AtoZLink";
import Letter from "../../molecules/Letter/Letter";
import AtoZMobileNav from "../../molecules/AtoZMobileNav/AtoZMobileNav";
import FilterTab from "../../molecules/AtoZTabs/FilterTab";
import { InsideUserData } from "../../../types/InsideUserData";
import { SetUserPreferences } from "../../../context/ActionTypes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../atoms/LoadingSpinner/LoadingSpinner";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import searchStyles from "../../molecules/SearchBar/MindbreezeAutoCompleteWithSearch.module.scss";

//displays table with alternating white/grey rows with the appropriate site/description duo in each one alphabetically
const AtoZView: React.FC = () => {
  document.title = "InSide - Sites A to Z";
  const [appState, dispatch]: AdwContextType = useContext(AdwContext);
  const alphabet = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  const aToZLinks: AdwAtoZView[] =(appState && appState.lists && appState.lists.aToZView) || [];
  const favSite: string =(appState && appState.user && appState.user.sitesAToZFavorites) || "";
  const visitedSite: string =(appState && appState.user && appState.user.recentlyVisitedSites) || "";
  const [activeLetter, setActiveLetter] = useState(null);
  const [searchString, setSearchString] = useState(null);
  const [sitesAToZFavorites, setSitesAToZFavorites] = useState([]);
  const [recentlyVisitedSites, setRecentlyVisitedSites] = useState([]);
  const [aToZLinksDisplayed, setAToZLinksDisplayed] = React.useState([]);
  const [favLinkCount, setFavLinkCount] = React.useState(0);
  const [sortOrder, setSortOrder] = React.useState<boolean>(false);
  const [activeFilter, setActiveFilter] = React.useState<string>("All");
  const [loadingData, setLoadingData] = React.useState(true);
  const [isMaxFav, setIsMaxFav] = React.useState(false);
  const [displayMessage, setDisplayMessage] = React.useState("none");

  useEffect(() => {
    setLoadingData(true);
    setAToZLinksDisplayed(aToZLinks);
    setLoadingData(false);
  }, [aToZLinks]);

  useEffect(() => {
    let sites = AdwAtoZView.getFavoriteSite(favSite, aToZLinks);
    setSitesAToZFavorites(sites);
    setFavLinkCount(sites.length);
  }, [favSite, aToZLinks]);

  useEffect(() => {
    if (visitedSite) {
      let sites = AdwAtoZView.getVisitedSite(visitedSite, aToZLinks);
      setRecentlyVisitedSites(sites);
    }
  }, [visitedSite, aToZLinks]);

  useEffect(() => {
    const searchBar = document.getElementById(searchStyles.search);

    if (searchBar) {
      searchBar.style.display = 'none';
    }

    return () => {
      if (searchBar) {
        searchBar.style.display = 'block';
      }
    }
  }, [])

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    let aToZNav = document.getElementById("AtoZNav");
    if (
      document.body.scrollTop > 140 ||
      document.documentElement.scrollTop > 140
    ) {
      if (aToZNav) {
        document.getElementById("AtoZNav").style.backgroundColor = "#ffffff";
        document.getElementById("AtoZNav").style.borderBottom =
          "4px solid #0047bb";
      }
    } else {
      if (aToZNav) {
        document.getElementById("AtoZNav").style.backgroundColor = "#ffffff";
        document.getElementById("AtoZNav").style.borderBottom =
          "0px solid #0047bb";
      }
    }
    let aToZNavMob = document.getElementById("AtoZNavMob");
    if (
      document.body.scrollTop > 140 ||
      document.documentElement.scrollTop > 140
    ) {
      if (aToZNavMob) {
        document.getElementById("AtoZNavMob").style.backgroundColor = "#ffffff";
        document.getElementById("AtoZNavMob").style.borderTop =
          "4px solid #0047bb";
      }
    } else {
      if (aToZNavMob) {
        document.getElementById("AtoZNavMob").style.backgroundColor = "#ffffff";
        document.getElementById("AtoZNavMob").style.borderTop =
          "0px solid #0047bb";
        document.getElementById("AtoZNavMob").style.borderBottom =
          "0px solid #0047bb";
      }
    }
  }

  const compareSiteNames =(aToZLinksDisplayed:AdwAtoZView[])=> {
    return aToZLinksDisplayed.sort((siteA, siteB) => {
    let siteNameA = siteA.SiteName.toUpperCase();
    let siteNameB = siteB.SiteName.toUpperCase();
    setSortOrder(!sortOrder);
    if (!sortOrder) {
      if (siteNameA < siteNameB) {
        return 1;
      }
      if (siteNameA > siteNameB) {
        return -1;
      }
    } else {
      if (siteNameA < siteNameB) {
        return -1;
      }
      if (siteNameA > siteNameB) {
        return 1;
      }
    }
    return 0;
  })
}

  const sortByName = (aToZLinksDisplayed: AdwAtoZView[]) => {
    if (aToZLinksDisplayed.length > 0) {
// Sort the array based on the compareSiteNames function
const sortedAToZLinksDisplayed = compareSiteNames(aToZLinksDisplayed);
      setAToZLinksDisplayed(sortedAToZLinksDisplayed);
      return sortedAToZLinksDisplayed;
    } else {
      return;
    }
  };

  const handleLetterClick = (letter: string) => {
    const list = aToZLinks.filter((aToZLink) =>
      aToZLink.SiteName.toLowerCase().startsWith(letter.toLowerCase())
    );
    setActiveLetter(letter);
    setAToZLinksDisplayed(list);
  };

  const backToTopClick = () => {
    setAToZLinksDisplayed(aToZLinks);
  };

  const handleFilterTabClick = (filterType: string, filterString: string) => {
    let list = [];
    setActiveFilter(filterType);
    if (filterType === "Favorites") {
      list = sitesAToZFavorites;
    } else if (filterType === "Recently Visited") {
      list = recentlyVisitedSites;
    } else {
      list = aToZLinks;
    }
    if (filterString !== "" && filterString !== null && filterType === "All") {
      if (filterString.length > 1) {
        list = list.filter((list) =>
          list.SiteName.toLowerCase().includes(filterString.toLowerCase())
        );
        if (list.length === 0) {
          setDisplayMessage("inline");
        }
      }
    } else {
      setSearchString(null);
    }
    setAToZLinksDisplayed(list);
    setActiveLetter(null);
  };

  function handleSearchOnChange(value: string): void {
    if (activeFilter === "All") {
      if (value.length > 1) {
        setSearchString(value);
      } else if (value.length === 1) {
        //check if letter, enable alphabet navigation
        if (/^[a-zA-Z]+$/.test(value)) {
          handleLetterClick(value);

        //set back to 'All' filter   
        }else{
          handleFilterTabClick(activeFilter, value);
        }
      }
    } else {
      setSearchString(null);
    }

    if (value.length > 1 || value.length === 0) {
      handleFilterTabClick(activeFilter, value);
    }
  }

  /* on fav change event */
  function refreshSelectOptions(isFav, linkId) {
    const insideUserData: InsideUserData = Object.assign({}, appState.user);
    //get current fav and visited string
    let aToZFavoritesString = appState.user.sitesAToZFavorites || "";
    let aToZrecentlyVisitedString = appState.user.recentlyVisitedSites;
    // convert to number array
    let favSiteArray =
      AdwAtoZView.convertSitesStringToArray(aToZFavoritesString);
    let recentSiteArray = AdwAtoZView.convertSitesStringToArray(
      aToZrecentlyVisitedString
    );
    // Get a count
    let favCount = favSiteArray.length;
    //if isFav is true & count is less than 10 -> check visited array to see if linkId exist
    let doExistInFavList = favSiteArray.find(
      (element) => Number(linkId) === element
    );
    let doExistInRecentList = recentSiteArray.find(
      (element) => Number(linkId) === element
    );
    if (favCount >= 10 && doExistInFavList === undefined && isFav) {
      toast(
        "You have selected the maximum number of favorites (10). Please remove a favorite before adding a new one"
      );
      setIsMaxFav(true);
    }
    if (favCount < 10 && doExistInFavList === undefined && isFav) {
      if (doExistInRecentList) {
        /* Find index of user preference */
        let objIndex = recentSiteArray.findIndex(
          (obj) => obj === Number(linkId)
        );
        recentSiteArray.splice(objIndex, 1);
        insideUserData.recentlyVisitedSites = recentSiteArray.join(", ");
      }
      favSiteArray.unshift(linkId);
      setFavLinkCount(favSiteArray.length);
      insideUserData.sitesAToZFavorites = favSiteArray.join(", ");
      dispatch(SetUserPreferences(insideUserData));
    }
    if (favCount <= 10 && doExistInFavList !== undefined && !isFav) {
      /* Find index of user preference */
      let objIndex = favSiteArray.findIndex((obj) => obj === Number(linkId));
      favSiteArray.splice(objIndex, 1);
      setFavLinkCount(favSiteArray.length);
      insideUserData.sitesAToZFavorites = favSiteArray.join(", ");
      dispatch(SetUserPreferences(insideUserData));
    }
  }

  function trackRecentlyVisitedSites(linkId) {
    //check if it exist in fav ? if yes do not add to recent list but navigate user away
    // if no then add to begining of recent list and save user pref
    // if recent is 10 or more remove last and add to the begining of list
    const insideUserData: InsideUserData = Object.assign({}, appState.user);
    //get current fav and visited string
    let aToZFavoritesString = appState.user.sitesAToZFavorites;
    let aToZrecentlyVisitedString = appState.user.recentlyVisitedSites;
    // convert to number array
    let favSiteArray =
      AdwAtoZView.convertSitesStringToArray(aToZFavoritesString);
    let recentSiteArray = AdwAtoZView.convertSitesStringToArray(
      aToZrecentlyVisitedString
    );
    // Get a count
    let recentCount = recentSiteArray.length;
    //if isFav is true & count is less than 10 -> check visited array to see if linkId exist
    let doExistInFavList = favSiteArray.find(
      (element) => Number(linkId) === element
    );
    let doExistInRecentList = recentSiteArray.find(
      (element) => Number(linkId) === element
    );
    if (doExistInFavList === undefined) {
      if (doExistInRecentList !== undefined) {
        // remove earlier and add new to list
        /* Find index of user preference */
        let objIndex = recentSiteArray.findIndex(
          (obj) => obj === Number(linkId)
        );
        recentSiteArray.splice(objIndex, 1);
        recentSiteArray.unshift(linkId);
        insideUserData.recentlyVisitedSites = recentSiteArray.join(", ");
        dispatch(SetUserPreferences(insideUserData));
      } else if (recentCount >= 10 && doExistInRecentList === undefined) {
        // remove last and add at begining of list
        recentSiteArray.pop();
        recentSiteArray.unshift(linkId);
        insideUserData.recentlyVisitedSites = recentSiteArray.join(", ");
        dispatch(SetUserPreferences(insideUserData));
      } else {
        // add to begining of the list
        recentSiteArray.unshift(linkId);
        insideUserData.recentlyVisitedSites = recentSiteArray.join(", ");
        dispatch(SetUserPreferences(insideUserData));
      }
    }
  }

  const handleDragEnd = (event) => {
    if (activeFilter === "Favorites") {
      if (!event.destination) return;
      let temporaryData = Array.from(sitesAToZFavorites);
      let [source_data] = temporaryData.splice(event.source.index, 1);
      temporaryData.splice(event.destination.index, 0, source_data);
      setSitesAToZFavorites(temporaryData);
      const AtoZIds = temporaryData.map((data) => data.AtoZId);
      const insideUserData: InsideUserData = Object.assign({}, appState.user);
      insideUserData.sitesAToZFavorites = AtoZIds.join(", ");
      dispatch(SetUserPreferences(insideUserData));
    }
  };

  const getData = (filter) => {
    if (filter === "Favorites") {
      return (
        <Droppable droppableId="droppable-1">
          {(provider) => {
            return (
              <tbody ref={provider.innerRef} {...provider.droppableProps}>
                {sitesAToZFavorites.map(function createLink(link, index) {
                  return (
                    <Draggable
                      key={link.Url}
                      draggableId={link.Url}
                      index={index}
                    >
                      {(provider1) => (
                        <AtoZLink
                          isMaxFav={isMaxFav}
                          provider={provider1}
                          setIsMaxFav={setIsMaxFav}
                          favLinkCount={favLinkCount}
                          key={link.AtoZId}
                          link={link}
                          activeFilter={activeFilter}
                          refreshSelectOptions={refreshSelectOptions}
                          trackRecentlyVisitedSites={trackRecentlyVisitedSites}
                        />
                      )}
                    </Draggable>
                  );
                })}
                {provider.placeholder}
              </tbody>
            );
          }}
        </Droppable>
      );
    } else if (filter === "Recently Visited") {
      return recentlyVisitedSites.map(function createLink(link, index) {
        return (
          <AtoZLink
            isMaxFav={isMaxFav}
            setIsMaxFav={setIsMaxFav}
            favLinkCount={favLinkCount}
            key={link.AtoZId}
            link={link}
            provider={null}
            activeFilter={activeFilter}
            refreshSelectOptions={refreshSelectOptions}
            trackRecentlyVisitedSites={trackRecentlyVisitedSites}
          />
        );
      });
    } else {
      return aToZLinksDisplayed.map(function createLink(link, index) {
        return (
          <AtoZLink
            isMaxFav={isMaxFav}
            setIsMaxFav={setIsMaxFav}
            favLinkCount={favLinkCount}
            key={link.AtoZId}
            link={link}
            provider={null}
            activeFilter={activeFilter}
            refreshSelectOptions={refreshSelectOptions}
            trackRecentlyVisitedSites={trackRecentlyVisitedSites}
          />
        );
      });
    }
  };

  return (
    <div className={styles.aToZView}>
        <div className={styles.aToZViewTitle}>
          <header className={styles.header}>
            <h1>Sites A to Z</h1>
          </header>
          <hr className={styles.line} />
          <a
            className={styles.feedbackLink}
            href={EnvironmentService.getNewAtoZFeedbackUrl()}
            target="_blank"
            rel="noopener noreferrer"
          >
            Give us feedback or request a new site
          </a>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={5000}
          className="foo"
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div id="AtoZNav" className={styles.letterNav}>
          <div className={styles.letterInnerWrap}>
              {alphabet.map(
                (letter) =>
                  letter && (
                    <Letter
                      activeFilter={activeFilter}
                      key={letter}
                      activeLetter={activeLetter === letter ? true : false}
                      letter={letter}
                      sites={aToZLinks}
                      handleLetterClick={handleLetterClick}
                    />
                  )
              )}
          </div>
        </div>

        <div id="FilterView" className={styles.filterView}>
            <div id="FilterTabWrap" className={styles.filterTabWrap}>
                <FilterTab
                  activeFilter={activeFilter}
                  activeLetter={activeLetter}
                  label="All"
                  onPress={() => handleFilterTabClick("All", searchString)}
                  count={0}
                />
                <FilterTab
                  activeFilter={activeFilter}
                  activeLetter={activeLetter}
                  label="Favorites"
                  onPress={() => handleFilterTabClick("Favorites", "")}
                  count={favLinkCount}
                />
                <FilterTab
                  activeFilter={activeFilter}
                  activeLetter={activeLetter}
                  label="Recently Visited"
                  onPress={() => handleFilterTabClick("Recently Visited", "")}
                  count={0}
                />
            </div>
          
          <div id="FilterSearchWrap" className={styles.filterSearchWrap}>
           {
           activeFilter === "All" ?
            <label id="searchDiv">Filter A to Z:  <input type="text" id="searchText" onChange={(e) => handleSearchOnChange(e.target.value)}/></label>
          :
            <div></div>
          }
          </div>
        </div>

      <div id="AtoZNavMob" className={styles.letterNavMob}>
        <AtoZMobileNav activeFilter={activeFilter} alphabet={alphabet} sites={aToZLinks} handleLetterClick={handleLetterClick} backToTopClick={backToTopClick} />
      </div>
      <div className={styles.tableWrapper}>
        <DragDropContext onDragEnd={handleDragEnd}>
        <table>
        <thead>
          <tr>
            {activeFilter !== "Recently Visited" && <th className={styles.starHeader}> </th>}
            <th className={`${styles.nameHeader} ${activeFilter === "Recently Visited" && styles.nameHeaderForRecentlyVisited}`}><p tab-index="0" className={styles.name}>Name
              {(aToZLinksDisplayed.length > 0 && activeFilter === "All") && <a onClick={() => sortByName(aToZLinksDisplayed)} href="#" title="Sort By Name"><Image src={require("../../../assets/images/sort-down.svg").default} alt=""
                imageClasses={!sortOrder ? styles.sortIconDown : styles.sortIconUp} id="sort-arrow-icon" /></a>}</p>
            </th>
            <th className={`${styles.descriptionHeader} ${activeFilter === "Recently Visited" && styles.descriptionHeaderForRecentlyVisited}`}><p className={styles.name}>Description</p></th>
            {activeFilter === "Favorites" && <th className={styles.dragAndDropIconHeader}></th>}
          </tr>
          </thead>
          <tbody>
      
          {loadingData ?
            <LoadingSpinner />
            :<>
            {
            (aToZLinksDisplayed.length > 0) ? 
              getData(activeFilter)
              :<>
              {(aToZLinksDisplayed.length === 0 && activeFilter === "All") ?
                <tr><td colSpan={3} style={{textAlign:"left", paddingTop:"15px"}}><span className={styles.noMatch} style={{display:displayMessage}}>There are no sites that match "{searchString}".</span></td></tr>
                :
                <tr><td colSpan={3}></td></tr>  
              } 
              </>
            }
            
           </>  
           }    
          </tbody>
        </table>
        </DragDropContext>
      </div>
    </div>
  )
}

export default AtoZView;
