import { SpCalendarEventType } from "./SpCalendarEventType";

class AdwCalendarEventType {
  public EventTypeId: number;
  public EventType: string;
  public EventColor: string;

  constructor(spCalendarEventType?: SpCalendarEventType) {
    if (spCalendarEventType) {
      this.EventTypeId = spCalendarEventType.fields.EVENT_TYPE_ID; //check API call data
      this.EventType = spCalendarEventType.fields.EVENT_TYPE;
      this.EventColor = spCalendarEventType.fields.EVENT_COLOR;
    }
  }
}

export default AdwCalendarEventType;