import React, { useContext } from "react";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AdwFooterMenuColumn from "../../../types/FooterTypes/AdwFooterMenuColumn";
import AdwFooterMenuItem from "../../../types/FooterTypes/AdwFooterMenuItem";
import AdwFooterMenuLink from "../../../types/FooterTypes/AdwFooterMenuLink";
import Link from "../../atoms/Link/Link";
import styles from "./FooterMenu.module.scss";

const FooterMenu: React.FC = (): JSX.Element => {
  const [appState]: AdwContextType = useContext(AdwContext);
  let footerMenuArray: AdwFooterMenuItem[] = appState && appState.lists && appState.lists.footerNavigation ? appState.lists.footerNavigation : [];
  let footerMenuColumnArray: AdwFooterMenuColumn[] = groupMenuItems(footerMenuArray);
  return (
    <div className={styles.footerMenu} id="footer-menu-wrapper">

      {footerMenuColumnArray && footerMenuColumnArray.map((footerMenuColumn, index) => (
        footerMenuColumn && footerMenuColumn.heading && 

          footerMenuColumn.links && footerMenuColumn.links.map((footerMenuLink, index) => (
            footerMenuLink && 
            <div id={`footer-item-${index}`} key={`footer-item-${footerMenuLink.Description}`}>
              {renderTextOrLink(footerMenuLink, index)}
            </div>
          ))

      ))}
    </div>
  );
};

export function renderTextOrLink(footerLink: AdwFooterMenuLink, index: number): JSX.Element {
  return footerLink && footerLink.Description && footerLink.Url === "https://www.nolink.com" ? (
    <div className={styles.footerItem} id={`footer-menu-text-${index}`}>
      {footerLink.Description}
    </div>
  ) : ( footerLink && footerLink.Url && footerLink.WindowTarget && footerLink.Description &&
        <Link
        id={`footer-menu-link-${index}`}
        url={footerLink.Url}
        target={footerLink.WindowTarget}
        linkClasses={styles.footerLink}
        linkText={footerLink.Description}
        />
  );
}

export function groupMenuItems(footerMenuArray: AdwFooterMenuItem[]): AdwFooterMenuColumn[] {
  let menu: AdwFooterMenuColumn[] = [];
  // Group links by category
  footerMenuArray && footerMenuArray.forEach((item: AdwFooterMenuItem) => {
    if (item && item.approvedForDisplay) {
      const link: AdwFooterMenuLink = new AdwFooterMenuLink(item);
      const category: AdwFooterMenuColumn = menu[item.HeadingOrder] ? menu[item.HeadingOrder] : {heading: item.Heading, links: [], order: item.HeadingOrder};
      category.links = category.links.concat(link);
      menu[item.HeadingOrder] = category;
    }
  })
  // Remove any null categories from the list caused by gaps in ordering (ex: ording 1,2,4 will cause an empty category in 3)
  menu = menu.filter(category => !!category);
  // Remove any empty categories and order links within each category
  menu.forEach(category => {
    const sortedLinks = [...category.links].sort((ItemA, ItemB) => ItemA.Order - ItemB.Order);
    category.links = sortedLinks;
    // category.links = category.links.sort((ItemA, ItemB) => ItemA.Order - ItemB.Order)
  })
  return menu;
}

export default FooterMenu;
