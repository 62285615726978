import React from "react";
import styles from "./HamburgerButton.module.scss";

export interface IHamburgerButtonProps {
  collapsed: boolean;
  handleButtonClick: () => void;
}

const HamburgerButton: React.FC<IHamburgerButtonProps> = ({ collapsed, handleButtonClick }) => {
  const menuCollapsed: string = require("../../../assets/images/nav-icon_mobile.svg").default;
  const menuOpen: string = require("../../../assets/images/Close-icon.svg").default;
 
return (
    <div className={styles.hamburgerButton} onClick={handleButtonClick}>
      {collapsed ?
      <>
      <img
                      src={menuCollapsed}
                      className={styles.hamburgerMenuIcon}
                      alt="menu"
                    />
      <span className={styles.hamburgertText}>MENU</span>
      </>
     :
     <>
           <img
           src={menuOpen}
           className={styles.hamburgerMenuIcon}
           alt="menu"
         />
<span className={styles.hamburgertText}>CLOSE</span>
</>
      }
    </div>
  );
};

HamburgerButton.defaultProps = {
  collapsed: false,
};

export default HamburgerButton;
