import React, { useContext, useEffect, useState } from "react";
import styles from "./AtoZLink.module.scss";
import AdwAtoZView from "../../../types/AtoZViewTypes/AdwAtoZView";
import FavoritesStar from "../FavoritesStar/FavoritesStar";
import { AdwContextType, AdwContext } from "../../../context/AdwContext";

const AtoZLink = ({ isMaxFav, setIsMaxFav, link, provider, activeFilter, refreshSelectOptions, trackRecentlyVisitedSites, favLinkCount }) => {
    const [appState]: AdwContextType = useContext(AdwContext);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isFavorite, setIsFavorite] = useState(link.FavoriteSite);
    const [isRecent, setIsRecent] = useState(link.RecentlyVisitedSite);

    useEffect(() => {
        let fav = AdwAtoZView.isFavSite(link, appState.user);
        setIsFavorite(fav);
        let recent = AdwAtoZView.isRecentSite(link, appState.user)
        setIsRecent(recent);
    }, [link, appState.user]);

    const toogleFavoriteSite = (value, linkId) => {
        if (favLinkCount >= 10 && value) {
            refreshSelectOptions(value, linkId)
        } else {
            setIsMaxFav(false);
            setIsFavorite(value);
            refreshSelectOptions(value, linkId)
        }
    }

    const onSiteLinkClick = (linkId) => {
        trackRecentlyVisitedSites(linkId);
    }
    return (
        <div className={styles.atozLink}>
            {(activeFilter === "Favorites" && isFavorite && provider) &&
                <tr className={styles.row_favorite} {...provider.draggableProps} ref={provider.innerRef}>
                    <td><FavoritesStar linkId={link.AtoZId} setIsFavorite={toogleFavoriteSite} isFavorite={isFavorite} /></td>
                    <td className={styles.name}><a href={link.Url} onClick={() => onSiteLinkClick(link.AtoZId)} target={link.Target} className={styles.atoZlinkText}><span className={isExpanded ? styles.expanded : styles.wrap}>{link.SiteName}</span></a></td>
                    <td className={styles.description} onClick={() => setIsExpanded(!isExpanded)}><span className={isExpanded ? styles.descriptionExpanded : styles.descriptionWrap}>{link.Description}</span></td>
                    <td className={styles.dragAndDropIcon} {...provider.dragHandleProps}><img src = {require('../../../assets/images/grab_handle.jpg')} alt = 'draganddropicon' /></td>
                </tr>
            }
            {(activeFilter === "Recently Visited" && isRecent) &&
                <tr className={styles.row}>
                    <td style={{display:'none'}}></td>
                    <td className={`${styles.name} ${activeFilter === "Recently Visited" && styles.nameFieldRecentlyVisited}`}><a href={link.Url} onClick={() => onSiteLinkClick(link.AtoZId)} target={link.Target} className={styles.atoZlinkText}><span className={isExpanded ? styles.expanded : styles.wrap}>{link.SiteName}</span></a></td>
                    <td className={`${styles.description} ${activeFilter === "Recently Visited" && styles.descriptionFieldForRecentlyVisited}`} onClick={() => setIsExpanded(!isExpanded)}><span className={isExpanded ? styles.descriptionExpanded : styles.descriptionWrap}>{link.Description}</span></td>
                </tr>
            }
            {activeFilter === "All" &&
                <tr className={styles.row}>
                    <td id={link.SiteName} ><FavoritesStar linkId={link.AtoZId} setIsFavorite={toogleFavoriteSite} isFavorite={isFavorite} /></td>
                    <td className={styles.name}><a href={link.Url} onClick={() => onSiteLinkClick(link.AtoZId)} target={link.Target} className={styles.atoZlinkText}><span className={isExpanded ? styles.expanded : styles.wrap}>{link.SiteName}</span></a></td>
                    <td className={styles.description} onClick={() => setIsExpanded(!isExpanded)}><span className={isExpanded ? styles.descriptionExpanded : styles.descriptionWrap}>{link.Description}</span></td>
                </tr>
            }

        </div>
    );
}

export default AtoZLink;