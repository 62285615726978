import React from "react";
import AdwCommonTool from "../../../types/CommonToolsTypes/AdwCommonTool";
import styles from "../../../App.module.scss";
import Link from "../Link/Link";

interface IAvailableToolsItemProps {
  tool: AdwCommonTool;
  positionIndex: number
}
const AvailableToolsItem: React.FC<IAvailableToolsItemProps> = ({ tool, positionIndex}) => {

  return (
    <li className={styles.all_available_tools_item}>
      <Link url={tool.Link.Url} linkClasses={styles.all_available_tools_item_link}
        id={`available-tool-link`}>
        <div data-toolid={tool.ID}
          id={`all_tools_item_button_${positionIndex}`}
          aria-label={tool.Title}
          className={styles.all_available_tools_item_button}>
          <div className={styles.is_new_tool_wrap}>
            {tool.NewTool &&
            <div className={styles.is_new_tool} style={{ background:  '#038574'  }}></div>
}
          </div>
          <div className={styles.tool_label_text_wrap}>
            <span className={styles.tool_label_text} >{tool.Title}</span>
          </div>
        </div>
      </Link>
    </li>
  )
}
export default AvailableToolsItem;
