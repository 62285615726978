
export default class PeopleUtils {
  public static openPeopleResult(userId: string, inNewTab: boolean): void {
    if (userId) {
      let url: string = PeopleUtils.employeeDetailViewUrl(userId);

      if (inNewTab) {
        const newWindow: Window = window.open(url, "_blank");
        if (newWindow) {
          newWindow.opener = null;
        }
      } else {
        window.open(url, "_self");
      }
    }
  }

  /**
   * sample output:
   * https://onyourside.sharepoint.com/sites/adw-dev/sitepages/app.aspx/employeedetail?nwieid=hsuehr
   */
  public static employeeDetailViewUrl(nwieID: string): string {
    return `/employeeDetail?nwieID=${nwieID}`;
  }

  public static openChat(email: string): void {
    window.open(`https://teams.microsoft.com/l/chat/0/0?users=${email}`, "_blank");
    
  }

  public static openEmail(email: string): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      window.parent.location.href = "mailto:".concat(email);
    } else {
      window.location.href = "mailto:".concat(email);
    }
  }
  
  /* convert Contractor to Non-Employee */
  public static formatJobTitle = (employeeType, jobTitle) => {
    if(employeeType !== undefined && employeeType !== null && employeeType.toUpperCase() === 'N'){
        return 'Non-Employee';
    }else{
      return jobTitle;
    }
  }

 /* format associate display name */
 public static formatAssociateDisplayName = (displayName) => {
    
  let name;

  if (displayName) {

    //displayName with comma/s
    if (displayName.indexOf(",") > 0) {
      let countComma = (displayName.match(/,/g) || []).length;
      let names = displayName.split(",");
      
      if(countComma == 2){
        name = (names[2].substring(0, names[2].length).trim() + " " + names[0]  + " " + names[1])

      }else if(countComma == 1){
        name = names[1].substring(0, names[1].length).trim() + " " + names[0]
        
      //more than 2 commas 
      }else{
        name = displayName        
      }

    //no comma - output displayName as-is
    }else{
      name = displayName
    }
  
    return name;
    
  //no displayName
  } else {
    return '';
  }  
}

}
