import { SpCampaignBanner } from "./SpCampaignBanner";

class AdwCampaignBanner {
  public id: number;
  public CampaignLink: {
    Description: string;
    Url: string;
  };
  public ImagePath: string; // set From the service, not in SP list
  public LinkPath: string;
  public WindowTarget: string;
  public StartDate: string;
  public EndDate: string;
  public BackgroundColor: string;

  constructor(spCampaignBanner: SpCampaignBanner) {
    if (!spCampaignBanner) {
      spCampaignBanner = {
        fields: {
          id: 0,
          BackgroundColor: "white",
          CampaignLink: null,
          ImagePath: null,
          EndDate: "",
          StartDate: "",
          Title: "",
          WindowTarget: "_self",
        }
      };
    }

    if (spCampaignBanner) {
      this.id = spCampaignBanner.fields.id;

      if (spCampaignBanner.fields.CampaignLink) {
        this.CampaignLink = spCampaignBanner.fields.CampaignLink;
      }

      this.WindowTarget = AdwCampaignBanner.getWindowTarget(spCampaignBanner.fields.WindowTarget);
      this.ImagePath = spCampaignBanner.fields.ImagePath ? spCampaignBanner.fields.ImagePath : null;
      this.StartDate = spCampaignBanner.fields.StartDate;
      this.EndDate = spCampaignBanner.fields.EndDate;
      this.BackgroundColor = spCampaignBanner.fields.BackgroundColor;
    }
  }

  public static getWindowTarget(target: string): string {
    return !target || target.toLowerCase() === "new" || target.toLowerCase() === "_blank" ? "_blank" : "_self";
  }
}

export default AdwCampaignBanner;
