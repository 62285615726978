"use client";
import React from "react";
import classNames from "classnames";
import { SkeletonTheme } from 'react-loading-skeleton';

import { ContactInfoSkeleton, HrLocationSkeleton, OrgChartSkeleton } from "../../molecules";

import styles from "./EmployeeDetailView.module.scss";

const EmployeeDetailViewSkeleton: React.FC = () => {


  return (
    <SkeletonTheme baseColor="#f2f2f2" highlightColor="#dadada">
      <div
        className={classNames(styles.employeeDetailViewContainer)}
      >
        <div className={styles.backgroundSkeleton}>
        </div>
        <div className={styles.contactInfo} id={"ContactInfoWrapper"}>
          <ContactInfoSkeleton />
        </div>
        <div className={styles.hrLocation} id={"hrLocation"}>
          <HrLocationSkeleton />
        </div>
        <div className={styles.orgchart} id={"orgChartWrapper"}>
          <OrgChartSkeleton />
        </div>
      </div>
    </SkeletonTheme>

  );
};
export default EmployeeDetailViewSkeleton;
