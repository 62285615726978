import React from "react";
import styles from "./PopularToolsView.module.scss";
import AdwPopularTool from "../../../types/PopularToolsTypes/AdwPopularTool";
import PopularToolsButton from "../../atoms/PopularToolsButton/PopularToolsButton";

export interface IPopularToolsViewProps {
  adwPopularTools: AdwPopularTool[];
}

const PopularToolsView: React.FC<IPopularToolsViewProps> = ({
  adwPopularTools
}) => {

  return ( 
    <ul id="Popular-Tools-Wrapper" className={styles.popularToolsButtonWrapper} tabIndex={-1}>
    {adwPopularTools.map((tool,index)=>
    ((tool.Enabled && tool.AvailableTo === 'Everyone') && 
    <PopularToolsButton tool={tool} key={tool.id} />
    )
    )}
    </ul>
  
  );
};

export default PopularToolsView;
