import React, { useEffect, useState } from "react";

import AllAnnouncementsLink from "../../molecules/AllAnnouncementsLink/AllAnnouncementsLink"
import GoToLink from "../../molecules/GoToLink/GoToLink";

import useFeatureSwitch from "../../../hooks/useFeatureSwitch";

import styles from './AnnouncementFooterSpace.module.scss';

const AnnouncementFooterSpace = ({ linkStyles, moreNewsStyles }) => {

  const { arrayOfFeaturesEnabled, isLoaded } = useFeatureSwitch(['Announcements Widget', 'Component Moved']);

  const [showCommonToolsMovedEnabled, setShowCommonToolsMovedEnabled] = useState(null);
  const [showAnnouncementsWidget, setShowAnnouncementsWidget] = useState(null);
  const [showCommonTools, setShowCommonTools] = useState(null);

  useEffect(() => {
    if (!isLoaded) return;

    let tempShowCommonToolsMovedEnabled = null;
    let tempShowAnnouncementsWidget = null;
    
    const [announcementsWidget, componentMoved] = arrayOfFeaturesEnabled;

    tempShowAnnouncementsWidget = announcementsWidget;

    if (componentMoved?.featureSettings?.length > 0) {
      componentMoved.featureSettings.map((setting) => {
        if (setting.component === "MYTOOL" && setting.enabled && RegExp(setting.allowRegex).exec(setting.nwieID)) {
          tempShowCommonToolsMovedEnabled=setting;
        }
      })
    }

    if (tempShowAnnouncementsWidget) {
      setShowAnnouncementsWidget(tempShowAnnouncementsWidget);
    } else if (tempShowCommonToolsMovedEnabled) {
      setShowCommonToolsMovedEnabled(tempShowCommonToolsMovedEnabled);
    } else {
      setShowCommonTools(true);
    }
    
  }, [isLoaded, arrayOfFeaturesEnabled])

  return (
    <>
      {!isLoaded && <AllAnnouncementsLink linkStyles={linkStyles} moreNewsStyles={moreNewsStyles} />}
      {isLoaded && showAnnouncementsWidget && <AllAnnouncementsLink linkStyles={linkStyles} moreNewsStyles={moreNewsStyles} />}
      {isLoaded && showCommonToolsMovedEnabled && <div className={moreNewsStyles}></div>}
      {isLoaded && showCommonTools && <div className={`${styles.customizeTools} ${moreNewsStyles}`}>
        <GoToLink
          linkText="Customize Tools"
          target={"_self"}
          url={`/customizetools`}
          id="customize-tools-goTo-link"
        />
      </div>}
    </>
  )
}

export default AnnouncementFooterSpace;