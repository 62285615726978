import DateUtils from "../utils/DateUtils"
import AuthService from "./AuthService"
import EnvironmentService from "./EnvironmentService"
import ErrorReportingService from "./ErrorReportingService"
import { checkOKResponse } from "./GraphService"

export interface GetAccrualResponse {
  shortName: string,
  accrualType: string,
  asOfDate: string,
  accrualAmount: string,
  accrualDays: string
}

export interface GetPaycheckResponse {
  shortName: string,
  payDate: string,
  grossAmt: number,
  netAmt: number,
  nextPayDate: string
}

export interface GetFowResponse {
  FoWCompleted: string,
  LIACompleted: string,
  digitalCompleted: string,
  fowYear: string,
  hireDate: string,
  hoursDigitalLearning: string,
  hoursLIALearning: string,
  totalFoWHours: string
}

export interface GetBenefitElectionsResponse {
  dentalCoverage: string,
  dentalPlan: string,
  endDateOE: string,
  enrollmentStatus: string,
  finalizeDateOE: string,
  hsaAmount: string,
  hsaCoverage: string,
  hsaPlan: string,
  medicalCoverage: string,
  medicalPlan: string,
  oeUrl: string,
  shortName: string,
  startDateOE: string,
  visionCoverage: string,
  visionPlan: string
}

export interface GetRetirementSavingsElectionsResponse {
  retirementSavingsElections: {
    employeePct: string,
    retirementPlanID: string,
    retirementPlanName: string
  }
}

export interface GetAWSNurseResponse {
  data: [{
    report_header: {
      c3: {
        name: string,
        type: string
      },
      c4: {
        name: string,
        type: string
      },
      c1: {
        name: string,
        type: string
      },
      c2: {
        name: string,
        type: string
      }
    },
    report_row:
    [{ c1: string, c2: string, c3: string, c4: string }]
  }]
}

export interface GetAWSCounselorResponse {
  data: [{
    report_header: {
      c3: {
        name: string,
        type: string
      },
      c1: {
        name: string,
        type: string
      },
      c2: {
        name: string,
        type: string
      }
    },
    report_row:
    [{ c1: string, c2: string, c3: string }]
  }]
}

export interface GetOpenReqsResponse {
  openTotalJobCount: string,
  openExternalJobCount: string
}

export interface GetOpenAppsResponse {
  jobApplication?: HrConnectJob[] | HrConnectJob
}

export interface HrConnectJob {
  jobTitle: string,
  jobReqName: string,
  status: string
}

export interface GetMyHealthProgramResponse {
  awardEarning: number,
  awardMedia: string,
  awardType: string,
  dob: string,
  emplid: string,
  firstName: string,
  hireDate: string,
  isEnrolled: string,
  lastName: string,
  maxAllowed: number,
  newHireCutoffDate: string,
  progEndDate: string,
  warningDays: string
}

export default class HRConnectService {

  private static TIME_OFF_URL: string = `${EnvironmentService.getApigeeConfig().domain}/hr-management/absence/v1/getaccrual`
  private static FOW_URL: string = `${EnvironmentService.getApigeeConfig().domain}/hr-management/fow/v1/getfow`
  private static OPEN_REQS_URL: string = `${EnvironmentService.getApigeeConfig().domain}/hr-management/job-applications/v1/getopenreqcounts`
  private static OPEN_APPS_URL: string = `${EnvironmentService.getApigeeConfig().domain}/hr-management/job-applications/v1/getmyopenapplications`
  private static BENEFIT_ELECTION_URL: string = `${EnvironmentService.getApigeeConfig().domain}/hr-management/Benefits/v1/getOEStatus`
  private static RETIREMENT_SAVINGS_ELECTIONS_URL: string = `${EnvironmentService.getApigeeConfig().domain}/hr-management/retirementsavings/v1/getretirementsavingselections`
  private static NET_PAY_URL: string = `${EnvironmentService.getApigeeConfig().domain}/hr-management/paycheck/v1/getpaycheck`
  private static NURSE_URL: string = `${EnvironmentService.getApigeeConfig().domain}/hr-management/counselor-nurse-info/v1/getnurses`
  private static COUNSELOR_URL: string = `${EnvironmentService.getApigeeConfig().domain}/hr-management/counselor-nurse-info/v1/getcounselors`
  private static MY_HEALTH_PROGRAM_URL: string = `${EnvironmentService.getApigeeConfig().domain}/hr-management/rally1/v1/getrallydata`
  /**
   * 
   * @param userId ID of the user to retrieve time off for
   * @returns [[current time off in hours, current time off in days], cumulative time off in hours, cumulative time off in days]
   */
  public static getTimeOff(userId: string): Promise<number[][]> {
    const yearEnd: Date = new Date();
    yearEnd.setMonth(11);
    yearEnd.setDate(31);
    const currentTime: Promise<number[]> = HRConnectService.getTime(userId, DateUtils.formatDateCustom(new Date(), "YYYY-MM-DD"))
    const cumulativeTime: Promise<number[]> = HRConnectService.getTime(userId, DateUtils.formatDateCustom(yearEnd, "YYYY-MM-DD"))
    return Promise.all([currentTime, cumulativeTime])
  }

  public static getFow(userId: string): Promise<{ total: number, digital: number, lia: number, hasError: boolean }> {
    return fetch(HRConnectService.FOW_URL, {
      method: "POST",
      headers: {
        client_id: EnvironmentService.getApigeeConfig().clientId,
        Authorization: AuthService.getAuthorizationHeader(),
        "x-nw-message-id": AuthService.getSecureRandomString(25),
        "content-type": "application/json"
      },
      body: JSON.stringify({ shortName: userId })
    })
      .then(checkOKResponse)
      .then(response => response.json())
      .then((response: GetFowResponse) => {
        return {
          total: response.FoWCompleted ? +response.FoWCompleted : 0,
          digital: response.digitalCompleted ? +response.digitalCompleted : 0,
          lia: response.LIACompleted ? +response.LIACompleted : 0,
          hasError: false
        }
      })
      .catch(err => {
        ErrorReportingService.reportErrorWithMessage(
          `Failed to retrieve HRConnect FoW information from fow API: ${err}`,
          "HRConnectService -> getFow",
          `User ID: ${userId}`)
        return { total: 0, digital: 0, lia: 0, hasError: true }
      })
  }

  public static getOpenReqs(state: string = "OH"): Promise<{ reqs: number, hasError: boolean }> {
    return fetch(HRConnectService.OPEN_REQS_URL, {
      method: "POST",
      headers: {
        client_id: EnvironmentService.getApigeeConfig().clientId,
        Authorization: AuthService.getAuthorizationHeader(),
        "x-nw-message-id": AuthService.getSecureRandomString(25),
        "content-type": "application/json"
      },
      body: JSON.stringify({ state })
    })
      .then(checkOKResponse)
      .then(response => response.json())
      .then((response: GetOpenReqsResponse) => {
        return { reqs: +response.openTotalJobCount, hasError: false };
      })
      .catch(err => {
        ErrorReportingService.reportErrorWithMessage(
          `Failed to retrieve HRConnect open reqs information from jobs API: ${err}`,
          "HRConnectService -> getOpenReqs",
          `State: ${state}`)
        return { reqs: 0, hasError: true }
      })
  }

  private static isActiveApp(response:GetOpenAppsResponse): number {
    let apps: number;
    if (response.jobApplication) {
      if (Array.isArray(response.jobApplication)) {
        apps = response.jobApplication.length;
      } else {
        apps = 1;
      }
    } else {
      apps = 0;
    }
    return apps;
  }

  public static getActiveApps(userId: string): Promise<{ apps: number, hasError: boolean }> {
    return fetch(HRConnectService.OPEN_APPS_URL, {
      method: "POST",
      headers: {
        client_id: EnvironmentService.getApigeeConfig().clientId,
        Authorization: AuthService.getAuthorizationHeader(),
        "x-nw-message-id": AuthService.getSecureRandomString(25),
        "content-type": "application/json"
      },
      body: JSON.stringify({ shortName: userId })
    })
      .then(checkOKResponse)
      .then(response => response.json())
      .then((response: GetOpenAppsResponse) => {
        // jobApplication is an array if multiple applications are open, or a single object if one application is open
        // jobApplication does not exist with no open applications
        const apps: number = HRConnectService.isActiveApp(response);
        return { apps, hasError: false };
      })
      .catch(err => {
        ErrorReportingService.reportErrorWithMessage(
          `Failed to retrieve HRConnect open apps information from jobs API: ${err}`,
          "HRConnectService -> getOpenApps",
          `User ID: ${userId}`)
        return { apps: 0, hasError: true }
      })
  }

  /**
   * Returns the time off for a given user as of a given date
   * @param userId ID of the user to retrieve time for
   * @param asOfDate Date for which time off should be retrieved
   * @returns [time off in hours, time off in days]
   */
  private static getTime(userId: string, asOfDate: string): Promise<number[]> {
    return fetch(HRConnectService.TIME_OFF_URL, {
      method: "POST",
      headers: {
        client_id: EnvironmentService.getApigeeConfig().clientId,
        Authorization: AuthService.getAuthorizationHeader(),
        "x-nw-message-id": AuthService.getSecureRandomString(25),
        "content-type": "application/json"
      },
      body: JSON.stringify({ shortName: userId, accrualType: "Your Time", asOfDate })
    })
      .then(checkOKResponse)
      .then(response => response.json())
      .then((response: GetAccrualResponse) => {
        if (!Number.isNaN(response.accrualAmount) && !Number.isNaN(response.accrualDays)) {
          return [+response.accrualAmount, +response.accrualDays]
        } else {
          throw new Error(`One number received from API is NaN: ${response.accrualAmount}, ${response.accrualDays}`)
        }
      })
      .catch(err => {
        ErrorReportingService.reportErrorWithMessage(
          `Failed to retrieve HRConnect Time off information from absense API: ${err}`,
          "HRConnectService -> getTime",
          `User ID: ${userId}`)
        return [0, 0]
      })
  }


  public static getPay(userId: string): Promise<{ netAmt: number, nextPayDay: string }> {
    return fetch(HRConnectService.NET_PAY_URL, {
      method: "POST",
      headers: {
        client_id: EnvironmentService.getApigeeConfig().clientId,
        Authorization: AuthService.getAuthorizationHeader(),
        "x-nw-message-id": AuthService.getSecureRandomString(25),
        "content-type": "application/json"
      },
      body: JSON.stringify({ shortName: userId, accrualType: "Your Pay" })
    })
      .then(checkOKResponse)
      .then(response => response.json())
      .then((response: GetPaycheckResponse) => {
        if (!Number.isNaN(response.netAmt) && (response.nextPayDate)) {
          return {
            netAmt: +response.netAmt,
            nextPayDay: response.nextPayDate
          }
        } else {
          throw new Error(`One number received from API is NaN: ${response.netAmt}, ${response.nextPayDate}`)
        }
      })
      .catch(err => {
        ErrorReportingService.reportErrorWithMessage(
          `Failed to retrieve HRConnect Time off information from absense API: ${err}`,
          "HRConnectService -> getTime",
          `User ID: ${userId}`)
        return { netAmt: null, nextPayDay: null }
      })
  }


  public static getBenefitElections(userId: string): Promise<{
    dentalCoverage: string,
    dentalPlan: string,
    endDateOE: string,
    enrollmentStatus: string,
    finalizeDateOE: string,
    hsaAmount: string,
    hsaCoverage: string,
    hsaPlan: string,
    medicalCoverage: string,
    medicalPlan: string,
    oeUrl: string,
    shortName: string,
    startDateOE: string,
    visionCoverage: string,
    visionPlan: string, hasError: boolean
  }> {
    return fetch(HRConnectService.BENEFIT_ELECTION_URL, {
      method: "POST",
      headers: {
        client_id: EnvironmentService.getApigeeConfig().clientId,
        Authorization: AuthService.getAuthorizationHeader(),
        "x-nw-message-id": AuthService.getSecureRandomString(25),
        "content-type": "application/json"
      },
      body: JSON.stringify({ shortName: userId })
    })
      .then(checkOKResponse)
      .then(response => response.json())
      .then((response: GetBenefitElectionsResponse) => {
        return {
          dentalCoverage: response.dentalCoverage,
          dentalPlan: response.dentalPlan,
          endDateOE: response.endDateOE,
          enrollmentStatus: response.enrollmentStatus,
          finalizeDateOE: response.finalizeDateOE,
          hsaAmount: response.hsaAmount,
          hsaCoverage: response.hsaCoverage,
          hsaPlan: response.hsaPlan,
          medicalCoverage: response.medicalCoverage,
          medicalPlan: response.medicalPlan,
          oeUrl: response.oeUrl,
          shortName: response.shortName,
          startDateOE: response.startDateOE,
          visionCoverage: response.visionCoverage,
          visionPlan: response.visionPlan,
          hasError: false
        }
      })
      .catch(err => {
        ErrorReportingService.reportErrorWithMessage(
          `Failed to retrieve HRConnect Benefit Elections information from Benefit Elections API: ${err}`,
          "HRConnectService -> getBenefitElections",
          `User ID: ${userId}`)
        return {
          dentalCoverage: null,
          dentalPlan: null,
          endDateOE: null,
          enrollmentStatus: null,
          finalizeDateOE: null,
          hsaAmount: null,
          hsaCoverage: null,
          hsaPlan: null,
          medicalCoverage: null,
          medicalPlan: null,
          oeUrl: null,
          shortName: null,
          startDateOE: null,
          visionCoverage: null,
          visionPlan: null,
          hasError: true
        }
      })
  }

  public static getRetirementSavingsElections(userId: string): Promise<{
    // retirementSavingsElections: {
    //   employeePct: string,
    //   retirementPlanID: string,
    //   retirementPlanName: string
    // },
    retirementSavingsElections,
    hasError: boolean
  }> {
    return fetch(HRConnectService.RETIREMENT_SAVINGS_ELECTIONS_URL, {
      method: "POST",
      headers: {
        client_id: EnvironmentService.getApigeeConfig().clientId,
        Authorization: AuthService.getAuthorizationHeader(),
        "x-nw-message-id": AuthService.getSecureRandomString(25),
        "content-type": "application/json"
      },
      body: JSON.stringify({ shortName: userId })
    })
      .then(checkOKResponse)
      .then(response => response.json())
      .then((response: GetRetirementSavingsElectionsResponse) => {

        let elemIndex = 0;
        let arrayFlag = false;

        //check if retirementSavingsElections response is null
        if ((response.retirementSavingsElections !== null) && (response.retirementSavingsElections !== undefined)) {
          //check if this is an array  
          if (Array.isArray(response.retirementSavingsElections)) {
            let count = 0;
            arrayFlag = true;
            for (let elem of response.retirementSavingsElections) {
              //get the index of retirementPlanID = 40401K
              if (elem.retirementPlanID.toUpperCase() === '40401K') {
                elemIndex = count;
              }
              count++;
            }
          }
        } else {
          throw new Error(`Retirement Savings Plan received from API is null: ${response.retirementSavingsElections}`);
        }

        return {
          retirementSavingsElections: arrayFlag ?
            {
              employeePct: response.retirementSavingsElections[elemIndex].employeePct,
              retirementPlanID: response.retirementSavingsElections[elemIndex].retirementPlanID,
              retirementPlanName: response.retirementSavingsElections[elemIndex].retirementPlanName
            }
            :
            {
              employeePct: response.retirementSavingsElections.employeePct,
              retirementPlanID: response.retirementSavingsElections.retirementPlanID,
              retirementPlanName: response.retirementSavingsElections.retirementPlanName
            },
          hasError: false
        }
      })
      .catch(err => {
        ErrorReportingService.reportErrorWithMessage(
          `Failed to retrieve HRConnect Retirement Savings Elections information from Retirement Savings Elections API: ${err}`,
          "HRConnectService -> getRetirementSavingsElections",
          `User ID: ${userId}`)
        return {
          retirementSavingsElections: {
            employeePct: null,
            retirementPlanID: null,
            retirementPlanName: null
          },
          hasError: true
        }
      })
  }

  public static getAWSCounselor(userId: string, homeState: string): Promise<{
    counselor: {
      counselorName: string,
      counselorPhone: string
    },
    hasError: boolean
  }> {
    return fetch(HRConnectService.COUNSELOR_URL, {
      method: "POST",
      headers: {
        client_id: EnvironmentService.getApigeeConfig().clientId,
        Authorization: AuthService.getAuthorizationHeader(),
        "x-nw-message-id": AuthService.getSecureRandomString(25),
        "content-type": "application/json"
      },
      body: JSON.stringify({
        "reportname": "CounselorList",
        "query": [
          {
            "label": "CITY_STATE",
            "value1": homeState
          }
        ]
      })
    })
      .then(checkOKResponse)
      .then(response => response.json())
      .then((response: GetAWSCounselorResponse) => {
        return {
          counselor: {
            counselorName: response.data[0].report_row[0].c2,
            counselorPhone: response.data[0].report_row[0].c3
          },
          hasError: false
        }
      })
      .catch(err => {
        ErrorReportingService.reportErrorWithMessage(
          `Failed to retrieve HRConnect AWS Nurse information from HR API: ${err}`,
          "HRConnectService -> getAssociateWellbeingSafety",
          `User ID: ${userId}`)
        return {
          counselor: {
            counselorName: null,
            counselorPhone: null
          },
          hasError: true
        }
      })
  }

  public static getAWSNurse(userId: string, state: string): Promise<{
    nurse: {
      nurseName: string,
      nursePhone: string,
      nurseEmail: string
    },
    hasError: boolean
  }> {
    return fetch(HRConnectService.NURSE_URL, {
      method: "POST",
      headers: {
        client_id: EnvironmentService.getApigeeConfig().clientId,
        Authorization: AuthService.getAuthorizationHeader(),
        "x-nw-message-id": AuthService.getSecureRandomString(25),
        "content-type": "application/json"
      },
      body: JSON.stringify({
        reportname: "NurseList",
        query: [{ label: "MAIL_PREFIX", value1: state }]
      })
    })
      .then(checkOKResponse)
      .then(response => response.json())
      .then((response: GetAWSNurseResponse) => {
        return {
          nurse:
          {
            nurseName: response.data[0].report_row[0].c1,
            nursePhone: response.data[0].report_row[0].c2,
            nurseEmail: response.data[0].report_row[0].c3,
          },
          hasError: false
        }
      })
      .catch(err => {
        ErrorReportingService.reportErrorWithMessage(
          `Failed to retrieve HRConnect AWS Nurse information from HR API: ${err}`,
          "HRConnectService -> getAssociateWellbeingSafety",
          `User ID: ${userId}`)
        return {
          nurse: { nurseName: null, nursePhone: null, nurseEmail: null },
          hasError: true
        }
      })
  }

  public static getMyHealthProgram(userId: string): Promise<{
    awardEarning: number,
    awardMedia: string,
    awardType: string,
    dob: string,
    emplid: string,
    firstName: string,
    hireDate: string,
    isEnrolled: string,
    lastName: string,
    maxAllowed: number,
    newHireCutoffDate: string,
    progEndDate: string,
    warningDays: string, hasError: boolean
  }> {
    return fetch(HRConnectService.MY_HEALTH_PROGRAM_URL, {
      method: "POST",
      headers: {
        client_id: EnvironmentService.getApigeeConfig().clientId,
        Authorization: AuthService.getAuthorizationHeader(),
        "x-nw-message-id": AuthService.getSecureRandomString(25),
        "content-type": "application/json"
      },
      body: JSON.stringify({ shortName: userId })
    })
      .then(checkOKResponse)
      .then(response => response.json())
      .then((response: GetMyHealthProgramResponse) => {
        return {
          awardEarning: response.awardEarning,
          awardMedia: response.awardMedia,
          awardType: response.awardType,
          dob: response.dob,
          emplid: response.emplid,
          firstName: response.firstName,
          hireDate: response.hireDate,
          isEnrolled: response.isEnrolled,
          lastName: response.lastName,
          maxAllowed: response.maxAllowed,
          newHireCutoffDate: response.newHireCutoffDate,
          progEndDate: response.progEndDate,
          warningDays: response.warningDays,
          hasError: false
        }
      })
      .catch(err => {
        ErrorReportingService.reportErrorWithMessage(
          `Failed to retrieve HRConnect My Health Program information from  My Health Program API: ${err}`,
          "HRConnectService -> getMyHealthProgram",
          `User ID: ${userId}`)
        return {
          awardEarning: null,
          awardMedia: null,
          awardType: null,
          dob: null,
          emplid: null,
          firstName: null,
          hireDate: null,
          isEnrolled: null,
          lastName: null,
          maxAllowed: null,
          newHireCutoffDate: null,
          progEndDate: null,
          warningDays: null,
          hasError: true
        }
      })
  }
}