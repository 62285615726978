import React, {  useContext, useEffect, useState } from "react";
import GraphService from "../../../services/GraphService";
import styles from "./NwOrgChart.module.scss";
import { GraphUserData } from "../../../types/GraphTypes/GraphUser";
import NwDirectReports from "./NwDirectReports";
import { InsideUserData } from "../../../types/InsideUserData";
import { UpdateViewUserOrgChart } from "../../../context/ActionTypes";
import { AdwContextType, AdwContext } from "../../../context/AdwContext";
import PeopleUtils from "../../../utils/PeopleUtils";

export interface INwOrgChartProps {
  currentAuthenticatedUser:InsideUserData
}

export interface ILevelTwoComponentProps {
  managerDirectReport:GraphUserData[],
  levelOneManager:GraphUserData,
  updateSelectedUser:any,
  selectedAssociateMail:string,
  formatAssociateDisplayName:any
}

const LevelTwoComponent: React.FC<ILevelTwoComponentProps> = ({managerDirectReport,levelOneManager,updateSelectedUser,selectedAssociateMail})=>{
  return(
<div className={styles.levelTwoWrapper}>
            {managerDirectReport && managerDirectReport.map((levelTwo, i) => {
              return (
                levelTwo && levelTwo?.userPrincipalName !== levelOneManager?.userPrincipalName && levelTwo.displayName.includes(",") &&
                <div className={styles.levelTwo} key={levelTwo?.onPremisesSamAccountName}>
                  <div className={styles.levelTwoCard}>
                    <button onClick={() => updateSelectedUser(levelTwo?.userPrincipalName?.toLowerCase())} 
                    className={selectedAssociateMail?.toLowerCase() == levelTwo?.userPrincipalName?.toLowerCase() ? styles.selectedName : styles.name}>{PeopleUtils.formatAssociateDisplayName(levelTwo?.displayName)}</button>
                    <p>{levelTwo?.jobTitle}</p>
                    <p>{levelTwo?.onPremisesSamAccountName?.toLowerCase()}</p>
                  </div>
                  <NwDirectReports managerMail={levelTwo?.userPrincipalName} selectedAssociateMail={selectedAssociateMail} onCardClick={updateSelectedUser} />
                </div>

              )
            })
            }
          </div>
  )
}

const NwOrgChart: React.FC<INwOrgChartProps> = ({currentAuthenticatedUser}) => {
  document.title = "InSide - Printable Organizational Chart";
  const [appState,dispatch] = useContext<AdwContextType>(AdwContext);
  const currentOrgChartUser = appState && appState.viewUserOrgChart || null;
  const authenticatedUser = appState && appState.graphUser && appState.graphUser.userPrincipalName;
  const [associateLevel, setAssociateLevel] = useState(0);
  const [selectedAssociateMail, setSelectedAssociateMail] = useState(currentOrgChartUser||authenticatedUser);
  const [levelOneManager, setLevelOneManager] = useState<GraphUserData>(null);
  const [managerDirectReport, setManagerDirectReport] = useState<GraphUserData[]>([]);

  
  useEffect(()=>{
    if(!currentOrgChartUser){
      setSelectedAssociateMail(authenticatedUser);
      dispatch(UpdateViewUserOrgChart(authenticatedUser));
     
    }else{
      setSelectedAssociateMail(currentOrgChartUser);
    }
  },[currentOrgChartUser,authenticatedUser])

  //Get Manager Data
  useEffect(() => {
    if (currentAuthenticatedUser && selectedAssociateMail) {
      GraphService.getManager(selectedAssociateMail).then((user) => {
        setLevelOneManager(user);
      });
    }
  }, [selectedAssociateMail, currentAuthenticatedUser]);

  //Get Direct Report Data
  useEffect(() => {
    if (currentAuthenticatedUser && selectedAssociateMail) {
      GraphService.getDirectReports(selectedAssociateMail).then((user) => {
        if (user && user.length > 0) {
          setAssociateLevel(2);
        } else {
          setAssociateLevel(0);
        }
      });
    }

  }, [selectedAssociateMail, currentAuthenticatedUser])

  //Get Manager Direct Reports Data
  useEffect(() => {
    if (currentAuthenticatedUser && levelOneManager && levelOneManager.userPrincipalName) {
      GraphService.getDirectReports(levelOneManager.userPrincipalName).then((user) => {
        setManagerDirectReport(user);
      });
    }
  }, [levelOneManager, currentAuthenticatedUser]);

  const updateSelectedUser = (email) => {
    setSelectedAssociateMail(email);
  }

  return (
    <>
      <div id="printableOrgChart" className={styles.orgContainer}>

        <h1 className={styles.department}>{levelOneManager?.department}</h1>
        <div id="level_two_manager">
          <div>
            <div className={styles.levelOne} >
              <button onClick={() => updateSelectedUser(levelOneManager?.userPrincipalName?.toLowerCase())} className={selectedAssociateMail?.toLowerCase() == levelOneManager?.userPrincipalName?.toLowerCase() ? styles.selectedName : styles.name}>{PeopleUtils.formatAssociateDisplayName(levelOneManager?.displayName)}</button>
              <p>{levelOneManager?.jobTitle}</p>
              <p>{levelOneManager?.onPremisesSamAccountName?.toLowerCase()}</p>
            </div>
          </div>
        </div>
        {associateLevel == 2 ?
          (<LevelTwoComponent managerDirectReport={managerDirectReport} levelOneManager={levelOneManager} updateSelectedUser={updateSelectedUser} selectedAssociateMail={selectedAssociateMail?.toLowerCase()} formatAssociateDisplayName={PeopleUtils.formatAssociateDisplayName}/>)
          : (
            
              <div className={styles.levelThreeWrapper}>
                <NwDirectReports managerMail={levelOneManager?.userPrincipalName} selectedAssociateMail={selectedAssociateMail?.toLowerCase()} onCardClick={updateSelectedUser} />
              </div>
          )
        }
      </div>
    </>
  )
};

export default NwOrgChart;
