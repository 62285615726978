import TypeKeyValue from "./TypeKeyValue";

export default class RequestBody {
  public searchTerm: string;
  public searchPage: boolean;
  public resultsRange: {
    startIndex: number;
    endIndex: number;
  };
  public fields: string[];
  public filters: TypeKeyValue[];
  public sorts: TypeKeyValue[];
  public resultsMetadata: TypeKeyValue[];

  public static currentPageFilter(filter: TypeKeyValue): boolean {
    return filter.type === "profile" && filter.key === "profile";
  }
  
  /**
   * Object which represents a request for results from the search API. May be a query, suggestions request, or
   * other type of request.
   *
   * @param searchTerm Term for which results should be retrieved
   * @param startIndex Index of the total results set at which returned results should start, inclusive.
   * Defaults to 0
   * @param totalResults Total number of results to return. Defaults to 10
   * @param fields List of desired fields to return for each result
   * @param filters List of filters to apply to the results
   * @param sorts List of sorts with which to sort results
   * @param resultsMetadata List of requested metadata fields to return about the total results set
   */
  constructor(
    searchTerm: string,
    startIndex: number = 0,
    totalResults: number = 10,
    fields: string[] = [],
    filters: TypeKeyValue[] = [],
    sorts: TypeKeyValue[] = [],
    resultsMetadata: TypeKeyValue[] = [],
  ) {
    this.searchTerm = searchTerm;
    this.resultsRange = {
      startIndex: startIndex >= 0 ? startIndex : 0,
      endIndex: totalResults >= 1 ? startIndex + totalResults - 1 : startIndex,
    };
    this.fields = fields;
    this.filters = filters;
    this.sorts = sorts;
    this.resultsMetadata = resultsMetadata;
  }
}
