import React, { useContext, useState } from "react";
import { SetNetPay, SetNextPaydays } from "../../../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../../../context/AdwContext";
import AuthService from "../../../../../services/AuthService";
import HRConnectService from "../../../../../services/HRConnectService";
import ShowHide from "../../ShowHide/ShowHide"
import widgetStyles from "../HRConnectWidgets.module.scss";
import styles from "./MyPay.module.scss";

let visibilityTimeout; 

const MyPay: React.FC = () => {    
    const [appState, dispatch]: AdwContextType = useContext(AdwContext);  
    const [hidden, setHidden] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const hasActiveSession: boolean = AuthService.hasActiveSession();
    
    function fetchPay(): void {
      const shouldFetch: boolean = appState && appState.hrConnect && appState.hrConnect.myPay
        && appState.hrConnect.myPay.net === null;
      if (shouldFetch && hasActiveSession) {
        setIsLoading(true);
        dispatch(SetNetPay(0));
        dispatch(SetNextPaydays(""));
        HRConnectService.getPay(appState.user.nwieID)
          .then(({netAmt, nextPayDay}) => {
            dispatch(SetNetPay(netAmt));
            dispatch(SetNextPaydays(nextPayDay));
            setIsLoading(false);
          });
      }
    }

    function getPay(): [string, string] {
      if (isLoading) {
        return ["--.--", "-/-"];
      }
      if (hidden || !hidden) {
        const myPay = appState && appState.hrConnect && appState.hrConnect.myPay && appState.hrConnect.myPay.net && appState.hrConnect.myPay.nextPayDay;
        if (myPay) {
          const newPay = '$' + appState.hrConnect.myPay.net.toString();
          let splitDate = appState.hrConnect.myPay.nextPayDay.split('-')
          const newDate = splitDate[1] + '/' + splitDate[2]
          return [
            newPay,
            newDate
          ]
        }

      }
      return ["XX.XX", "X/X"]
    }

    function toggleVisibility(): void {
      fetchPay();
      if (hidden) {
        visibilityTimeout = setTimeout(() => {
          setHidden(true)
        }, 10000)
      } else {
        clearTimeout(visibilityTimeout)
      }
      setHidden(!hidden)
    }
    
    const payInfo = getPay()    

    return (
    <div className={widgetStyles.widget}>
      <div className={widgetStyles.header}><h3>My Pay</h3></div>
        <div className={widgetStyles.section}>
          <div className={widgetStyles.subSectionHeader}>Net Pay</div>
            <div className={`${widgetStyles.subSectionContents} ${styles.showRow}`}>
              <span data-tip data-for="mypay-netpay" className={hidden ? widgetStyles.obfuscate : ""}>{payInfo[0]}</span>
              <ShowHide featureTitle="My Pay" showing={!hidden} toggle={toggleVisibility} ariaLabel="Hide/Show net pay and next payday."/>
            </div>
        </div>
        <div className={widgetStyles.divider} />
          <div className={widgetStyles.section}>
            <div className={widgetStyles.subSectionHeader}>Next Payday:</div>
              <div className={widgetStyles.subSectionContents}>
                <span data-tip data-for="mypay-nextpayday" className={hidden ? widgetStyles.obfuscate : ""}>{payInfo[1]}</span>
            </div>
          </div>
        <div className={widgetStyles.buttonRow}>
          <a
            href="https://www.myworkday.com/nationwide/d/inst/13102!CK5mGhIKBggDEMenAhIICgYI1A0Qrgk~*MKA2YUUzDpU~/cacheable-task/2998$43525.htmld#backheader=true"
            target="_blank"
            rel="noopener noreferrer"
            className={widgetStyles.buttonLink}
          >
            Visit Pay dashboard
          </a>
        </div>
      </div>
    )
}

export default MyPay;
