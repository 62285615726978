/**
 * Enumerated values for possible date formats used by the
 * formatDate() and formatDateString() methods.
 */
export default class DateFormat {
  public static ISO_8601 = "ISO_8601";
  public static SHORT_DATE = "SHORT_DATE";
  public static MEDIUM_DATE = "MEDIUM_DATE";
  public static LONG_DATE = "LONG_DATE";
  public static SHORT_TIME = "SHORT_TIME";
  public static LONG_TIME = "LONG_TIME";
  public static SHORT_MONTH = "SHORT_MONTH";
  public static LONG_MONTH = "LONG_MONTH";
  public static SHORT_YEAR = "SHORT_YEAR";
  public static LONG_YEAR = "LONG_YEAR";
  public static SHORT = "SHORT";
  public static MEDIUM = "MEDIUM";
  public static LONG = "LONG";
  public static DAY_OF_MONTH = "DAY_OF_MONTH";
  public static HOUR = "HOUR";
  public static AGO = "AGO";
  public static IN = "IN";
}
