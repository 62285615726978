import React, { useEffect, useState } from "react";
import styles from "./EditProfilePhotoLink.module.scss";
import AdwEmployee from "../../../types/CallupTypes/AdwEmployee";
export interface IContactInfoProps {
    user: string;
    adwEmployee: AdwEmployee;
    openEditPhotoModal:()=>void
  }
  
  const EditProfilePhotoLink = ({user,adwEmployee,openEditPhotoModal}) => {
    const [showEditLink,setShowEditLink] = useState<boolean>(false);

useEffect(()=>{
  if(user === adwEmployee.shortName){
    setShowEditLink(true);
  }
  return ()=>{
    setShowEditLink(false);
  }
},[user,adwEmployee])

    return (showEditLink ?
      <span
      className={styles.editPhotoLink}
      id={`edit-profile-photo-link`}
      onClick={openEditPhotoModal}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.code === 'Enter') {
          openEditPhotoModal();
        }
      }}
    >
      edit photo
    </span>
    :
    <></>
    );
  };

export default EditProfilePhotoLink;