import { SpFooterMenuItem } from "./SpFooterMenuItem";

class AdwFooterMenuItem {
  public Heading: string;
  public HeadingOrder: number;
  public Item: {
    Description: string;
    Url: string;
  };
  public ItemOrder: number;
  public WindowTarget: string;
  public approvedForDisplay: boolean;

  constructor(menuItem?: SpFooterMenuItem) {
    if (menuItem) {
      const max: number = 40;
      this.Heading = this.setMenuTextSafely(max, menuItem.fields.Heading);
      this.HeadingOrder = menuItem.fields.HeadingOrder;
      this.ItemOrder = menuItem.fields.ItemOrder;
      this.WindowTarget = menuItem.fields.WindowTarget;

      if (menuItem.fields.Item) {
        this.Item = {
          Url: menuItem.fields.Item.Url,
          Description: this.setMenuTextSafely(max, menuItem.fields.Item.Description)
        }
      }
      this.approvedForDisplay = this.isApprovedForDisplay(menuItem.fields._ModerationStatus);
    }
  }

  /*
  * OData__ModerationStatus: 0 means approved
  * OData__ModerationStatus: 2 means pending
  */
  private isApprovedForDisplay(moderationStatus: number): boolean {
    let approved: boolean = false;
    if (moderationStatus === 0) {
      approved = true;
    }
    return approved;
  }

  private setMenuTextSafely(max: number, unsafeString: string): string {
    let returnValue: string = unsafeString;
    if (unsafeString && unsafeString.length > max) {
      returnValue = unsafeString.substring(0, max);
    }
    return returnValue;
  }
}

export default AdwFooterMenuItem;
