import React from "react";
import { Modal } from "react-bootstrap";
import styles from "./EmployeeDetailModal.module.scss";
import "./EmployeeDetailModalPlain.css";

export interface IEmployeeDetailModalProps {
  onHide: () => void;
  show: boolean;
  title?: string;
  subheading?: JSX.Element;
  subcontent?: JSX.Element;
  contents?: JSX.Element;
  url?: string;
}

export const EmployeeDetailModal: React.FC<IEmployeeDetailModalProps> = (
  props
) => {

  return (
    <Modal
      {...props}
      id="modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className={styles.employeeModalContainer}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className={styles.modalTitleText}>{props.title}</div>
          <button onClick={props.onHide} className={styles.modalCloseButton}>
            <img
              src={require("../../../assets/images/nav-icon-mobile-close.png")}
              alt="close"
              style={{ height: "18px", width: "19px", marginTop: "-8px", marginLeft: '-4px' }}
            />
            <span>CANCEL</span>
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.modalContent}>{props.contents}</div>
        <span className={styles.modalSubHeading}>{props.subheading}</span>
        <span className={styles.modalSubContent}>{props.subcontent}</span>
      </Modal.Body>
      <Modal.Footer>
          <a
            href={props.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={props.onHide}
            className={styles.continueButton}
          >
            Continue
          </a>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeDetailModal;
