import React from "react";
import widgetStyles from "../HRConnectWidgets.module.scss";
import styles from "./ComingSoon.module.scss";

export interface IComingSoonProps {
  unavailable?: boolean
}

const ComingSoon: React.FC<IComingSoonProps> = ({ unavailable = true }) => {

  return (
    <>
      <div className={`${widgetStyles.widget} ${styles.message}`}>
        {
          unavailable ?
            <span className={`${styles.text} ${styles.unavailable}`}>Data currently unavailable</span> :
            <span className={`${styles.text} ${styles.comingSoon}`}>Coming soon</span>
        }
        <div className={`${widgetStyles.divider} ${styles.divider}`} />
      </div>
    </>
  )
}

export default ComingSoon;