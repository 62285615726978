import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AdwCommonTool from "../../../types/CommonToolsTypes/AdwCommonTool";
import ToggleArrowButton from "../../molecules/ToggleArrowButton/ToggleArrowButton";
import Tool from "../../molecules/Tool/Tool";
import styles from "./CommonTools.module.scss";
import { useNavigate } from "react-router-dom";
import { randomAlphaNumeric } from "../../../utils/ArrayUtils";

export interface ICommonToolsProps {
  commonToolsStyles: string;
  showEditingTools: boolean;
  defaultOrPreferredTools: AdwCommonTool[];
  toolHeadingStyles?: string;
  toolPickerClickEvent?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  selectTool?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface ICommonToolsState {
  pageOfItems: AdwCommonTool[];
  isToggleDisable: boolean;
  pageNumber: number;
}

const CommonToolsView: React.FC<ICommonToolsProps> = ({
  defaultOrPreferredTools,
  showEditingTools,
  commonToolsStyles,
  toolHeadingStyles,
  toolPickerClickEvent,
  selectTool,
}) => {
  const navigate = useNavigate();
  const [appState]: AdwContextType = useContext(AdwContext);
  const [pageOfItems, setPageOfItems] = useState<AdwCommonTool[]>([]);
  const [isToggleDisable, setIsToggleDisable] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const toolsWithEmptySpaces: AdwCommonTool[] = determineIfEmptyToolsExist(
      defaultOrPreferredTools
    );
    const displayArray: AdwCommonTool[] = getDisplayArray(toolsWithEmptySpaces);
    setPageOfItems(displayArray);
    setIsToggleDisable(
      defaultOrPreferredTools && defaultOrPreferredTools.length <= 5
        ? true
        : false
    );
    window.addEventListener("resize", handleResize);
  }, [defaultOrPreferredTools]);

  useLayoutEffect(() => {
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });


  const commonToolsClasses: string = commonToolsStyles
    ? `${commonToolsStyles} ${styles.CommonToolsWrapper}`
    : `${styles.CommonToolsWrapper}`;

  const numberOfNewTools: number = AdwCommonTool.getNumberOfNewTools(
    appState.lists.commonTools,
    appState.user
  );

  const toolHeadingClasses: string = toolHeadingStyles
    ? `${styles.toolsHeading} ${toolHeadingStyles}`
    : `${styles.toolsHeading}`;
  const firstFiveTools: any = defaultOrPreferredTools
    ? defaultOrPreferredTools.slice(0, 5)
    : [];
  const nextFiveTools: any = defaultOrPreferredTools
    ? defaultOrPreferredTools.slice(5, 10)
    : [];

  const getDisplayArray = (toolsArray: AdwCommonTool[]) => {
    let displayArray: AdwCommonTool[];
    if (pageNumber === 1) {
      displayArray = toolsArray ? toolsArray.slice(0, 5) : [];
    } else {
      displayArray = toolsArray ? toolsArray.slice(5, 10) : [];
    }
    return displayArray;
  };

  const determineIfEmptyToolsExist = (
    tools: AdwCommonTool[]
  ): AdwCommonTool[] => {
    if (tools && tools.length < 10 && showEditingTools) {
      for (let i: number = tools.length; i < 10; i++) {
        const emptyTool: AdwCommonTool = new AdwCommonTool(null, null);
        emptyTool.ID = 1 * i+100;
        tools.push(emptyTool);
      }
    }
    return tools;
  };

  const handleResize = () => {
    let displayArray: AdwCommonTool[] = getDisplayArray(
      defaultOrPreferredTools
    );
    if (detectmob()) {
      setPageOfItems(displayArray);
    } else {
      setPageOfItems(defaultOrPreferredTools);
    }
  };

  const detectmob = (): boolean => {
    if (window.innerWidth < 768) {
      return true;
    } else {
      return false;
    }
  };

  const isMobile: boolean = detectmob();

  const getData = (pageOfItems: AdwCommonTool[], pageNumber: number): void => {
    setPageOfItems(pageOfItems);
    setPageNumber(pageNumber);
  };

  const handleCloseButtonClick = (): void => {
    navigate("/");
  };

  const viewToolsHeading = (): JSX.Element => {
    return showEditingTools ? (
      <span>Tools</span>
    ) : (
      <a   onClick={(e)=>{   e.preventDefault();
        e.stopPropagation();
        navigate('/customizetools')}}
        id="new-tools-link"
        className={styles.titleLink}
      >
        <span>Tools</span>
      </a>
      
    );
  };

  return (
    <div className={commonToolsClasses} id="Common-Tools-Wrapper">
      <div className={toolHeadingClasses}>
        <h4 className={styles.title} id="Common-Tools-Title">
          {viewToolsHeading()}
        </h4>
        {!showEditingTools ? (
          <div className={styles.newToolsOrArrows}>
            <ToggleArrowButton
              toggleArrowButtonClasses={styles.arrows}
              disabled={isToggleDisable}
              onLeftArrowClick={() => getData(firstFiveTools, 1)}
              onRightArrowClick={() => getData(nextFiveTools, 2)}
            />
            {numberOfNewTools > 0 && (
              <a onClick={(e)=>{   e.preventDefault();
                e.stopPropagation();
                navigate('/customizetools')}}
                 id="new-tools-link">
                <span className={styles.newTools}>
                  {numberOfNewTools} new available
                </span>
              </a>
            )}
          </div>
        ) : (
          <div
            className={styles.closeButton}
            onClick={handleCloseButtonClick}
            id="Common-Tools-Close-Button"
          >
            <div className={styles.closeX} />
            <span className={styles.closeText}>CLOSE</span>
          </div>
        )}
      </div>
      {showEditingTools && (
        <span
          className={styles.customizeToolsText}
          id="Common-Tools-AdviceMessage"
        >
          Customize your tools to fit your everyday needs
        </span>
      )}
      <div className={styles.toolsDisplay}>
        {defaultOrPreferredTools && !showEditingTools
          ? (isMobile ? pageOfItems : defaultOrPreferredTools).map( // NOSONAR
              (tool, index) => (
                <Tool
                  tool={tool}
                  availableTools={false}
                  customizeTools={showEditingTools}
                  index={index}
                  key={tool.ID+'-'+randomAlphaNumeric(5)}
                />
              )
            )
          : defaultOrPreferredTools &&
            defaultOrPreferredTools.map((tool, index) => (
              <Tool
                tool={tool}
                availableTools={false}
                customizeTools={showEditingTools}
                toolStyles={
                  tool.ImagePath &&
                  tool.ImagePath.length &&
                  tool.ImagePath.length > 0
                    ? ""
                    : styles.emptyTool
                }
                index={index}
                toolPickerClickEvent={toolPickerClickEvent}
                toolPickerClick={selectTool}
                key={tool.ID+'-'+randomAlphaNumeric(5)}
              />
            ))}
      </div>
    </div>
  );
};

export default CommonToolsView;
