import * as React from "react";
import styles from "./CallupErrorMessage.module.scss";

const CallupErrorMessage: React.FC = () => {
  return (
    <div className={styles.error}>
      <div className={styles.body}>
        <div className={styles.mainMessage}>
          <h2>
            Sorry, we’re having trouble accessing that information right now.
          </h2>
          We’re working to correct the issue. In the meantime, you can <a target="_blank" rel="noopener noreferrer" href="https://onyourside.sharepoint.com/_layouts/15/sharepoint.aspx?v=%2Fsearch%2Fpeople">search for people in M365</a>.
        </div>
      </div>
    </div>
  );
}

export default CallupErrorMessage;
