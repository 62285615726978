import React from "react";

export interface IHeaderProps {
    level?: 1 | 2 | 3 | 4 | 5 | 6;
    id?: string;
    className?: string;
    children?:React.ReactNode;
}

const Header: React.FC<IHeaderProps> = ({ children, level, id, className }) => {
    const headerProps: IHeaderProps = {
        id: id ? id : null,
        className: className ? className : null,
    }
    switch (level) {
        case 2:
            return <h1 {...headerProps}>{children}</h1>
        case 3:
            return <h2 {...headerProps}>{children}</h2>
        case 4:
            return <h3 {...headerProps}>{children}</h3>
        case 5:
            return <h4 {...headerProps}>{children}</h4>
        default:
            return <h5 {...headerProps}>{children}</h5>
    }
};

export default Header;