import React from "react";
import styles from "./Copyright.module.scss";

export const id: string = "footer-copyright-value";

const Copyright: React.FC = () => {
  const today: Date = new Date();
  return (
    <div className={styles.copyright} id={id}>
      Nationwide Mutual Insurance Company &copy; {today.getFullYear()}
    </div>
  );
};

export default Copyright;
