import React, { useContext, useEffect, useState } from "react";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AdwHrConnectLink from "../../../types/FooterTypes/AdwHrConnectLink";
import styles from "./HRConnect.module.scss";
import { getWidget } from "./WidgetFactory";
import { AdwHrConnectSwitch } from "../../../types/HRConnectTypes/AdwHrConnectSwitch";
import Customize from "./Customize/Customize";
import customizeStyles from "./Customize/Customize.module.scss";
import HRConnectWidgetDropdown from "../../molecules/HRConnectWidgetDropdown/HRConnectWidgetDropdown";
import InsideDataService from "../../../services/InsideDataService";
import Link from "../../atoms/Link/Link";
import QuickLinks from "./widgets/QuickLinks/QuickLinks";

import AwsS3ImageNoDefault from "../../atoms/AwsS3Image/AwsS3ImageNoDefault";
function isLinksAsText(): boolean {
  return window.innerWidth <= 667;
}

const HRConnect: React.FC = () => {

  const [appState]: AdwContextType = useContext(AdwContext);
  const [customize, setCustomize] = React.useState(true);
  const [showCustomize, setShowCustomize] = React.useState(false);
  const [linksAsText, setLinksAsText] = useState(isLinksAsText())
  const [widgetOne, setWidgetOne] = useState("");
  const [widgetTwo, setWidgetTwo] = useState("");
  const [widgetThree, setWidgetThree] = useState("");
  const adwLoginID: string = (appState && appState.user && appState.user.adwLoginId);
  
  const links: AdwHrConnectLink[] =
    appState && appState.lists && appState.lists.hrConnectLinks
      && appState.lists.hrConnectLinks.length ? appState.lists.hrConnectLinks : [];

  const hrConnectSwitchList: AdwHrConnectSwitch[] =
    appState && appState.lists && appState.lists.hrConnectSwitches
      && appState.lists.hrConnectSwitches.length ? appState.lists.hrConnectSwitches : [];

  function handleResize(): void {
    setLinksAsText(isLinksAsText());
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => { window.removeEventListener("resize", handleResize) }
  })

  useEffect(() => {
    getUserWidgets();
  }, [adwLoginID])

  useEffect(() => {
    if (adwLoginID && hrConnectSwitchList.length) {
      hrConnectSwitchList.every((switchOption => {
        if (switchOption.position === 0 && switchOption.displayWidget && adwLoginID.match(switchOption.allowRegex)) {
          setShowCustomize(true);
          return false;
        }
        return true;
      }))
    }
  }, [adwLoginID, hrConnectSwitchList.length])



  function customizeDone(): void {
    // if Done is clicked */
    if (!customize) {
      if (appState.user.hrPreferredWidgets != null) {

        // save user pref
        InsideDataService.saveDataToInside(appState.user);

        //refresh user pref
        getUserWidgets();

      }
    }
    setCustomize(!customize)
  }

  //needs to ask if to use API user pref legacy or ADW InsideDataService
  const getUserWidgets = () => {
    InsideDataService.getUserPreferences(adwLoginID).then(
      (adwUserPreferences) => {
        if (adwUserPreferences.hrPreferredWidgets) {
          let widgetsArray = adwUserPreferences.hrPreferredWidgets.split(',');
          setWidgets(widgetsArray[0].trim(), widgetsArray[1].trim(), widgetsArray[2].trim())
        } else {
          getDefaultWidget()
        }
      }
    );
  }


  // no user preference - use default widgets based on display and position values
  const getDefaultWidget = () => {
    hrConnectSwitchList.forEach((hrSwitch => {
      if (hrSwitch.displayWidget) {
        switch (hrSwitch.position) {
          case 1:
            setWidgetOne(hrSwitch.widget);
            return;
          case 2:
            setWidgetTwo(hrSwitch.widget);
            return;
          case 3:
            setWidgetThree(hrSwitch.widget);
            return;
          default:
            return;
        }
      }
    }))
  }

  //function to set each widget
  function setWidgets(widget1: string, widget2: string, widget3: string) {
    setWidgetOne(widget1);
    setWidgetTwo(widget2);
    setWidgetThree(widget3);
  }

  return (
    <div className={styles.hrConnect}>
      <div className={styles.body}>
        <header className={styles.header}>
          <Link
            url="https://hrconnect.nationwide.com"
            target="_blank"
            linkClasses={styles.title}
            id="HRConnect"
          >
            <h2>HRConnect</h2>
          </Link>
          {showCustomize &&
            <Customize showing={!customize} toggle={customizeDone} />
          }
        </header>
        {customize ?
          <div className={styles.widgets}>
            <div className={`${styles.widget}`}>{getWidget(widgetOne)}</div>
            <div className={`${styles.widget}`}>{getWidget(widgetTwo)}</div>
            <div className={`${styles.widget}`}>{getWidget(widgetThree)}</div>
            <div className={`${styles.widget} ${styles.linksWidget}`}><QuickLinks links={links}/></div>
          </div>
          :
          <div className={styles.widgets}>
            <div className={`${customizeStyles.subHeader}`}>Customize your HRConnect worklets</div>
            <div className={`${customizeStyles.subSectionHeader}`}>Choose three (3) worklets from the HRConnect dashboard you would like to display on your InSide homepage.</div>
            {/* <div className={styles.widgets}> */}
            <HRConnectWidgetDropdown selectedWidgets={[widgetOne, widgetTwo, widgetThree]} />
            {/* </div> */}
          </div>
        }

      </div>
      <div className={styles.links}>
        <div className={styles.links_container}>
        {links.map((link => {
          return (
            <a
              className={styles.linkWrapper}
              href={link.Url}
              target={link.WindowTarget}
              key={`hr_connect_link_${link.Title}`}
            >
              {linksAsText ?
                link.Title :
                <AwsS3ImageNoDefault src={link.ImagePath} 
                  id={`hrconnect-aws-img-main-${link.Title}`} 
                  alt={link.Title}
                  className={styles.link}
                />}
            </a>
          );
        }))}
        </div>
      </div>
    </div>
  );
}

export default HRConnect;