import React from "react";
import AdwCommonTool from "../../../types/CommonToolsTypes/AdwCommonTool";
import styles from "../../../App.module.scss";
import AvailableToolsItem from "../../atoms/AvailableToolsItem/AvailableToolsItem";
import { randomAlphaNumeric } from "../../../utils/ArrayUtils";


interface IAllAvailableToolsWidgetProps {
  availableTools: AdwCommonTool[];
  numberOfNewAvailableTools: number;
}

const AllAvailableToolsWidget: React.FC<IAllAvailableToolsWidgetProps> = ({ availableTools,numberOfNewAvailableTools }) => {
  return (
    <div className={styles.all_available_tools_widget}>
      <div className={styles.all_available_tools_heading}>
        <div className={styles.all_available_tools_title}>
          <h2 className={styles.all_available_tools_title_text}>List of available tools</h2>
        </div>
        {numberOfNewAvailableTools > 0 &&
          <div className={styles.newly_added_tools}>
            <div className={styles.is_new_tool_wrap}>
              <div className={styles.is_new_tool} style={{ background: '#038574' }}></div>
            </div>
            <span>{numberOfNewAvailableTools} new available</span>
          </div>
        }
      </div>
      <ul className={styles.all_available_tools_list}>
        {availableTools && availableTools.map((availableTool, ind) => (
          <AvailableToolsItem key={randomAlphaNumeric(ind+1)}  positionIndex={ind} tool={availableTool}/>
        ))}
      </ul>
    </div>
  )
}
export default AllAvailableToolsWidget;
