import { CallupEmployee } from "./CallupEmployee";

class AdwEmployee {
  public fullName: string;
  public preferedFullName: string;
  public managerPrefFullName: string;
  public emplid: string;
  public shortName: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public prefFirstName: string;
  public prefLastName: string;
  public prefMiddleName: string;
  public companyCode: string;
  public jobTitle: string;
  public department: string;
  public workEmail: string;
  public workPhone: string;
  public workPhoneExtension: string;
  public locID: string;
  public locName: string;
  public mailDrop: string;
  public managerFlag: string;
  public managerShortName: string;
  public managerFirstName: string;
  public managerMiddleName: string;
  public managerLastName: string;
  public managerPrefName: string;
  public address1: string;
  public address2: string;
  public address3: string;
  public city: string;
  public state: string;
  public postal: string;
  public country: string;
  public disbCode2Descr: string;
  public emplStatus: string;
  public disbCode: string;
  public positionID: string;
  public supOrgID: string;
  public supOrgDescr: string;
  public costCenterID: string;
  public employeeType: string;
  public workPager: string;
  public workFax: string;
  public isAdmin: string;
  public expDate: string;
  public vendorDescr: string;
  public profilePictureUrl: string;
  public bossIDs: string[];
  public tabOnce: boolean;
  public tabTwice: boolean;
  public floor: string;
  public employeeLocation: string;

  public fromError: boolean;

  /**
   * @param callupEmployeeDetail user information from callup API
   * @param fromError True if this is a default user returned as a result of a caught
   * error in the Callup API, false otherwise. Helps to determine if blank user is due
   * to API call error or bad callup data
   */
  constructor(callupEmployeeDetail?: CallupEmployee, fromError: boolean = false) {
    this.fromError = fromError;
    if (callupEmployeeDetail) {
      this.emplid = callupEmployeeDetail.emplid;
      this.shortName = callupEmployeeDetail.shortName;
      this.firstName = callupEmployeeDetail.firstName;
      this.middleName = callupEmployeeDetail.middleName;
      this.lastName = callupEmployeeDetail.lastName;
      this.prefFirstName = callupEmployeeDetail.prefFirstName;
      this.prefLastName = callupEmployeeDetail.prefLastName;
      this.prefMiddleName = callupEmployeeDetail.prefMiddleName;
      this.companyCode = callupEmployeeDetail.companyCode;
      this.jobTitle = callupEmployeeDetail.jobTitle;
      this.department = callupEmployeeDetail.department;
      this.workEmail = callupEmployeeDetail.workEmail;
      this.workPhone = this.formatPhoneNumber(callupEmployeeDetail.workPhone);
      this.workPhoneExtension = callupEmployeeDetail.workPhoneExtension;
      this.locID = callupEmployeeDetail.locID;
      this.locName = callupEmployeeDetail.locName;
      this.mailDrop = callupEmployeeDetail.mailDrop;
      this.managerFlag = this.translateManagerFlag(callupEmployeeDetail.managerFlag);
      this.managerShortName = callupEmployeeDetail.managerShortName;
      this.managerFirstName = callupEmployeeDetail.managerFirstName;
      this.managerMiddleName = callupEmployeeDetail.managerMiddleName;
      this.managerLastName = callupEmployeeDetail.managerLastName;
      this.managerPrefName = callupEmployeeDetail.managerPrefName;
      this.address1 = callupEmployeeDetail.address1;
      this.address2 = callupEmployeeDetail.address2;
      this.address3 = callupEmployeeDetail.address3;
      this.city = callupEmployeeDetail.city;
      this.state = callupEmployeeDetail.state;
      this.postal = callupEmployeeDetail.postal;
      this.country = callupEmployeeDetail.country;
      this.disbCode2Descr = callupEmployeeDetail.disbCode2Descr;
      this.emplStatus = this.getEmployeeStatus(callupEmployeeDetail.emplStatus);
      this.disbCode = this.getDisbCode(callupEmployeeDetail.disbCode);
      this.positionID = callupEmployeeDetail.positionID;
      this.supOrgID = callupEmployeeDetail.supOrgID;
      this.supOrgDescr = callupEmployeeDetail.supOrgDescr;
      this.costCenterID = callupEmployeeDetail.costCenterID;
      this.employeeType = callupEmployeeDetail.employeeType;
      this.workPager = callupEmployeeDetail.workPager;
      this.workFax = callupEmployeeDetail.workFax;
      this.isAdmin = callupEmployeeDetail.isAdmin;
      this.expDate = callupEmployeeDetail.expDate;
      this.vendorDescr = callupEmployeeDetail.vendorDescr;
      this.profilePictureUrl = this.getProfilePictureUrl(callupEmployeeDetail.shortName);
      this.fullName = this.buildFullName();
      this.preferedFullName = this.buildFullPreferedName();
      this.managerPrefFullName = this.buildManagerFullPreferredName();
      this.bossIDs = this.getBossIds(callupEmployeeDetail);
      this.floor = this.getFloor(callupEmployeeDetail.mailDrop);
      this.employeeLocation = this.getEmployeeLocation(callupEmployeeDetail.address1, this.floor, callupEmployeeDetail.locID);
    } else {
      this.emplid = " ";
      this.shortName = " ";
      this.firstName = " ";
      this.middleName = " ";
      this.lastName = " ";
      this.prefFirstName = " ";
      this.prefLastName = " ";
      this.prefMiddleName = " ";
      this.companyCode = " ";
      this.jobTitle = " ";
      this.department = " ";
      this.workEmail = " ";
      this.workPhone = " ";
      this.workPhoneExtension = " ";
      this.locID = " ";
      this.locName = " ";
      this.mailDrop = " ";
      this.managerFlag = " ";
      this.managerShortName = " ";
      this.managerFirstName = " ";
      this.managerMiddleName = " ";
      this.managerLastName = " ";
      this.managerPrefName = " ";
      this.address1 = " ";
      this.address2 = " ";
      this.address3 = " ";
      this.city = " ";
      this.state = " ";
      this.postal = " ";
      this.country = " ";
      this.disbCode2Descr = " ";
      this.emplStatus = " ";
      this.disbCode = " ";
      this.positionID = " ";
      this.supOrgID = " ";
      this.supOrgDescr = " ";
      this.costCenterID = " ";
      this.employeeType = " ";
      this.workPager = " ";
      this.workFax = " ";
      this.isAdmin = " ";
      this.expDate = " ";
      this.vendorDescr = " ";
      this.profilePictureUrl = this.getProfilePictureUrl(undefined);
      this.fullName = "";
      this.preferedFullName = "";
      this.managerPrefFullName = "";
      this.bossIDs = [];
      this.floor = " ";
      this.employeeLocation = "";
    }
  }
  /**
   * https://outlook.office365.com/owa/service.svc/s/GetPersonaPhoto?email=naile2@nationwide.com&size=HR240x240&cb=637006288516408477
   * https://outlook.office365.com/owa/service.svc/s/GetPersonaPhoto?email=eric.chin@nationwide.com&size=HR240x240&cb=637006288516408477
   */
  private getProfilePictureUrl(nwie: string): string {
    if (!nwie) {
      nwie = `portladm`;
      //https://inside.nwie.net/includes/media/missingPerson.bmp
    }
    let pictureUrl: string = `https://outlook.office365.com/owa/service.svc/s/GetPersonaPhoto?email=`;
    pictureUrl = `${pictureUrl}${nwie}@nationwide.com&size=HR240x240&cb=637006288516408477`;
    return pictureUrl;
  }

  /**
   * Build a string in this format LASTNAME, FIRSTNAME, Middle initial ( prefered name )
   * Example: Jones, Doug C. ( Tim )
   */
  public buildFullName(): string {
    let middleInitial: string = this.middleName !== null ? `${this.middleName.substring(0, 1)}.` : "";
    let name = `${this.lastName}, ${this.firstName} ${middleInitial}`;
    return this.prefFirstName !== null ? `${name} (${this.prefFirstName})` : name;
  }

  /**
   * Display a person's name. Build a string in this format FIRSTNAME LASTNAME ( where either or both are preferred name set by employee )
   * Example: Tim Jones
   */
  public buildFullPreferedName(): string {
    let firstname = (this.prefFirstName !== null && this.prefFirstName.trim() !== "") ? `${this.prefFirstName}` : this.firstName;
    let lastname = (this.prefLastName !== null && this.prefLastName.trim() !== "") ? `${this.prefLastName}` : this.lastName;
    let fullName = `${firstname} ${lastname}`;
    return fullName;
  }

  /**
   * Display name for a person's manager. Build a string in this format FIRSTNAME LASTNAME ( where either or both are preferred name set by employee )
   * Example: Tim Jones
   */
  public buildManagerFullPreferredName(): string {
    let fullname = this.managerPrefName !== null ? `${this.managerPrefName}` : `${this.managerLastName}, ${this.managerFirstName}`;
    let namearray = fullname.split(',', 2);
    let mgrFullName = `${namearray[1]} ${namearray[0]}`;
    return mgrFullName;
  }

  /**
   * 1 == Yes
   * 0 == No
   */
  public translateManagerFlag(managerFlag: string): string {
    let returnValue: string = "No";
    if (managerFlag && managerFlag === "1") {
      returnValue = "Yes";
    }
    return returnValue;
  }

  /*
   * Per Janna / Jessica this is the format (614) 249-0329
   */
  private formatPhoneNumber(phoneNumber: string): string {
    let formatted = ``;
    if (phoneNumber && phoneNumber != null && phoneNumber !== " " && phoneNumber.length >= 9) {
      formatted = phoneNumber.substring(0, 9) + `-` + phoneNumber.substring(9);
    }
    return formatted;
  }

  private getEmployeeStatus(status: string): string {
    let employeeStatus: string = status;
    if (employeeStatus && employeeStatus != null && employeeStatus === "A") {
      employeeStatus = "Active";
    }
    if (employeeStatus === null) {
      employeeStatus = ``;
    }
    return employeeStatus;
  }

  /**
   * @param mailDrop
   * Take the mail drop, pull out the floor
   * example mail drops: 3-14-201 AD-A2-101
   */
  private getFloor(mailDrop: string): string {
    let floor: string = ``;
    if (mailDrop) {
      floor = mailDrop.substring(mailDrop.indexOf("-") + 1, mailDrop.lastIndexOf("-")).replace(/[A-Za-z]/gi, ""); // NOSONAR
    }
    return floor;
  }

  private getDisbCode(disbCode: string) {
    let code: string = disbCode;
    if (code && code.length > 6) {
      code = code.substring(0, 6);
    }
    return code;
  }

  private getEmployeeLocation(building: string, floor: string, locID: string): string {
    let employeeLocation: string = ` `;

    switch (building) {
      case `Three Nationwide Plaza`:
        building = "Plaza 3";
        break;
      case `Two Nationwide Plaza`:
        building = "Plaza 2";
        break;
      case `One Nationwide Plaza`:
        building = "Plaza 1";
        break;
      default:
        building = locID;
        floor = ``;
        break;
    }

    if (building && floor) {
      employeeLocation = `${building} Floor ${floor}`;
    } else if (building) {
      employeeLocation = `${building}`;
    }

    return employeeLocation;
  }

  /**
   * Build an array, of employeeId's of type string
   * The Big Boss is the highest paid in the group
   */
  private getBossIds(callupEmployee: CallupEmployee): string[] { // NOSONAR
    let bossIDs: string[] = [];

    if (callupEmployee.level11FromTopEmplid && callupEmployee.level11FromTopEmplid != null) {
      bossIDs.push(callupEmployee.level11FromTopEmplid);
    }
    if (callupEmployee.level10FromTopEmplid && callupEmployee.level10FromTopEmplid != null) {
      bossIDs.push(callupEmployee.level10FromTopEmplid);
    }
    if (callupEmployee.level09FromTopEmplid && callupEmployee.level09FromTopEmplid != null) {
      bossIDs.push(callupEmployee.level09FromTopEmplid);
    }
    if (callupEmployee.level08FromTopEmplid && callupEmployee.level08FromTopEmplid != null) {
      bossIDs.push(callupEmployee.level08FromTopEmplid);
    }
    if (callupEmployee.level07FromTopEmplid && callupEmployee.level07FromTopEmplid != null) {
      bossIDs.push(callupEmployee.level07FromTopEmplid);
    }
    if (callupEmployee.level06FromTopEmplid && callupEmployee.level06FromTopEmplid != null) {
      bossIDs.push(callupEmployee.level06FromTopEmplid);
    }
    if (callupEmployee.level05FromTopEmplid && callupEmployee.level05FromTopEmplid != null) {
      bossIDs.push(callupEmployee.level05FromTopEmplid);
    }
    if (callupEmployee.level04FromTopEmplid && callupEmployee.level04FromTopEmplid != null) {
      bossIDs.push(callupEmployee.level04FromTopEmplid);
    }
    if (callupEmployee.level03FromTopEmplid && callupEmployee.level03FromTopEmplid != null) {
      bossIDs.push(callupEmployee.level03FromTopEmplid);
    }
    if (callupEmployee.level02FromTopEmplid && callupEmployee.level02FromTopEmplid != null) {
      bossIDs.push(callupEmployee.level02FromTopEmplid);
    }

    return bossIDs;
  }
}

export default AdwEmployee;
