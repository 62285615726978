import React from "react";
import ReactTooltip from "react-tooltip";
import AdwCommonTool from "../../../types/CommonToolsTypes/AdwCommonTool";
import Link from "../../atoms/Link/Link";
import ToolPicker from "../ToolPicker/ToolPicker";
import styles from "./Tool.module.scss";

export interface IToolProps {
  tool: AdwCommonTool;
  toolStyles?: string;
  availableTools: boolean;
  customizeTools: boolean;
  index: number;
  toolPickerClickEvent?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  toolPickerClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Tool: React.FC<IToolProps> = ({
  availableTools,
  customizeTools,
  index,
  tool,
  toolStyles,
  toolPickerClickEvent,
  toolPickerClick,
}) => { // NOSONAR
  let toolClasses: string = toolStyles ? `${styles.tool} ${toolStyles}` : `${styles.tool}`;
  toolClasses = availableTools && tool.NewTool ? `${toolClasses} ${styles.newTool}` : `${toolClasses}`;
  toolClasses = !customizeTools ? `${toolClasses} ${styles.hoverableTool}` : `${toolClasses}`;
  const toolTipStyles: string = availableTools ? `${styles.tooltipStyle} ${styles.availableToolsTooltip}` : `${styles.tooltipStyle}`;
  const toolLinkStyle: string = availableTools ? `${styles.toolLink} ${styles.availabletoolLink}` : `${styles.toolLink}`

  const dataAttributes: {} = {
    "data-preferredtoolid": customizeTools ? tool.ID : null,
    "data-index": index,
  };

  const toolBoxID: string = customizeTools ? `tool-c-${index}` : `tool-${index}`;


  let toolComponent: JSX.Element = null;
  if (customizeTools || availableTools) {
    const toolContents: JSX.Element = (
      <button onClick={toolPickerClickEvent} className={styles.customizeTool} {...dataAttributes} >
        <div className={styles.toolTextWrapper}>
          <strong className={''} id={`custom-tool-title-${index}`}>
            {tool.Title}
          </strong>
        </div>
        {customizeTools && !tool.RequiredTool && (
          <ToolPicker toolId={tool.ID} toolPickerClick={toolPickerClick} toolIndex={index} />
        )}
        {availableTools && (
          <ReactTooltip id={`Tooltip-text-link-${tool.ID}-${index}`} className={toolTipStyles}>
            <span id={`Tooltip-text-${index}`}> {tool.TooltipText} </span>
        </ReactTooltip>    
        )}
      </button>
    );
    toolComponent = customizeTools ? (
      toolContents
    ) : (
      <Link url={tool.Link.Url} linkClasses={toolLinkStyle} target={"_blank"} id={`custom-tool-link-${index}`}>
        {toolContents}
      </Link>
    );
  } else {
    toolComponent = (
      <>
        <ReactTooltip id={`Tooltip-text-link-${tool.ID}-${index}`} className={styles.tooltipStyle}>
          <span id={`Tooltip-text-${index}`}> {tool.TooltipText} </span>
        </ReactTooltip>
        <Link url={tool.Link.Url} linkClasses={toolLinkStyle} target={tool.WindowTarget} id={`custom-tool-link-${index}`}>
         
          <div className={styles.toolTextWrapper}><strong id={`custom-tool-title-${index}`}>{tool.Title}</strong></div>
        </Link>
      </>
    );
  }

  return (
    <>
      <div className={toolClasses} data-toolid={tool.ID} data-tip data-for={`Tooltip-text-link-${tool.ID}-${index}`} id={toolBoxID}>
        {toolComponent}
      </div>
    </>
  );
};

export default Tool;
