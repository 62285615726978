import Polyfills from "../../assets/js/polyfills";
import { SpReminder } from "./SpReminder";

class AdwReminder {
  public ID: number;
  public ReminderText: string;
  public StartDate: string;
  public ExpirationDate: string;
  public ReminderLink: { Description: string; Url: string };
  public WindowTarget: string;

  constructor(spReminder: SpReminder) {
    Polyfills.isInteger();
    this.buildAdwReminder(spReminder);
  }

  private buildAdwReminder = (spReminder: SpReminder): void => {
    this.ID = Number(spReminder.fields.id);
    this.ReminderText = spReminder.fields.ReminderText;
    this.StartDate = spReminder.fields.StartDate;
    this.ExpirationDate = spReminder.fields.ExpirationDate;
    this.ReminderLink = {
      Description: spReminder.fields.ReminderLink ? spReminder.fields.ReminderLink.Description : "",
      Url: spReminder.fields.ReminderLink ? spReminder.fields.ReminderLink.Url : "/#",
    };
    this.WindowTarget = spReminder.fields.WindowTarget;
  };

  public static idStringToArray = (checkedReminders: string): number[] => {
    let numberArray: number[] = [];
    if (checkedReminders) {
      const checkedReminderssArray: string[] = checkedReminders.split(",");
      numberArray = checkedReminderssArray.map(checkedReminder => parseInt(checkedReminder, 0)).filter(id => Number.isInteger(id));
    }
    return numberArray;
  };

  public static idArrayToString(checkedReminders: number[]): string {
    let returnVal: string = "";
    if (checkedReminders && checkedReminders.length > 0) {
      returnVal = checkedReminders.filter(id => Number.isInteger(id)).join(",");
    }
    return returnVal;
  }
}

export default AdwReminder;
