import EnvironmentService from "./EnvironmentService";
import { v4 as uuidv4 } from 'uuid';
import AuthService from "./AuthService";

export default class ErrorReportingService {
  private static apigeeUrl: string = EnvironmentService.getApigeeConfig().domain;
  private static apigeeKey: string = EnvironmentService.getApigeeConfig().clientId;
  private static user: string = "";

  public static setUser(user) {
    this.user = user;
  }

  public static sendToSplunk(payload): void {
    const url: string = `${ErrorReportingService.apigeeUrl}/it-management/client-logging/v1/client-logs-cloud`;
    let uuid = uuidv4();
    console.log("ErrorReporting >>> sendToSplunk : ", JSON.stringify(payload))
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: AuthService.getAuthorizationHeader(),
        "Content-Type": "application/json",
        "client_Id": ErrorReportingService.apigeeKey,
        "X-Nw-Transaction-Id": uuid,
      },
      body: JSON.stringify(payload),
    })
      .then(resp => {
        if (!resp.ok) {
          throw new Error(`Received Non 200 Response - ${resp.status} - ${resp.statusText}`);
        }
        else {
          return resp;
        }
      })
      .catch(err => {
        console.error("Error logging to splunk", err);
        return {
          employeeNumber: 1,
          events: null,
          paydays: null,
        };
      });
  }


  public static reportErrorWithResponse(error: Response, func: string, extra: any): void {
    let desc: string;

    if (error && error.status) {
      if (error.status === 400) {
        desc = "Your request is invalid and/or not formed properly. You need to reformulate your request.";
      }
      else if (error.status === 401) {
        desc = "Unauthorized. Either you need to provide authentication credentials, or the credentials provided aren't valid."
      }
      else if (error.status === 403) {
        desc = "We understand your request, but are refusing to fulfill it. Possible authentication error. Review message within error."
      }
      else if (error.status === 404) {
        desc = "Not found. Either you're requesting an invalid URI or the resource in question doesn't exist."
      }
      else if (error.status === 429 || error.status === 502) {
        desc = "Returned if Intervals is down or being upgraded, or if the system is overloaded and API requests are being throttled."
      }
      else if (error.status === 500) {
        desc = "The server encountered an error. They are likely aware / looking into it."
      }
      else {
        desc = "Unknown error: " + error.status + error.statusText
      }
    }
    let timestamp = new Date();
    let payload: any = {
      "splunkToken": "5CC81CBF-2213-4006-AF54-54EB2956CC8F",
      "logs": [
        {
          "event": `{ 
          "File->Function": "${func}",
          "Environment": "${EnvironmentService.getEnvironment()}", 
          "Timestamp": "${timestamp}",
          "Extra": "${extra}",
          "User": "${this.user}",
          "Description": "${desc}"
         }`,
          "fields": "{}",
          "sourceType": "inside_json"
        }
      ]
    }


    this.sendToSplunk(payload);
  };



  public static reportErrorWithMessage(error: string, func: string, extra: any): void {
    let timestamp = new Date();

    let payload: any = {
      "splunkToken": "5CC81CBF-2213-4006-AF54-54EB2956CC8F",
      "logs": [
        {
          "event": `{ 
          "Error": "${error}", 
          "File->Function": "${func}",
          "Environment": "${EnvironmentService.getEnvironment()}", 
          "Timestamp": "${timestamp}",
          "Extra": "${extra}",
          "User": "${this.user}"
         }`,
          "fields": "{}",
          "sourceType": "inside_json"
        }
      ]
    }

    this.sendToSplunk(payload);
  };

  public static reportLogsWithMessage(description: string, func: string, extra: any): void {
    let timestamp = new Date();
    let payload: any = {
      "splunkToken": "5CC81CBF-2213-4006-AF54-54EB2956CC8F",
      "logs": [
        {
          "event": `{ 
          "Information": "${description}", 
          "File->Function": "${func}",
          "Environment": "${EnvironmentService.getEnvironment()}", 
          "Timestamp": "${timestamp}",
          "Extra": "${extra}",
          "User": "${localStorage.getItem("currentUser")}"
         }`,
          "fields": "{}",
          "sourceType": "inside_json"
        }
      ]
    }

    this.sendToSplunk(payload);
  };
}



// 200 OK, 201 Created, 202 Accepted:
// Everything went awesome.

// 400 Bad Request:
// Your request is invalid and/or not formed properly. You need to reformulate your request.

// 401 Not Authorized:
// Either you need to provide authentication credentials, or the credentials provided aren't valid.

// 403 Forbidden:
// We understand your request, but are refusing to fulfill it. An accompanying error message should explain why.

// 404 Not Found:
// Either you're requesting an invalid URI or the resource in question doesn't exist (ex: no such user).

// 500 Internal Server Error:
// We did something wrong. We'll be notified and we'll look into it.

// 502 Bad Gateway:
// Returned if Intervals is down or being upgraded, or if the system is overloaded and API requests are being throttled.

// 503 Service Unavailable:
// Usually as a result of suspension, we are refusing to process this request. You *may* try again later.