import * as React from "react";
import Copyright from "../../atoms/Copyright/Copyright";
import FooterMenu from "../../molecules/FooterMenu/FooterMenu";
import SystemAdmin from "../../molecules/SystemAdmin/SystemAdmin";
import styles from "./FooterView.module.scss";

export const FOOTER_ID: string = "adw-footer";
export const FOOTER_MENU_WRAPPER: string = "footer-menu-wrapper";
export const FOOTER_MENU_ID: string = "footer-menu";
export const FOOTER_COPYRIGHT_ID: string = "copyright-wrapper";
export const FOOTER_SYSTEM_ADMIN_ID: string = "footer-system-admin";


const FooterView: React.FC = () => {
  return (
    <div role="contentinfo" className={styles.footerWrapper} id={FOOTER_ID}>
      <div className={styles.footerMenuWrapper} id={FOOTER_MENU_WRAPPER}>
        <div className={styles.footerMenu} id={FOOTER_MENU_ID}>
          <FooterMenu />
        </div>
        <div className={styles.footerSystemAdminWrapper} id={FOOTER_SYSTEM_ADMIN_ID}>
          <SystemAdmin />
        </div>
      </div>
      <div className={styles.copyrightBox} id={FOOTER_COPYRIGHT_ID}>
        <Copyright />
      </div>

    </div>
  )
}

export default FooterView;
