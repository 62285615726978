import { SpAlert } from "./SpAlert";

class AdwAlert {
  public AlertLink: { Description: string; Url: string };
  public AlertMessage: string;
  public AlertType: string;
  public EndDate: string;
  public Id: number;
  public StartDate: string;
  public Target: string;
  public Title: string;

  constructor(spAlert: SpAlert) {
    this.AlertLink = spAlert.fields.AlertLink;
    this.AlertMessage = spAlert.fields.AlertMessage;
    this.AlertType = spAlert.fields.AlertType;
    this.EndDate = spAlert.fields.EndDate;
    this.Id = spAlert.fields.id;
    this.StartDate = spAlert.fields.StartDate;
    this.Target = spAlert.fields.Target;
    this.Title = spAlert.fields.Title;
  }
}

export default AdwAlert;
