// class to set browser compatible methods... IE 11 does not support Object.entries()... see below for example
/* tslint:disable */

class Polyfills {
  public static entries = (): void => {
    Object.entries = (obj: any): any[] => {
      let ownProps: string[] = Object.keys(obj),
        i: number = ownProps.length,
        resArray: any[] = new Array(i); // preallocate the Array
      while (i--) {
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
      }
      return resArray;
    };
  };

  public static remove = (): void => {
    Element.prototype.remove = function(): void {
      if (this.parentNode) {
        this.parentNode.removeChild(this);
      }
    };
  };

  // https://stackoverflow.com/questions/31533963/polyfill-for-push-method-in-javascript
  public static push = (): void => {
    // eslint-disable-next-line
    Array.prototype.push = function(): number {
      for (let i: number = 0, len: number = arguments.length; i < len; i++) {
        this[this.length] = arguments[i];
        if (
          Object.prototype.toString
            .call(this)
            .slice(8, -1)
            .toLowerCase() === "object"
        ) {
          this.length += 1;
        }
      }
      return this.length;
    };
  };

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes
  public static includes = (): void => {
    // eslint-disable-next-line
    String.prototype.includes = function(search: string, start: number): boolean {
      if (typeof start !== "number") {
        start = 0;
      }

      if (start + search.length > this.length) {
        return false;
      } else {
        return this.indexOf(search, start) !== -1;
      }
    };
  };

  public static beginsWith = (): void => {
    // eslint-disable-next-line
    Object.defineProperty(String.prototype, "beginsWith", {
      value: function(search: string, pos: number): boolean {
        return this.substring(!pos || pos < 0 ? 0 : +pos, pos + search.length) === search;
      },
    });
  };

  public static isInteger = (): void => {
    Number.isInteger =
      Number.isInteger ||
      function(value) {
        return typeof value === "number" && isFinite(value) && Math.floor(value) === value;
      };
  };

  // note:  We can use npm i core-js
 public static fromEntries =(iterable):void=> {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val
    return obj
  }, {})
}
}

export default Polyfills;
