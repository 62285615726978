import AdwFooterMenuItem from "./AdwFooterMenuItem";

class AdwFooterMenuLink {
  public Description: string;
  public Url: string;
  public Order: number;
  public WindowTarget: string;

  constructor( adwFooterMenuItem: AdwFooterMenuItem) {
    this.Description = adwFooterMenuItem.Item.Description;
    this.Url = adwFooterMenuItem.Item.Url;
    this.Order = adwFooterMenuItem.ItemOrder;
    this.WindowTarget = adwFooterMenuItem.WindowTarget;
  }

}

export default AdwFooterMenuLink;
