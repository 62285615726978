import * as Msal from "@azure/msal-browser";
import BrowserUtils from "../utils/BrowserUtils";
import EnvironmentService from "./EnvironmentService";

export const msalConfig: Msal.Configuration = {
  auth: {
    clientId: EnvironmentService.getAzureClientId(),
    redirectUri: `${window.location.origin}`,
    authority: `https://login.microsoftonline.com/${EnvironmentService.getAzureTenantId()}`
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  }
}

const loginRequest = {
  scopes: ["openid", "profile", "User.Read"]
}

/**
 * Sets flag forceRefresh to true, the program will skip the cached id token in sessionStorage,
 * it will call the server directly to get a new id token and replace the catched one with this new
 * value to sessionStorage.
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/FAQ.md#how-do-i-renew-tokens-with-msaljs
 */
const tokenRequestForceRefresh = {
  scopes: ["User.Read"],
  forceRefresh: true,
}

export let myMSALObj: Msal.PublicClientApplication;
export let username = "";

if (!BrowserUtils.isIframe()) {
  myMSALObj = new Msal.PublicClientApplication(msalConfig);
  // Redirect: once login is successful and redirects with tokens, call Graph API
  myMSALObj.handleRedirectPromise().then(handleResponse).catch(err => {
    console.error("MsalAuthService >>> BrowserUtils >>> handleRedirectPromise. "+err);
  });
}

export function hasActiveMsalSession(): boolean {
  return !!username;
}

function handleResponse(resp) {
  if (resp !== null) {
    username = resp.account.username;
    myMSALObj.setActiveAccount(resp.account);
  } else {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    const currentAccounts = myMSALObj.getAllAccounts();
    if (currentAccounts === null) {
      return;
    } else if (currentAccounts.length > 1) {
      // Add choose account code here
      console.warn("MsalAuthService >>> handleResponse >>> Multiple accounts detected.");
    } else if (currentAccounts.length === 0) {
      signIn();
    } else if (currentAccounts.length === 1) {
      username = currentAccounts[0].username;
      myMSALObj.setActiveAccount(currentAccounts[0]);
    }
  }
}

function signIn() {
  myMSALObj.loginRedirect(loginRequest);
}

function getTokenRedirect(request) {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  return myMSALObj.acquireTokenSilent(request).then(data=>{
    request.account = myMSALObj.getActiveAccount()
    return data}).catch(error => {
    console.warn("MsalAuthService >>> getTokenRedirect : Silent token acquisition fails. acquiring token using redirect");
    if (error instanceof Msal.InteractionRequiredAuthError || (error instanceof Msal.BrowserAuthError && error.message == "no_account_error")) {
      // fallback to interaction when silent call fails
      return myMSALObj.acquireTokenRedirect(request);
    } else {
      console.warn("MsalAuthService >>> getTokenRedirect : " + error);
    }
  });
}

export function getAccessToken(): Promise<string> {
  return getTokenRedirect(loginRequest).then(response => {
    if (!response || !response.accessToken) {
      console.log("MsalAuthService >>> getAccessToken : Failed to get access token, no redirect response");
      return "";
    } else {
      return response.accessToken;
    }
  })
}


export function getIdTokenFromAzure(): Promise<string> {
  return getTokenRedirect(loginRequest).then(response => {
    if (!response || !response.idToken) {
      console.log("MsalAuthService >>> getIdTokenFromAzure : Failed to get access token, no redirect response");
      return "";
    } else {
      return response.idToken;
    }
  })
}

export function getIdTokenFromAzureWithForceRefresh(): Promise<string> {
  return getTokenRedirect(tokenRequestForceRefresh).then(response => {    
    if (!response || !response.idToken) {
      console.log("MsalAuthService >>> getIdTokenFromAzureWithForceRefresh : Failed to forceRefresh to get id token, no redirect response");
      return "";
    } else {
      return response.idToken;
    }
  })
}

