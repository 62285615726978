import React from 'react'
import { Link } from "react-router-dom";
import styles from './AllAnnouncementsLink.module.scss'

export interface IAllAnnouncementsLinkProps {
  linkStyles: string;
  moreNewsStyles: string;
}

const AllAnnouncementsLink: React.FC<IAllAnnouncementsLinkProps> = ({linkStyles, moreNewsStyles}) => {
  return (
  <div className={`${moreNewsStyles} ${styles.link}`}>
    <Link className={linkStyles} to='/seeAllAnnouncements'>
      <span>View all announcements</span>
    </Link>
  </div>)
}

export default AllAnnouncementsLink;