import React, { useContext, useEffect, useState } from "react";
import { UpdateViewUserDetailNwie } from "../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AuthService from "../../../services/AuthService";
import CallupService from "../../../services/CallupService";
import AdwEmployee from "../../../types/CallupTypes/AdwEmployee";
import styles from "./Admin.module.scss";

export interface IAdminProps {
  adminClasses?: string;
  adwEmployee: AdwEmployee;
}

const Admin: React.FC<IAdminProps> = ({ adminClasses, adwEmployee }) => {

  const [, dispatch] = useContext<AdwContextType>(AdwContext);

  const [adminList, setAdminList] = useState([]);

  useEffect(() => { updateCallupAdmin(); }, [adwEmployee])

  const updateCallupAdmin = (): void => {
    if (AuthService.hasActiveSession()) {
      CallupService.getCallupAdminList(adwEmployee.shortName)
        .then(adminList => {
          setAdminList(adminList);
        })
        .catch(error => {
          console.log("Admin >>> updateCallupAdmin : Error retrieving admins list:", error);
        });
    } else {
      setTimeout(updateCallupAdmin, 500);
    }
  }

  const onFocusChange = (): void => {
    setTimeout(() => {
      document.getElementById("fancy-name").focus();
    }, 500)
  }


  const onClickEmployee = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>, nwie: string): void => {
    e.preventDefault();
    dispatch(UpdateViewUserDetailNwie(nwie));
    onFocusChange();
  }


  return adminList && adminList[0] ? (
    <div className={styles.admin}>
      <li id={`admin-link`}>
        <div className={styles.rowWrapper}>
          <div className={styles.label}>ADMINISTRATIVE ASSISTANT</div>
          <div className={styles.value} >
            <div
              className={styles.admin}
              id={`callup-admin-link`}
              tabIndex={0}
              onClick={e => {
                onClickEmployee(e, adminList[0].shortName);
                document.getElementById("fancy-name").style.outline = "0";
              }}
              onKeyDown={(e: any) => {
                if (e.code === 'Enter') {
                  onClickEmployee(e, adminList[0].shortName);
                }
              }}
            >
              {adminList[0].buildFullPreferedName() ? (`${adminList[0].buildFullPreferedName()}`) : (`${adminList[0].buildFullName()}`)}
            </div>
          </div>
        </div>
      </li>
    </div>
  ) : null;
}

export default Admin;