import { useEffect, useState, useContext } from "react";
import { AdwContextType, AdwContext } from "../context/AdwContext";
import { AdwFeatureSwitch } from "../types/FeatureSwitchTypes/AdwFeatureSwitch";

export type features="Component Moved"|"Announcements Widget";

const useFeatureSwitch = ( featuresArr : features[]) => {
  const [arrayOfFeaturesEnabled, setArrayOfFeaturesEnable] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const [appState]: AdwContextType = useContext(AdwContext);
  const nwieID: string = (appState && appState.user && appState.user.nwieID);
  const featureSwitchList: AdwFeatureSwitch[] =
    appState && appState.lists && appState.lists.featureSwitches
      && appState.lists.featureSwitches.length ? appState.lists.featureSwitches : [];

  useEffect(() => {
    if (nwieID && featureSwitchList.length) {
      const arrayOfFeatures = featuresArr.map((feature) => {
        const matchingFeature = featureSwitchList.find((switchOption) => switchOption.feature === feature && switchOption.displayWidget);
        if (matchingFeature) {
          return { ...matchingFeature, nwieID };
        }
        return null;
      })

      setArrayOfFeaturesEnable(arrayOfFeatures)
      setIsLoaded(true);
    }

  }, [nwieID, featureSwitchList]);

  return { arrayOfFeaturesEnabled, isLoaded };
};

export default useFeatureSwitch;
