import React, { useEffect, useRef, useState } from "react";
import { UpdateSearchQuery, UpdateViewUserDetailNwie } from "../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import { ReactComponent as SearchIcon } from "../../../assets/images/MagnifyingGlass_searchBar_icon.svg";
import { ReactComponent as ResetIcon } from "../../../assets/images/Cross.svg";
import styles from "./MindbreezeAutoCompleteWithSearch.module.scss";
import AuthService from "../../../services/AuthService";
import PeopleUtils from "../../../utils/PeopleUtils";
import "./MindbreezeAutoCompleteWithSearch.css";
import "../../organisms/NewSearch/NewSearch.css";
import { useNavigate } from "react-router-dom";
const mail: string = require("../../../assets/images/Mail.svg").default;
const peopleResultDefault: string = require("../../../assets/images/PeopleSearch_Avatar.png");

export interface IMindbreezeAutoCompleteWithSearchProps {
  visibility:boolean
}

const MindbreezeAutoCompleteWithSearch: React.FC<IMindbreezeAutoCompleteWithSearchProps> = ({visibility}) => { // NOSONAR

  const navigate = useNavigate();
  const [, dispatch]: AdwContextType = React.useContext(AdwContext);



  let spacerStyles: string = `${styles.search} ${styles.searchPage}`;

  let appRootElRef = useRef();

  const [query, setQuery] = useState("");

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    spacerStyles = `${styles.search} ${styles.searchPage}`;
    const interval = setInterval(() => {
      // @ts-ignore
      if (window.Mindbreeze) {
        scriptLoaded();
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateQuery = (query): void => {
    spacerStyles = `${styles.search} ${styles.searchPage}`;
    setQuery(query);
  }

  const scriptLoaded = () => { // NOSONAR
    const rootEl = appRootElRef.current;
    updateQuery(query);


    // @ts-ignore
    window.Mindbreeze.require([
      "client/application",
      'component!suggest',
      "jquery",
      'underscore',
      'backbone',
      'i18n/i18n'
    ], function (
      Application,
      SuggestTemplate,
      $,
      _,
      Backbone,
      i18n
    ) {
      let myApplication = Application.extend({
        handleEmailClick: function (email) {
          PeopleUtils.openEmail(email.email);
        },
      });

      new myApplication({ // NOSONAR
        rootEl: rootEl,
        loginAsError: true,
        disableClientNotifications: true,
        ajaxOptions: {
          requestHeadersAsync: async function (url, callback) {
            let token = await AuthService.getAuthorizationToken();
            if (token) {
              if (AuthService.isTokenCurrent(token)) {
                callback(null, { 'Authorization': token });
              } else {
                let newToken = await AuthService.getMindbreezeHeadersWithForceRefresh();
                console.log('MindbreezeAutoCompleteWithSearch >>> scriptLoaded : new id token with forceRefresh ');
                callback(null, { 'Authorization': newToken });
              }
            }
          }
        },
        onRequestFailed: function (error) {
          console.log('MindbreezeAutoCompleteWithSearch >>> scriptLoaded : error.status : ', error.status);
          console.log('MindbreezeAutoCompleteWithSearch >>> scriptLoaded : error.reason : ', error.reason);
        },
      })

      /* Store original functionality */
      let originalAutocompleteSource = SuggestTemplate.View.prototype._autocompleteSource;

      /* Store original sort suggest results */
      let originalSortSuggestResults = SuggestTemplate.View.prototype._sortSuggestResults;

      /* Create new SuggestTemplateAutocompleteSource */
      let SuggestTemplateAutocompleteSource = function () {
        // @ts-ignore
        this.initialize.apply(this, arguments);
      };

      /* dropdown - Content & People */
      let afterResponse = function () {
        let tempContainer = $("ul[id^='ui-id']"),
          listClass = 'sub-list', //ul
          /* comment out for now - no result message */
          //hideDropDown = $("#hideDropDown").val(),
          tempId = tempContainer[0].attributes.id.value,
          container = $("#" + tempId),

          /* Get App padding/margin and set as Content margin*/
          tempAppStyle = $("div[class^='App_padded']");

        let queryVal = $('#query').val();
        /* hide dropdown if no search term */
        if (queryVal === '') {
          $("ul[id^='ui-id']").hide();
        }

        /* remove existing divUl */
        $('#divUl').remove();

        container.each(function (this) {
          /* create a div inside <ul> and set width like App width */

          let tempitems = "",
            pitems = $(this).find('[aria-describedBy="People"]'),
            citems = $(this).find('[aria-describedBy="Content"]');

          let hidePeople = false;
          if ((queryVal !== '') && queryVal.toUpperCase().indexOf('ASSOCIATE', 0) > -1) {
            hidePeople = true;
          }

          /* determine if user is on search page */
          if ($('#searchKey').length) {
            //search page
            this.onSearchPage = "true";
          } else {
            //home page
            this.onSearchPage = "";
          }
          /*   Mindbreeze - client.js set display:none when no result 
            *  Added attributes 'top' and 'display' to display message if there's no result
            *  for both content and people. 
            *  This only happens when first search doesn't have a match. */
          if (pitems.length == 0 && citems.length == 0) {
            // commented out - remove no result message for now
            // if(hideDropDown !== 'true'){
            //   let inputHeight = $('#query').height() + 10;
            //   $(this).css('top', inputHeight+'px');
            //   $(this).css('display','block');
            // }
            $('#divUl').remove();
          } else {
            /* Home page - App style is available */
            if (tempAppStyle.length > 0) {
              $(this).append("<div id='divUl'><div/>");
              $('#divUl').attr('style', 'max-width: 1200px' + ' !important');
              $(this).find('#divUl').html($('<ul id="ul-content-app" ></ul>').addClass(listClass + ' content-app'));
              $(this).find("#ul-content-app").html('<li id="content" [aria-describedBy="Content"]>Content</li>');
              if (citems.length > 0) {
                $("#ul-content-app").append(citems);
              }

              /* vertical line - 2 columns */
              $(this).find("#divUl").append($('<div></div>').addClass(' vertical'));
              $(this).find('#divUl').append($('<ul id="ul-people-app"></ul>').addClass(listClass + ' people-app'));
              $(this).find("#ul-people-app").html('<li id="people" [aria-describedBy="People"]>People</li>');

              tempitems = "ul-people-app";

              /* Search page - use vw or % when setting margins or paddings */
            } else {
              $(this).append("<div id='divUlSearch'><div/>");
              $('#divUlSearch').attr('style', 'max-width: 1200px' + ' !important');
              $(this).find('#divUlSearch').html($('<ul id="ul-content" ></ul>').addClass(listClass + ' content'));
              $(this).find("#ul-content").html('<li id="content" [aria-describedBy="Content"]>Content</li>');

              if (citems.length > 0) {
                $("#ul-content").append(citems);

              }

              /* vertical line - 2 columns */
              $(this).find("#divUlSearch").append($('<div></div>').addClass(' vertical'));
              $(this).find('#divUlSearch').append($('<ul id="ul-people"></ul>').addClass(listClass + ' people'));
              $(this).find("#ul-people").html('<li id="people" [aria-describedBy="People"]>People </li>');

              tempitems = "ul-people";

            }

            if (pitems.length > 0) {
              if (hidePeople) {
                pitems.hide();
                $('#people').removeClass('marginBottom'); $('#people').addClass('noMarginBottom');

              } else {
                /* adjust margin-bottom */
                $('#people').removeClass('noMarginBottom');
                $('#people').addClass('marginBottom');
                $("#" + tempitems).append(pitems);
                $(this).find("#" + tempitems).append('<li><button type="button" class="btn btn-link" id="bSeeAll">See all people</button></li>');
              }

            } else {
              /* no content result */
              /* adjust margin-bottom */
              $('#people').removeClass('marginBottom'); $('#people').addClass('noMarginBottom');
            }

            this.ulStyles = `${styles.ulwidth}`;
            $("#" + tempId).removeClass(`${styles.ulwidthNotSearchPage}`);
            $("#" + tempId).addClass(this.ulStyles);
            spacerStyles = `${styles.search} ${this.onSearchPage ? styles.searchPage : ""}`;
          }
        });
        $(".vertical").css("height", $("#" + tempId).height());

        /* See all people link */
        $('#bSeeAll').click(function () {
          /* hide the dropdown */
          $("ul[id^='ui-id']").hide();
          dispatch(UpdateSearchQuery($('#query').val(), true));
          if ($('#searchKey').length) {
            /* search page - click magnifying button */
            $(".tab-People").click();
            $('#bMagnifying').click();
          } else {
            /* redirect to search page */
            navigate("/SearchResults");
          }
        })
      }
      SuggestTemplateAutocompleteSource.prototype = _.extend({}, Backbone.Events, {
        initialize: function (options) {
          this.options = options;
        },

        autocomplete: function (suggestRequest) {
          let that = this;
          return new Promise(function (resolve, reject) {
            that.options.originalAutocompleteSource(suggestRequest, function (data) {
              resolve(data);
            });
          });
        },
      });

      /* Create new SearchAutompleteSource */
      let SearchAutocompleteSource = function () {
        // @ts-ignore
        this.initialize.apply(this, arguments);
      };

      SearchAutocompleteSource.prototype = _.extend({}, Backbone.Events, {
        initialize: function (options) {

          /* HTML template generated to show search results. Nationwide -> Change as needed */
          const autocompleteTemplate = (properties) =>
            `
              <div class="suggested-person-result" data-username="${properties.mgru_onPremisesSamAccountName}">
                <div class="suggested media center">                
                  <div class="suggested-row">
                    <div id="suggested-column-left">
                      <span class="media-object-icon" aira-hiddent="true">																							
                         ${properties['mes:thumbnail'] ? `<img src=${properties['mes:thumbnail']}>` : `<img id="people-default-photo" src="${peopleResultDefault}" width="70px">`}
                      </span>
                    </div>
                    <div id="suggested-column-right">
                      <div id="suggested-preferred-name">${properties.preferred_firstname} ${properties.preferred_lastname}</div>
                      <div id="suggested-job-title">${properties.mgru_jobTitle}</div>
                      <p></p>
                      <div id="suggested-login-id">
                        ${properties.mgru_onPremisesSamAccountName}
		               <img src="${mail}" alt="email" onClick="handleEmailClick('${properties.mgru_mail}')"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
			`;

          this.searchModel = options.searchModel;
          this.model = this.searchModel.clone();

          /* Options for widget. Nationwide -> Change as needed */
          this.options = _.extend({}, {
            minTermLength: 4,
            template: autocompleteTemplate,
            groupLabel: 'People',
            properties: [
              'mes:thumbnail',
              'preferred_firstname',
              'preferred_lastname',
              'mgru_jobTitle',
              'mgru_employeeType',
              'mgru_businessPhones',
              'mgru_onPremisesSamAccountName',
              'mgru_mail',
              'icon'
            ],
            requestName: _.uniqueId('searchautocompletesource'),
            count: 4,
            constraint: {
              unparsed: 'category:"Microsoft Graph"'
            },
          },
            options
          );
        },

        autocomplete: function (suggestRequest) {
          let that = this;
          return new Promise(function (resolve, reject) {
            if (suggestRequest.term.length > 0) {
              /* show refresh */
              $('#bRefresh').show();
              if (!suggestRequest.term || suggestRequest.term.length < 3) {
                resolve([]);
              } else {
                let searchRequest = that.searchModel.input.get();
                that.stopListening();
                that.listenTo(that.model, 'computed', function () {
                  resolve(that.transformResults());
                });
                that.model.set(that.getSearchRequest(searchRequest, suggestRequest));
              }
            } else {
              /* hide dropdown */
              $("ul[id^='ui-id']").hide();

              /* hide refresh */
              $('#bRefresh').hide();
            }
          });
        },

        transformResults: function () {
          let resultSet = this.model.get('resultset.results');
          resultSet.forEach(element => {
            /* convert Contractor job title to Non-Employee based on employeeType */
            element.properties.mgru_jobTitle.data[0].value = PeopleUtils.formatJobTitle(element.properties.mgru_employeeType.data[0].value.toString(), element.properties.mgru_jobTitle.data[0].value.toString()); 
          });

          return _.map(
            _.map(resultSet, _.bind(this.getProperties, this)),
            _.bind(this.createEntry, this)
          );
        },

        createEntry: function (properties) {
          return {
            value: properties.preferred_lastname + ', ' + properties.preferred_firstname,
            actions: properties.actions && properties.actions.list,
            label: this.options.template(properties),
            score: 1,
            category: i18n(this.options.groupLabel),
          };
        },

        getProperties: function (result) {
          let getPropertyValue = _.bind(this.getPropertyValue, this);

          return _.reduce(
            result && result.properties,
            function (properties, property) {
              properties[property.id] = getPropertyValue(property);
              return properties;
            }, {}
          );
        },

        getPropertyValue: function (property) {
          let value;
          let values = _.pluck(property && property.data, 'value');
          if (values && values.length) {
            value = {
              toString: function () {
                return (values[0] && values[0].href) || values[0];
              },
            };
            value.list = values;
          }


          return value;
        },
        /* Builds Search Request. Nationwide -> Change as needed */
        getSearchRequest: function (searchRequest, suggestRequest) {
          return {
            source_context: searchRequest.source_context,
            user: {
              query: {
                and: [{
                  unparsed: suggestRequest.term,
                  id: 'query'
                }]
              }
            },
            timeout_in_seconds: 1,
            name: this.options.requestName,
            properties: _.reduce(
              this.options.properties,
              function (properties, property) {
                properties[property] = {
                  formats: [property === 'action' ? 'PROPERTY' : 'VALUE'],
                };
                return properties;
              }, {}
            ),
            count: this.options.count,
            constraint: this.options.constraint ?  // NOSONAR
              searchRequest.constraint ? // NOSONAR
                {
                  and: [this.options.constraint, searchRequest.constraint] // NOSONAR
                } :
                this.options.constraint : searchRequest.constraint, // NOSONAR
          };
        },

        destroy: function () {
          this.stopListening();
        },
      });

      /* Sort the results and display the search term as the first suggestion */

      SuggestTemplate.View.prototype._sortSuggestResults = function (response, limit) {
        let sortedResults = originalSortSuggestResults(response, limit);
        let searchQuery = $('#query').val();
        let querySuggestResult = {
          value: searchQuery,
          label: `<span class="matched">${searchQuery}</span>`,
          category: 'Content',
          originalCategory: 'DOCUMENT_PROPERTY',
          score: 0
        }
        sortedResults.unshift(querySuggestResult);
        return sortedResults;
      };
      /* Overwrite default behavior */
      SuggestTemplate.View.prototype._autocompleteSource = function (request, responseDone) {
        let sources = [];

        sources.push(
          // @ts-ignore
          new SearchAutocompleteSource({
            searchModel: this.options.application.models.search,
          })
        );

        sources.push(
          // @ts-ignore
          new SuggestTemplateAutocompleteSource({
            originalAutocompleteSource: _.bind(originalAutocompleteSource, this),
          })
        );

        let promises = sources.map(function (source) {
          return source.autocomplete(request);
        });

        Promise.all(promises).then(function (responses) {
          let overallResponse = _.reduce(
            responses,
            function (overallResponse, sourceResponse) {
              if (sourceResponse) return overallResponse.concat(sourceResponse);
              return overallResponse;
            },
            []
          );

          /* remove content & people list */
          $('#ul-content').remove(); $('#ul-people').remove(); $('.vertical').remove();

          for (let i in overallResponse) {
            if (overallResponse[i].originalCategory != null) { //response from Mindbreeze - use "!=" instead of "!=="
              overallResponse[i].category = 'Content';
            }
          }

          let sortedby = ['Content', 'People'];
          overallResponse.sort((a, b) => {
            /* type-ahead order */
            if (sortedby.indexOf(a.category) > sortedby.indexOf(b.category)) {
              return 1;
            }
            if (sortedby.indexOf(a.category) < sortedby.indexOf(b.category)) {
              return -1;
            }
            if ((sortedby.indexOf(a.category) <= sortedby.indexOf(b.category)) && (sortedby.indexOf(a.category) >= sortedby.indexOf(b.category))) {
              return 0;
            }

          });
          responseDone(overallResponse);

          if ($('#query').val() !== '') {
            /* suggestion dropdown */
            afterResponse();
          }
        });
      };


      SuggestTemplate.View.prototype._triggerSelect = function (event, data, traceContext, options) {
        /* handle enter key */
        if(event.type == "keypress" && event.keyCode == 13) {
          searchNavigate(event.currentTarget.value);
        }

        if (data && data.item) {
          let category = data.item.category;
      
          if (category == 'People') {
            let selectedPeople = data.item.label;
            let query = $(selectedPeople).attr('data-username');
            if (query) {
              dispatch(UpdateViewUserDetailNwie(query));
              navigate("/employeeDetail")
              return;
            }
            
          } else if (category == 'Content') {
            searchNavigate(data.item.value);

          } else {
            console.warn('Unexpected category encountered: category=', category);
          }

        } else {
          console.warn('Unable to select suggestion, cannot find data or item');
        }
      };




    }
    )
  }

  const searchNavigate = (value): void =>{
    if (value) {
      window['newrelic'].addPageAction('search', { 'query': value });
      if (window.location.pathname.toLowerCase().indexOf("searchresults") < 0) {
        dispatch(UpdateSearchQuery(value, false));
        navigate("/SearchResults");
      } else {
        let form: HTMLFormElement = document.getElementById("search-form") as HTMLFormElement;
        if (form) {
          if (form.elements && form.elements.length) {
            let input: HTMLInputElement = form.elements[0] as HTMLInputElement;
            input.value = value;
            let button: HTMLButtonElement = form.elements[1] as HTMLButtonElement;
            button.click();
          }
        }
      }
    }
  }

  /* remove hideDropDown */
  const handleKeyboardEvent = (): void => {

    if (document.getElementById('query') !== null) {
      let input: HTMLInputElement = document.getElementById("query") as HTMLInputElement;
      /* handle refresh button when user hit backspace */
      if (input.value && (input.value.length - 1) > 0) {
        document.getElementById("bRefresh").style.display = "block";
      } else {
        document.getElementById("bRefresh").style.display = "none";
      }
    }
    if (document.getElementById('hideDropDown') !== null) {
      document.getElementById('hideDropDown').remove();
    }
  }

  /* refresh */
  const handleRefresh = (element: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>): void => {
    if (document.getElementById('query') !== null) {
      let input: HTMLInputElement = document.getElementById("query") as HTMLInputElement;
      if (input.value && input.value.length > 0) {
        input.value = "";
        document.getElementById("bRefresh").style.display = "none";
      }
    }
  }

  const handleClick = (element: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>): void => { // NOSONAR
    if (element && element.currentTarget && element.currentTarget.parentElement && element.currentTarget.parentElement.children && element.currentTarget.parentElement.children.length) {
      let newQuery = element.currentTarget.parentElement.children[0] as HTMLInputElement;

      if (newQuery && newQuery.value) {
        if (window.location.pathname.toLowerCase().indexOf("searchresults") < 0) {
          window['newrelic'].addPageAction('search', { 'query': newQuery.value });
          dispatch(UpdateSearchQuery(newQuery.value, false));
          updateQuery(newQuery.value);
          navigate("/SearchResults");
        } else {
          let form: HTMLFormElement = document.getElementById("search-form") as HTMLFormElement;
          if (form) {
            if (form.elements && form.elements.length) {
              let input: HTMLInputElement = form.elements[0] as HTMLInputElement;
              input.value = newQuery.value;
              let button: HTMLButtonElement = form.elements[1] as HTMLButtonElement;
              button.click();
            }
          }
        }
      }
    }
  }

  const handleResize = () => {
    let suggestDropDown = document.querySelector('[id^="ui-id-"]').id;
    if (suggestDropDown != undefined || suggestDropDown == '') {
      document.getElementById(suggestDropDown).style.display = "none";
    }
  };

  return (
    <div style={{visibility:visibility?'visible': 'hidden'}} id={styles.search} ref={appRootElRef} className={spacerStyles} aria-label="search bar" role="search">
      <link href="https://search.mindbreeze.com/apps/css/v2/adapted.css" rel="stylesheet" />
      <form style={{ position: "absolute" }} data-template="searchform" className="mb-header-search-form" id="search_mindbreeze">
        <input
          style={{ maxWidth: '1200px' }}
          data-template="suggest"
          data-count="10"
          placeholder="Search People & Content"
          name="query"
          type="search"
          id="query"
          data-property-constraint='NOT (fqcategory:"DataIntegration:EmployeeCSVData") AND NOT (fqcategory:"Microsoft SharePoint Online:Enterprise")'
          onKeyDown={handleKeyboardEvent}
          aria-label="Search People & Content"
        />
        <button aria-label="clear search keyword" className={`${styles.refresh}`} style={{ display: "none" }}
          onKeyDown={(e) => {
            if (e.code == 'Enter' || e.code == 'Space') {
              handleRefresh(e);
            }
          }}
          onClick={handleRefresh} id='bRefresh'>
          <ResetIcon className={styles.resetIcon} />
        </button>
        <button aria-label="search" className={`btn btn-link mb-no-print ${styles.magnifyingG}`}
          onKeyDown={(e) => {
            if (e.code == 'Enter' || e.code == 'Space') {
              handleClick(e);
            }
          }}
          onClick={handleClick} id='bMagnifying' type="submit">
          <SearchIcon className={styles.searchIcon} />
        </button>
      </form>
    </div>
  )

}

export default MindbreezeAutoCompleteWithSearch;

