import React from "react";
import styles from "./OrgChartWidget.module.scss";
import DirectReport from "../DirectReport/DirectReport";
import { GraphAvatarData } from "../../../types/GraphTypes/GraphUser";
import { SkeletonTheme } from "react-loading-skeleton";
import DirectReportSkeleton from "../DirectReport/DirectReportSkeleton";

export interface IOrgChartWidgetProps {
  orgChart: GraphAvatarData[];
  showOrgChart: boolean;
}

const OrgChartWidget= ({orgChart,showOrgChart}) => {

  if (!orgChart || orgChart.length === 0) {
    return (
      <SkeletonTheme baseColor="#f2f2f2" highlightColor="#dadada">
        <DirectReportSkeleton />
        <DirectReportSkeleton />
        <DirectReportSkeleton />
      </SkeletonTheme>
    )
  }

  return (orgChart && orgChart.map((employee: GraphAvatarData, index: number) => (
      <li
        className={styles.line}
        id={`employee-${index}`}
        key={employee.id}
      >
        <DirectReport avatarData={employee} showOrgChart={showOrgChart} />
      </li>
  )));
};

export default OrgChartWidget;
