import React from "react";
import styles from "./CircleButton.module.scss";

export interface ICircleProps {
  id: string;
  style?: React.CSSProperties;
  height: string;
  width: string;
  circleText?: string;
  imgSrc: string;
  hoverImgSrc?: string;
  CirclebackgroundColor: string;
  url: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLAnchorElement>) => void;
}
const CircleButton: React.FC<ICircleProps> = ({
  id,
  height,
  width,
  circleText,
  imgSrc,
  hoverImgSrc,
  url,
  onClick,
  onKeyDown,
}) => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const anchorClickProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = onClick ? { onClick: onClick } : null;
  const anchorKeyDownProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = onKeyDown ? { onKeyDown: onKeyDown } : null;

  return (
    <div id={id} className={styles.button}>
      <a
        className={styles.noBorderButton}
        href={url}
        target={`_blank`}
        onMouseEnter={() => setIsHovering(hoverImgSrc ? true : false)}
        onMouseLeave={() => setIsHovering(false)}
        {...anchorClickProps}
        {...anchorKeyDownProps}
      >
        {/* <div className={styles.iconCircle}> */}
          <img className={styles.iconCircle} src={isHovering ? hoverImgSrc : imgSrc} width={width} height={height} alt="button" />
        {/* </div> */}
        <span className={styles.userCardBtnText}>{circleText}</span>
      </a>
    </div>
  );
};

export default CircleButton;
