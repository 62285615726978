import React, { useContext, useEffect, useState } from "react";
import { AdwContext } from "../../../context/AdwContext";
import AdwCommonTool from "../../../types/CommonToolsTypes/AdwCommonTool";
import styles from "../../../App.module.scss";
import { InsideUserData } from "../../../types/InsideUserData";
import { SetUserPreferences } from "../../../context/ActionTypes";
import AuthService from "../../../services/AuthService";
import AllAvailableToolsWidget from "../../molecules/AllAvailableToolsWidget/AllAvailableToolsWidget";
import CustomizeToolsWidget from "../../molecules/CustomizeToolsWidget/CustomizeToolsWidget";
import searchStyles from "../../molecules/SearchBar/MindbreezeAutoCompleteWithSearch.module.scss";  

export interface INwCustomizeCommonToolsViewProps {
  className?: string;
}

const NwCustomizeCommonToolsView: React.FC<INwCustomizeCommonToolsViewProps> = () => {
  document.title = "InSide - Customize tools";
  const [appState,dispatch] = useContext(AdwContext);
  const adwCommonTools: AdwCommonTool[] = appState && appState.lists && appState.lists.commonTools || [];
  const lastToolsVisitedOn = appState && appState.user && appState.user.lastToolsVisit;
  const [availableTools, setAvailableTools] = useState([]);
  const [numberOfNewAvailableTools, setNumberOfNewAvailableTools] = useState(0);

  useEffect(() => {
    if(adwCommonTools && adwCommonTools.length > 0 && lastToolsVisitedOn){
    setAvailableTools(identifyNewlyAddedTools(lastToolsVisitedOn,adwCommonTools));
    }
  }, [adwCommonTools,lastToolsVisitedOn])

  useEffect(() => {
    if(availableTools && availableTools.length > 0 ){
    setNumberOfNewAvailableTools(availableTools.filter(tool => tool.NewTool).length);
    }else{
      setNumberOfNewAvailableTools(0);
    }
  }, [availableTools])
  
  
  useEffect(() => {
    if (AuthService.hasActiveSession()) {
      setTimeout(() => {
        saveCommonToolsPageVisitTimestamp();
      }, 10000);
    }

    const searchBar = document.getElementById(searchStyles.search);

    if (searchBar) {
      searchBar.style.display = 'none';
    }

    return () => {
      if (searchBar) {
        searchBar.style.display = 'block';
      }
    }
  }, []);

  const saveCommonToolsPageVisitTimestamp = (): void => {
    const insideUserData: InsideUserData = Object.assign({}, appState.user);
    if (insideUserData) {
      insideUserData.lastToolsVisit = new Date().toLocaleString();
      dispatch(SetUserPreferences(insideUserData));
    }
  };

const sortAvailableCommonTools = (filteredTools:AdwCommonTool[]) => {
  return filteredTools.sort((toolA, toolB) => {
    if (toolA.Title.toLowerCase() < toolB.Title.toLowerCase()) {
      return -1;
    } else if (toolA.Title > toolB.Title) {
      return 1;
    } else {
      return 0;
    }
  })
}

  //Returns all the tools from available that are created after the last visited date and elminate those created before
  const identifyNewlyAddedTools = (lastVisitedDate:string,availableTools:AdwCommonTool[]) => {
    const parsedLastVisitedDate = new Date(lastVisitedDate);
    const filteredTools = availableTools.map(tool => {
    let toolCreationDate = new Date(tool.Created);
    let NewTool = toolCreationDate > parsedLastVisitedDate;
    return { ...tool, NewTool };
});
let sortedTools = sortAvailableCommonTools(filteredTools);
return sortedTools;
}

  return (
    <div className={styles.customize_tools_page} >
      <h1 className={styles.page_heading}>Customize your tools</h1>
      <div className={styles.customize_tools_content_wrapper}>

        <CustomizeToolsWidget
          allAvailableCommonTools={availableTools}
        />
        <AllAvailableToolsWidget availableTools={availableTools} numberOfNewAvailableTools={numberOfNewAvailableTools}/>
      </div>
    </div>
  )
};

export default NwCustomizeCommonToolsView;
