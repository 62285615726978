import React from "react";
import { GetBenefitElectionsResponse } from "../../../../../services/HRConnectService";
import widgetStyles from "../HRConnectWidgets.module.scss";
import styles from "./SelectedBenefits.module.scss"

export interface ISelectedBenefitsProps {
  benefitElections: GetBenefitElectionsResponse
}

const SelectedBenefits: React.FC<ISelectedBenefitsProps> = (props) => {

  return (<>
    <div className={styles.benefitElectionBottomSection} >
      <div className={`row ${widgetStyles.beRow}`}>
        <div className={`col-sm-6 ${widgetStyles.benefitElectionHeader}`}>
          <span className="">Benefit plan</span>
        </div>
        <div className={`col-sm-6 ${widgetStyles.benefitElectionHeader}`}>
          <span className="">Coverage</span>
        </div>
      </div>

      {props && props.benefitElections &&
        <>
          <div className={`row ${widgetStyles.beRow}`}>
            <div className={`col-sm-6 ${widgetStyles.benefitElectionRowClear}`}>
              <span className="">{props.benefitElections.medicalPlan}</span>
            </div>
            <div className={`col-sm-6 ${widgetStyles.benefitElectionRowClear}`}>
              <span className="">{props.benefitElections.medicalCoverage}</span>
            </div>
          </div>

          <div className={`row ${widgetStyles.beRow}`}>
            <div className={`col-sm-6 ${widgetStyles.benefitElectionRowBg}`}>
              <span className="">{props.benefitElections.dentalPlan}</span>
            </div>
            <div className={`col-sm-6 ${widgetStyles.benefitElectionRowBg}`}>
              <span className="">{props.benefitElections.dentalCoverage}</span>
            </div>
          </div>

          <div className={`row ${widgetStyles.beRow}`}>
            <div className={`col-sm-6 ${widgetStyles.benefitElectionRowClear}`}>
              <span className="">{props.benefitElections.visionPlan}</span>
            </div>
            <div className={`col-sm-6 ${widgetStyles.benefitElectionRowClear}`}>
              <span className="">{props.benefitElections.visionCoverage}</span>
            </div>
          </div>

          <div className={`row ${widgetStyles.beRow}`}>
            <div className={`col-sm-6 ${widgetStyles.benefitElectionRowBg}`}>
              <span className="">{props.benefitElections.hsaPlan}</span>
            </div>
            <div className={`col-sm-6 ${widgetStyles.benefitElectionRowBg}`}>
              <span className="">{props.benefitElections.hsaCoverage}</span>
            </div>
          </div>
        </>
      }

    </div>



    <div className={widgetStyles.benefitElectionsButtonRow}>
      <a
        href="https://www.myworkday.com/nationwide/d/task/2998$38789.htmld"
        target="_blank"
        rel="noopener noreferrer"
        className={`${widgetStyles.benefitElectionButtonLink} ${widgetStyles.btnLeft}`}
      >
        View all elections
      </a>
      <a
        href="https://onyourside.sharepoint.com/sites/Associate-Benefits"
        target="_blank"
        rel="noopener noreferrer"
        className={`${widgetStyles.benefitElectionButtonLink} ${widgetStyles.btnRight}`}
      >
        Benefit info
      </a>
    </div>
  </>

  );
}

export default SelectedBenefits;