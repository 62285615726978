import React from "react";
import widgetStyles from "../HRConnectWidgets.module.scss";
import styles from "./OpenBenefitElection.module.scss"
import infoIcon from "../../../../../assets/images/info-icon.svg";

export interface IopenBenefitProps {
  endDate: string;
  endTime: string;
}

const OpenBenefitElection: React.FC<IopenBenefitProps> = (props) => {
  const endDate = new Date(props.endDate);

  function formatEndDate(): string {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthName = monthNames[endDate.getMonth()];
    const day = endDate.getDate();
    return `${monthName} ${day} at ${props.endTime}`;
  }

  function remainingDaysText(): string {
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = endDate.getTime() - (new Date().getTime());
    const diffInDays = Math.round(diffInTime / oneDay);
    return `There are ${diffInDays} days left to make your elections for next year!`
  }

  return (
    <>
      <div className={styles.benefitElectionBottomSection} >
        <div className={styles.content1}>Open Enrollment is here</div>
        <div className={styles.content2}>BENEFIT ELECTIONS ARE DUE</div>
        <div className={styles.content3}>{formatEndDate()}</div>
        <div className={`row ${widgetStyles.beRow}`}>
          <div className={styles.infoDiv}><img className={styles.infoIcon} src={infoIcon} alt=''/></div>
          <div className={styles.infoContentDiv}>
            <div><div className={styles.infoContent1}>Open Enrollment for Associates is now active.</div></div>
            <div><div className={styles.infoContent2}>{remainingDaysText()}</div></div>
          </div>
        </div>
      </div>

      <div className={styles.btnHolder}>
        <a
          href="https://onyourside.sharepoint.com/sites/Associate-Benefits/SitePages/Open-Enrollment.aspx"
          target="_blank"
          rel="noopener noreferrer"
          style={{width: '100%'}}
          className={`${styles.openBenefitElectionButtonLink} ${widgetStyles.btnLeft}`}
        >
          View all elections
        </a>
        <a
          href="https://www.myworkday.com/nationwide/d/task/2998$3215.htmld"
          target="_blank"
          rel="noopener noreferrer"
          style={{minWidth: "138px"}}
          className={`${styles.openBenefitElectionButtonLink} ${widgetStyles.btnRight}`}
        >
          Benefit info
        </a>
      </div>
    </>
  );
}

export default OpenBenefitElection;
