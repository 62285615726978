import React, { useContext, useEffect, useState } from "react";
import AdwCommonTool from "../../../types/CommonToolsTypes/AdwCommonTool";
import styles from "../../../App.module.scss";
import { randomAlphaNumeric } from "../../../utils/ArrayUtils";
import { useNavigate } from "react-router";
import { SetUserPreferences } from "../../../context/ActionTypes";
import { AdwContext } from "../../../context/AdwContext";
import AuthService from "../../../services/AuthService";
import CustomizeMyToolsItem from "../../atoms/CustomizeMyToolsItem/CustomizeMyToolsItem";
import { ReactComponent as CloseIcon } from "../../../assets/images/Close-button-icon.svg";

interface ICustomizeToolsWidgetProps {
  allAvailableCommonTools: AdwCommonTool[];
}


const CustomizeToolsWidget: React.FC<ICustomizeToolsWidgetProps> = ({
  allAvailableCommonTools
}) => {
  const [appState, dispatch] = useContext(AdwContext);
  const userPreferredCommonTools =  appState && appState.user && appState.user.preferredCommonTools;
  const navigate = useNavigate();
  const [newPefaultOrPreferredTools, setNewPefaultOrPreferredTools] = useState<AdwCommonTool[]>([]);
  const [userPreferredTools, setUserPreferredTools] = useState<AdwCommonTool[]>([]);
  const [activeDropdown, setActiveDropdown] = useState(-1);
  const [enableSave, setEnableSave] = useState(false);

  useEffect(() => {
    if(allAvailableCommonTools){
      setUserPreferredTools(determineDefaultOrPreferredTools());
    }
  }, [userPreferredCommonTools,allAvailableCommonTools]);

  useEffect(() => {
    if (userPreferredTools && userPreferredTools.length > 0) {
      setNewPefaultOrPreferredTools(userPreferredTools);
    }
  }, [userPreferredTools]);

  const onToolPicked = (preferredTool, index) => {
    let updatedtool = newPefaultOrPreferredTools;
    updatedtool[index] = preferredTool;
    setNewPefaultOrPreferredTools(updatedtool);
    enableSaveButton();
  }

  const onResetToDefaultButtonClicked = (event:any) => {
    event.persist();
    setNewPefaultOrPreferredTools(allAvailableCommonTools.filter(tool => tool.DefaultTool || tool.RequiredTool));
    setActiveDropdown(-1);
    setEnableSave(true);
  }

  const getSelectedTools = () => {
    const idValues = newPefaultOrPreferredTools.map(obj => obj.ID);
    const cleanedValues = idValues.filter(id => id.toString() != '');
    const limitedValues = cleanedValues.slice(0, 10);
    return limitedValues.join(', ');
  }

  const determineDefaultOrPreferredTools = (): AdwCommonTool[] => {
    let toolsForDisplay: AdwCommonTool[] = [];
    if (userPreferredCommonTools) {
      toolsForDisplay = AdwCommonTool.getPrefferedTools(
        userPreferredCommonTools,
        allAvailableCommonTools
      );
    } else {
      toolsForDisplay = allAvailableCommonTools.filter(
        (tool) => tool.DefaultTool || tool.RequiredTool
      );
    }
    return toolsForDisplay;
  };


  const enableSaveButton=()=>{
    let newCommonToolsString = getSelectedTools();
    if(newCommonToolsString != userPreferredCommonTools){
      setEnableSave(true);
    }else{
      setEnableSave(false);
    }
  }

  const onSaveButtonClicked = () => {
    let newCommonToolsString = getSelectedTools();
    if (AuthService.hasActiveSession()) {
      appState.user.preferredCommonTools = newCommonToolsString;
      dispatch(SetUserPreferences(appState.user));
      navigate("/");
    }
  }

  const onCloseButtonPressed = (): void => {
    navigate("/");
  };


  return (
    <div className={styles.customize_tools_widget}>
      <div className={styles.customize_tools_heading}>
        <div className={styles.customize_tools_title}>
          <h2 className={styles.customize_tools_title_text}>My tools</h2>
        </div>
        <div className={styles.customize_tools_close}>
          <button aria-label="Close Customize Tools" className={styles.close_button_wrapper} onClick={onCloseButtonPressed}>
            <CloseIcon className={styles.close_button} />
          </button>
        </div>
      </div>

      <ul className={styles.customize_tools_editor}>
        {newPefaultOrPreferredTools && newPefaultOrPreferredTools.map((prefTool, ind) => (
          <CustomizeMyToolsItem key={randomAlphaNumeric(ind+1)} tool={prefTool} allAvailableTools={allAvailableCommonTools}
            onNewToolSelected={onToolPicked} onDropDownOpen={setActiveDropdown}
            positionIndex={ind}  activeDropdown={activeDropdown} 
            expanded={ind === activeDropdown}
            />
        ))}
      </ul>
      <div className={styles.button_group}>
        <button onClick={onResetToDefaultButtonClicked} className={`${styles.reset_to_default_button} ${styles.customize_widget_button}`}>
          Reset to default
        </button>
        <button disabled={!enableSave} onClick={onSaveButtonClicked} className={`${styles.save_button} ${styles.customize_widget_button} ${enableSave ? styles.active_button : styles.inactive_button}`} aria-label="Save your customize tools to fit your everyday needs.">
          Save
        </button>
      </div>
    </div>
  )
}

export default CustomizeToolsWidget;
