import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from "./DirectReport.module.scss";

const DirectReportSkeleton: React.FC = () => {
  return (
    <div className={styles.directReportWrapperSkeleton}>
      <Skeleton circle={true} height={44} width={44} />
      
      <div className={styles.nameTitleWrapperSkeleton}>
        <Skeleton width={110} height={18} />
        <Skeleton width={165} height={18} />
      </div>
    </div>
  );
};

export default DirectReportSkeleton;