import React from "react";
import ArrowButton, { Direction } from "../../atoms/ArrowButton/ArrowButton";
import styles from "./ToggleArrowButton.module.scss";

export interface IToggleArrowButtonProps {
  toggleArrowButtonClasses?: string;
  disabled?: boolean;
  onLeftArrowClick: (e?: React.MouseEvent<HTMLDivElement>) => void;
  onRightArrowClick: (e?: React.MouseEvent<HTMLDivElement>) => void;
}

export interface IToggleArrowButtonState {
  leftRectangleColored: boolean;
  rightRectangleColored: boolean;
  leftArrowEnabled: boolean;
  rightArrowEnabled: boolean;
}

class ToggleArrowButton extends React.Component<IToggleArrowButtonProps, IToggleArrowButtonState> {
  constructor(props: IToggleArrowButtonProps) {
    super(props);
    this.state = {
      leftRectangleColored: props.disabled ? false : true,
      rightRectangleColored: false,
      leftArrowEnabled: props.disabled ? false : true,
      rightArrowEnabled: props.disabled ? false : true,
    };
  }

  public componentDidUpdate(prevProps: IToggleArrowButtonProps): void {
    if (this.props.disabled !== prevProps.disabled) {
      this.setState({
        leftRectangleColored: this.props.disabled ? false : true,
        rightRectangleColored: false,
        leftArrowEnabled: this.props.disabled ? false : true,
        rightArrowEnabled: this.props.disabled ? false : true,
      });
    }
  }

  private handleLeftArrowButtonClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.preventDefault();
    this.setState({
      leftRectangleColored: true,
      rightRectangleColored: false,
    });
    this.props.onLeftArrowClick(e);
  }

  private handleRightArrowButtonClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.preventDefault();
    this.setState({
      leftRectangleColored: false,
      rightRectangleColored: true,
    });
    this.props.onRightArrowClick(e);
  }

  public render(): React.ReactElement<IToggleArrowButtonProps> {
    const { toggleArrowButtonClasses } = this.props;

    const toggleClass: string = `${styles.toggleArrowButton} ${toggleArrowButtonClasses}`;
    const classes: string = `${styles.toggleArrowButton}`;
    const leftArrowClass: string = this.state.leftArrowEnabled ? `${styles.toggleArrowEnabled}` : `${styles.disableToggle}`;
    const rightArrowClass: string = this.state.rightArrowEnabled ? `${styles.toggleArrowEnabled}` : `${styles.disableToggle}`;
    const leftActiveIndicatorStyle: string = this.state.leftRectangleColored ? `${styles.rectangleColored}` : `${styles.rectangleNoColor}`;
    const rightActiveIndicatorStyle: string = this.state.rightRectangleColored
      ? `${styles.rectangleColored}`
      : `${styles.rectangleNoColor}`;
    const leftClick: Object = this.state.leftArrowEnabled ? { onClick: this.handleLeftArrowButtonClick.bind(this) } : {};
    const rightClick: Object = this.state.rightArrowEnabled ? { onClick: this.handleRightArrowButtonClick.bind(this) } : {};

    return (
      <div className={toggleClass}>
        <ArrowButton
          buttonClasses={classes}
          chevronClasses={leftArrowClass}
          arrowDirection={Direction.LEFT}
          {...leftClick}
          id="favorites-left-arrow"
        />
        <div className={leftActiveIndicatorStyle} />
        <div className={rightActiveIndicatorStyle} />
        <ArrowButton
          buttonClasses={classes}
          chevronClasses={rightArrowClass}
          arrowDirection={Direction.RIGHT}
          {...rightClick}
          id="favorites-right-arrow"
        />
      </div>
    );
  }
}

export default ToggleArrowButton;
