import React, { useState, useContext } from "react";
import ReactTooltip from "react-tooltip";
import { Subscription } from "rxjs";
import { SetUserPreferences } from "../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import { InsideUserData } from "../../../types/InsideUserData";
import AdwReminder from "../../../types/ReminderTypes/AdwReminder";
import Link from "../../atoms/Link/Link";
import GoToLink from "../../molecules/GoToLink/GoToLink";
import NoResults from "../../molecules/NoResults/NoResults";
import styles from "./Reminders.module.scss";
import FeaturedContent from "../../molecules/FeaturedContent/FeaturedContent";

export interface IReminderProps {
  reminderClasses?: string;
  isDisplaySeeAllReminders?: boolean;
}

export interface IReminderState {
  isToolTipDisplay?: boolean;
  fading: number[];
}

const Reminders: React.FC<IReminderProps> = ({
  reminderClasses = "",
  isDisplaySeeAllReminders = false,
}): any => {
  const [isToolTipDisplay, setIsToolTipDisplay] = useState(false);
  const [fading, setFading] = useState([]);

  const [appState, dispatch]: AdwContextType = useContext(AdwContext);

  const NO_RESULTS_TEXT: string =
    "Looks like you have no reminders left! Check back later.";
  const CHECKBOX_IMG_PATH: string = require("../../../assets/images/checkmark-gray-icon.png");
  const remindersSubscription: Subscription = null;

  const getTooltipId = (index: number): string => {
    return `reminder-text-link-${index}`;
  };

  const getReminders = (): AdwReminder[] => {
    const allReminders =
      appState && appState.lists && appState.lists.reminders
        ? appState.lists.reminders
        : [];
    const todaysDate: Date = new Date();
    todaysDate.setHours(0, 0, 0, 0);
    if (!allReminders) return [];
    const activeRemindersArray: AdwReminder[] = allReminders
      .filter(
        (reminder) =>
          reminder.ExpirationDate &&
          reminder.StartDate &&
          +new Date(reminder.ExpirationDate) >= todaysDate.getTime() &&
          +new Date(reminder.StartDate) <= new Date().getTime()
      )
      .sort(
        (reminderA, reminderB) =>
          +new Date(reminderA.StartDate).getTime() -
          +new Date(reminderB.StartDate).getTime()
      );
    return isDisplaySeeAllReminders
      ? activeRemindersArray
      : activeRemindersArray
          .filter(
            (reminder) =>
              !isChecked(reminder.ID) || fading.indexOf(reminder.ID) >= 0
          )
          .slice(0, 4);
  };

  const updateUserPreferencesForCheckboxClick = (checkedId: number): void => {
    let newCheckedReminders: number[] = AdwReminder.idStringToArray(
      (appState && appState.user && appState.user.checkedReminders) || ""
    );
    if (newCheckedReminders.length > 0 && isChecked(checkedId)) {
      newCheckedReminders = newCheckedReminders.filter(
        (checked) => checked !== checkedId
      );
    } else {
      newCheckedReminders.push(checkedId);
    }
    const newUserPreferences: InsideUserData = Object.assign({}, appState.user);
    newUserPreferences.checkedReminders =
      AdwReminder.idArrayToString(newCheckedReminders);
    dispatch(SetUserPreferences(newUserPreferences));
  };

  const handleFading = (id: number) => {
    if (!isDisplaySeeAllReminders) {
      let fadingCopy = fading.slice();
      if (isChecked(id)) {
        fadingCopy = fadingCopy.filter((fadingId) => fadingId !== id);
      } else {
        fadingCopy.push(id);
        setTimeout(() => {
          setFading(fading.filter((checked) => checked !== id));
        }, 2000);
      }
      setFading(fadingCopy);
    }
  };

  const onMouseEnterShow = (e): void => {
    let target: HTMLInputElement = e.currentTarget
      ? e.currentTarget
      : e.srcElement;
    let isdots: boolean = target && target.scrollHeight > target.offsetHeight;
    setIsToolTipDisplay(isdots);
  };

  const onMouseLeaveHide = (): void => {
    setIsToolTipDisplay(false);
  };

  const truncatedTextTooltip = (
    link: AdwReminder,
    index: number
  ): JSX.Element => {
    if (isToolTipDisplay) {
      return (
        <ReactTooltip id={getTooltipId(index)} className={styles.tooltipStyle}>
          <span>{link.ReminderText}</span>
        </ReactTooltip>
      );
    }
  };

  const isChecked = (id: number): boolean => {
    const checkedReminders = AdwReminder.idStringToArray(
      (appState && appState.user && appState.user.checkedReminders) || ""
    );
    return checkedReminders && checkedReminders.indexOf(id) >= 0;
  };

  const renderReminderText = (
    link: AdwReminder,
    index: number
  ): JSX.Element => {
    const fadedStyle =
      isChecked(link.ID) && isDisplaySeeAllReminders
        ? styles.elementToFadeInAndOut
        : styles.reminderText;
    return (
      <span>
        <p
          data-tip
          data-for={getTooltipId(index)}
          onMouseEnter={onMouseEnterShow}
          onMouseLeave={onMouseLeaveHide}
          className={fadedStyle}
        >
          {link.ReminderText}
        </p>
        {truncatedTextTooltip(link, index)}
      </span>
    );
  };

  const reminderTextOrLink = (
    link: AdwReminder,
    index: number
  ): JSX.Element => {
    if (link.ReminderLink.Url.trim() === "#") {
      return (
        <span
          className={`${styles.reminderSpanStyle} ${
            isDisplaySeeAllReminders ? styles.SeeAllReminderSpanStyle : ""
          }`}
          id={`reminder-text-${index}`}
        >
          {renderReminderText(link, index)}
        </span>
      );
    } else {
      return (
        <Link
          url={link.ReminderLink.Url}
          target={link.WindowTarget}
          linkClasses={`${styles.reminderLinkStyle} ${
            isDisplaySeeAllReminders ? styles.SeeAllReminderLinkStyle : ""
          }`}
          id={`reminder-link-${index}`}
        >
          {renderReminderText(link, index)}
        </Link>
      );
    }
  };

  const showResultsOrMessage = (): JSX.Element => {
    const reminderArray: AdwReminder[] = getReminders();
    let returnElement: JSX.Element = (
      <NoResults
        imagePath={CHECKBOX_IMG_PATH}
        textForDisplay={NO_RESULTS_TEXT}
        noResultsClasses={`${styles.noResultStyles} ${
          isDisplaySeeAllReminders ? styles.noResultBackgroundStyles : ""
        }`}
        id="Reminders-NoResults"
      />
    );
    if (reminderArray && reminderArray.length > 0) {
      returnElement = (
        <ul className="remindersList">
          {reminderArray.map((link, index) => {
            const liClass: string = `${
              link.ReminderLink.Url.trim() === "#"
                ? styles.noLink
                : styles.activeLink
            } ${fading.indexOf(link.ID) >= 0 ? styles.hiding : ""}`;
            return (
              <li
                key={link.ID}
                id={`reminder-site-${index}`}
                className={liClass}
              >
                <input
                  id={`inputreminder-site-${link.ID}`}
                  type="checkbox"
                  value={link.ReminderText}
                  name={link.ReminderText}
                  onChange={(e) => {
                    handleFading(link.ID);
                    updateUserPreferencesForCheckboxClick(link.ID);
                  }}
                  checked={isChecked(link.ID)}
                />
                <label htmlFor={`inputreminder-site-${link.ID}`} />
                {reminderTextOrLink(link, index)}
              </li>
            );
          })}
        </ul>
      );
    }
    return returnElement;
  };

  const viewRemindersHeading = (): JSX.Element => {
    return isDisplaySeeAllReminders ? (
      <span>Reminders</span>
    ) : (
      <Link
        linkClasses={styles.remindersLink}
        linkText="Reminders"
        target="_self"
        url="/SeeAllReminders"
        id="reminders-see-all-reminders-link"
      />
    );
  };

  return (
    <div className={`${styles.reminders} ${reminderClasses}`}>
      <h4 className={styles.remindersHeading}>{viewRemindersHeading()}</h4>
      {/* //<span onClick={onCLickEvent} className={styles.heading}>Reminders</span> */}
      {showResultsOrMessage()}
      <div className={styles.remindersBorder} />
      {isDisplaySeeAllReminders ? null : (
        <div>
          <div className={styles.visitAllReminderslink}>
            <GoToLink
              linkText="See all reminders"
              target="_self"
              url="/SeeAllReminders"
              id="reminders-see-all-reminders-link"
            />
          </div>
          <FeaturedContent parentClasses={styles.featuredContentArea} />
        </div>
      )}
    </div>
  );
};

export default Reminders;
