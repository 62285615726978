"use client";
import React, { ReactNode, useEffect, useRef } from "react";
import shave from "shave";
import AdwNewsStory from "../../../types/CompanyNewsTypes/AdwNewsStory";
import Link from "../../atoms/Link/Link";
import styles from "./FeaturedStory.module.scss";
import ContentTime from "../../atoms/ContentTime/ContentTime";
import AwsS3Image from "../../atoms/AwsS3Image/AwsS3Image";

export interface IFeaturedStoryProps {
  featuredStory: AdwNewsStory;
  featuredStoryStyles?: string;
}

interface IContainerProps {
  children: ReactNode;
  featuredStory: AdwNewsStory;
  id: string;
  className: string;
}

export const Container: React.FC<IContainerProps> = ({ children, featuredStory, id, className }) => {
  return featuredStory && featuredStory.storyLink && featuredStory.imageAlternativeText ? (
    <Link
      url={featuredStory.storyLink}
      target={featuredStory.windowTarget}
      linkClasses={className}
      id={id}
    >
      {children}
    </Link>
  ) : (
    <div className={className}>
      {children}
    </div>
  );
};

const FeaturedStory: React.FC<IFeaturedStoryProps> = ({
  featuredStory,
  featuredStoryStyles
  }) => {
  
  const headingRef = useRef(null);

  useEffect(() => {
    if (!featuredStory) return;

    const applyShave = () => {
      if (headingRef.current) {
        shave(headingRef.current, 60);
      }
    };

    window.addEventListener('resize', applyShave);
    applyShave();

    return () => {
      window.removeEventListener('resize', applyShave);
    };
  }, [featuredStory]); 

  if (!featuredStory) {
    return null;
  }

  return (
    <div className={`${styles.featuredStory} ${featuredStoryStyles} ${styles.noBorder}`}>
      <header className={styles.header}>
        <h2>Internal News</h2>
      </header>
      <Container featuredStory={featuredStory} id={`companyNews-featuredStory-image-link`} className={styles.imgLink}>
        <AwsS3Image src={featuredStory.imagePath} id={`companyNews-featuredStory-image-aws-img`} 
          alt={featuredStory.imageAlternativeText}
          className={styles.featuredImage}/>
      </Container>
      <Container featuredStory={featuredStory} id={`companyNews-featuredStory-title`} className={styles.link}>
        <h3 ref={headingRef} title={featuredStory.title} className={styles.heading}>
          {featuredStory.title}
        </h3>
      </Container>
      <ContentTime contentAction={featuredStory.contentAction} contentTime={featuredStory.contentTime}/>
      <p
        className={styles.storySummary}
        id={`companyNews-featuredStory-summary`}
      >
        {featuredStory.storySummary}
      </p>
    </div>
  )
};

export default FeaturedStory;
