import React from "react";
import styles from "./PayDayBar.module.scss";
import { EventInput } from "@fullcalendar/core";
import moment from "moment";

export interface IPayDayBarProps {
  id: string;
  payDayArray: EventInput[];
}

const PayDayBar: React.FC<IPayDayBarProps> = ({ id, payDayArray }) => {

const formatPayDay=(payDay)=>{
 return moment.utc(payDay).format('MM/DD');
};

  return (
    <div className={styles.payDayBar} id={id}>
      <div className={styles.nextpayday}>
        <div className={`${styles.npItem} ${styles.npLabel}`}>Next Paydays:</div>
        {payDayArray &&
          <ul>
            {payDayArray.map((adwPayDay, index) => (
              adwPayDay && 
              <li className={styles.npItem} key={adwPayDay.id} id={`npItem-${index}`}>
                <span>{formatPayDay(adwPayDay.date)}</span>
              </li>
            ))}
          </ul>
        }
      </div>
    </div>
  );
};

export default PayDayBar;
