import React from "react";
import styles from "./Button.module.scss";

export interface IButtonProps {
  buttonClasses?: string;
  onClick?: () => void;
  id: string;
  style?: React.CSSProperties;
  children?:React.ReactNode;
}

const Button: React.FC<IButtonProps> = ({ buttonClasses, onClick, children, id, ...otherProps }) => {
  buttonClasses = buttonClasses === undefined ? styles.button : `${styles.button} ${buttonClasses}`;

  return (
    <button className={buttonClasses} onClick={onClick} id={id} {...otherProps}>
      {children}
    </button>
  );
};

export default Button;
