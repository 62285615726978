import React, { useContext, useEffect } from "react";
import { SetMyHealthProgram } from "../../../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../../../context/AdwContext";
import AuthService from "../../../../../services/AuthService";
import HRConnectService, { GetMyHealthProgramResponse } from "../../../../../services/HRConnectService";
import { AdwHrConnectSwitch } from "../../../../../types/HRConnectTypes/AdwHrConnectSwitch";
import ShowHide from "../../ShowHide/ShowHide";
import ComingSoon from "../ComingSoon/ComingSoon";
import widgetStyles from "../HRConnectWidgets.module.scss";
import styles from "./MyHealthProgram.module.scss"

let visibilityTimeout;

const MyHealthProgram: React.FC = () => { // NOSONAR

  const [appState, dispatch]: AdwContextType = useContext(AdwContext);
  const [hidden, setHidden] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const [hasDataError, setHasDataError] = React.useState(false);
  const hasActiveSession: boolean = AuthService.hasActiveSession();
  const nwieID: string = (appState && appState.user && appState.user.nwieID);
  const switches: AdwHrConnectSwitch[] = (appState && appState.lists && appState.lists.hrConnectSwitches) || []


  useEffect(() => {
    if (nwieID && switches && switches.length > 0) {
      switches.every((switchOption => {
        if (switchOption.widget === 'MyHealthProgram' && switchOption.displayWidget && nwieID.match(switchOption.allowRegex)) {
          setIsVisible(true);
          return false;
        }
        return true;
      }))
    }
  }, [nwieID, switches])

  function fetchData(): void {

    const shouldFetch: boolean = true;

    if (shouldFetch && hasActiveSession) {
      setIsLoading(true);
      dispatch(SetMyHealthProgram(null));
      HRConnectService.getMyHealthProgram(nwieID)
        .then(be => {
          dispatch(SetMyHealthProgram(be));
          if (be.hasError) {
            setHasDataError(true)
          }
          setIsLoading(false);
        })
    }
  }


  function toggleVisibility(): void {
    if (hidden) {
      fetchData();
      visibilityTimeout = setTimeout(() => {
        setHidden(true)
      }, 10000)
    } else {
      clearTimeout(visibilityTimeout)
    }
    setHidden(!hidden)
  }

  const getmyHealthProgram = (hidden:boolean,isLoading:boolean,appState:any): GetMyHealthProgramResponse => {
    let myHealthProgram: GetMyHealthProgramResponse;

    if (hidden || isLoading || !appState || !appState.hrConnect) {
        myHealthProgram = null;
    } else {
        myHealthProgram = appState.hrConnect.myHealthProgram || null;
    }
    return myHealthProgram;
  }
  
  const myHealthProgram:GetMyHealthProgramResponse = getmyHealthProgram(hidden,isLoading,appState);
  const hireDateCheck: boolean = (myHealthProgram && myHealthProgram.hireDate && myHealthProgram.newHireCutoffDate && (new Date(myHealthProgram.hireDate) <= new Date((new Date()).getFullYear + "-" + myHealthProgram.newHireCutoffDate)));
  const firstBar: boolean = (myHealthProgram && myHealthProgram.awardEarning >= 25) || hireDateCheck;
  const secondBar: boolean = (myHealthProgram && myHealthProgram.awardEarning >= 50) || hireDateCheck;
  const thirdBar: boolean = (myHealthProgram && myHealthProgram.awardEarning >= 100) || hireDateCheck;

  return (
    <div className={widgetStyles.widget}>
      <div className={widgetStyles.header}><h3>My Health Program</h3></div>
      {!isVisible || hasDataError ?
        <ComingSoon unavailable={hasDataError} /> :
        <>
          <div className={styles.myHealthProgramTopSection}>
            <div className={`${widgetStyles.subSectionContents} ${styles.showRow}`}>
              <span className={`${widgetStyles.subSectionHeader} ${widgetStyles.benefitElectionSubHeader}`}>My progress</span>
              <ShowHide featureTitle="My Health Program" showing={!hidden} toggle={toggleVisibility} className={styles.showHide} ariaLabel="Hide/Show My Health reward progress."/>
            </div>
          </div>


          <div className={hidden ? styles.obfuscate : styles.myHealthProgramBottomSection}>
            <div className={`row  ${widgetStyles.beRow}`} >
              <div className={!hidden && firstBar ? `col-sm-3 ${widgetStyles.progressBarFilled}` : `col-sm-3 ${widgetStyles.progressBarUnfilled}`}>

              </div>
              <div className={!hidden && secondBar ? `col-sm-4 ${widgetStyles.progressBarFilled}` : `col-sm-4 ${widgetStyles.progressBarUnfilled}`}>

              </div>
              <div className={!hidden && thirdBar ? `col-sm-5 ${widgetStyles.progressBarFilled}` : `col-sm-5 ${widgetStyles.progressBarUnfilled}`}>
                <span className={thirdBar ? `${widgetStyles.hideSpan}` : ``} >Due: Oct. 31</span>
              </div>
            </div>
            <div className={`row ${widgetStyles.beRow}`}>
              <div className={`col-sm-3 ${styles.textWordBreak}`}>
                <span className=" ">Health assessment</span>
              </div>
              <div className={`col-sm-4`}>
                <span className=" ">Biometric screening</span>
              </div>
              <div className={`col-sm-5`}>
                <span className=" ">Biometrics met or coaching completed</span>
              </div>
            </div>
          </div>
        </>
      }
      <div className={widgetStyles.benefitElectionsButtonRow}>
        <a
          href="https://onyourside.sharepoint.com/sites/AssociateWellbeingandSafety/SitePages/My-Health.aspx"
          target="_blank"
          rel="noopener noreferrer"
          style={{flex: '3'}}
          className={`${widgetStyles.benefitElectionButtonLink} ${widgetStyles.btnLeft}`}
        >
          Program Guide
        </a>
        <a
          href="https://identity.nationwide.com/idp/startSSO.ping?PartnerSpId=Rally-Production"
          target="_blank"
          rel="noopener noreferrer"
          style={{flex: '1'}}
          className={`${widgetStyles.benefitElectionButtonLink} ${widgetStyles.btnRight}`}
        >
          Rally
        </a>
      </div>
    </div>
  );
}

export default MyHealthProgram;