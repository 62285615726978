import React, { useEffect } from "react";
import DesktopArrowButton from "../../atoms/DesktopArrowButton/DesktopArrowButton";
import Reminders from "../Reminders/Reminders";
import styles from "./SeeAllRemindersView.module.scss";
import { useNavigate } from "react-router-dom";

export interface ISeeAllRemindersProps {
  className?: string;
}

const SeeAllRemindersView: React.FC<ISeeAllRemindersProps> =({className})=>{
const navigate = useNavigate();
  useEffect(()=>{
    document.title = "InSide - All reminders";
  },[])

  const redirectHome = (): void => {
    navigate("/");
  };


    return (
      <div className={className ? className : ""}>
        <div className={styles.SeeAllRemindersWrapper}>
          <div className={styles.toolsHeading}>
            <div className={styles.closeButton} id="close-button-link" onClick={redirectHome}>
              <div className={styles.closeX} />
              <span className={styles.closeText}>CLOSE</span>
            </div>
          </div>
          <div className={styles.RemindersArrayHeight}>
            <Reminders isDisplaySeeAllReminders />
          </div>
          <div className={styles.backLink} id="back-link" onClick={redirectHome}>
            <DesktopArrowButton desktopArrow={styles.arrow} />
            <span>Back</span>
          </div>
        </div>
      </div>
    );

}

export default SeeAllRemindersView;
