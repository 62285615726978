import React, { useContext, useState } from "react";
import ReactTooltip from "react-tooltip";
import { SetHrTimeOffCumulative, SetHrTimeOffCurrent } from "../../../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../../../context/AdwContext";
import AuthService from "../../../../../services/AuthService";
import HRConnectService from "../../../../../services/HRConnectService";
import ShowHide from "../../ShowHide/ShowHide";
import widgetStyles from "../HRConnectWidgets.module.scss";
import styles from "./YourTime.module.scss";

let visibilityTimeout;

const YourTime: React.FC = () => {

  const [appState, dispatch]: AdwContextType = useContext(AdwContext);
  const [hidden, setHidden] = useState(true)
  const [isLoading, setIsLoading] = useState(false);

  const hasActiveSession: boolean = AuthService.hasActiveSession();

  function fetchTime(): void {
    const shouldFetch: boolean = appState && appState.hrConnect && appState.hrConnect.yourTime
      && appState.hrConnect.yourTime.current && appState.hrConnect.yourTime.current.days === null;
    if (shouldFetch && hasActiveSession) {
      setIsLoading(true);
      dispatch(SetHrTimeOffCurrent(0, 0));
      dispatch(SetHrTimeOffCumulative(0, 0));
      HRConnectService.getTimeOff(appState.user.nwieID)
        .then(([[currentHours, currentDays], [cumHours, cumDays]]) => {
          dispatch(SetHrTimeOffCurrent(currentHours, currentDays));
          dispatch(SetHrTimeOffCumulative(cumHours, cumDays));
          setIsLoading(false);
        });
    }
  }

  /** 
   * Returns time available as [days, hours]
   * @param cumulative returns cumulative time if true, current time if false
   */
  function getTime(cumulative: boolean = false): [string, string] {
    if (isLoading) {
      return ["--.--", "--.--"];
    }
    if (!hidden) {
      const yourTime = appState && appState.hrConnect && appState.hrConnect.yourTime;
      let time = yourTime && (cumulative ? yourTime.cumulative : yourTime.current);
      if (!time) {
        time = { hours: 0, days: 0 };
      }
      return [
        (Math.round(time.days * 100) / 100).toFixed(2).toString(),
        (Math.round(time.hours * 100) / 100).toFixed(2).toString()
      ]
    }
    return ["XX.XX", "XX.XX"]
  }

  function toggleVisibility(): void {
    if (hidden) {
      fetchTime();
      visibilityTimeout = setTimeout(() => {
        setHidden(true)
      }, 10000)
    } else {
      clearTimeout(visibilityTimeout)
    }
    setHidden(!hidden)
  }

  const current = getTime();
  const cumulative = getTime(true);

  return <div className={widgetStyles.widget}>
    <div className={widgetStyles.header}><h3>Your Time</h3></div>
    <div className={widgetStyles.section}>
      <div className={widgetStyles.subSectionHeader}>Current balance</div>
      <div className={`${widgetStyles.subSectionContents} ${styles.showRow}`}>
        <span data-tip data-for="yourtime-current-balance" className={hidden ? widgetStyles.obfuscate : ""}>{current[0]} days</span>
        {!hidden && <ReactTooltip id="yourtime-current-balance" className={widgetStyles.tooltip}>
          {current[1]} hours
        </ReactTooltip>}
        <ShowHide featureTitle="Your Time" showing={!hidden} toggle={toggleVisibility} ariaLabel="Hide/Show your current and year-end time off balance."/>
      </div>
    </div>
    <div className={widgetStyles.divider} />
    <div className={widgetStyles.section}>
      <div className={widgetStyles.subSectionHeader}>Year-end balance</div>
      <div className={widgetStyles.subSectionContents}>
        <span data-tip data-for="yourtime-cumulative-balance" className={hidden ? widgetStyles.obfuscate : ""}>{cumulative[0]} days</span>
        {!hidden && <ReactTooltip id="yourtime-cumulative-balance" className={widgetStyles.tooltip}>
          {cumulative[1]} hours
        </ReactTooltip>}
      </div>
    </div>
    <div className={widgetStyles.buttonRow}>
      <a
        href="https://www.myworkday.com/nationwide/d/task/2997$11831.htmld"
        target="_blank"
        rel="noopener noreferrer"
        className={widgetStyles.buttonLink}
      >
        Request an absence
      </a>
    </div>
  </div>
}

export default YourTime;