import React, { useRef, useContext, useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AdwEmployee from "../../../types/CallupTypes/AdwEmployee";
import PeopleUtils from "../../../utils/PeopleUtils";
import CircleButton from "../../atoms/CircleButton/CircleButton";
import styles from "./ContactInfo.module.scss";
import GraphService from "../../../services/GraphService";
import { EditModalType } from "../../../types/EditModalType/EditModalType";
import EditPhoneNumberLink from "../../atoms/EditPhoneNumberLink/EditPhoneNumberLink";
import EditProfilePhotoLink from "../../atoms/EditProfilePhotoLink/EditProfilePhotoLink";

export interface IContactInfoProps {
  contactInfoClasses?: string;
  userPersonalizedEmail: string
  adwEmployee: AdwEmployee;
  mobileEditModal: any;
  setMobileEditModal: any;
  avatarUser: any;
}

export interface IContactInfoState {
  nwieToolTipText: string;
  imageSource: string;
  editPhoneModalData: EditModalType;
  editPhotoModalData: EditModalType;
}

const scheduleIconHover: string = require("../../../assets/images/iconCircle-calendarhover.svg").default;
const bravoIconHover: string = require("../../../assets/images/iconCircle-awardhover.svg").default;
const emailIcon: string = require("../../../assets/images/icon-email.svg").default;
const chatIcon: string = require("../../../assets/images/icon-chat.svg").default;
const scheduleIcon: string = require("../../../assets/images/iconCircle-calendar.svg").default;
const bravoIcon: string = require("../../../assets/images/iconCircle-award.svg").default;

const ContactInfo: React.FC<IContactInfoProps> = ({
  adwEmployee,
  userPersonalizedEmail,
  mobileEditModal,
  setMobileEditModal,
  avatarUser
}) => {
  const [appState] = useContext<AdwContextType>(AdwContext);

  const [imageSource, setImageSource] = useState(
    require(`../../../assets/images/NoPhoto.png`)
  );

  const editPhoneModalData = {
      url: "https://www.myworkday.com/nationwide/d/task/2998$2708.htmld",
      title: "How to edit your office phone number",
      contents: (
        <p>
          InSide pulls your office number from Workday. In order to update your
          number, you’ll need to visit Workday and edit it there.
        </p>
      ),
      subcontents: (
        <ul>
          <li>Select ‘Personal Information’ from the list of Applications </li>
          <li> Under ‘Change’, select ‘Contact Information’ </li>
          <li> At the top of the page, select the ‘Edit’ button </li>
          <li>
            {" "}
            Scroll down to ‘Work Contact Information’ and find the phone number
            you want to edit{" "}
          </li>
          <li>
            {" "}
            Select the pencil icon to edit your number. Once you’re done, select
            the checkmark icon
          </li>
        </ul>
      ),
      subheading: <span>From the home page of Workday:</span>
  };

  const editPhotoModalData = {
    url: "https://onyourside-my.sharepoint.com/person.aspx",
    title: "You’re about to update your profile photo",
    contents: (
      <>
        <p>Your photo will be updated on InSide and all Microsoft 365 sites. It will not be updated in Workday.</p>
      </>
    ),
    subcontents: (
      <>
        <p>
        Use a square photo at a resolution between 300 x 300 pixels and 1,000 x 1,000 pixels.
        </p>
        <p>
        When you select continue, you will be taken to the personal info page of your Microsoft 365 account. Click the camera icon on your current photo to upload a new photo. (It may take a moment for the camera icon to appear.) After you update your photo, you may need to reload InSide to see the changes. Teams can take up to 24 hours to update. After 24 hours, try clearing your cache.
        </p>
      </>
    ),
    subheading: <span style={{ color: "#007d8a" }}>FOR BEST RESULTS:</span>
  };


  const [nwieToolTipText, setNwieToolTipText] = useState("Copy to clipboard");
  const [tooltipUpdated, setTooltipUpdated] = useState(false);

  useEffect(() => {
    getUserImage(adwEmployee.shortName);
  });

  useEffect(() => {
    if (tooltipUpdated) {
      ReactTooltip.show(tooltipRef.current);
      setTooltipUpdated(false);
    }
  }, [tooltipUpdated]);

  let preferredFullName = adwEmployee.preferedFullName;
  const tooltipRef = useRef(null);

  const defaultBackground: React.CSSProperties = {
    backgroundSize: "100px, 100px",
  };

  const formatPhoneNumber = (phone: string): string => {
    let formattedPhone: string = phone;
    if (phone) {
      let trimmedNum: string = phone.replace(/\D+/g, "").replace(/\s+/g, "");

      if (trimmedNum.length === 10) {
        formattedPhone = `1-${trimmedNum.substring(
          0,
          3
        )}-${trimmedNum.substring(3, 6)}-${trimmedNum.substring(6)}`;
      }
    }
    return formattedPhone;
  };

  const getUserImage = (nwie: string): void => {
    Promise.resolve(GraphService.getUsersPhoto(nwie)).then((image) => {
      setImageSource(image);
    });
  };

  const copyToClipboard = (textToCopy:string) =>{
    if (navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          console.log("Text copied to clipboard successfully!");
        })
        .catch((error) => {
          console.error("Error copying text to clipboard:", error);
        });
    } else {
      // Fallback for browsers that do not support the Clipboard API
      // You can use the old method here if needed
      console.warn("Clipboard API not supported. Consider using execCommand() as a fallback.");
      const el = document.createElement("textarea");
      el.value = textToCopy;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy"); // NOSONAR
      document.body.removeChild(el);
    }
  }
  

  const onClickShortName = (nwie: string): void => {
    copyToClipboard(nwie);
    let tooltip: any = tooltipRef.current;
    ReactTooltip.hide(tooltip);
    changeMessageOfToolTip(nwie);
  };

  const changeMessageOfToolTip = (nwie) => {
    setNwieToolTipText(`Copied ${nwie} to the clipboard`);
    setTooltipUpdated(true);
  };

  const closeEditModal = (): void => {
    mobileEditModal(false);
  };

  const openEditPhoneModal = (): void => {
    mobileEditModal(true);
    setMobileEditModal(editPhoneModalData, closeEditModal);
  };

  const openEditPhotoModal = (): void => {
    mobileEditModal(true);
    setMobileEditModal(editPhotoModalData, closeEditModal);
  };

  return (
    <>
      <div className={styles.contactInfo} id="ContactInfo">
        <div id={`imageWrapper`} className={styles.imageWrapper}>
          <img
            width="100"
            height="100"
            className={styles.employeeImage}
            id={adwEmployee.emplid}
            src={imageSource}
            alt={`${adwEmployee.firstName}-${adwEmployee.lastName} `}
            style={defaultBackground}
          />
          <EditProfilePhotoLink adwEmployee={adwEmployee} user={appState.user && appState.user.nwieID
            ? appState.user.nwieID.toLocaleUpperCase()
            : ""} openEditPhotoModal={openEditPhotoModal}/>
        </div>
        <div
          className={styles.identification}
          id={`identification`}
        >
          {adwEmployee.fullName && (
            <h1 className={styles.name} id={`fancy-name`}>
              {preferredFullName}
            </h1>
          )}
          {avatarUser ? (
            <div className={styles.jobTitle} id={`job-title`}>
              {PeopleUtils.formatJobTitle(avatarUser.employeeType, avatarUser.jobTitle)}
            </div>
          ) : (
            <div className={styles.jobTitle} id={`job-title`}>
              Non-Employee
            </div>
          )}
          {adwEmployee.department && (
            <div className={styles.department} id={`department`}>
              {adwEmployee.department}
            </div>
          )}
        </div>
        <div className={styles.line}></div>
        <div className={styles.contactMethods} id={`contact-methods`}>
          {adwEmployee.shortName && (
            <>
              <div
                id={`employee-nwie-id`}
                className={styles.userShortName}
                onClick={() => {
                  onClickShortName(adwEmployee.shortName);
                }}
              >
                <span
                  id="employee-id"
                  data-tip={nwieToolTipText}
                  ref={tooltipRef}
                  onMouseLeave={() =>
                    setNwieToolTipText("Copy to clipboad")
                  }
                  style={{ width: "auto", cursor: "pointer" }}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.code === 'Enter') {
                      onClickShortName(adwEmployee.shortName);
                    }
                    if (e.key === 'Escape' || e.code === 'Escape') {
                      setNwieToolTipText("Copy to clipboad");
                    }
                  }}
                >
                  {adwEmployee.shortName}
                </span>
              </div>
              <ReactTooltip data-id={`contact-info-nwie-tip`} />
            </>
          )}
          <ReactTooltip/>

          {adwEmployee && adwEmployee.workPhone && (
            <div id={`phone-number-wrapper`} className={styles.phoneWrapper}>
              <div id={`phone-number`}>
                Office: {formatPhoneNumber(adwEmployee.workPhone)} {adwEmployee.workPhoneExtension && ('+' + adwEmployee.workPhoneExtension)}
              </div>
              <div id={`edit-phone-number`}>
              <EditPhoneNumberLink adwEmployee={adwEmployee} user={appState.user && appState.user.nwieID
                  ? appState.user.nwieID.toLocaleUpperCase()
                  : ""} openEditPhoneModal={openEditPhoneModal}/>
              </div>
            </div>
          )}
          {userPersonalizedEmail && (
            <>
              <div id={`email-address`} className={styles.emailAddress}>
                {userPersonalizedEmail.toLowerCase()}
              </div>
              <div className={styles.userCardBtnContainer}>
              <div
                id={`email-button`}
                tabIndex={0}
                role="button"
                className={styles.buttonContainer}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.code === 'Enter') {
                    PeopleUtils.openEmail(userPersonalizedEmail);
                  }
                }}
              >
                <div
                  className={styles.userCardEmailBtn}
                  onClick={() => PeopleUtils.openEmail(userPersonalizedEmail)}
                  onTouchStart={() =>
                    PeopleUtils.openEmail(userPersonalizedEmail)
                  }
                >
                  <img
                    src={emailIcon}
                    className={styles.emailIcon}
                    alt="e-mail"
                  />
                  <span className={styles.userCardBtnText}>Email</span>
                </div>
              </div>
              <div
                id={`chat-button`}
                tabIndex={0}
                role="button"
                className={styles.buttonContainer}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.code === 'Enter') {
                    PeopleUtils.openChat(userPersonalizedEmail);
                  }
                }}
              >
                <div
                  className={styles.userCardChatBtn}
                  onClick={() => PeopleUtils.openChat(userPersonalizedEmail)}
                  onTouchStart={() =>
                    PeopleUtils.openChat(userPersonalizedEmail)
                  }
                >
                  <img
                    src={chatIcon}
                    className={styles.chatIcon}
                    alt="chat"
                  />
                  <span className={styles.userCardBtnText}>Teams</span>
                </div>
              </div>
              </div>
            </>
          )}
          <div className={styles.circleButtons}>
            <CircleButton
              id={`schedule-meeting-button`}
              imgSrc={scheduleIcon}
              hoverImgSrc={scheduleIconHover}
              url="https://outlook.office.com/owa/?realm=nationwide.com&path=/calendar/view/Week"
              circleText="Schedule Meeting"
              CirclebackgroundColor="#fbfbfb"
              height="20"
              width="20"
            />
            <CircleButton
              id={`send-bravo-button`}
              imgSrc={bravoIcon}
              hoverImgSrc={bravoIconHover}
              url={`https://fed.octanner.net/sp/startSSO.ping?PartnerIdpId=https%3A%2F%2Fsts.windows.net%2F22140e4c-d390-45c2-b297-a26c516dc461%2F`}
              circleText="Send Bravo"
              CirclebackgroundColor="#fbfbfb"
              height="20"
              width="17"
              onKeyDown={(e) => {
                if (e.key === 'Tab' || e.code === 'Tab') {
                  window.scrollTo(0, 0);
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactInfo;