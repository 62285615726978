import { SpAtoZView } from "./SpAtoZView";
import { InsideUserData } from "../InsideUserData";

class AdwAtoZView {
  public Url: string;
  public SiteName: string;
  public Target: string;
  public Category: string;
  public Contact: string;
  public Description: string;
  public FavoriteSite: boolean = true;
  public RecentlyVisitedSite: boolean = true;
  public AtoZId: number;
  
  constructor(spAtoZView: SpAtoZView, insideUser?: InsideUserData) {
    if (spAtoZView !== null && insideUser !== null) {
      this.AtoZId = spAtoZView.fields.AtoZID; //check API call data
      this.Url = spAtoZView.fields.URL;
      this.SiteName = spAtoZView.fields.SITENAME;
      this.Target = spAtoZView.fields.TARGET;
      this.Category = spAtoZView.fields.CATEGORY;
      this.Contact = spAtoZView.fields.CONTACT;
      this.Description = spAtoZView.fields.DESCRIPTION;
    }
  }

  public static readonly convertSitesStringToArray = (sites: string): number[] => {
    let numberArray: number[] = [];
    if (sites) {
      const sitesArray: string[] = sites.split(", ");
      numberArray = sitesArray.map(site => parseInt(site, 0));
    }
    return numberArray;
  };

  public static getFavoriteSite(favIdString: string, adwAtoZ: AdwAtoZView[]): AdwAtoZView[] {
    let userFavSite: AdwAtoZView[] = [];
    const userFavSiteArray: number[] = AdwAtoZView.convertSitesStringToArray(favIdString);
    userFavSite = userFavSiteArray.map(siteId => adwAtoZ.find(site => (Number(site.AtoZId) === siteId)));
    userFavSite = userFavSite.filter(site => site !== undefined);
    return userFavSite;
  }

  public static getVisitedSite(visitedIdString: string, adwAtoZ: AdwAtoZView[]): AdwAtoZView[] {
    let userVisitedSite: AdwAtoZView[] = [];
    const userVisitedSiteArray: number[] = AdwAtoZView.convertSitesStringToArray(visitedIdString);

    userVisitedSite = userVisitedSiteArray.map(siteId => adwAtoZ.find(site => (Number(site.AtoZId) === siteId)));

    userVisitedSite = userVisitedSite.filter(site => site !== undefined);
    return userVisitedSite;
  }

  public static readonly isFavSite = (spAtoZView: AdwAtoZView, insideUser: InsideUserData): boolean => {
    let favoriteSites: string = "";
    if (insideUser && insideUser.sitesAToZFavorites) {
      favoriteSites = insideUser.sitesAToZFavorites;
    }
    const sitesArray: number[] = AdwAtoZView.convertSitesStringToArray(favoriteSites);

    return !!(sitesArray &&
      sitesArray.some(siteId => {
        return (siteId === Number(spAtoZView.AtoZId));
      }));
  };

  public static readonly isRecentSite = (spAtoZView: AdwAtoZView, insideUser: InsideUserData): boolean => {
    let recentSites: string = "";
    if (insideUser && insideUser.recentlyVisitedSites) {
      recentSites = insideUser.recentlyVisitedSites;
    }
    const sitesArray: number[] = AdwAtoZView.convertSitesStringToArray(recentSites);
    return !!(sitesArray &&
      sitesArray.some(siteId => {
        return (siteId === Number(spAtoZView.AtoZId));
      }));
  };
}

export default AdwAtoZView;