import React, { useContext, useEffect } from "react";
import { SetHrActiveApps, SetHrOpenJobs } from "../../../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../../../context/AdwContext";
import AuthService from "../../../../../services/AuthService";
import CallupService from "../../../../../services/CallupService";
import HRConnectService from "../../../../../services/HRConnectService";
import AdwEmployee from "../../../../../types/CallupTypes/AdwEmployee";
import { AdwHrConnectSwitch } from "../../../../../types/HRConnectTypes/AdwHrConnectSwitch";
import ShowHide from "../../ShowHide/ShowHide";
import ComingSoon from "../ComingSoon/ComingSoon";
import widgetStyles from "../HRConnectWidgets.module.scss";
import styles from "./Jobs.module.scss"

let visibilityTimeout;

const Jobs: React.FC = () => {

  const [appState, dispatch]: AdwContextType = useContext(AdwContext);
  const [hidden, setHidden] = React.useState(true)
  const [state, setState] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const [hasDataError, setHasDataError] = React.useState(false);

  const hasActiveSession: boolean = AuthService.hasActiveSession();
  const nwieID: string = (appState && appState.user && appState.user.nwieID);

  const switches: AdwHrConnectSwitch[] = (appState && appState.lists && appState.lists.hrConnectSwitches) || []

  useEffect(() => {
    if (nwieID && hasActiveSession && !state) {
      CallupService.getEmployeeByShortName(nwieID).then((user: AdwEmployee) => {
        const userState: string = user && (user.state || "OH");
        setState(userState);
      });
    }
  }, [nwieID, hasActiveSession, state])

  useEffect(() => {
    if (nwieID && switches && switches.length > 0) {
      switches.every((switchOption => {
        if (switchOption.widget === 'Jobs' && nwieID.match(switchOption.allowRegex)) {
          setIsVisible(true);
          return false;
        }
        return true;
      }))
    }
  }, [nwieID, switches])

  function fetchData(): void {
    const shouldFetch: boolean = appState && appState.hrConnect && appState.hrConnect.jobs
      && appState.hrConnect.jobs.activeApps === null && !!state;
    if (shouldFetch && hasActiveSession) {
      setIsLoading(true);
      // Set to 0 to make shouldFetch return false above and prevent future calls to API
      dispatch(SetHrOpenJobs(0));
      dispatch(SetHrActiveApps(0));
      // Retrieve both sets of data before displaying results
      Promise.all([
        HRConnectService.getOpenReqs(state)
          .then(openReqs => {
            dispatch(SetHrOpenJobs(openReqs.reqs));
            if (openReqs.hasError) {
              setHasDataError(true)
            }
          }),
        HRConnectService.getActiveApps(appState.user.nwieID).then(activeApps => {
          dispatch(SetHrActiveApps(activeApps.apps))
          if (activeApps.hasError) {
            setHasDataError(true)
          }
        })
      ]).then(() => {
        setIsLoading(false)
      })
    }
  }

  function toggleVisibility(): void {
    if (hidden) {
      fetchData();
      visibilityTimeout = setTimeout(() => {
        setHidden(true)
      }, 10000)
    } else {
      clearTimeout(visibilityTimeout)
    }
    setHidden(!hidden)
  }

  const getOpenJobs = (hidden:boolean,isLoading:boolean,appState:any): string => {
    let openJobs: string;

    if (hidden) {
      openJobs = "XX";
    }else if(isLoading){
      openJobs = "--";
    } else if (appState && appState.hrConnect && appState.hrConnect.jobs && appState.hrConnect.jobs.openJobs) {
      openJobs = appState.hrConnect.jobs.openJobs.toString() || "0";
    } else {
      openJobs = "0";
    }
    return openJobs;
  }

  const getActiveApps = (hidden:boolean,isLoading:boolean,appState:any): string => {
    let activeApps: string;

    if (hidden) {
        activeApps = "XX";
    }else if(isLoading){
      activeApps = "--";
    } else if (appState && appState.hrConnect && appState.hrConnect.jobs && appState.hrConnect.jobs.activeApps) {
        activeApps = appState.hrConnect.jobs.activeApps.toString() || "0";
    } else {
        activeApps = "0";
    }
    return activeApps;
  }

  
  const openJobs: string = getOpenJobs(hidden,isLoading,appState);
  const activeApps: string = getActiveApps(hidden,isLoading,appState);
  
  return (
    <div className={widgetStyles.widget}>
      <div className={widgetStyles.header}><h3>Jobs</h3></div>
      {!isVisible || hasDataError ?
        <ComingSoon unavailable={hasDataError} /> :
        <>
          <div className={widgetStyles.section}>
            <div className={widgetStyles.subSectionHeader}>All open jobs{state ? ` in ${state}` : ""}</div>
            <div className={`${widgetStyles.subSectionContents} ${styles.showRow}`}>
              <span className={hidden ? widgetStyles.obfuscate : ""}>{openJobs}</span>
              <ShowHide featureTitle="Jobs" showing={!hidden} toggle={toggleVisibility} ariaLabel="Hide/Show open jobs and active applications."/>
            </div>
          </div>
          <div className={widgetStyles.divider} />
          <div className={widgetStyles.section}>
            <div className={widgetStyles.subSectionHeader}>My active applications</div>
            <div className={widgetStyles.subSectionContents}>
              <span className={hidden ? widgetStyles.obfuscate : ""}>{activeApps}</span>
            </div>
          </div>
        </>
      }
      <div className={widgetStyles.buttonRow}>
        <a
          href="https://www.myworkday.com/nationwide/d/task/2998$39218.htmld"
          target="_blank"
          rel="noopener noreferrer"
          className={`${widgetStyles.buttonLink}`}
        >
          My job alerts
        </a>
        <a
          href="https://www.myworkday.com/nationwide/d/task/1422$961.htmld"
          target="_blank"
          rel="noopener noreferrer"
          className={`${widgetStyles.buttonLink}`}
        >
          Find jobs
        </a>
      </div>
    </div>
  );
}

export default Jobs;