import React, { useEffect, useRef, useState } from "react";
import MyToolsView from "../../organisms/MyTools/MyToolsView";
import AtoZFavoritesView from "../../organisms/AtoZFavorites/AtoZFavoritesView";
import {ReactComponent as ToolIcon} from "../../../assets/images/Tools.svg";
import {ReactComponent as ToolDropdownIcon} from "../../../assets/images/Tools-arrow.svg";
import {ReactComponent as NavigationBackIcon} from "../../../assets/images/chevron-left.svg";
import {ReactComponent as NavigationNextIcon} from "../../../assets/images/chevron-right.svg";
import styles from "./ToolsDropdown.module.scss";
import searchIconStyles from "../../organisms/MegaMenu/MegaMenuView.module.scss";
import defaultLayoutStyles from "../../layouts/DefaultLayout/DefaultLayout.module.scss";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AdwPopularTool from "../../../types/PopularToolsTypes/AdwPopularTool";
import AdwCommonTool from "../../../types/CommonToolsTypes/AdwCommonTool";
import { isMobileOnly } from "react-device-detect";
import PopularToolsView from "../../organisms/PopularToolsView/PopularToolsView";

export interface IToolsDropdownProps {
  mobileCollapsed?:boolean
  }

const ToolsDropdown: React.FC<IToolsDropdownProps> = ({mobileCollapsed})=>{ // NOSONAR
    const [toolsDropDownContent,setToolsDropDownContent] = useState(false);
    const [toolsToDisplay, setToolsToDisplay] = useState<AdwCommonTool[]>([]);
    const [selectedLink,setSelectedLink]=useState('default');
  const [appState]: AdwContextType = React.useContext(AdwContext);
  const preferredCommonTools = appState && appState.user && appState.user.preferredCommonTools;
  const toogleRef = useRef(null);
  const dropdownRef = useRef(null);
  const adwPopularTools: AdwPopularTool[] = appState.lists.popularTools;

useEffect(()=>{
    if (
        appState &&
        appState.user &&
        appState.lists &&
        appState.lists.commonTools &&
        appState.lists.commonTools.length
      ) {
        const currentPreferredTools = toolsToDisplay
          .map((tool: any) => tool.ID)
          .join(", ");
        if (currentPreferredTools !== preferredCommonTools) {
          setToolsToDisplay(determineDefaultOrPreferredTools());
        }
      }
},[preferredCommonTools])


  useEffect(() => {
    if (appState && appState.lists && appState.lists.commonTools) {
      const initialTools = determineDefaultOrPreferredTools();
      setToolsToDisplay(initialTools);
    }
  }, [appState.lists, appState.user]);
  useEffect(()=>{
    setToolsDropDownContent(getToolsDropDownContent(isMobileOnly, mobileCollapsed));
    setSelectedLink('default');
  },[mobileCollapsed])

  useEffect(() => {
    const searchIconButtonEl: HTMLElement = document.querySelector(`.${searchIconStyles.searchIconButton}`);

    if (searchIconButtonEl) {
      if (toolsDropDownContent) {
        searchIconButtonEl.style.marginRight = '40px';
      } else {
        searchIconButtonEl.style.marginRight = '150px';
      }
    }
  }, [toolsDropDownContent])

  const getToolsDropDownContent=(isMobileOnly,mobileCollapsed)=> {
    return isMobileOnly && mobileCollapsed || false;
}

  const determineDefaultOrPreferredTools = (): AdwCommonTool[] => {
    const adwCommonTools: AdwCommonTool[] =
      appState && appState.lists && appState.lists.commonTools;
    let toolsForDisplay: AdwCommonTool[] = [];
    if (appState && appState.user && appState.user.preferredCommonTools) {
      toolsForDisplay = AdwCommonTool.getPrefferedTools(
        appState.user.preferredCommonTools,
        adwCommonTools
      );
    } else {
      toolsForDisplay = adwCommonTools.filter(
        (tool) => tool.DefaultTool || tool.RequiredTool
      );
    }
    return toolsForDisplay;
  };

  const onToolsBackButtonClicked = ()=>{
    if(selectedLink !=='default'){
      setSelectedLink('default');
    }else{
      setToolsDropDownContent(false)
    }
  }
    return(
        <>
           <div onClick={()=>{isMobileOnly && setToolsDropDownContent(!toolsDropDownContent)}} 
    className={styles.toolsNavigationLink} 
    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.code === 'Escape') {
        setToolsDropDownContent(false);
        toogleRef.current.focus();
      }
      if (e.code === 'ArrowDown') {
        document.getElementById('toolsDropDownContent').focus();
      }
    }}
            >
         
        <button onClick={()=>{ 
          setToolsDropDownContent(!toolsDropDownContent)
          const searchBoxEl: HTMLElement = document.querySelector('.' + defaultLayoutStyles.searchBoxDefault);
          searchBoxEl.classList.remove(defaultLayoutStyles.searchBoxFixed);
        }} 
        aria-controls="toolsDropDownContent"
        aria-expanded={toolsDropDownContent} id="tools_dropdown_button"
        key="tools"
        ref={toogleRef}
        className={toolsDropDownContent ? styles.toolsDropDownOpen : styles.toolsDropDown}>
          <div className={styles.toolsDropDownIcon}>
            <ToolIcon className={styles.toolsIcon} />
         </div>
          <div className={styles.toolsDropDownLink}>
            <span>Tools</span>
            <div className={styles.toolsArrowIconContainer}>
            <ToolDropdownIcon className={styles.toolsArrowIcon} />
            </div>
          </div>
        </button>
        </div>
        <div ref={dropdownRef} 
        style={{display:toolsDropDownContent ? 'flex' : 'none'}} className={styles.toolsDropDownContent} 
        aria-labelledby="tools_dropdown_button"  id="toolsDropDownContent"
        onMouseEnter={()=>{!isMobileOnly && setToolsDropDownContent(true)}} 
        onMouseLeave={()=>{!isMobileOnly && setToolsDropDownContent(false)}}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.code === 'Escape') {
            e.preventDefault();
            setToolsDropDownContent(false);
          }
        }}
        >
          <div className={styles.toolsDropDownContentInnerWrap}>    
            <div className={styles.toolsNavigationPrevButtonWrapper} style={{
              background: (selectedLink === 'default' && isMobileOnly) ?  '#141B4D' :  'none'
              }}>
              <div style={{flexDirection:'row',display:'flex', cursor: 'pointer',padding: isMobileOnly ? '0px 20px': '0px'}} onClick={()=>{onToolsBackButtonClicked()}}>
              <div className={styles.navBackArrowWrapper} >
                <NavigationBackIcon className={`${styles.navigationBackIcon} ${selectedLink !== 'default' ? styles.darkBlueFill : styles.mintFill} `} />
              </div>
              <div className={styles.toolIconWrapper} >
            <ToolIcon className={`${styles.toolIcon} ${selectedLink !== 'default' ? styles.vibrantBlueStroke : styles.mintStroke} `} />
            </div>
              <div className={styles.toolsNavTitle}>
                <span className={styles.toolsNavTitleText} style={{
                  color: (selectedLink === 'default' && isMobileOnly)  ? '#6ECEB2' : '#231F20',
                }}>{selectedLink === 'Popular tools' ? selectedLink : 'Tools'}</span>
                </div>
                </div>
            </div>
            {(isMobileOnly && selectedLink === 'default') &&
            <div className={styles.toolsSubCategoryWrapper}>
            <div className={styles.mobileOnlyView}>
            <div className={styles.popularToolsLabel} onClick={()=>setSelectedLink('Popular tools')}>
            <div className={styles.popularToolsLabelWrapper}>
              <span className={styles.popularToolsTitle}>Popular tools</span>
              </div>
              <div className={styles.mobileOnlyView} onClick={()=>{setToolsDropDownContent(true)}}>
              <NavigationNextIcon className={styles.navigationBackIcon}/>
            </div>
          </div>
</div>
<div className={styles.mobileOnlyView}>
            <div className={styles.popularToolsLabel} onClick={()=>setSelectedLink('My tools')}>
            <div className={styles.popularToolsLabelWrapper}>
              <span className={styles.popularToolsTitle}>My tools</span>
              </div>
              <div className={styles.mobileOnlyView} onClick={()=>{setToolsDropDownContent(true)}}>
              <NavigationNextIcon className={styles.navigationBackIcon}/>
            </div>
          </div>
</div>
<div className={styles.mobileOnlyView}>
          <div className={styles.popularToolsLabel} onClick={()=>setSelectedLink('A-Z favorites')}>
            <div className={styles.popularToolsLabelWrapper}>
              <span className={styles.popularToolsTitle}>A-Z favorites</span>
              </div>
              <div className={styles.mobileOnlyView} onClick={()=>{setToolsDropDownContent(true)}}>
              <NavigationNextIcon className={styles.navigationBackIcon}/>
            </div>
          </div>
</div>
            </div>
            }

{( (isMobileOnly && selectedLink === 'Popular tools') || (!isMobileOnly && selectedLink === 'default')) && (adwPopularTools && adwPopularTools.length > 0) &&
  <div className={styles.popularToolsWrapper}>
{!isMobileOnly &&
<div className={styles.popularToolsLabel}>
              <div className={styles.popularToolsLabelWrapper}>
                <h3 className={styles.popularToolsTitle} id="Popular-Tools-Title">
          <strong>Popular tools</strong>
        </h3>
                </div>    
            </div>
}
<PopularToolsView adwPopularTools={adwPopularTools}/>
            </div>
             }
          
    
            <div className={styles.myToolsAtoZWrapper}>
            {( (isMobileOnly && selectedLink === 'My tools') || (!isMobileOnly && selectedLink === 'default')) &&
            <>
            <div className={`${styles.mobileOnlyView} ${styles.myToolsLabel}`}>
              <div className={styles.mobileOnlyView}>
                <NavigationBackIcon className={styles.navigationBackIcon}/>
              </div>
              <div className={styles.mobileOnlyView}>
            <ToolIcon className={styles.toolsIcon} />
            </div>
              <div className={styles.mobileOnlyView}>
                <span className={styles.popularToolsTitle}>My tools</span>
                </div>
            </div>
           
            <div className={styles.myToolsWrapper}>
            <MyToolsView
              defaultOrPreferredTools={toolsToDisplay}
              onViewAllClicked={()=>setToolsDropDownContent(false)}  
              />
</div>
</>
}

{( (isMobileOnly && selectedLink === 'A-Z favorites') || (!isMobileOnly && selectedLink === 'default')) &&
<>
<div className={`${styles.mobileOnlyView} ${styles.myToolsLabel}`}>
              <div className={styles.mobileOnlyView}>
                <NavigationBackIcon className={styles.navigationBackIcon}/>
              </div>
              <div className={styles.mobileOnlyView}>
            <ToolIcon className={styles.toolsIcon} />
            </div>
              <div className={styles.mobileOnlyView}>
                <span className={styles.popularToolsTitle}>A-Z favorites</span>
                </div>
            </div>
<div className={styles.myAtoZFavWrapper}>
<AtoZFavoritesView 
              onViewAllClicked={()=>setToolsDropDownContent(false)}   />
            </div>
            </>
            }
            </div>
          </div>
          
        </div>
        </>
    )
}

export default ToolsDropdown;