import React from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';

import styles from "./HrLocation.module.scss";


const HrLocationSkeleton: React.FC = () => {

  const renderSkeletonRows = (count: number) => {
    return Array.from({ length: count }, (_, index) => (
      <li key={index}>
        <div className={styles.rowWrapper}>
          <Skeleton width={150} height={18} />
          <div className={styles.value}>
            <Skeleton width={104} height={18} />
          </div>
        </div>
      </li>
    ));
  };

  return (
    <div className={styles.hrLocation}>
      <div className={classNames(styles.titleWrapper, styles.borderBottomSkeleton)}>
        <Skeleton width={278} height={18} />
      </div>
      <div className={styles.hrCssGrid}>
        <div className={styles.leftColumn}>
          <ul id={`left-column-list`} className={styles.columnList}>
            {renderSkeletonRows(4)}
          </ul>
        </div>
        <div className={styles.rightColumn}>
          <ul id={`right-column-list`} className={styles.columnList}>
            {renderSkeletonRows(4)}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HrLocationSkeleton;