export const buildMultiDimensionalArray = <T>(arr: T[], numberOfElementsPerArray: number): T[][] => {
  let k: number = 0;
  return arr.reduce((result, currentValue, i) => {
    if (i !== 0 && i % numberOfElementsPerArray === 0) k++;
    const targetArray: T[] = result[k] || [];
    targetArray.push(currentValue);
    result[k] = targetArray;
    return result;
  }, []);
};

export const randomAlphaNumeric = (length:number) => { // NOSONAR
  let s = '';
  Array.from({ length }).some(() => { // NOSONAR
    s += Math.random().toString(36).slice(2);  // NOSONAR
    return s.length >= length;
  });
  return s.slice(0, length);
};
