import { SpMyTechNotification } from "./SpMyTechNotification";
class AdwMyTechNotification {
  public id: number;
  public Title: string;
  public notificationData: {
    notificationCount: number
};
  public userGuid: string;

  constructor(spMyTechNotification?: SpMyTechNotification) {
    if (spMyTechNotification) {
      this.id = spMyTechNotification.fields.id;
      this.Title = spMyTechNotification.fields.Title;
      this.userGuid = spMyTechNotification.fields.userGuid;
     this.notificationData= this.formatNotificationData(spMyTechNotification.fields.notificationData);
    }
  }
  private formatNotificationData(data){
    return JSON.parse(data);
  }

}

export default AdwMyTechNotification;