import moment from "moment";
import AdwCalendarEventType from "./AdwCalendarEventType";
import { SpCalendarEvent } from "./SpCalendarEvent";
import momenttimezone from 'moment-timezone';
class AdwFcEvent {
  public id: number;
  public description: string;
  public title: string;
  public date: string;
  public eventtype: string;
  public startTimeVar: string;
  public location: string;
  public colorTag: string;
  public eventTime: string;
  public allDay: boolean;
  public start: string;
  public end: string;
  public type: AdwCalendarEventType;
  public endDateTimeICS: string | moment.Moment;
  public timeZone: string;
  public localtz = momenttimezone.tz.guess();

  constructor(spCalendarEvent?: SpCalendarEvent, eventTypeList?:AdwCalendarEventType[]) {
    if (spCalendarEvent) {
      this.type= this.getEventType(spCalendarEvent.fields.EVENT_TYPE_ID,eventTypeList);
      this.id = spCalendarEvent.fields.id;
      this.description = spCalendarEvent.fields.DESCRIPTION;
      this.title = spCalendarEvent.fields.CALENDAR_TITLE;
      this.date = spCalendarEvent.fields.EVENT_DATE;
      this.eventtype = this.type.EventType;
      this.startTimeVar = moment(spCalendarEvent.fields.START_TIME).tz(this.localtz).format();
      this.location = spCalendarEvent.fields.LOCATIONS;
      this.colorTag = this.type.EventColor;
      this.eventTime = moment(spCalendarEvent.fields.EVENT_DATE).tz(this.localtz).format();
      this.allDay=(spCalendarEvent.fields.START_TIME || spCalendarEvent.fields.END_TIME) ? false : true;
      this.start =this.getStartTime(this.allDay,moment(spCalendarEvent.fields.START_TIME).tz(this.localtz).format(),moment(spCalendarEvent.fields.EVENT_DATE).tz(this.localtz).format());
      this.end =this.getEndTime(this.allDay, moment(spCalendarEvent.fields.END_TIME).tz(this.localtz).format(),moment(spCalendarEvent.fields.EVENT_DATE).tz(this.localtz).format());  
      this.endDateTimeICS= this.getEndDateTimeICS(this.date,this.start,this.end,this.allDay);
    }
  }
  private getEventType(eventTypeId,eventTypes){
    if(eventTypeId && eventTypes){
      let eventType = eventTypes.find(( eventtype ) => eventtype.EventTypeId === eventTypeId);
      return eventType;
    }
    return '';
  }

  private getEndDateTimeICS(eventDate,start,end,allDay){
    let endDateTimeICS:string | moment.Moment = moment(eventDate, "YYYY/MM/DD");
   if (allDay) {
     endDateTimeICS = endDateTimeICS.format("YYYY-MM-DD") + " 12:00 AM";
    }else if (start !== eventDate && !allDay) {
       endDateTimeICS = moment(endDateTimeICS.format("YYYY-MM-DD") + " " + end).toISOString(true);
   }
   return endDateTimeICS;
  }

  private getStartTime(allDay,startDate,eventDate){
    if(allDay){
      return new Date(eventDate).toISOString().split("T")[0]
    }else{
      return startDate;
    }
  }
  private getEndTime(allDay,endDate,eventDate){
    if(allDay){
      return new Date(eventDate).toISOString().split("T")[0]
    }else{
      return endDate;
    }
  }
}

export default AdwFcEvent;