import React, { HTMLAttributes, ReactNode, FC } from "react";
import classNames from "classnames";

import Header, { IHeaderProps } from "../Header/Header";

import styles from "./Widget.module.scss";

export interface IContainerProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  children: ReactNode;
}

export interface IWHeaderProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  children: ReactNode;
}

export interface ITitleProps extends IHeaderProps {
  className?: string;
  children: ReactNode;
}

export interface IWidgetSeperatorProps {
  className?: string;
}

interface WidgetProps extends FC<IContainerProps> {
  Header: FC<IWHeaderProps>;
  Title: FC<ITitleProps>;
  Separator: FC<IWidgetSeperatorProps>;
}

const Widget: WidgetProps = ({ className, children, ...props }) => {
  return <section className={classNames(className)} {...props}>
    {children}
  </section>
}

const WidgetHeader: FC<IWHeaderProps> = ({ className, children }) => {
  return <header className={classNames(styles.header, className)}>
    {children}
  </header>
}

const WidgetTitle: FC<ITitleProps> = ({ level = 5, id = null, className, children }) => {
  return <Header level={level} id={id} className={classNames(styles.title, className)}>
    {children}
  </Header>
}

const WidgetSeperator: FC<IWidgetSeperatorProps> = ({ className }) => {
  return <hr className={classNames(styles.seperator, className)} />
}

Widget.Header = WidgetHeader;
Widget.Title = WidgetTitle;
Widget.Separator = WidgetSeperator;

export default Widget;
