import React, { useContext, useEffect } from "react";
import { SetBenefitElections } from "../../../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../../../context/AdwContext";
import AuthService from "../../../../../services/AuthService";
import HRConnectService, { GetBenefitElectionsResponse } from "../../../../../services/HRConnectService";
import { AdwHrConnectSwitch } from "../../../../../types/HRConnectTypes/AdwHrConnectSwitch";
import ShowHide from "../../ShowHide/ShowHide";
import ComingSoon from "../ComingSoon/ComingSoon";
import widgetStyles from "../HRConnectWidgets.module.scss";
import OpenBenefitElection from "../OpenBefintsElection/OpenBenefitElection";
import SelectedBenefits from "../SelectedBenefits/SelectedBenefits";
import styles from "./BenefitElections.module.scss"

let visibilityTimeout;

const BenefitElections: React.FC = () => {

  const [appState, dispatch]: AdwContextType = useContext(AdwContext);
  const [hidden, setHidden] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const [hasDataError, setHasDataError] = React.useState(false);
  const [startDate, setStartDate] = React.useState(undefined);
  const [endDate, setEndDate] = React.useState(undefined);
  const [endTime, setEndTime] = React.useState(undefined);

  const hasActiveSession: boolean = AuthService.hasActiveSession();
  const nwieID: string = (appState && appState.user && appState.user.nwieID);

  const switches: AdwHrConnectSwitch[] = (appState && appState.lists && appState.lists.hrConnectSwitches) || []


  useEffect(() => {
    if (nwieID && switches && switches.length > 0) {
      switches.every((switchOption => {
        if (switchOption.widget === 'BenefitElections' && switchOption.displayWidget && nwieID.match(switchOption.allowRegex)) {
          setIsVisible(true);
          setStartDate(switchOption.startDate);
          setEndDate(switchOption.endDate);
          setEndTime(switchOption.endTime);
          return false;
        }
        return true;
      }))

    }
  }, [nwieID, switches])

  function fetchData(): void {

    const shouldFetch: boolean = true;

    if (shouldFetch && hasActiveSession) {
      setIsLoading(true);
      dispatch(SetBenefitElections(null));
      HRConnectService.getBenefitElections(nwieID)
        .then(be => {
          dispatch(SetBenefitElections(be));
          if (be.hasError) {
            setHasDataError(true)
          }
          setIsLoading(false);
        })
    }
  }


  function toggleVisibility(): void {
    if (hidden) {
      fetchData();
      visibilityTimeout = setTimeout(() => {
        setHidden(true)
      }, 10000)
    } else {
      clearTimeout(visibilityTimeout)
    }
    setHidden(!hidden)
  }

  const getmyBenefitElections = (hidden:boolean,isLoading:boolean,appState:any): GetBenefitElectionsResponse => {
    let benefitElections: GetBenefitElectionsResponse;

    if (hidden || isLoading || !appState || !appState.hrConnect) {
      benefitElections = null;
    } else {
      benefitElections = appState.hrConnect.benefitElections || null;
    }
    return benefitElections;
  }

  let currentDate: Date = new Date();
  let openEnrollmentActive: boolean = (startDate && endDate && currentDate > new Date(startDate) && currentDate < new Date(endDate)) || false;
  const benefitElections: GetBenefitElectionsResponse = getmyBenefitElections(hidden,isLoading,appState); 

  return (
    <div className={widgetStyles.widget}>
      <div className={widgetStyles.header}><h3>Benefit Elections</h3></div>
      {!isVisible || hasDataError ?
        <ComingSoon unavailable={hasDataError} /> : (
          <>
            {openEnrollmentActive ? <OpenBenefitElection endDate={endDate} endTime={endTime} ></OpenBenefitElection>
              :
              <>
                <div className={widgetStyles.benefitElectionTopSection}>
                  <div className={`${widgetStyles.subSectionContents} ${styles.showRow}`}>
                    <span className={`${widgetStyles.subSectionHeader} ${widgetStyles.benefitElectionSubHeader}`}>Current year</span>
                    <ShowHide featureTitle="Benefit Elections" showing={!hidden} toggle={toggleVisibility} className={styles.showHide} ariaLabel="Hide/Show current year benefit elections."/>
                  </div>
                </div>
                <SelectedBenefits benefitElections={benefitElections} ></SelectedBenefits>
              </>
            }

          </>
        )
      }

    </div>
  );
}

export default BenefitElections;