export default class AdwDateTimeService {

  public static monthNames: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  private static dayNames: string[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  private static abbreviatedDayNames: string[] = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ];

  public static getMonthName = (month: number): string => {
    return AdwDateTimeService.monthNames[month];
  };

  public static getDayName = (day: number): string => {
    return AdwDateTimeService.dayNames[day];
  };

  public static getAbbreviatedDay = (day: number): string => {
    return AdwDateTimeService.abbreviatedDayNames[day];
  };
}
