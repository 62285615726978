import React, { useEffect, useState } from "react";
import styles from "./EditPhoneNumberLink.module.scss";
import AdwEmployee from "../../../types/CallupTypes/AdwEmployee";
export interface IContactInfoProps {
    user: string;
    adwEmployee: AdwEmployee;
    openEditPhoneModal:()=>void
  }
  
const EditPhoneNumberLink = ({user,adwEmployee,openEditPhoneModal}) => {
    const [showEditLink,setShowEditLink] = useState<boolean>(false);

    useEffect(()=>{
      if(user === adwEmployee.shortName){
        setShowEditLink(true);
      }
      return ()=>{
        setShowEditLink(false);
      }
    },[user,adwEmployee])

    return(
      <>
      {showEditLink && adwEmployee.employeeType === "Regular" &&
      (
        <div id={`edit-phone-number`}>
          <span
            className={styles.editPhoneLink}
            id={`edit-phonenumber-link`}
            onClick={openEditPhoneModal}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.code === 'Enter') {
                openEditPhoneModal();
              }
            }}
          >
            edit
          </span>
        </div>
    )}
    </>
  )
  };
export default EditPhoneNumberLink;