
import React, { useContext, useState } from "react";
import styles from "../../organisms/HRConnect/HRConnect.module.scss";
import customizeStyles from "../../organisms/HRConnect/Customize/Customize.module.scss";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import { AdwHrConnectSwitch } from "../../../types/HRConnectTypes/AdwHrConnectSwitch";

export interface IHrConnectWidget {
  selectedWidgets: (string)[]; //current widgets
}

const HRConnectWidgetDropdown: React.FC<IHrConnectWidget> = ({ selectedWidgets = []}: IHrConnectWidget) => {
  const [appState]: AdwContextType = useContext(AdwContext);
  const widgetList = [0, 1, 2]; // 3 widgets - use index so it can be used in the loop
  const userPrefWidgets = selectedWidgets;
  const [userSelected, setUserSelected] = useState([]);

  let selectedTitle : string; // widget title
  let description: string;    // widget description
  let descArr = [];           // widget description array - SP mulitples lines and parsed as <li>

  const hrConnectSwitchList: AdwHrConnectSwitch[] =
    appState && appState.lists && appState.lists.hrConnectSwitches
    && appState.lists.hrConnectSwitches.length ? appState.lists.hrConnectSwitches : [];

    return (
      <div className={styles.widgets}>
        
        {
        //loop through widgets 
        widgetList.map((widgetIndex => {
          return(
            <div className={`${styles.widget}`} key={widgetIndex}>  
              <div className={`${customizeStyles.section}`}>
                {/* dropdown */}
                <select className={customizeStyles.hrSwitch}  onChange={ e => refreshSelectOptions(e, selectedWidgets[widgetIndex], widgetIndex) }>
                  <option className={customizeStyles.selectOne} value="0">Select Worklet</option>
                  {
                  hrConnectSwitchList && 
                  hrConnectSwitchList.map((hrSwitch => {
                    
                    let matchSwitch = false;
                    if(hrSwitch.widget !== '' && hrSwitch.position > 0){

                      //check if this is one of the selected widgets so it will grayed out
                      if(selectedWidgets.includes(hrSwitch.widget)){
                        matchSwitch = true;
                      }

                      // get the selected value for this widget
                      if(hrSwitch.widget === selectedWidgets[widgetIndex]){
                        selectedTitle = hrSwitch.title;
                        description = hrSwitch.description;
                        descArr = getDescriptions(description);
                        matchSwitch = true;
                      }           
                      return (                   
                        matchSwitch ?
                          <option value={hrSwitch.widget} key={hrSwitch.widget+widgetIndex} className={customizeStyles.hrSwitchSelected} disabled>{hrSwitch.title}</option> :
                          <option value={hrSwitch.widget} key={hrSwitch.widget+widgetIndex}>{hrSwitch.title}</option>
                      );
                    }
                  }
                  ))
                  }
                </select>
              </div>
              <div className={`${customizeStyles.section}`}>Currently selected: 
                {` ${selectedTitle}`}
              </div>
              <div className={`${customizeStyles.sectionDesc}`}>
                <Description name={descArr} />
              </div>
            </div>
            );
          }))
        }
    </div>
    );

  /* on change event */  
  function refreshSelectOptions(event: React.FormEvent<HTMLSelectElement>, userSelectedValue, position){
    
    /* current user preference */
    let currentSelectedValue = event.currentTarget.value;

    /* when user select 'Select Worklet', it will select the current selected widget for this position */
    if(currentSelectedValue === '0'){
      currentSelectedValue = userPrefWidgets[position];

    /* user selected different widget */
    }else{

      let index = selectedWidgets.indexOf(userSelectedValue);
    
      if (index !== -1) {
        /* Find index of user preference */    
        let objIndex = selectedWidgets.findIndex((obj => obj === userSelectedValue));

        /* Update with current selected value */
        selectedWidgets[objIndex] = currentSelectedValue;
      }

    }

    /* change state */
    setUserSelected([...userSelected, currentSelectedValue]);

    /* set user's new selected widgets */
    appState.user.hrPreferredWidgets = selectedWidgets.join(", ");

  }
};

export default HRConnectWidgetDropdown;

// get widget descriptions from SP list
function getDescriptions(description: string) {
  let descArr = [];
  if((description !== null) && (description !== undefined)){
    // check first if there's a pipe before you loopin\g
    if(description.indexOf('|') > 0){
      // parse the value and pipe as a new element 
      while(description.indexOf('|') > 0){
        let descTemp = description.substring(0, description.indexOf('|') + 1);
        description = description.substring(descTemp.length + 1).trim();        
        descTemp = descTemp.replaceAll('|', '').trim();
        descArr.push(descTemp);
      }
    }
    // first or last element
    if(description.length > 0){
      descArr.push(description.trim());
    }
  }
  return descArr;
}

function DescriptionItem(props) {
  return <li className={customizeStyles.sectionDesc}>{props.value}</li>;
}

function Description(props) {
  const descriptions = props.name;
  return (
    <ul>
      {descriptions.map((desc) =>
        <DescriptionItem key={desc.toString()} value={desc} />
      )}
    </ul>
  );
} 