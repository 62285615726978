import * as React from "react";
import CachingService from "../../../services/CachingService";
import styles from "./ComponentErrorMessage.module.scss";

interface IComponentErrorMessageProps {
  parentClass: string;
  heading?: string;
  bgImageSrc?: string;
}

const ComponentErrorMessage: React.FC<IComponentErrorMessageProps> = ({ parentClass, heading, bgImageSrc }) => {

  const containerStyle: React.CSSProperties = bgImageSrc ? {
    backgroundImage: `url(${bgImageSrc})`,
    backgroundPosition: `center 10%`,
    backgroundRepeat: `no-repeat`,
    backgroundOrigin: `padding-box`
  } : {
      backgroundColor: `transparent`
    }

  return (
    <div className={`${parentClass} ${styles.ErrorDiv}`}>
      {heading && (
        <h4 className={styles.heading}>{heading}</h4>
      )}
      <div
        style={containerStyle}
      >
        <h2 className={!bgImageSrc ? styles.noImageSrcStyle : " "}>Sorry,</h2>
        <p>
          something went wrong and we can’t load this content. Try{" "}
          <span
            onClick={CachingService.clearLocalStorageAndRefreshNoPrompt}
            className={styles.errorLink}
          >
            refreshing your page
          </span>
          , and if that doesn’t work{" "}
          <a
            href="https://nwproduction.service-now.com/csm/"
            className={styles.errorLink}
          >
            contact support
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default ComponentErrorMessage;
