export default class BrowserUtils {

  public static isIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  public static isIE(): boolean {
    return BrowserUtils.getUserBrowser() === BrowserUtils.BrowserTypes.IE;
  }

  public static isEdgeMobile(): boolean {
    return BrowserUtils.getUserBrowser() === BrowserUtils.BrowserTypes.EDGE_MOBILE;
  }

  public static getUserBrowser(): string {
    let userBrowser: string = BrowserUtils.BrowserTypes.UNKNOWN;
    let chromeDetected: boolean = false;
    let iphone: boolean = false;
    let android: boolean = false;
    let edgeMobile: boolean = false;
    let userAgent: string = navigator.userAgent;

    if (userAgent.indexOf("Trident") > 0 || userAgent.indexOf("MSIE") > 0) {
      userBrowser = BrowserUtils.BrowserTypes.IE;
    }

    if (userAgent.indexOf("Chrome") > 0) {
      userBrowser = BrowserUtils.BrowserTypes.CHROME;
      chromeDetected = true;
    }

    if (userAgent.indexOf("EdgiOS") > 0 || userAgent.indexOf("EdgA") > 0) {
      userBrowser = BrowserUtils.BrowserTypes.EDGE_MOBILE;
      edgeMobile = true;
    }

    if (userAgent.indexOf("iPhone") > 0) {
      userBrowser = BrowserUtils.BrowserTypes.IPHONE;
      iphone = true;
    }

    if (userAgent.indexOf("Android") > 0) {
      userBrowser = BrowserUtils.BrowserTypes.ANDROID;
      android = true;
    }

    if (userAgent.indexOf("Safari") > 0 && !chromeDetected && !iphone && !android && !edgeMobile) {
      userBrowser = BrowserUtils.BrowserTypes.SAFARI;
    }

    if (userAgent.indexOf("Firefox") > 0) {
      userBrowser = BrowserUtils.BrowserTypes.FIREFOX;
    }
    return userBrowser;
  }

  public static BrowserTypes = {
    IE: "internet_explorer",
    CHROME: "chrome",
    IPHONE: "iphone",
    ANDROID: "android",
    SAFARI: "safari",
    FIREFOX: "firefox",
    EDGE_MOBILE: "edge_mobile",
    UNKNOWN: "unknown",
  };
}
