import React from 'react';
import Skeleton from "react-loading-skeleton";

import styles from "./CircleButton.module.scss";

const CircleButtonSkeleton: React.FC = () => {
  return (
    <div className={styles.button}>
      <div className={styles.noBorderButton}>
        <Skeleton circle={true} height={40} width={40} />
        <Skeleton width={127} height={14} />
      </div>
    </div>
  );
};

export default CircleButtonSkeleton;