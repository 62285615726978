import React from "react";
import ArrowButton, { Direction } from "../../atoms/ArrowButton/ArrowButton";
import { isSubcategoryNull } from "../MegaMenuCategory/MegaMenuCategories";
import styles from "./MegaMenuButton.module.scss";
import { MegaMenuCategory } from "../../../types/MegaMenuTypes/MegaMenuCategory";

export interface IMegaMenuButtonProps {
  category: MegaMenuCategory;
  buttonClasses: string;
  buttonData: number;
  handleButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyPress: (e:React.KeyboardEvent<HTMLButtonElement>) => void;
  menuIndex:number
}

const MegaMenuButton: React.FC<IMegaMenuButtonProps> = (props: IMegaMenuButtonProps) => {
  const { buttonClasses, buttonData, category, onKeyPress, handleButtonClick, 
     menuIndex} = props;
  const classes: string = `${styles.megaMenuButton } ${buttonClasses}`;

    return (
      <button 
      {...category.categoryTitle !== 'Home' && {'aria-expanded':"false"}}
      {...!isSubcategoryNull(category) && {'aria-controls': `menuDropDown-${menuIndex}`}}
      aria-haspopup={category.categoryTitle !== 'Home'}
        className={classes}
        onClick={handleButtonClick}
        data-mega-button={buttonData}
        onKeyDown={onKeyPress}
        id={`mm-megaMenuButton-${buttonData - 1}`}
      >
       <span id={`mm-megaMenuButton-labelValue-${buttonData - 1}`}>{category.categoryTitle}</span> 
        {!isSubcategoryNull(category) && (
         <ArrowButton buttonClasses={styles.megaMenuChevron} chevronClasses={styles.megaMenuButtonArrow} arrowDirection={Direction.DOWN} /> 
        )}
      </button>

    );
}

export default MegaMenuButton;
