import moment from "moment";
import DateFormat from "./DateFormat";

export default class DateUtils {
  /**
   * Given a JavaScript Date object, return a formatted date in the specified
   * locale using the format specified.
   */
  public static formatDate(date: Date, format: DateFormat = DateFormat.ISO_8601, locale: string = "en"): string {
    const m = moment(date).locale(locale);
    switch (format) {
      case DateFormat.SHORT_DATE:
        return m.format("L");
      case DateFormat.MEDIUM_DATE:
        return m.format("ll");
      case DateFormat.LONG_DATE:
        return m.format("dddd, LL");
      case DateFormat.SHORT_TIME:
        return m.format("LT");
      case DateFormat.LONG_TIME:
        return m.format("LTS");
      case DateFormat.SHORT_MONTH:
        return m.format("MMM. YYYY");
      case DateFormat.LONG_MONTH:
        return m.format("MMMM YYYY");
      case DateFormat.SHORT_YEAR:
        return m.format("\u2019YY");
      case DateFormat.LONG_YEAR:
        return m.format("YYYY");
      case DateFormat.SHORT:
        return m.format("L LT");
      case DateFormat.MEDIUM:
        return m.format("lll");
      case DateFormat.LONG:
        return m.format("LLLL");
      case DateFormat.DAY_OF_MONTH:
        return m.format("MMMM D");
      case DateFormat.HOUR:
        return m.format("h:00A");
      case DateFormat.AGO:
        return m.fromNow();
      case DateFormat.IN:
        return m.toNow();
      case DateFormat.ISO_8601:
      default:
        return m.format();
    }
  }

  /**
   * Given a JavaScript Date object, return a formatted date in the specified
   * locale using the moment.js format string specified.
   */
  public static formatDateCustom(date: Date, formatString: string, locale: string = "en"): string {
    const m = moment(date).locale(locale);
    return m.format(formatString);
  }

  /**
   * Given a string, return a Date object created by parsing it.
   */
  public static stringToDate(dateString: string): Date {
    return moment(dateString).toDate();
  }

  /**
   * Given a string, return a formatted date in the specified locale using
   * the format specified.
   */
  public static formatDateString(dateString: string | null, format: DateFormat, locale: string = "en"): string {
    if (dateString) {
      const date = DateUtils.stringToDate(dateString);
      return DateUtils.formatDate(date, format, locale);
    }
    return "";
  }
}
