import React from "react";
import DirectReport from "../DirectReport/DirectReport";
import { GraphAvatarData } from "../../../types/GraphTypes/GraphUser";

export interface IOrgWorkGroupProps {
  workGroup: GraphAvatarData[];
  showOrgChart: boolean;
}


const OrgWorkGroup = ({workGroup,showOrgChart}) => {
  return( workGroup && workGroup.map((avatarUser: GraphAvatarData, index: number) => (
    avatarUser &&
      <li id={`employee-${index}`} key={avatarUser.id}>
        <DirectReport avatarData={avatarUser} showOrgChart={showOrgChart} />
      </li>
    
  ))
  )
};


export default OrgWorkGroup;
