import { SpFeatureSwitch } from "./SpFeatureSwitch";

export class AdwFeatureSwitch {
  public widget: string;
  public allowRegex: string;
  public position: number;
  public displayWidget: boolean;
  public feature: string;
  public description: string;
  public page: string;
  public title: string;
  public featureSettings: any;

  constructor(spSwitch: SpFeatureSwitch) {
    this.widget = spSwitch.fields.Widget;
    this.allowRegex = spSwitch.fields.AllowRegex;
    this.position = spSwitch.fields.Position;
    this.displayWidget = spSwitch.fields.DisplayWidget;
    this.feature = spSwitch.fields.Feature;
    this.description = spSwitch.fields.Description;
    this.page = spSwitch.fields.Page;
    this.title = spSwitch.fields.Title;
    this.featureSettings = this.formatFeatureSettingsData(spSwitch.fields.FeatureSettings);
  }
  private formatFeatureSettingsData(data){
    if(data){
      return JSON.parse(data);
    }else{
      return null;
    }
  }
}