import { SpHrConnectLink } from "./SpHrConnectLink";

class AdwHrConnectLink {
  public Title: string;
  public LinkOrder: number;
  public ImagePath: string;
  public Url: string;
  public WindowTarget: string;

  constructor(spHrConnectLink?: SpHrConnectLink) {
    if (spHrConnectLink) {
      this.Title = spHrConnectLink.fields.Title;
      this.LinkOrder = spHrConnectLink.fields.LinkOrder;
      this.ImagePath = spHrConnectLink.fields.ImagePath;
      this.Url = spHrConnectLink.fields.Url;
      this.WindowTarget = spHrConnectLink.fields.WindowTarget;
    }
  }
}

export default AdwHrConnectLink;