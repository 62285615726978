import React from "react";
import styles from "./Image.module.scss";

export interface IImageProps {
  id: string;
  src: string;
  alt: string;
  imageClasses?: string;
  onMouseOver?: any;
  onMouseOut?: any;
  onClick?: () => void;
}

const Image: React.FC<IImageProps> = ({ imageClasses, ...otherProps }) => {
  const imageStyles: string = imageClasses ? `${styles.image} ${imageClasses}` : styles.image;
  return <img {...otherProps} className={imageStyles} alt=''/>;
};

export default Image;
