import { InsideUserData } from "../types/InsideUserData";
import { DispatchAction } from "./AdwContextTypes";
import { GraphAvatarData, GraphUser } from "../types/GraphTypes/GraphUser";
import { GetBenefitElectionsResponse, GetMyHealthProgramResponse } from "../services/HRConnectService";

/** Types for actions that can be taken against global app state */
export default class ActionTypes {
  /** SEARCH */
  public static SEARCH_DO_SEARCH = "SEARCH_DO_SEARCH";
  public static SEARCH_DO_PEOPLE_SUGGESTIONS_SEARCH = "SEARCH_DO_PEOPLE_SUGGESTIONS_SEARCH";
  public static SEARCH_UPDATE_QUERY = "SEARCH_UPDATE_QUERY";
  public static SEARCH_ADD_OR_UPDATE_FILTERS = "SEARCH_ADD_OR_UPDATE_FILTERS";
  public static SEARCH_DELETE_FILTERS = "SEARCH_DELETE_FILTERS";
  public static SEARCH_UPDATE_SORT = "SEARCH_UPDATE_SORT";
  public static SEARCH_UPDATE_RESPONSE = "SEARCH_UPDATE_RESULT";
  public static SEARCH_UPDATE_PEOPLE_SUGGESTIONS = "SEARCH_UPDATE_PEOPLE_SUGGESTIONS";
  public static SEARCH_UPDATE_PEOPLE_RESPONSE = "SEARCH_UPDATE_PEOPLE_RESULT";
  public static SEARCH_UPDATE_ERROR_MESSAGE = "SEARCH_UPDATE_ERROR_MESSAGE";
  public static SEARCH_UPDATE_SUGGESTIONS_ERROR_MESSAGE = "SEARCH_UPDATE_SUGGESTIONS_ERROR_MESSAGE";
  public static SEARCH_UPDATE_CURRENTLY_SEARCHING = "SEARCH_UPDATE_CURRENTLY_SEARCHING";
  public static SEARCH_UPDATE_RESULTS_RANGE = "SEARCH_UPDATE_RESULTS_RANGE";
  public static SEARCH_UPDATE_LAST_SEARCH_TERM = "SEARCH_UPDATE_LAST_SEARCH_TERM";
  public static SEARCH_UPDATE_FLAG_MODAL = "SEARCH_UPDATE_FLAG_MODAL";

  /** INSIDE */
  /** Set the user preferences */
  public static SET_USER_PREFERENCES = "SET_USER_PREFERENCES";
  public static SET_IS_ADMIN = "SET_IS_ADMIN";

  /** SHAREPOINT */
  public static SET_GRAPH_USER = "SET_GRAPH_USER";
  public static SET_USER_DETAILS_NWIE = "SET_USER_DETAILS_NWIE";
  public static SET_SP_LIST_CONTENTS = "SET_SP_LIST_CONTENTS";

  /** HRConnect */
  public static SET_HR_TIME_OFF_CURRENT = "SET_HR_TIME_OFF_CURRENT";
  public static SET_HR_TIME_OFF_CUMULATIVE = "SET_HR_TIME_OFF_CUMULATIVE";
  public static SET_NET_PAY = "SET_NET_PAY";
  public static SET_NEXT_PAY_DAY = "SET_NEXT_PAY_DAY"
  public static SET_HR_OPEN_JOBS = "SET_HR_OPEN_JOBS";
  public static SET_HR_ACTIVE_APPS = "SET_HR_ACTIVE_APPS";
  public static SET_HR_FOW_TOTAL = "SET_HR_FOW_TOTAL";
  public static SET_HR_FOW_DIGITAL = "SET_HR_FOW_DIGITAL";
  public static SET_HR_FOW_LIA = "SET_HR_FOW_LIA";
  public static SET_BENEFIT_ELECTIONS = "SET_BENEFIT_ELECTIONS";
  public static SET_HR_RETIREMENT_EMPLOYEEPCT = "SET_HR_RETIREMENT_EMPLOYEEPCT";
  public static SET_HR_AWS_NURSE = "SET_HR_AWS_NURSE";
  public static SET_HR_AWS_COUNSELOR = "SET_HR_AWS_COUNSELOR";
  public static SET_MY_HEALTH_PROGRAM = "SET_MY_HEALTH_PROGRAM";

  /** Inside Org Chart */
  public static SET_USER_ORG_CHART = "SET_USER_ORG_CHART";
  public static SET_AVATAR_ORG_CHART = "SET_AVATAR_ORG_CHART";
}

export function UpdateViewUserDetailNwie(nwie: string): DispatchAction {
  return {
    type: ActionTypes.SET_USER_DETAILS_NWIE,
    nwie,
  };
}


export function UpdateViewUserOrgChart(viewUserOrgChart: string): DispatchAction {
  return {
    type: ActionTypes.SET_USER_ORG_CHART,
    viewUserOrgChart,
  };
}

export function UpdateViewAvatarOrgChart(viewAvatarOrgChart: GraphAvatarData): DispatchAction {
  return {
    type: ActionTypes.SET_AVATAR_ORG_CHART,
    viewAvatarOrgChart,
  };
}

/** Returns a DispatchAction for setting the search query */
export function UpdateSearchQuery(query: string, searchPage: boolean): DispatchAction {
  return {
    type: ActionTypes.SEARCH_UPDATE_QUERY,
    query,
    searchPage
  };
}


export function UpdateFlagModal(flagModalIndex: number): DispatchAction {
  return {
    type: ActionTypes.SEARCH_UPDATE_FLAG_MODAL,
    flagModalIndex
  }
}

/** Returns a DispatchAction for setting the user preferences */
export function SetUserPreferences(user: InsideUserData, initialLoad: boolean = false): DispatchAction {
  return {
    type: ActionTypes.SET_USER_PREFERENCES,
    user,
    initialLoad,
  };
}

/** Returns a DispatchAction for setting the users admin status */
export function SetIsAdmin(isAdmin: boolean): DispatchAction {
  return {
    type: ActionTypes.SET_IS_ADMIN,
    isAdmin
  };
}

/** Returns a DispatchAction for setting the sp user  */
export function SetGraphUser(graphUser: GraphUser): DispatchAction {
  return {
    type: ActionTypes.SET_GRAPH_USER,
    graphUser
  };
}

export function SetSpListContents(list: string, contents: any[]): DispatchAction {
  return {
    type: ActionTypes.SET_SP_LIST_CONTENTS,
    list,
    contents
  }
}

export function SetHrTimeOffCurrent(hours: number, days: number): DispatchAction {
  return {
    type: ActionTypes.SET_HR_TIME_OFF_CURRENT,
    hours,
    days
  }
}

export function SetHrTimeOffCumulative(hours, days): DispatchAction {
  return {
    type: ActionTypes.SET_HR_TIME_OFF_CUMULATIVE,
    hours,
    days
  }
}

export function SetNetPay(netPay): DispatchAction {
  return {
    type: ActionTypes.SET_NET_PAY,
    netPay
  }
}

export function SetNextPaydays(nextPayDay): DispatchAction {
  return {
    type: ActionTypes.SET_NEXT_PAY_DAY,
    nextPayDay
  }
}

export function SetHrOpenJobs(jobs: number): DispatchAction {
  return {
    type: ActionTypes.SET_HR_OPEN_JOBS,
    jobs
  }
}

export function SetHrActiveApps(apps: number): DispatchAction {
  return {
    type: ActionTypes.SET_HR_ACTIVE_APPS,
    apps
  }
}

export function SetHrFowTotal(total: number): DispatchAction {
  return {
    type: ActionTypes.SET_HR_FOW_TOTAL,
    total
  }
}

export function SetHrFowDigital(digital: number): DispatchAction {
  return {
    type: ActionTypes.SET_HR_FOW_DIGITAL,
    digital
  }
}

export function SetHrFowLia(lia: number): DispatchAction {
  return {
    type: ActionTypes.SET_HR_FOW_LIA,
    lia
  }
}


export function SetBenefitElections(benefitElections: GetBenefitElectionsResponse): DispatchAction {
  return {
    type: ActionTypes.SET_BENEFIT_ELECTIONS,
    benefitElections
  }
}

export function SetHrRetirementEmployeePct(employeePct: string): DispatchAction {
  return {
    type: ActionTypes.SET_HR_RETIREMENT_EMPLOYEEPCT,
    employeePct
  }
}

export function SetHrAwsCounselor(counselorName: string, counselorPhone: string): DispatchAction {
  return {
    type: ActionTypes.SET_HR_AWS_COUNSELOR,
    counselorName,
    counselorPhone
  }
}

export function SetHrAwsNurse(nurseName: string, nursePhone: string, nurseEmail: string): DispatchAction {
  return {
    type: ActionTypes.SET_HR_AWS_NURSE,
    nurseName,
    nursePhone,
    nurseEmail
  }
}

export function SetMyHealthProgram(myHealthProgram: GetMyHealthProgramResponse): DispatchAction {
  return {
    type: ActionTypes.SET_MY_HEALTH_PROGRAM,
    myHealthProgram
  }
}
