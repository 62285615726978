import React from "react";
import styles from "./NoResults.module.scss";

export interface INoResultsProps {
  textForDisplay: string;
  imagePath: string;
  noResultsClasses?: string;
  id: string;
}

const NoResults: React.FC<INoResultsProps> = ({ textForDisplay, imagePath, noResultsClasses }) => {
  const containerBG: React.CSSProperties = {
    backgroundImage: `url(${imagePath})`,
    backgroundPosition: `center 10%`,
    backgroundRepeat: `no-repeat`,
    backgroundOrigin: `padding-box`,
  };

  if (noResultsClasses) {
    noResultsClasses = `${styles.noResults} ${noResultsClasses}`;
  } else {
    noResultsClasses = styles.noResults;
  }


  return (
    <div className={noResultsClasses} style={containerBG}>
      <p>{textForDisplay}</p>
    </div>
  );
};

export default NoResults;
