import React from "react";
import styles from "./FavoritesStar.module.scss";

//displays table with alternating white/grey rows with the appropriate site/description 
const FavoritesStar = ({ linkId, isFavorite, setIsFavorite }) => {

  const yellowStar: string = require("../../../assets/images/Star-yellow.png");
  const grayStar: string = require("../../../assets/images/Star-gray.png");

  const starImage = isFavorite ? yellowStar : grayStar;
  const altText = isFavorite ? "added to favorites is on." : "add to from favorites is off.";

  return (
    <input
      className={styles.star}
      type="image"
      src={starImage}
      name="saveForm"
      id="saveForm"
      alt={altText}
      onClick={() => setIsFavorite(!isFavorite, linkId)}
    />
  )
}

export default FavoritesStar;