import React, { useEffect, useRef, useState } from "react";
import AdwCommonTool from "../../../types/CommonToolsTypes/AdwCommonTool";
import styles from "../../../App.module.scss";
import { randomAlphaNumeric } from "../../../utils/ArrayUtils";
import { ReactComponent as DropdownIcon } from "../../../assets/images/Dropdown-arrow-icon.svg";


interface ICustomizeMyToolsItemProps {
  activeDropdown: number;
  positionIndex: number
  onNewToolSelected: any
  tool: AdwCommonTool;
  onDropDownOpen: any;
  expanded:boolean;
  allAvailableTools: AdwCommonTool[];
}

const CustomizeMyToolsItem: React.FC<ICustomizeMyToolsItemProps> = ({ onNewToolSelected, tool, positionIndex, onDropDownOpen, activeDropdown , expanded ,allAvailableTools}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [currentTool, setCurrentTool] = useState(tool);
  const buttonRef = useRef(null);
  
  useEffect(() => {
    if (activeDropdown == positionIndex) {
      setIsDropdownOpen(true);
    }else{
      setIsDropdownOpen(false);
    }
    return()=>setIsDropdownOpen(false)
  }, [activeDropdown])

  useEffect(() => {
    if (isDropdownOpen) {
        buttonRef.current.focus();
    }
}, [isDropdownOpen]);

  const onToolChanged = (selectedTool) => {
    if (selectedTool.ID != currentTool.ID) {
      setCurrentTool(selectedTool);
      onNewToolSelected(selectedTool, positionIndex);
    }
    setIsDropdownOpen(false);
  }

  const onToolButtonClicked = () => {
    setIsDropdownOpen(prevState => !prevState);
    if(!isDropdownOpen && activeDropdown != positionIndex){
      onDropDownOpen(positionIndex);
    }
  }

  return (
    <li className={styles.customize_tools_item}
    onMouseLeave={()=>setIsDropdownOpen(false)}
    >
      <button onClick={onToolButtonClicked} 
        ref={buttonRef}
        id={`customize_tools_item_button_${positionIndex}`}
        aria-controls={`customize_tools_dropdown_${positionIndex}`}
        aria-expanded={isDropdownOpen}
        aria-label={currentTool.Title}
        aria-haspopup="true"
        className={styles.customize_tools_item_button}>
        <span className={styles.button_label}>
          {isDropdownOpen ? 'Select tool' : currentTool.Title}
        </span>
        <div className={styles.tools_dropdown_icon_wrap}>
          <DropdownIcon className={styles.tools_dropdown_icon} />
        </div>
      </button>
      <ul id={`customize_tools_dropdown_${positionIndex}`} style={{ display: isDropdownOpen ? 'block' : 'none' }}
        aria-labelledby={`customize_tools_item_button_${positionIndex}`}
        className={styles.customize_tools_dropdown}>
        {allAvailableTools && allAvailableTools.map((availableTool, index) => (
          <li key={randomAlphaNumeric(index+1)} className={styles.customize_tools_dropdown_option} >
            <button onClick={() => onToolChanged(availableTool)}
              style={{ fontWeight: currentTool.ID === availableTool.ID ? '700' : '400' }}
              className={styles.customize_tools_dropdown_option_button}>
              {availableTool.Title}
            </button>
          </li>
        ))}
      </ul>
    </li>
  )
}
export default CustomizeMyToolsItem;
