import React, { useContext, useEffect, useState } from "react";
import { AdwContext } from "../../../context/AdwContext";
import CachingService from "../../../services/CachingService";
import Link from "../../atoms/Link/Link";
import GoToLink from "../../molecules/GoToLink/GoToLink";
import NoResults from "../../molecules/NoResults/NoResults";
import styles from "./AtoZ.module.scss";
import AdwAtoZView from "../../../types/AtoZViewTypes/AdwAtoZView";

export interface IAtoZProps {
  aToZGrid: string;
}

//lets get AtoZlist here and construct fav and recently visited and do same in AtoZ page so we can share data accross
const AtoZWidget: React.FC<IAtoZProps> = ({ aToZGrid }) => {
  const [appState] = useContext(AdwContext);
  const [sitesAToZFavorites, setSitesAToZFavorites] = useState([]);

  let aToZLinks: AdwAtoZView[] =
    (appState && appState.lists && appState.lists.aToZView) || [];
  let favSite: string =
    (appState && appState.user && appState.user.sitesAToZFavorites) || "";

  useEffect(() => {
    let sites = AdwAtoZView.getFavoriteSite(favSite, aToZLinks);
    setSitesAToZFavorites(sites);
  }, [favSite, aToZLinks]);

  const showResultsOrMessage = (siteList: AdwAtoZView[]): JSX.Element | JSX.Element[] => {
    return (
      <>
        {siteList && siteList.length > 0 ? (
          <ul className={`${styles.favoriteList} `} key="favorites-list">
            {siteList.map(
              (link, index) =>
                link && (
                  <li
                    id={`favorite-site-${index}`}
                    key={`favorite-site-${link.AtoZId}`}
                  >
                    <Link
                      linkText={link.SiteName ? link.SiteName : ""}
                      url={link.Url}
                      target={link.Target}
                      linkClasses={styles.favLinkStyle}
                      id={`sites-a-z-link-${index}`}
                    >
                      {link.SiteName}
                    </Link>
                  </li>
                )
            )}
          </ul>
        ) : (
          <NoResults
            imagePath={require("../../../assets/images/star.png")}
            textForDisplay="You haven’t favorited any items in A-Z yet. Visit A-Z and try clicking the star next to some of your favorites!"
            noResultsClasses={styles.noResultStyles}
            id="AtoZ-NoResults"
          />
        )}
      </>
    );
  };

  return (
    <div className={`${aToZGrid} ${styles.aToZ}`} id="AtoZ-Wrapper">
      <h4 className={styles.heading}>
        <Link
          linkClasses={styles.headingLink}
          linkText="A - Z Favorites"
          url={`/atoz`}
          id="AtoZ-Title"
        />
      </h4>
      <div className={styles.favoriteLists} id="favorites-lists">
        {showResultsOrMessage(sitesAToZFavorites)}
      </div>
      <div className={styles.aTozBorder} id="AtoZ-Border" />
      <div
        className={styles.visitAtoZlink}
        onClick={() =>
          CachingService.removeValueFromCache("AdwUserPreferences")
        }
      >
        <GoToLink
          linkText="Visit A - Z"
          target={"_blank"}
          id="AtoZ-visit-link"
          url={`/atoz`}
        />
      </div>
    </div>
  );
};

export default AtoZWidget;
