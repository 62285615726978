import React, { useContext, useEffect } from "react";
import { SetHrRetirementEmployeePct } from "../../../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../../../context/AdwContext";
import AuthService from "../../../../../services/AuthService";
import HRConnectService from "../../../../../services/HRConnectService";
import { AdwHrConnectSwitch } from "../../../../../types/HRConnectTypes/AdwHrConnectSwitch";
import ShowHide from "../../ShowHide/ShowHide";
import ComingSoon from "../ComingSoon/ComingSoon";
import widgetStyles from "../HRConnectWidgets.module.scss";
import styles from "./RetirementSavingsElections.module.scss"

let visibilityTimeout;

const RetirementSavingsElections: React.FC = () => {

  const [appState, dispatch]: AdwContextType = useContext(AdwContext);
  const [hidden, setHidden] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const [hasDataError, setHasDataError] = React.useState(false);

  const hasActiveSession: boolean = AuthService.hasActiveSession();
  const nwieID: string = (appState && appState.user && appState.user.nwieID);

  const switches: AdwHrConnectSwitch[] = (appState && appState.lists && appState.lists.hrConnectSwitches) || []

  useEffect(() => {
    if (nwieID && switches && switches.length > 0) {
      switches.every((switchOption => {
        if (switchOption.widget === 'RetirementSavingsElections' && nwieID.match(switchOption.allowRegex)) {
          setIsVisible(true);
          return false;
        }
        return true;
      }))
    }
  }, [nwieID, switches])

  function fetchData(): void {
    const shouldFetch: boolean = appState && appState.hrConnect && appState.hrConnect.retirementSavingsElections && appState.hrConnect.retirementSavingsElections.employeePct === null;
    if (shouldFetch && hasActiveSession) {
      setIsLoading(true);
      // Set to 0 to make shouldFetch return false above and prevent future calls to API
      dispatch(SetHrRetirementEmployeePct("0%"));
      //Retrieve both sets of data before displaying results
      HRConnectService.getRetirementSavingsElections(appState.user.nwieID)
        .then(({ retirementSavingsElections, hasError }) => {
          if (hasError) {
            setHasDataError(true)
          }else{
            // here we evaluate our plans
            if(retirementSavingsElections.retirementPlanID.toUpperCase() === '40401K'){
              dispatch(SetHrRetirementEmployeePct(retirementSavingsElections.employeePct));
            }
          }
        }).then(() => {
          setIsLoading(false)
        })
    }
  }

  function toggleVisibility(): void {

    if (hidden) {
      fetchData();
      visibilityTimeout = setTimeout(() => {
        setHidden(true)
      }, 10000)
    } else {
      clearTimeout(visibilityTimeout)
    }
    setHidden(!hidden)
  }
  const getEmployeePercent = () => {
    let employeePercent:string;
    if (hidden) {
      employeePercent = "XXX";
    } else if (isLoading) {
      employeePercent = "--";
    } else if (appState && appState.hrConnect) {
      const pct = appState.hrConnect.retirementSavingsElections.employeePct;
      employeePercent = pct ? pct + "%" : "0%";
    } else {
      employeePercent = "0%";
    }
    return employeePercent;
  }

  const employeePct: string = getEmployeePercent();

  return (
    <div className={widgetStyles.widget}>
      <div className={widgetStyles.header}><h3>Nationwide Savings Plan</h3></div>
      {!isVisible || hasDataError ?
        <ComingSoon unavailable={hasDataError} />
        :
        <>
          <div className={widgetStyles.section}>
            <div className={widgetStyles.subSectionHeader}>Pre-tax contribution</div>
            <div className={`${widgetStyles.subSectionContents} ${styles.showRow}`}>
              <span className={hidden ? widgetStyles.obfuscate : "0"}>{employeePct}</span>
              <ShowHide featureTitle="Nationwide Savings Plan" showing={!hidden} toggle={toggleVisibility} ariaLabel="Hide/Show pre-tax contribution percentage."/>
            </div>
          </div>
          <div className={widgetStyles.divider} />
          <div className={widgetStyles.section}>
            <div className={widgetStyles.subSectionHeader}>Employer Matching Contribution</div>
            <div className={widgetStyles.subSectionContents}>50% on contributions up to 8%</div>
          </div>
        </>
      }
      <div className={widgetStyles.buttonRow}>
        <a
          href="https://nb.fidelity.com/public/nb/nationwide/home"
          target="_blank"
          rel="noopener noreferrer"
          className={`${widgetStyles.buttonLink}`}
        >
          Go to Fidelity
        </a>
      </div>
    </div>
  );
}

export default RetirementSavingsElections;