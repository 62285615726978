import React, { useState, useEffect, useContext } from "react";
import AdwEmployee from "../../../types/CallupTypes/AdwEmployee";
import { AboutMeType } from "../../../types/AboutMeTypes/AboutMeType";
import styles from "./AboutMe.module.scss";
import GraphService from "../../../services/GraphService";
import AdwDateTimeService from "../../../services/AdwDateTimeService";
import { AdwContextType, AdwContext } from "../../../context/AdwContext";
import { EditModalType } from "../../../types/EditModalType/EditModalType";

export interface IAboutMeProps {
  adwEmployee: AdwEmployee;
  userPersonalizedEmail: any;
  mobileEditModal: any;
  setMobileEditModal: any;
}

const AboutMeEditModalData: EditModalType = {
  url: "https://nam.delve.office.com/",
  title: "How to update your about me details",
  contents: (
    <>
      <p>
        InSide pulls your about me data from your Office 365 profile. To update
        your info, visit your O365 profile or follow these steps:
      </p>
      <ul>
        <li>Tap your profile image in the top right</li>
        <li>Select “My Office profile”</li>
        <li>On the following page, tap "Update profile"</li>
      </ul>
      <p>From your profile page, you can update your:</p>
      <ul>
        <li>About me</li>
        <li>Birthday</li>
        <li>Projects</li>
        <li>Schools and education</li>
        <li>Skills and expertise</li>
        <li>Interests and hobbies</li>
      </ul>
    </>
  ),
  subheading: null,
  subcontents: null,
};

const AboutMe: React.FC<IAboutMeProps> = (props) => { // NOSONAR
  const [birthdayText, setBirthdayText] = useState<string>("");
  const [usersOwnPage, setUsersOwnPage] = useState<boolean>(false);
  const [showZeroState, setShowZeroState] = useState<boolean>(true);
  const [noAboutMeDataText, setNoDataText] = useState<string>("");
  const [aboutData, setAboutData] = useState<AboutMeType>({
    aboutMe: null,
    birthday: null,
    interests: null,
    pastProjects: null,
    schools: null,
    skills: null,
  });
  const [editModalData] = useState<EditModalType>(AboutMeEditModalData);
  const [appState]: AdwContextType = useContext(AdwContext);
  const [numLowerSectionItems, updateLowerNum] = useState<number>(0);
  const [lowerSectionColumnStyle, updateLowerColumnCount] = useState<string>(
    numLowerSectionItems > 3 && window.innerWidth >= 1024
      ? styles.twoColumn
      : styles.oneColumn
  );

  // Style was not switching on resize until this was added
  useEffect(() => {
    const handleResize = () => {
      numLowerSectionItems > 3 && window.innerWidth >= 1024
        ? updateLowerColumnCount(styles.twoColumn)
        : updateLowerColumnCount(styles.oneColumn);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    numLowerSectionItems > 3 && window.innerWidth >= 1024
      ? updateLowerColumnCount(styles.twoColumn)
      : updateLowerColumnCount(styles.oneColumn);
  }, [numLowerSectionItems]);

  useEffect(() => {
    if (props.userPersonalizedEmail && props.userPersonalizedEmail.length > 1) {
      updateLowerNum(0);
      GraphService.getAboutMeData(
        props.userPersonalizedEmail,
        false
      ).then((data) => setAboutData({ ...data }));
    }
  }, [props.userPersonalizedEmail]);

  useEffect(() => {
    if (appState.user && appState.user.nwieID &&
      props.adwEmployee && props.adwEmployee.shortName && 
      props.adwEmployee.shortName.toLowerCase() ===
      appState.user.nwieID.toLowerCase()
    ) {
      setUsersOwnPage(true);
    } else {
      setUsersOwnPage(false);
      props.mobileEditModal(false);
    }
  }, [appState.user, props]);

  useEffect(() => {
    if (usersOwnPage) {
      setNoDataText(
        "You haven’t added any details to your Office 365 profile. To get started, select edit."
      );
    } else {
      if (props.adwEmployee) {
        if (props.adwEmployee.prefFirstName) {
          setNoDataText(
            `${props.adwEmployee.prefFirstName} hasn’t added any details to their profile. They can update their about me details by visiting their profile on InSide and selecting edit.`
          );
        } else {
          setNoDataText(
            `${props.adwEmployee.firstName} hasn’t added any details to their profile. They can update their about me details by visiting their profile on InSide and selecting edit.`
          );
        }
      }
    }
  }, [usersOwnPage, props.adwEmployee]);

  // Takes birthday and converts it to viewable text
  useEffect(() => {
    if (aboutData.birthday) {
      const d = new Date(aboutData.birthday);
      const mo = AdwDateTimeService.getMonthName(d.getUTCMonth());
      const da = d.getUTCDate();
      /*
       * Users who have updated their birthday have the birth year of 2000.
       * Users who have not updated their birthday have a birth year of 0001.
       * I do not know why Microsoft chose to do it this way, but they did.
       */
      if (d.getUTCFullYear() === 2000) {
        setBirthdayText(`${mo} ${da}`);
      } else {
        setBirthdayText("");
      }
    } else {
      setBirthdayText("");
    }
  }, [aboutData.birthday]);

  useEffect(() => { // NOSONAR
    let counter: number = 0;
    if (aboutData) {
      if (
        (aboutData.aboutMe && aboutData.aboutMe.length > 0) ||
        birthdayText.length > 0 ||
        (aboutData.pastProjects && aboutData.pastProjects.length > 0) ||
        (aboutData.schools && aboutData.schools.length) > 0 ||
        (aboutData.interests && aboutData.interests.length) > 0 ||
        (aboutData.skills && aboutData.skills.length) > 0
      ) {
        setShowZeroState(false);
        if (aboutData.pastProjects && aboutData.pastProjects.length > 0) {
          counter++;
        }
        if (birthdayText.length > 0) {
          counter++;
        }
        if (aboutData.schools && aboutData.schools.length > 0) {
          counter++;
        }
        if (aboutData.interests && aboutData.interests.length > 0) {
          counter++;
        }
        if (aboutData.skills && aboutData.skills.length > 0) {
          counter++;
        }
        updateLowerNum(counter);
      } else {
        setShowZeroState(true);
      }
    } else {
      setShowZeroState(true);
    }
  }, [aboutData, birthdayText]);

  const hideEditModal = () => {
    props.mobileEditModal(false);
  };

  const toggleShowEditModal = () => {
    props.mobileEditModal(true);
    props.setMobileEditModal(editModalData, hideEditModal);
  };

  return (
    <>
      <div className={styles.aboutMe}>
        <div id={`aboutMe`} className={styles.aboutMeTitleWrapper}>
          <h2 className={styles.title}>
            About me
          </h2>
          {usersOwnPage ? (
            <div className={styles.editLink}>
              <span tabIndex={0} onClick={toggleShowEditModal}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.code === 'Enter') {
                  toggleShowEditModal();
                }
              }}
              >edit</span>
            </div>
          ) : null}
        </div>
        <div className={styles.line}></div>
        {showZeroState ? (
          <div className={styles.AboutMeCssGrid}>
            <div className={styles.aboutMeZeroState}>{noAboutMeDataText}</div>
          </div>
        ) : (
          <div className={styles.AboutMeCssGrid}>
            {aboutData.aboutMe ? (
              <div
                className={styles.aboutMeParagraph}
                dangerouslySetInnerHTML={{ __html: aboutData.aboutMe }}
              />
            ) : null}
            <div className={lowerSectionColumnStyle}>
              {birthdayText.length > 0 ? (
                <div className={styles.birthday}>
                  <h3>Birthday</h3>
                  {birthdayText}
                </div>
              ) : null}
              {aboutData.skills && aboutData.skills.length > 0 ? (
                <div className={styles.skills}>
                  <h3>Skills</h3> {aboutData.skills.join(`, `)}
                </div>
              ) : null}
              {aboutData.pastProjects && aboutData.pastProjects.length > 0 ? (
                <div className={styles.projects}>
                  <h3>Projects</h3>
                  {aboutData.pastProjects.join(`, `)}
                </div>
              ) : null}
              {aboutData.interests && aboutData.interests.length > 0 ? (
                <div className={styles.interests}>
                  <h3>Interests & Hobbies</h3> {aboutData.interests.join(`, `)}
                </div>
              ) : null}
              {aboutData.schools && aboutData.schools.length > 0 ? (
                <div className={styles.schools}>
                  <h3>Education</h3>
                  {aboutData.schools.map((school) => (
                    <p key={school}>{school}</p>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>{" "}
    </>
  );
};

export default AboutMe;
