import AuthService from "./AuthService";
import EnvironmentService from "./EnvironmentService";
import ErrorReportingService from "./ErrorReportingService";
import { checkOKResponse } from "./GraphService";

export default class AmazonWebService {
  private static apigeeUrl: string = EnvironmentService.getAWSApigeeUrl();
  /**
   * Get image from aws s3 bucket by image path
   * @param imagePath : string : must be the image path like CompanyNews/Arena_District.jpg
   */
  public static getImageFromS3Bucket(imagePath: string): Promise<any> | string {
    if (!imagePath || !imagePath.trim()) {
      return Promise.resolve('');
    }
    const getS3BucketImage: string = `${AmazonWebService.apigeeUrl}/${imagePath}`;
   
    const messageId: string = "123456";

    return fetch(getS3BucketImage, {
      method: "get",
      headers: {
        client_id: EnvironmentService.getApigeeConfig().clientId,
        "Content-Type": `image/*`,
        "X-NW-Message-ID": `${messageId}`,
        "Accept": "*/*",
        Authorization: AuthService.getAuthorizationHeader(),
      }
    })
      .then((response) => {
        if (!response.ok) {
          ErrorReportingService.reportErrorWithResponse(
            response,
            "AmazonWebService.ts -> getImageFromS3Bucket",
            `Attempted to retrieve: ${response.url}`
          );
        }
        return response;
      })
      .then(checkOKResponse)
      .then(async(response) => {
        /* no image returns application/json */
        if(response.headers.get('Content-Type') === 'application/json') {
          return '';
        }else{
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        }
      })
      .catch(error => {
        console.error(error);
        return '';
      });

  }

}