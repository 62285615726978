import { SpFeaturedContent } from "../../types/FeaturedContentTypes/SpFeaturedContent";

class AdwFeaturedContent {
  public title: string;
  public message: string;
  public color: string;
  public bgColor: string;
  public goTo: {
    Description: string;
    Url: string;
  };
  public targetWindow: string;
  public startDate: string;
  public endDate: string;
  public default: boolean;

  constructor(spFeaturedContent: SpFeaturedContent) {
    if (!spFeaturedContent) {
      spFeaturedContent = this.buildSpFeaturedContent();
    }

    this.title = spFeaturedContent.fields.Title;
    this.color = spFeaturedContent.fields.TextColor;
    this.bgColor = spFeaturedContent.fields.BackgroundColor;
    this.goTo = spFeaturedContent.fields.Link;
    this.targetWindow = this.getLinkTarget(spFeaturedContent.fields.WindowTarget);
    this.startDate = spFeaturedContent.fields.Start;
    this.endDate = spFeaturedContent.fields.End;
    this.message = spFeaturedContent.fields.Message;
    this.default = spFeaturedContent.fields.default;
  }

  public static getFeaturedContent(contentArray: AdwFeaturedContent[]): AdwFeaturedContent {
    let featuredContent: AdwFeaturedContent = null;
    if (contentArray && contentArray.length) {
      const todaysDate: Date = new Date();
      const featuredContentArray: AdwFeaturedContent[] = contentArray.filter(
        content => new Date(content.startDate) <= todaysDate && todaysDate <= new Date(content.endDate),
      );
      if (featuredContentArray && featuredContentArray.length) {
        featuredContent = featuredContentArray[0];
      } else {
        const defaultContentArray: AdwFeaturedContent[] = contentArray.filter(content => content.default);
        if (defaultContentArray && defaultContentArray.length) {
          featuredContent = defaultContentArray[0];
        }
      }
    }
    return featuredContent;
  }

  /* We must account for an empty list, or invalid data */
  private buildSpFeaturedContent(): SpFeaturedContent {
    let spFeaturedContent: SpFeaturedContent = {
      fields: {
        id: 0,
        BackgroundColor: "white",
        Start: "",
        End: "",
        TextColor: "black",
        Title: "",
        WindowTarget: "_self",
        Message: "",
        default: false,
        Link: {
          Description: "",
          Url: "/#",
        },
      }
    };
    return spFeaturedContent;
  }

  private getLinkTarget(target: string): string {
    let linkTarget: string = "";
    linkTarget = target.toLowerCase() === "new" ? "_blank" : "_self";
    return linkTarget;
  }
}

export default AdwFeaturedContent;
