import React from "react";
import widgetStyles from "../HRConnectWidgets.module.scss";
import styles from "./QuickLinks.module.scss";
import AdwHrConnectLink from "../../../../../types/FooterTypes/AdwHrConnectLink";
import Link from "../../../../atoms/Link/Link";

const QuickLinks: React.FC<{ links: AdwHrConnectLink[] }> = ({ links = [] }) => {

  return <div className={`${widgetStyles.widget} ${styles.container}`}>
    <div className={widgetStyles.header}><h3>Quick Links</h3></div>
    <div className={widgetStyles.section}>
      <ul className={styles.list}>{links.map((link) => {
        return (
          <li key={link.Title}>
            <Link
              url={link.Url}
              id={`hr_connect_link_${link.Title}`}
              linkText={link.Title}
              target={link.Title}
              linkClasses={styles.title}
            />
          </li>
        )
      })}</ul>
    </div>
  </div>
}

export default QuickLinks;