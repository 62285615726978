import React, { HTMLAttributes, FC } from "react";
import classNames from "classnames";

import AnnouncementCard, { IAnnouncementCardContentProps } from "../AnnouncementCard/AnnouncementCard";

import styles from './GenericAnnouncementCard.module.scss';

export interface ToolsAnnouncementCardProps extends HTMLAttributes<HTMLElement>, IAnnouncementCardContentProps {
  className?: string;
  title: string;
}

const GenericAnnouncementCard: FC<ToolsAnnouncementCardProps> = ({ className, title, description, icon }) => {
  return (
    <AnnouncementCard className={classNames(className)}>
      <AnnouncementCard.Header>
        <AnnouncementCard.Title className={styles.title}>{title}</AnnouncementCard.Title>
      </AnnouncementCard.Header>
      <AnnouncementCard.Content icon={icon} description={description} />
    </AnnouncementCard>
  );
}

export default GenericAnnouncementCard;
