import React from "react";
import styles from "./DesktopArrowButton.module.scss";

export interface IDesktopArrowButtonProps {
  desktopArrow?: string;
  arrowColor?: string;
}

export const DEFAULT_COLOR: string = "#0072cf";

const DesktopArrowButton: React.FC<IDesktopArrowButtonProps> = ({ desktopArrow, arrowColor, ...otherProps }) => {
  const color: string = arrowColor || DEFAULT_COLOR;
  const linkColorCssProp: React.CSSProperties = {
    backgroundColor: color,
    color: color,
  };
  const arrowClasses: string = desktopArrow ? `${styles.desktopArrowDirection} ${desktopArrow}` : `${styles.desktopArrowDirection}`;
  return <div className={arrowClasses} style={linkColorCssProp} />;
};

export default DesktopArrowButton;
