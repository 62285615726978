import React, { useContext } from "react";
import { AdwContext, AdwContextType } from '../../../context/AdwContext';
import AdwFeaturedContent from "../../../types/FeaturedContentTypes/AdwFeaturedContent";
import GoToLink from "../../molecules/GoToLink/GoToLink";
import styles from "./FeaturedContent.module.scss";

export interface IFeaturedContentProps {
  parentClasses?: string;
}

const FeaturedContent: React.FC<IFeaturedContentProps> = ({parentClasses = ""}) => { // NOSONAR
  const [appState]: AdwContextType = useContext(AdwContext);
  let featuredContent: AdwFeaturedContent = AdwFeaturedContent.getFeaturedContent(appState.lists && appState.lists.featuredContent);
  let color: string = featuredContent && featuredContent.color ? featuredContent.color : "#fff";

  const featuredContentProps: React.CSSProperties = {
    borderColor: color,
    color: color,
    backgroundColor: featuredContent && featuredContent.bgColor ? featuredContent.bgColor : "#fff",
  };

  const h4StyleProps: React.CSSProperties = {
    color: color,
    borderColor: color,
  };

  return featuredContent ? (
    <div className={`${styles.featuredContent} ${parentClasses}`} style={featuredContentProps} id="featured-content-box">
      <h4 className={styles.featuredContentHeading} style={h4StyleProps} id="featured-content-title">
        {featuredContent.title ? featuredContent.title : ""}
      </h4>
      <p className={styles.featuredContentMessage} id="featured-content-message">
        {featuredContent.message ? featuredContent.message : ""}
      </p>
      <div className={styles.featuredContentLink} id="featured-content-go-to-link-box">
        <GoToLink
          linkText={featuredContent.goTo && featuredContent.goTo.Description ? featuredContent.goTo.Description : ""}
          target={featuredContent.targetWindow ? featuredContent.targetWindow : "_blank"}
          url={featuredContent.goTo && featuredContent.goTo.Url ? featuredContent.goTo.Url : "/#"}
          id="featured-content-go-to-link"
          linkColorString={color}
        />
      </div>
    </div>
  ) : null;

}

export default FeaturedContent;
