export default class QueryParamUtils {
  /**
   * Pull a param from the urlsearchparams by name
   * @param paramName the value you want to pull out of the url search params
   * @param defaultValue the value you want to set if you cannot find it by name
   */
  public static getParameter(paramName: string, defaultValue: string = "") {
    const queryParams: URLSearchParams = new URLSearchParams(window.location.search);
    return queryParams.has(paramName) ? queryParams.get(paramName) : defaultValue;
  }

  public static hasParameter(paramName: string): boolean {
    return new URLSearchParams(window.location.search).has(paramName);
  }

  /**
   * This version of the method, also takes into account upper/lower case of the same param name
   * @param paramName the value you want to pull out of the url search params
   * @param defaultValue the value you want to set if you cannot find it by name
   */
  public static getParameterByName(paramName: string, defaultValue: string = "") {
    let returnValue: string = "";

    if (paramName && paramName !== null) {
      const queryParams: URLSearchParams = new URLSearchParams(window.location.search);

      if (queryParams.has(paramName)) {
        returnValue = queryParams.get(paramName);
      } else if (queryParams.has(paramName.toLowerCase())) {
        returnValue = queryParams.get(paramName.toLowerCase());
      } else {
        returnValue = defaultValue;
      }
    }
    return returnValue;
  }

  public static getIframeSrcParameter(paramName: string, defaultValue: string = "") {
    const iframeSrc: string = window.frameElement && (window.frameElement as HTMLIFrameElement).src;
    if (iframeSrc) {
      const queryParams: URLSearchParams = new URLSearchParams(iframeSrc);
      if (queryParams.has(paramName)) {
        return queryParams.get(paramName);
      }
    }
    return defaultValue;
  }
}
