import React from "react";
import FutureOfWork from "./widgets/FutureOfWork/FutureOfWork";
import Jobs from "./widgets/Jobs/Jobs";
import YourTime from "./widgets/YourTime/YourTime";
import BenefitElections from "./widgets/BenefitElections/BenefitElections";
import MyHealthProgram from "./widgets/MyHealthProgram/MyHealthProgram";
import RetirementSavingsElections from "./widgets/RetirementSavingsElections/RetirementSavingsElections";
import MyPay from "./widgets/MyPay/MyPay";
import AssociateWellbeingSafety from "./widgets/AssociateWellbeingSafety/AssociateWellbeingSafety";

export function getWidget(widget: string): JSX.Element {
  //Check state for HR Connect Switch and use position if no user pref exist
  if (widget) {
    switch (widget.toLowerCase()) {
      case 'yourtime':
        return <YourTime />;
      case 'fow':
        return <FutureOfWork />;
      case 'jobs':
        return <Jobs />;
      case 'benefitelections':
        return <BenefitElections />;
      case 'retirementsavingselections':
        return <RetirementSavingsElections />;
      case 'mypay':
        return <MyPay />;
      case 'associatewellbeingsafety':
        return <AssociateWellbeingSafety />;
      case 'myhealthprogram':
        return <MyHealthProgram />
      default:
        return null;
    }
  }
}