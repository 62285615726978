import React from "react";
import { AdwContext, AdwContextType } from '../../../context/AdwContext';
import AdwCommonTool from "../../../types/CommonToolsTypes/AdwCommonTool";
import styles from "./ToolPicker.module.scss";
import { randomAlphaNumeric } from "../../../utils/ArrayUtils";

export interface IToolPickerProps {
  toolId: number;
  toolIndex: number;
  toolPickerClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ToolPicker: React.FC<IToolPickerProps> = ({ toolId, toolPickerClick, toolIndex }) => {
  const [appState]: AdwContextType = React.useContext(AdwContext);
  let sortedToolsList: AdwCommonTool[] = [].concat(appState.lists.commonTools);

  sortedToolsList.sort((toolA, toolB) => {
    if (toolA.Title.toLowerCase() < toolB.Title.toLowerCase()) {
      return -1;
    } else if (toolA.Title > toolB.Title) {
      return 1;
    } else {
      return 0;
    }
  });

  return (
    <div className={styles.toolPicker}>
      <div className={styles.toolPickerArrow} id="tool-picker-arrow" />
      <div className={styles.toolsList}>
        {sortedToolsList && sortedToolsList.map((tool, index) => {
          const toolTitleClasses: string = tool.ID === toolId ? `${styles.toolTitle} ${styles.selectedTool}` : `${styles.toolTitle}`;
          if (!tool.RequiredTool) {
            return (
              <button
                className={toolTitleClasses}
                data-toolpickerid={tool.ID}
                onClick={e => toolPickerClick(e)}
                key={tool.ID+'-'+randomAlphaNumeric(5)}
                id={`tool-picker-${toolIndex}-tool-${index - 1}`}
              >
                {tool.Title}
              </button>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default ToolPicker;
