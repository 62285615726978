import React, {  useEffect, useState } from "react";
import AuthService from "../../../services/AuthService";
import CachingService from "../../../services/CachingService";
import EnvironmentService from "../../../services/EnvironmentService";
import InsideDataService from "../../../services/InsideDataService";
import { InsideAdmin } from "../../../types/AdminTypes/InsideAdmin";
import Button from "../../atoms/Button/Button";
import styles from "./DiagnosticsView.module.scss";
import GraphService from "../../../services/GraphService";
import { GraphUser } from "../../../types/GraphTypes/GraphUser";
import CallupService from "../../../services/CallupService";
const latestBuild: any = require("../../../../src/latestbuild.json");

export interface IDiagnosticsViewProps {
  className?: string;
}

export interface IDiagnosticsViewState {
  build: any;
  insideApiStatus: string;
  graphApiStatus: string;
  searchApiStatus: string;
  callUpApiStatus: string;
  commitInfo: any;
  searchResults: any;
  hasSearched: boolean;
}

const UPDATING: string = "Updating...";
const AVAILABLE: string = "Available";
const UNREACHABLE: string = "Unreachable";
const ERROR: string = "Error";
const UNAVAILABLE: string = "Unavailable";

const DiagnosticsView: React.FC<IDiagnosticsViewProps>=({className})=> {
const [build]=useState(latestBuild);
const [insideApiStatus,setInsideApiStatus]=useState(UPDATING);
const [graphApiStatus,setGraphApiStatus]=useState(UPDATING);
const [searchApiStatus,setSearchApiStatus]=useState(UPDATING);
const [callUpApiStatus,setCallUpApiStatus]=useState(UPDATING);
const [commitInfo,setCommitInfo]=useState({
  message: UPDATING,
  author: {
    name: UPDATING,
    date: UPDATING,
  },
},);
const [hasSearched]=useState(false);

  let searchInterval: any = null;

useEffect(()=>{
  document.title = "InSide - Diagnostics";
    checkAll();
},[])

  setTimeout(() => {
    if (searchApiStatus === UPDATING) {
      clearInterval(searchInterval);
      setSearchApiStatus(UNREACHABLE);
    }
  }, 60000);

  

  const checkAll=(): void =>{ // NOSONAR
    if (!AuthService.hasActiveSession()) {
      setTimeout(checkAll, 1000);
      return;
    }
    // verify sp user data and inside data API services running
    GraphService.getCurrentUser()
      .then((user: GraphUser) => {
        setGraphApiStatus(user && user.mail.indexOf("default") < 0 ? AVAILABLE : UNREACHABLE);
      })
      .then(GraphService.getAdwLoginID)
      .then(InsideDataService.getUserAdminStatus)
      .then((insideAdmin: InsideAdmin) => {
        setInsideApiStatus(insideAdmin && insideAdmin.contentType !== "default" ? AVAILABLE : UNREACHABLE);
      });

    // get commit info
    const gitApiUrl: string = `https://github.nwie.net/api/v3/repos/Nationwide/ADW_AWS/git/commits/${build.commit}`;
    fetch(gitApiUrl).then((response: Response) => {
      if (response.ok) {
        response.json().then((responseBody: any) => {
          setCommitInfo(responseBody);
        });
      } else {
        setCommitInfo({
          message: build.commit,
          author: {
            name: UNAVAILABLE,
            date: build.time,
          }})
      }
    });

    //Check Search API
    let count = 0;
    searchInterval = setInterval(() => {
      if (!hasSearched && AuthService.hasActiveSession()) {
        getSearchResults();
      }
      else if (count >= 10 || hasSearched) {
        clearInterval(searchInterval);
      }
      count++;
    }, 1000);

    //Check Callup api status
    CallupService.getEmployeeByShortName("eshd1").then((result) => {
      setCallUpApiStatus(result && result.fullName ? AVAILABLE : UNREACHABLE);
    });
  }

  /**
   * Using the OIDC token, get a search session and make an api call
   */
  const getSearchResults=async(): Promise<void> =>{
    return null;
  }

  const getRow=(label: string, value: any): JSX.Element =>{
    let valueClass: string;
    switch (value) {
      case UNREACHABLE:
      case ERROR:
        valueClass = styles.error;
        break;
      case AVAILABLE:
        valueClass = styles.success;
        break;
      case UPDATING:
        valueClass = styles.unknown;
        break;
      default:
        valueClass = "";
    }
    return (
      <tr className={styles.row}>
        <td className={styles.column}>{label}</td>
        <td className={`${styles.column} ${valueClass}`}>{value}</td>
      </tr>
    );
  }

  
    return (
      <div className={className ? className : ""}>
        <Button
          id={"clear_storage_button_help"}
          buttonClasses={`btn ${styles.clearStorageButton}`}
          onClick={CachingService.clearLocalStorageAndRefresh}
        >
          Clear storage
        </Button>
        <h3>Service Information</h3>
        <table id="service_information" className={styles.table}>
          {getRow("Inside API", insideApiStatus)}
          {getRow("Graph API", graphApiStatus)}
          {getRow("CallUp API", callUpApiStatus)}
        </table>
        <h3>Release Information</h3>
        <table id="release_information" className={styles.diagnosticTable}>
          {getRow("Commit Author", commitInfo.author.name)}
          {getRow("Commit Message", commitInfo.message)}
          {getRow("Commit Date", commitInfo.author.date)}
          {getRow("Commit Hash", build && build.commit ? build.commit : UNAVAILABLE)}
          {getRow("Docker Image Tag", build && build.imageTag ? build.imageTag : UNAVAILABLE)}
          {getRow("Docker Image Build Date", build && build.time ? build.time : UNAVAILABLE)}
        </table>
        <h3>Environment Information</h3>
        <table id="environment_information" className={styles.diagnosticTable}>
          {getRow("User Agent", navigator.userAgent)}
          {getRow("Environment", EnvironmentService.getEnvironment().toUpperCase())}
          {getRow("Sharepoint URL", EnvironmentService.getSharepointSiteUrl())}
          {getRow("Apigee URL", EnvironmentService.getInsideUserDataServiceUrl())}
        </table>
        <h3>Auth Session Information</h3>
        <table id="environment_information" className={styles.diagnosticTable}>
          {getRow("id_token", AuthService.ApigeeAuth.id_token)}
          {getRow("access_token", AuthService.ApigeeAuth.access_token)}
          {getRow("token_type", AuthService.ApigeeAuth.token_type)}
          {getRow("scope", AuthService.ApigeeAuth.scope)}
          {getRow("expires_at", AuthService.ApigeeAuth.expires_at && AuthService.ApigeeAuth.expires_at.toISOString())}
          {getRow("error", AuthService.ApigeeAuth.error)}
        </table>
      </div>
    );
  
}
export default DiagnosticsView;