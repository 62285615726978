import React from "react";
import { Link } from "react-router-dom";

export interface IMoreNewsLinkProps {
  linkStyles: string;
  moreNewsStyles: string;
  id: string;
}

const MoreNewsLink: React.FC<IMoreNewsLinkProps> = ({ linkStyles, moreNewsStyles, id }) => {
  return (
    <div className={moreNewsStyles}>
      <Link className={linkStyles} to={`https://onyourside.sharepoint.com/sites/InSide-News`} target="_blank" id={id}>
        <span>More news</span>
      </Link>
    </div>
  );
};

export default MoreNewsLink;
