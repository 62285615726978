import React from "react";
import Skeleton from 'react-loading-skeleton';

import { CircleButtonSkeleton } from "../../atoms";

import styles from "./ContactInfo.module.scss";

const ContactInfoSkeleton: React.FC = () => {

  return (
    <div className={styles.contactInfo}>
      <div className={styles.imageWrapper}>
        <Skeleton circle={true} height={100} width={100} />
      </div>

      <div className={styles.identification}>
        <Skeleton width={206} height={18} />
        <Skeleton width={142} height={14} count={2} />
      </div>

      <div className={styles.lineSkeleton}></div>

      <div className={styles.contactMethods}>
        <Skeleton width={142} height={14} />
        <Skeleton width={186} height={14} />
        <Skeleton width={232} height={14} />

        <div className={styles.userCardBtnContainer}>
          <Skeleton width={200} height={40} />
          <Skeleton width={200} height={40} />
        </div>

        <div className={styles.circleButtons}>
          <div className={styles.circleButtons}>
            <CircleButtonSkeleton />
            <CircleButtonSkeleton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoSkeleton;