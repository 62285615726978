import React, { HTMLAttributes, ReactNode, FC } from "react";
import classNames from "classnames"

import Header, { IHeaderProps } from "../../atoms/Header/Header";
import Widget from "../../atoms/Widget/Widget";

import styles from './AnnouncementsCard.module.scss'

export interface IAnnouncementCardProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  children: ReactNode;
}

export interface IAnnouncementCardHeaderProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  children: ReactNode;
}

export interface IAnnouncementCardTitleProps extends IHeaderProps {
  className?: string;
  children: ReactNode;
}

export interface IAnnouncementCardContentProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  icon: "bell" | "star" | "clock" | undefined;
  description: string;
}

interface AnnouncementCardProps extends FC<IAnnouncementCardProps> {
  Header: FC<IAnnouncementCardHeaderProps>;
  Title: FC<IAnnouncementCardTitleProps>;
  Content: FC<IAnnouncementCardContentProps>;
}

const AnnouncementCard: AnnouncementCardProps = ({ className, children }) => {
  return (
    <Widget className={classNames(className, styles.card)}>
      {children}
    </Widget>
  )
}

const AnnouncementCardHeader: FC<IAnnouncementCardHeaderProps> = ({ className, children }) => {
  return (  
    <header className={classNames(styles.header, className)}>
      {children}
    </header>
  )
}

const AnnouncementCardTitle: FC<IAnnouncementCardTitleProps> = ({ level = 3, id = null, className, children }) => {
  return (
    <Header level={level} id={id} className={classNames(styles.title, className)}>
      {children}
    </Header>
  )
}

const AnnouncementCardContent: FC<IAnnouncementCardContentProps> = ({ className, description, icon }) => {
  const getComponentFeatureIcon = (icon) => {
    switch (icon) {
      case "bell":
        return require('../../../assets/images/Alert.svg').default;
      case "star":
        return require('../../../assets/images/icon-gear.svg').default;
      case "clock":
        return require('../../../assets/images/Cross.svg').default
      default:
        return require('../../../assets/images/Alert.svg').default
    }

  }

  return (
    <div className={classNames(styles.content, className)}>
      <img className={styles.icon} alt="" src={getComponentFeatureIcon(icon)} />
      <p className={styles.description}>{description}</p>
    </div>
  )
}

AnnouncementCard.Header = AnnouncementCardHeader;
AnnouncementCard.Title = AnnouncementCardTitle;
AnnouncementCard.Content = AnnouncementCardContent;

export default AnnouncementCard