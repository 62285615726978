import moment from "moment";
import React, { useEffect, useState } from "react";
import styles from "./addtocalendar.module.scss";
import { EventInput } from "@fullcalendar/core";

export interface IAddToCalendarButtonProps {
  event: EventInput;
  id: string;
}

const calendarIcon: string = require("../../../assets/images/calendar-icon.png");

const AddToCalendarButton: React.FC<IAddToCalendarButtonProps> = ({
  event,
  id
}): any => {
  const [locations, setLocations] = useState(null);
  const [description, setDescription] = useState(null);
  const [title, setTitle] = useState(null);
  const [allDayEvent, setAllDayEvent] = useState(false);
  const [displayStartDate, setDisplayStartDate] = useState(null);
  const [displayEndDate, setDisplayEndDate] = useState(null);

  useEffect(() => {
    setLocations(event.location);
    setDescription(formatDescription(event.description));
    setTitle(event.title);
    setAllDayEvent(event.allDay);
    setDisplayStartDate(event.start);
    setDisplayEndDate(event.end);
    if (event.allDay) {
      setDisplayStartDate(event.start);
      setDisplayEndDate(moment(event.end)
        .add(1, "day")
        .format("YYYY-MM-DD hh:mm A"))
    }
  }, [event]);


  const formatDate = (input, allDay, type) => {
    if (allDay) {
      if (type == 'start') {
        return moment(new Date(input), "YYYYMMDD[T]HHmmss").add(1, "day").format(
          "YYYYMMDD"
        );
      } else {
        return moment(new Date(input), "YYYYMMDD[T]HHmmss").format(
          "YYYYMMDD"
        );
      }
    } else {
      return moment(new Date(input), "YYYYMMDD[T]HHmmss").format(
        "YYYYMMDD[T]HHmmss"
      );
    }
  };

  const formatDescription = (eventDescription) => {
    //Sharepoint automatically convert absolute URL to relative URL
    if (eventDescription !== undefined && eventDescription !== null) {
      if (
        eventDescription.length > 0 &&
        eventDescription.indexOf('<a href="/') > 0
      ) {
        eventDescription = eventDescription.replaceAll(
          '<a href="/',
          '​<a href="https://onyourside.sharepoint.com/'
        );
      }
    } else {
      eventDescription = "";
    }
    return eventDescription;
  }
  const getEventLocation = (locations) => {
    if (locations) {
      return locations;
    } else {
      return '';
    }
  }
  const addToCalendarOnClick = (e) => {
    e.stopPropagation();
    let iCalString =
      "BEGIN:VCALENDAR\n" +
      "PRODID:-//Microsoft Corporation//Outlook 16.0 MIMEDIR//EN\n" +
      "VERSION:2.0\n" +
      //"METHOD:PUBLISH\n" + vTimeZone + "\n" +
      "BEGIN:VEVENT\n" +
      "CLASS:PUBLIC\n" +
      "CREATED:20170811T124152Z\n" +
      "DESCRIPTION:" +
      description +
      "\\n\n" +
      "DTEND:" +
      formatDate(displayEndDate, allDayEvent, 'end') +
      "\n" +
      "DTSTAMP:20170811T124152Z\n" +
      "DTSTART:" +
      formatDate(displayStartDate, allDayEvent, 'start') +
      "\n" +
      "LAST-MODIFIED:20170811T124152Z\n" +
      "LOCATION:" +
      getEventLocation(locations) +
      "\n" +
      "PRIORITY:5\n" +
      "SEQUENCE:0\n" +
      "SUMMARY;LANGUAGE=en-us:" +
      title +
      "\n" +
      'X-ALT-DESC;FMTTYPE=text/html: <html xmlns:v="urn:schemas-microsoft-com:vml" \n' +
      ' xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:w="urn:schemas-microsoft-com:office:word\n' +
      ' xmlns:m="http://schemas.microsoft.com/office/2004/12/omml\n' +
      ' xmlns="http://www.w3.org/TR/REC-html40"><head></head><body>' +
      description +
      "\\n\n" +
      "</body></html>\n" +
      "TRANSP:OPAQUE\n" +
      "X-MICROSOFT-CDO-BUSYSTATUS:BUSY\n" +
      "X-MICROSOFT-CDO-IMPORTANCE:1\n" +
      "X-MICROSOFT-DISALLOW-COUNTER:FALSE\n" +
      "X-MS-OLK-AUTOFILLLOCATION:FALSE\n" +
      "X-MS-OLK-CONFTYPE:0\n" +
      "BEGIN:VALARM\n" +
      "TRIGGER:-PT15M\n" +
      "ACTION:DISPLAY\n" +
      "DESCRIPTION:Reminder\n" +
      "END:VALARM\n" +
      "END:VEVENT\n" +
      "END:VCALENDAR";

    const blob = new Blob([iCalString]);
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const fileUrl = URL.createObjectURL(blob);
      link.setAttribute("href", fileUrl);
      link.setAttribute("download", "calendar.ics");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(fileUrl);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      addToCalendarOnClick(e);
    }
  };

  return (
    id && event && event.id &&
    event.title && (
      <div
        className={styles.eventDetailOutlook}
        id={id}
        onClick={(e)=>addToCalendarOnClick(e)}
        tabIndex={0}
        role="button"
        onKeyDown={(e) => handleKeyDown(e)}
        title={`Add_${event.title}_to_outlook`}
      >
        <div className={styles.eventCalendarIcon} style={{ backgroundImage: "url(" + calendarIcon + ")" }} />
        <div className={styles.eventLinkText}>Add to Outlook</div>
      </div>
    )
  );
};

export default AddToCalendarButton;