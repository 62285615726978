import React, { useRef } from "react";
import styles from "./Customize.module.scss";

export interface ICustomizeProps {
  showing: boolean;
  toggle: () => void;
  className?: string;
}

const gearIcon = require("../../../../assets/images/icon-gear.svg").default;

const Customize: React.FC<ICustomizeProps> = ({ showing, toggle, className = "" }: ICustomizeProps) => {
 
  const customizeButton = useRef(null);
  const icon = showing ? "" : gearIcon;
  const text = showing ? "Done" : "Customize"

  function handleClick(): void {
    if (customizeButton && customizeButton.current) {
      customizeButton.current.blur();
    }
    toggle();
  }

  function handleKeyPress(e: React.KeyboardEvent<HTMLSpanElement>): void {
    if (e.key === 'Enter' || e.code === 'Enter') {
      toggle();
    }
  }

  return (<span tabIndex={0} ref={customizeButton} onClick={handleClick} onKeyDown={handleKeyPress} className={`${styles.customize} ${text==='Done'&& styles.done}`} aria-label="Customize HR Connect widget.">
    <span>{text}&nbsp;</span>{icon ? <img src={icon} alt="" /> : ''}</span>);

}

export default Customize;