import { toInteger } from "lodash";
import BrowserUtils from "./BrowserUtils";
import AuthService from "../services/AuthService";

export default class TimingUtils {
  
  static componentsLoaded: string[] = [];
  static allComponents = ['PopularTools', 'AtoZ', 'Tools', 'News', 'UserPreferences', 'Announcements'];
  static nonPrimaryComponents = ['Signout'];
  static dataSent = false;

  /**
   * Registers a component as loaded. If the component is not already registered,
   * it adds the component to the session storage and the internal list of loaded components.
   * Then, it checks if all necessary components have been loaded.
   * @param componentName - The name of the component to register as loaded.
   */
  public static registerComponentLoad(componentName: string): void {
    let registeredComponents = JSON.parse(sessionStorage.getItem('registeredComponents') || '[]');
    if (!registeredComponents.includes(componentName)) {
      registeredComponents.push(componentName);
      sessionStorage.setItem('registeredComponents', JSON.stringify(registeredComponents));
    }

    if (!this.componentsLoaded.includes(componentName)) {
      this.componentsLoaded.push(componentName);
      const componentsToCheck = AuthService.hasActiveSession() ? this.allComponents : this.nonPrimaryComponents;
      this.checkComponentsLoaded(componentsToCheck);
    }
  }

  /**
   * Checks if all components in the provided list are loaded.
   * If all components are loaded and the current window location is the root path,
   * If all required components are registered, it sends timing data.
   * @param componentListToCheck - The list of components to check for loading status.
   * @returns {void}
   */
  public static checkComponentsLoaded(componentListToCheck): void {
    for(let componentToCheck of componentListToCheck){
      if (!componentListToCheck.includes(componentToCheck)) {
        return;
      }
    }

    /* send timing when all components are loaded */
    if (window.location.pathname === '/' && !BrowserUtils.isIframe()) {
      // check if all components are added to sessionStorage
      let allRegisteredComponents = JSON.parse(sessionStorage.getItem('registeredComponents'));
      if (allRegisteredComponents && allRegisteredComponents.length > 0) {
        const componentsToCheck = AuthService.hasActiveSession() ? this.allComponents : this.nonPrimaryComponents;
        if (componentsToCheck.every(component => allRegisteredComponents.includes(component))) {
          this.sendTimingData();
          this.clearRegisteredComponents();
        }
      }
    }    
  }

  /**
   * Sends timing data to New Relic
   * If New Relic is available, it adds a page action with the event name 'All components loaded'
   * and custom attributes retrieved from `getCustomAttributes`.
   */
  public static sendTimingData(): void {
    this.dataSent = true;
    if (window['newrelic']) {
      window['newrelic'].addPageAction('All components loaded', this.getCustomAttributes());
    }
  }

  /**
   * Clears the registered components by resetting the componentsLoaded and 
   * nonPrimaryComponents arrays, and setting dataSent to false.
   */
  public static clearRegisteredComponents(): void {
    this.componentsLoaded = [];
    this.nonPrimaryComponents = [];
    this.dataSent = false;
  }

  private static getCustomAttributes() {
    /*
      Try to get full duration from start of page navigation to last registered component load.
      PerformanceNavigationTiming returns '0' as the navigation start time and then the rest of
      the timings are based off of that, including the domContentLoadedEventStart (How many 
      milliseconds since navigationStart did x occur).

      index.tsx records the current timestamp in localStorage which should be one of the first
      things to run as the dom content starts to load.

      This code tries to calculate the timestamp in milliseconds of the navigation start time by
      taking the appLoadStart - domContentLoadedEventStart. Then calculates full duration by
      taking the current timestamp - navigation start time
    */
    let startTimeString = sessionStorage.getItem('appLoadStart');
    let startTime = toInteger(startTimeString);
    let domContentLoadedEventStart = 
      (window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming).domContentLoadedEventStart;
    startTime = startTime - domContentLoadedEventStart;
    
    let duration = (Date.now() - startTime) / 1000;

    // Get location and department from localStorage
    let location = localStorage.getItem("insideUserLocation");
    let department = localStorage.getItem("insideUserDepartment");

    return {
      duration: duration,
      location: location,
      department: department
    };
  }
}