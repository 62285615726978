import React, { useState, useEffect, useContext } from "react";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AuthService from "../../../services/AuthService";
import EnvironmentService from "../../../services/EnvironmentService";
import ErrorReportingService from "../../../services/ErrorReportingService";
import styles from "./OrgChart.module.scss";
import jsonpath from "jsonpath";
import OrgChartListContents from "../OrgChartListContents/OrgChartListContents";
import { AdwFeatureSwitch } from "../../../types/FeatureSwitchTypes/AdwFeatureSwitch";
import {  useNavigate } from "react-router-dom";
import { UpdateViewAvatarOrgChart, UpdateViewUserOrgChart } from "../../../context/ActionTypes";
import GraphService from "../../../services/GraphService";
import { GraphAvatarData } from "../../../types/GraphTypes/GraphUser";

export interface IOrgChartProps {
  avatarUser: GraphAvatarData;
  userPersonalizedEmail: string;
}

export interface IOrgChartState {
  workGroup: GraphAvatarData[];
  orgChart: GraphAvatarData[];
  hasDirectReports: boolean;
  showOrgChart: boolean;
  mesKey: string;
}

const OrgChart: React.FC<IOrgChartProps> = ({ avatarUser, userPersonalizedEmail }) => {
  const navigate = useNavigate();
  const [appState,dispatch] = useContext<AdwContextType>(AdwContext);
  const [workGroup, setWorkGroup] = useState<GraphAvatarData[]>([]);
  const [orgChart, setOrgChart] = useState<GraphAvatarData[]>([]);
  const [hasDirectReports, setHasDirectReports] = useState<boolean>(false);
  const [showOrgChart, setShowOrgChart] = useState<boolean>(true);
  const [mesKey, setMesKey] = useState("");
  const expandedOrgChartLinkId: string = "expandedOrgChartLink";
  const CEOShortName: string = "walkerk";
  const nwieID: string = (appState && appState.user && appState.user.nwieID);
  const featureSwitchList: AdwFeatureSwitch[] =
    appState && appState.lists && appState.lists.featureSwitches
      && appState.lists.featureSwitches.length ? appState.lists.featureSwitches : [];
  const [nationwideOrgChartEnabled, setNationwideOrgChartEnabled] = useState(null);
  let orgChartBossList : GraphAvatarData[];

  useEffect(() => {
    if (nwieID && featureSwitchList.length) {
      featureSwitchList.forEach((switchOption => {
        if (switchOption.feature === 'Nationwide Org Chart' && switchOption.page === "EmployeeDetails" && switchOption.displayWidget && nwieID.match(switchOption.allowRegex)) {
          setNationwideOrgChartEnabled(switchOption);
        }
      }))
    }
  }, [nwieID, featureSwitchList])


  const getOrgChartNwie = (avatarUser: GraphAvatarData, hasDirectReports: boolean, appState: any): string => {
    let orgChartNwie: string;
    if (avatarUser) {
      if (hasDirectReports) {
        orgChartNwie = avatarUser.onPremisesSamAccountName;
      } else {
        orgChartNwie = avatarUser.manager.onPremisesSamAccountName;
      }
    } else if (appState.viewUserDetails) {
      orgChartNwie = appState.viewUserDetails;
    } else {
      orgChartNwie = "";
    }
    return orgChartNwie;
  }

  const orgChartNwie: string = getOrgChartNwie(avatarUser, hasDirectReports, appState);

  //Call updateContents on component mount and if avatarUser changes
  useEffect(() => {
    if(avatarUser){
      dispatch(UpdateViewAvatarOrgChart(avatarUser));
      orgChartBossList = [];
      updateContents();
    }
  }, [avatarUser]);

  useEffect(()=>{
    if(userPersonalizedEmail){
      dispatch(UpdateViewUserOrgChart(userPersonalizedEmail));
    }
  },[userPersonalizedEmail])

  const updateContents = (): void => {
    /* Get selected avatar user information  */
    if (avatarUser.manager.onPremisesSamAccountName) {
      if (AuthService.hasActiveSession()) {      
        GraphService.getSelectedOrgChartUser(appState.viewUserDetails).then(
          (avatarData : GraphAvatarData) => {
            UpdateViewAvatarOrgChart(avatarData)
            buildWorkGroup(avatarData.manager)
            buildOrgChart(avatarData);
            isBoss(avatarData)
          });
      } else {
        setTimeout(updateContents, 100);
      }
    }
  };

  //   /**
  //    * The user clicked the orgchart/workgroup link
  //    * so we need to lead the data appropriately
  //    */
  const toggleListView = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    setShowOrgChart(!showOrgChart);
  };

  /* Determine if user has direct reports */
  const isBoss = (userData : GraphAvatarData) => {
    GraphService.hasDirectReports(userData.userPrincipalName).then(
      (hasDirectReports: boolean) => {
        setHasDirectReports(hasDirectReports);
        console.log(
          "OrgChart >>> getDirectReportsList :  updateContents hasDirectReports = " +
          hasDirectReports
        );

        /* If user has direct reports, user's shortname set as orgChartNwie, else, set user manager's shortname */
        const orgChartNwie = hasDirectReports
          ? userData.onPremisesSamAccountName
          : userData.manager.onPremisesSamAccountName;
        console.log(
          "OrgChart >>> getDirectReportsList : updateContents orgChartNwie =  " +
          orgChartNwie
        );
        getExpandedOrgChartMesKeyFromMBSearchApi(orgChartNwie);
    });
  }

  //   /**
  //    * For a given employee, this is a list of their boss and everyone who reports to that boss
  //    */
  const buildWorkGroup = (manager: GraphAvatarData): void => {    
    GraphService.getAvatarDirectReports(manager.userPrincipalName).then(
      (workGroup: GraphAvatarData[]) => {
        workGroup = workGroup.map((workGroup) => {
          workGroup.tabOnce = true;
          return workGroup;
        });
        
        //Remove CEO as a direct report
        workGroup = workGroup.filter(
          (directReport) =>
            directReport && directReport?.onPremisesSamAccountName?.toLowerCase() !== CEOShortName
        );
        //add the manager at the beginning of the list
        workGroup.unshift(manager);
        setWorkGroup(workGroup);
    });
  };

  /**
   * Display all associates above the current associate in the organization
   */
  const buildOrgChart = (avatar : GraphAvatarData): void => {
    let bossList: GraphAvatarData[] = [];

    if(avatar){
      GraphService.getBossList(avatar.userPrincipalName).then(
        (bossData:GraphAvatarData)=>{
          /* Loop through boss list */
          loopThroughBossList(bossData);
          orgChartBossList.reverse();

          /* selected employee */
          avatar.tabOnce = true;
          orgChartBossList.push(avatar)

          /* Get Direct Report */
          GraphService.getAvatarDirectReports(avatar.userPrincipalName).then(
            (directReports: GraphAvatarData[]) => {              
              if (directReports) {
                directReports = directReports.map((avatarUser) => {
                  avatarUser.tabTwice = true;
                  return avatarUser;
                });
                directReports = directReports.filter(
                  (directReport) =>
                    directReport.onPremisesSamAccountName.toLowerCase() !== CEOShortName
                );
                bossList = [...orgChartBossList, ...directReports];

                //cleanup the array to contain only unique values, callup adds duplicates
                bossList = Array.from(new Set(bossList));
              }
              setOrgChart(bossList);
          })
        })
    }
  }

  const getExpandedOrgChartRequestPayload = (
    orgChartUserId: string
  ): string => {
    const requestPayload: any = {
      query: {
        unparsed: `${orgChartUserId}`,
      },
      count: 1,
      properties: [
        {
          name: "mes:key",
          formats: ["VALUE"],
        },
      ],
      source_context: {
        constraints: [
          {
            unparsed: 'fqcategory:"DataIntegration:EmployeeCSVData"',
          },
        ],
      },
    };
    return requestPayload;
  };

  const getExpandedOrgChartMesKeyFromMBSearchApi = async (
    orgChartUserId: string
  ) => {
    if (!orgChartUserId) {
      return "Undefined";
    }

    const searchUrl: string = `${EnvironmentService.getMindBreezeSearchUrl()}/search/api/v2/search`;
    let idToken = await AuthService.getAuthorizationToken();
    fetch(searchUrl, {
      method: "POST",
      headers: {
        Authorization: idToken,
      },
      body: JSON.stringify(getExpandedOrgChartRequestPayload(orgChartUserId)),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          ErrorReportingService.reportErrorWithResponse(
            resp,
            "OrgChart.tsx -> getExpandedOrgChartMesKeyFromMBSearchApi",
            `Attempted to retrieve: ${resp.url}`
          );
          throw new Error(
            "Failed to retrieve mesKey for expanded Org Chart from Mindbreeze search API"
          );
        }
      })
      .then((json) => {
        const mesKey: string = getMesKeyValue(json);
        console.log(
          "OrgChart >>> getExpandedOrgChartMesKeyFromMBSearchApi : orgChartUserId = " +
          orgChartUserId +
          " || meskey = " +
          mesKey
        );
        setMesKey(mesKey);
        return mesKey;
      });
  };

  const getMesKeyValue = (response: any): string => {
    return jsonpath.query(
      response,
      `$.resultset.results[0].properties[?(@.id=="mes:key")].data[0].value.str`
    );
  };

  const getExpandedOrgChart = (): void => {
    const link: HTMLLinkElement = document.getElementById(
      expandedOrgChartLinkId
    ) as HTMLLinkElement;
    if (link) {
      link.click();
    }
  };

  const getExpandedOrgChartQueryUrl = (): string => {
    const mindbreezeSearchUrl = EnvironmentService.getMindBreezeSearchUrl();
    const searchPath = "/search/inside/apps/org-chart?query=";
    return mindbreezeSearchUrl.concat(searchPath);
  };

  const openNewTabOnExpandedOrgChart = (mesKeyValue: string): void => {
    const url = getExpandedOrgChartQueryUrl().concat(mesKeyValue);
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const openNewTabOnNationwideExpandedOrgChart = (): void => {
    navigate("/org-chart/");
  };

  return (
    <div className={styles.orgChart}>
      <div
        id={`orgTitleWrapper`}
        className={styles.orgTitleWrapper}
      >
        <div className={styles.title}>
          {showOrgChart ? "Organization" : "Work Group"}
        </div>
      </div>
      <div className={styles.viewLinksContainer}>
        <a
          href="#"
          className={styles.viewWorkGroupLink}
          tabIndex={0}
          id={`view-workgroup-link`}
          onClick={toggleListView}
        >
          {showOrgChart ? "view work group" : "view org chart"}
        </a>
      
      {orgChartNwie && showOrgChart && (
        <div
          className={styles.expandedOrgChart}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.code === 'Enter') {
              getExpandedOrgChart();
            }
          }}
        >
          {nationwideOrgChartEnabled ? (
            <>
             <button className={styles.expandedOrgChartLink}
              onClick={() => openNewTabOnNationwideExpandedOrgChart()} 
               //  to={"/org-chart"}
               id="inside_org_chart"
             >
             view expanded org chart
          </button>
            </>
          
          ) : (
             <a
             href="#"
             id={expandedOrgChartLinkId}
             className={styles.expandedOrgChartLink}
             onClick={() => openNewTabOnExpandedOrgChart(mesKey)}
           >
             <span>view expanded org chart</span>
           </a>
          )}
        </div>
      )}
      </div>
      <OrgChartListContents avatarUser={avatarUser} showOrgChart={showOrgChart} workGroup={workGroup} orgChart={orgChart} cEOShortName={CEOShortName} />
    </div>
  );
  function loopThroughBossList(obj: GraphAvatarData) {
    for (let key in obj) {
      if (typeof obj[key] === 'object') {
        orgChartBossList.push(obj[key])
        loopThroughBossList(obj[key]); 
      } 
    }
  }
};

export default OrgChart;
