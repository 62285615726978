import { InsideAdmin } from "../types/AdminTypes/InsideAdmin";
import { TimeUnits } from "../types/CachingTypes/TimeUnits";
import { buildInsideUserData, InsideUserData } from "../types/InsideUserData";
import BrowserUtils from "../utils/BrowserUtils";
import TimingUtils from "../utils/TimingUtils";
import AuthService from "./AuthService";
import CachingService from "./CachingService";
import EnvironmentService from "./EnvironmentService";
import ErrorReportingService from "./ErrorReportingService";
import { checkOKResponse } from "./GraphService";

class InsideDataService {
  private static apigeeUrl: string = EnvironmentService.getInsideUserDataServiceUrl();
  private static clientId: string = EnvironmentService.getApigeeConfig().clientId;
  private static contentType: string = "application/json";

  public static getUserAdminStatus = (adwLoginId: string = ""): Promise<InsideAdmin> => {
    if (!adwLoginId || !adwLoginId.trim()) {
      return Promise.resolve({
        contentType: "default",
        insideAdmin: false
      });
    }

    adwLoginId = adwLoginId.trim().toLowerCase();
    const url: string = `${InsideDataService.apigeeUrl}/user-admin-status`;
    const messageId: string = "userAdmin-123456";

    return fetch(url, {
      method: "POST",
      headers: {
        client_id: InsideDataService.clientId,
        "Content-Type": `${InsideDataService.contentType}`,
        "X-NW-Message-ID": `${messageId}`,
        Authorization: AuthService.getAuthorizationHeader(),
      },
      body: JSON.stringify({ userId: adwLoginId }),
    })
      .then((response) => {
        if (!response.ok) {
          ErrorReportingService.reportErrorWithResponse(
            response,
            "InsideDataService.ts -> getUserAdminStatus",
            `Attempted to retrieve: ${response.url}`
          );
          /* validate access token */
          AuthService.validateToken().then(isValid => {
            if (!isValid) {
              /* invalid access token - clear storage and request new access token */
              console.log("App >>> interval : Access token is invalid.")
              AuthService.clearStorageAndRequestAccessToken();
            }
          })
        }
        return response;
      })
      .then(checkOKResponse)
      .then(response => response.json())
      .catch(err => {
        console.error("Error getting user admin status", err);
        return {
          contentType: "default",
          insideAdmin: false
        };
      });
  };

  public static getUserPreferences = (adwLoginId: string): Promise<InsideUserData> => {
    const listName: string = "AdwUserPreferences";
    return CachingService.getObjectByKey<InsideUserData>(
      listName,
      (): Promise<InsideUserData> => InsideDataService.getUserPreferencesCallback(adwLoginId),
      0,
      TimeUnits.minutes,
    );
  };

  private static getUserPreferencesCallback = (adwLoginId: string): Promise<InsideUserData> => {
    if (!adwLoginId || !adwLoginId.trim()) {
      return Promise.resolve(buildInsideUserData(adwLoginId));
    };

    adwLoginId = adwLoginId.trim().toLowerCase();

    const url: string = `${InsideDataService.apigeeUrl}/user-preferences`;
    const messageId: string = "userPreference-123456";

    return fetch(url, {
      method: "POST",
      cache: "no-store",
      headers: {
        client_id: InsideDataService.clientId,
        "Content-Type": `${InsideDataService.contentType}`,
        "X-NW-Message-ID": `${messageId}`,
        Authorization: AuthService.getAuthorizationHeader(),
      },
      body: JSON.stringify({
        userId: adwLoginId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          ErrorReportingService.reportErrorWithResponse(
            response,
            "InsideDataService.ts -> getUserPreferencesCallback",
            `Attempted to Post: ${response.url}`
          );
          /* validate access token */
          AuthService.validateToken().then(isValid => {
            if (!isValid) {
              /* invalid access token - clear storage and request new access token */
              console.log("App >>> interval : Access token is invalid.")
              AuthService.clearStorageAndRequestAccessToken();
            }
          })
        }
        return response;
      })
      .then(checkOKResponse)
      .then(response => response.json())
      .then((insideUser: InsideUserData) => {
        if (insideUser.preferredName === "preferred name") {
          insideUser.preferredName = null;
        }
        if (insideUser.givenName === "given name") {
          insideUser.givenName = null;
        }
        localStorage.setItem("insideUserLocation", insideUser.postOfficeBox);
        localStorage.setItem("insideUserDepartment", insideUser.department);
        window['newrelic'].setCustomAttribute('location', insideUser.postOfficeBox);
        window['newrelic'].setCustomAttribute('department', insideUser.department);
        if (!BrowserUtils.isIframe()) {
          TimingUtils.registerComponentLoad('UserPreferences');
        }
        return insideUser;
      })
      .catch(err => {
        console.error("Error getting user preferences", err);
        return buildInsideUserData(adwLoginId);
      });
  };

  public static saveDataToInside = (insideUserData: InsideUserData): Promise<Response> => {

    if (!insideUserData || !insideUserData.adwLoginId || !insideUserData.adwLoginId.trim()) {
      console.error("Invalid user data given to save.", insideUserData);
      return Promise.resolve(null);
    }

    const url: string = `${InsideDataService.apigeeUrl}/save-user-preferences`;
    const messageId: string = "savePreference-123456";

    CachingService.saveValueToCache(insideUserData, "AdwUserPreferences", 15, TimeUnits.minutes);

    const requestData: {} = {
      adwUser: insideUserData,
    };

    return fetch(url, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(requestData),
      cache: "no-cache",
      headers: {
        client_id: InsideDataService.clientId,
        "Content-Type": `${InsideDataService.contentType}`,
        "X-NW-Message-ID": `${messageId}`,
        Authorization: AuthService.getAuthorizationHeader(),
      },
    })
      .then((response) => {
        if (!response.ok) {
          ErrorReportingService.reportErrorWithResponse(
            response,
            "InsideDataService.ts -> saveDataToInside",
            `Attempted to Post: ${response.url}`
          );
          /* validate access token */
          AuthService.validateToken().then(isValid => {
            if (!isValid) {
              /* invalid access token - clear storage and request new access token */
              console.log("App >>> interval : Access token is invalid.")
              AuthService.clearStorageAndRequestAccessToken();
            }
          })
        }
        return response;
      })
      .then(checkOKResponse)
      .catch(error => {
        console.error("Error saving user data", error);
        return null;
      });
  };
}

export default InsideDataService;