import React, { useContext } from "react";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import CachingService from "../../../services/CachingService";
import styles from "./SystemAdmin.module.scss";

export const ADMIN_WRAPPER_ID: string = "footer-admin-wrapper";

const SystemAdmin: React.FC = () => {
  const [appState]: AdwContextType = useContext(AdwContext);
  const isSiteAdmin: boolean = appState && appState.isAdmin;

  return (
    <div className={styles.SystemAdmin}>
      <div className={isSiteAdmin ? styles.adminBox : styles.adminBoxHidden} id={ADMIN_WRAPPER_ID}>
        <button className={styles.clearStorageButton} onClick={CachingService.clearLocalStorageAndRefresh}>
          Clear storage
        </button>
      </div>
    </div>
  );
};
export default SystemAdmin;
