import React, { useLayoutEffect, useState } from "react";
import AuthService from "../../../services/AuthService";
import AmazonWebService from "../../../services/AmazonWebService";

export interface IImageProps {
  id: string;
  src: string;
  alt: string;
  className?: string;
  imageClasses?: string;
  onMouseOver?: any;
  onMouseOut?: any;
  onClick?: () => void;
  onError?:any;
}

const AwsS3Image: React.FC<IImageProps> = ({ id,src,imageClasses,className,onError, ...otherProps }) => {
  let hasActiveMsalSession = AuthService.hasActiveSession();
  const DEFAULT_NEWS_IMAGE = require("../../../assets/images/NandEagle_white_News_image.png");
  const [imageUrl, setImageUrl] = useState<string>('');

  const getImage = async(src:string)=>{
    if(hasActiveMsalSession){
      const url = await AmazonWebService.getImageFromS3Bucket(src);
      setImageUrl(url);
    }
  }

  useLayoutEffect( ()=>{
    getImage(src);
  },[hasActiveMsalSession,src]);

  return imageUrl ? <img {...otherProps} id={id} src={imageUrl} alt='' className={className} /> :
  <img {...otherProps} src={DEFAULT_NEWS_IMAGE} alt='' className={className} />;
};

export default AwsS3Image;