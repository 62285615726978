import React, { useState, useEffect, useContext } from "react";
import ReactTooltip from "react-tooltip";
import { UpdateViewUserDetailNwie } from "../../../context/ActionTypes";
import { AdwContext, AdwContextType } from "../../../context/AdwContext";
import AdwEmployee from "../../../types/CallupTypes/AdwEmployee";
import { LocationName } from "../../../types/Locations/LocationName";
import { MailCodeFloor } from "../../../types/Locations/MailCodeFloor";
import Admin from "../Admin/Admin";
import styles from "./HrLocation.module.scss";
import GraphService from "../../../services/GraphService";
import { TimeUnits } from "../../../types/CachingTypes/TimeUnits";

export interface IHrLocationProps {
  adwEmployee: AdwEmployee;
}

export interface IHrLocationState {
  floorAndBuilding: MailCodeFloor;
  buildingNickname: LocationName;
}

const HrLocation: React.FC<IHrLocationProps> = ({ adwEmployee }) => {
  const [floorAndBuilding, setFloorAndBuilding] = useState({
    MailCode: null,
    Building: null,
    Floor: null,
  });
  const [buildingNickname, setBuildingNickname] = useState({
    Location: null,
    NwBuildName: null,
  });

  useEffect(() => {
    updateFloor();
  });

  const [appState, dispatch] = useContext<AdwContextType>(AdwContext);
  const currentUser: string =
    appState.user && appState.user.nwieID ? appState.user.nwieID : "";

  const updateFloor = (): void => {
    const { mailCodeFloor, nwLocationName } = appState.lists;

    // Update floor and building in global lists
    if (
      adwEmployee &&
      adwEmployee.mailDrop &&
      adwEmployee.mailDrop !== floorAndBuilding.MailCode
    ) {
      setFloorAndBuilding({
        MailCode: adwEmployee.mailDrop,
        Building: null,
        Floor: null,
      });
      setBuildingNickname({ Location: null, NwBuildName: null });
      GraphService.getListItems(
        "MailCodeFloor",
        dispatch,
        0,
        TimeUnits.days,
        [],
        "",
        false,
        0,
        {
          field: "MailCode",
          value: adwEmployee.mailDrop,
        }
      );
    }
    // Update building nickname in global lists
    if (
      floorAndBuilding.Building &&
      floorAndBuilding.Building !== buildingNickname.Location
    ) {
      setBuildingNickname({
        Location: floorAndBuilding.Building,
        NwBuildName: null,
      });
      GraphService.getListItems(
        "NwLocationName",
        dispatch,
        0,
        TimeUnits.days,
        [],
        "",
        false,
        0,
        {
          field: "Location",
          value: floorAndBuilding.Building,
        }
      );
    }
    // Update floor and building in state
    if (mailCodeFloor && mailCodeFloor.length) {
      // With move to Graph API, response object now has data in a fields
      //  object within it, needs unwrapped
      const GraphAPIUnwrapper: any = mailCodeFloor[0];
      const mcf: MailCodeFloor = GraphAPIUnwrapper.fields;
      if (
        mcf &&
        mcf.MailCode === floorAndBuilding.MailCode &&
        (mcf.Building !== floorAndBuilding.Building ||
          mcf.Floor !== floorAndBuilding.Floor)
      ) {
        setFloorAndBuilding({
          MailCode: mcf.MailCode,
          Building: mcf.Building,
          Floor: mcf.Floor,
        });
      }
    }
    // Update building nickname in state
    if (nwLocationName && nwLocationName.length) {
      // With move to Graph API, response object now has data in a fields
      //  object within it, needs unwrapped
      const GraphAPIUnwrapper: any = nwLocationName[0];
      const newNickname: LocationName = GraphAPIUnwrapper.fields;
      if (
        newNickname &&
        newNickname.NwBuildName !== buildingNickname.NwBuildName
      ) {
        setBuildingNickname({
          Location: buildingNickname.Location,
          NwBuildName: newNickname.NwBuildName,
        });
      }
    }
  };

  const getLocation = (): string => {
    if (
      floorAndBuilding &&
      floorAndBuilding.Building &&
      floorAndBuilding.Floor
    ) {
      return `${
        buildingNickname && buildingNickname.NwBuildName
        ? buildingNickname.NwBuildName
        : floorAndBuilding.Building
        } Floor: ${floorAndBuilding.Floor}`;
    }
    return adwEmployee && adwEmployee.employeeLocation
      ? adwEmployee.employeeLocation
      : "";
  };

  const getAddress = (adwEmployee: AdwEmployee): string => {
    let returnValue: string = "";
    if (adwEmployee.city !== ` `) {
      returnValue = `${adwEmployee.city}, ${adwEmployee.state} ${adwEmployee.postal}`;
    }
    return returnValue;
  };

  const onFocusChange = (): void => {
    setTimeout(() => {
      document.getElementById("fancy-name").focus();
    }, 500);
  };

  const onClickReportsTo = (
    e:
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>,
    nwie: string
  ): void => {
    e.preventDefault();
    dispatch(UpdateViewUserDetailNwie(nwie));
    onFocusChange();
  };

  /**
   * Only show the link if the person you are looking at is NOT kirk walker
   */
  const renderReportsTo = (adwEmployee: AdwEmployee): JSX.Element => {
    let reportsTo: JSX.Element;
    let notKirkWalker: boolean =
      adwEmployee.shortName.toLowerCase() === "walkerk";

    if (!notKirkWalker) {
      reportsTo = (
        <li id={`employee-reports-to`}>
          <div className={styles.rowWrapper}>
            <div className={styles.label}>
              REPORTS TO
            </div>
            <div className={styles.value}>
              <a
                href="/#"
                id={`reports-to-link`}
                className={styles.reportsToLink}
                onClick={(e) => {
                  onClickReportsTo(e, adwEmployee.managerShortName);
                  document.getElementById("fancy-name").style.outline = "0";
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.code === 'Enter') {
                    onClickReportsTo(e, adwEmployee.managerShortName);
                  }
                }}
              >
                {`${adwEmployee.managerPrefFullName}`}
              </a>
            </div>
          </div>
        </li>
      );
    } else {
      reportsTo = null;
    }
    return reportsTo;
  };

  /**
   * Only show the link if you are looking at yourself
   * Only show IF you are NOT a contractor
   */
  const renderMailCodeHelp = (
    adwEmployee: AdwEmployee,
    currentUserShortName: string
  ): JSX.Element => {
    let helpLink: JSX.Element;
    let lookingAtCurrentUser: boolean =
      currentUserShortName.toLocaleUpperCase() ===
      adwEmployee.shortName.toLocaleUpperCase();
    let fullTimeEmployee: boolean = adwEmployee.employeeType === "Regular";

    if (lookingAtCurrentUser && fullTimeEmployee) {
      helpLink = (
        <>
          <div
            data-tip="Your manager can update this in Workday for you"
            id={`mail-code-help-tooltip`}
            className={styles.helpLink}
            tabIndex={0}
          >
            help?
          </div>
          <ReactTooltip />
        </>
      );
    } else {
      helpLink = null;
    }
    return helpLink;
  };

  return (
    <div className={styles.hrLocation}>
      <div id={`hrlocationdetails`} className={styles.titleWrapper}>
        <h2 className={styles.title}>
          HR & Location Information
        </h2>
      </div>
      <div className={styles.hrCssGrid}>
        <div className={styles.leftColumn}>
          <ul id={`left-column-list`} className={styles.columnList}>
            {renderReportsTo(adwEmployee)}
            <li id={`employee-location`}>
              <div className={styles.rowWrapper}>
                <div className={styles.label} >
                  LOCATION
                </div>
                <div className={styles.value} >
                  {getLocation()}
                </div>
              </div>
            </li>
            <Admin adwEmployee={adwEmployee} />
            <li id={`employee-mail-code`}>
              <div className={styles.rowWrapper}>
                <div className={styles.mailCodeWrapper}>
                  <div className={styles.label} >
                    MAIL CODE
                  </div>
                  {renderMailCodeHelp(adwEmployee, currentUser)}
                </div>
                <div className={styles.value} >
                  {adwEmployee.mailDrop}
                </div>
              </div>
            </li>
            <li id={`employee-business-location`}>
              <div className={styles.rowWrapper}>
                <div className={styles.label} >
                  HR BUSINESS LOCATION
                </div>
                <div
                  className={`${styles.value} ${styles.businessLocationStyles}`}

                >
                  {adwEmployee.locID}
                </div>
                <div
                  className={`${styles.value} ${styles.businessLocationStyles}`}

                >
                  {adwEmployee.address1}
                </div>
                <div
                  className={`${styles.value} ${styles.businessLocationStyles}`}

                >
                  {getAddress(adwEmployee)}
                </div>
              </div>
            </li>
            <li id={`employee-office-code`}>
              <div className={styles.rowWrapper}>
                <div className={styles.label} >
                  OFFICE CODE
                </div>
                <div className={styles.value} >
                  {adwEmployee.disbCode2Descr}
                </div>
              </div>
            </li>
          </ul>
        </div>
        {/* right column - css grid */}
        <div className={styles.rightColumn}>
          <ul id={`right-column-list`} className={styles.columnList}>
            <li id={`employee-pay-status`}>
              <div className={styles.rowWrapper}>
                <div className={styles.label} >
                  PAY STATUS
                </div>
                <div className={styles.value} >
                  {adwEmployee.emplStatus}
                </div>
              </div>
            </li>
            <li id={`employee-company-code`}>
              <div className={styles.rowWrapper}>
                <div className={styles.label} >
                  COMPANY CODE
                </div>
                <div className={styles.value} >
                  {adwEmployee.companyCode}
                </div>
              </div>
            </li>
            <li id={`employee-gl-pay-type`}>
              <div className={styles.rowWrapper}>
                <div className={styles.label} >
                  GL PAY TYPE
                </div>
                <div className={styles.value} >
                  {adwEmployee.costCenterID}
                </div>
              </div>
            </li>
            <li id={`employee-position-number`}>
              <div className={styles.rowWrapper}>
                <div className={styles.label} >
                  HR POSITION NUMBER
                </div>
                <div className={styles.value} >
                  {adwEmployee.positionID}
                </div>
              </div>
            </li>
            <li id={`employee-department-id`}>
              <div className={styles.rowWrapper}>
                <div className={styles.label} >
                  HR DEPARTMENT ID
                </div>
                <div className={styles.value} >
                  {adwEmployee.supOrgID}
                </div>
              </div>
            </li>
            <li id={`employee-business-state`}>
              <div className={styles.rowWrapper}>
                <div className={styles.label} >
                  HR BUSINESS STATE
                </div>
                <div className={styles.value} >
                  {adwEmployee.state}
                </div>
              </div>
            </li>
            <li id={`employee-manager-boolean`}>
              <div className={styles.rowWrapper}>
                <div className={styles.label} >
                  MANAGER ?
                </div>
                <div className={styles.value} >
                  {adwEmployee.managerFlag}
                </div>
              </div>
            </li>
            <li id={`employee-disbCode`}>
              <div className={styles.rowWrapper}>
                <div className={styles.label} >
                  DISBURSEMENT CODE
                </div>
                <div
                  className={styles.value}

                  onKeyDown={(e) => {
                    if (e.key === 'Tab' || e.code === 'Tab') {
                      window.scrollTo(0, 0);
                    }
                  }}
                >
                  {adwEmployee.disbCode}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HrLocation;
