import React from "react";
import styles from "./CommonToolsMoved.module.scss";

export interface ICommonToolsProps {
  commonToolsStyles: string;
  id:string;
  feature:any
}

const CommonToolsMoved: React.FC<ICommonToolsProps> = ({
  commonToolsStyles,id,feature
}) => {

  const commonToolsClasses: string = commonToolsStyles
    ? `${commonToolsStyles} ${styles.CommonToolsWrapper}`
    : `${styles.CommonToolsWrapper}`;

  const getComponentFeatureIcon = (icon) => {
    switch (icon) {
      case "bell":
        return require('../../../assets/images/Mail.svg').default;
      case "star":
        return require('../../../assets/images/icon-gear.svg').default;
      case "clock":
        return require('../../../assets/images/Cross.svg').default
      default:
        return require('../../../assets/images/Alert.svg').default
    }
   
  }

  return (
    <div className={commonToolsClasses} id={`${id}-Wrapper`} >

<div className={styles.toolsMovedWrapper}>
  <div className={styles.toolsMovedTitleWrapper} >
          <h3 className={styles.toolsMovedTitle}>{feature.title}</h3>
  </div>
  <div className={styles.toolsMovedIconWrapper}>
  <img className={styles.toolsMovedIcon} alt="" src={getComponentFeatureIcon(feature.icon)} /> 
  </div>
  <div className={styles.toolsMovedContentWrapper}>
          <p className={styles.toolsMovedContent}>{feature.description}</p>
  </div>

</div>
    </div>
  );
};

export default CommonToolsMoved;
