import React, { useEffect, useState } from "react";
import AdwAtoZView from "../../../types/AtoZViewTypes/AdwAtoZView";
import styles from "./SelectLetter.module.scss";

export interface ILetterProps {
    letter: string;
    sites: AdwAtoZView[];
    handleLetterClick: Function
}

const SelectLetter: React.FC<ILetterProps> = (props: ILetterProps) => {
    const { letter, sites, handleLetterClick } = props;
    const [hasSites, setHasSites] = useState(false);
    const [, setActiveLetter] = useState(true);

    useEffect(() => {
        if (sites != null) {
            sites.forEach((element) => {
                if (element.SiteName.toLowerCase().indexOf(letter) === 0) {
                    setHasSites(true);
                }
            })
        }
    }, [sites, letter])

    const handleClick = (letter) => {
        handleLetterClick(letter)
        setActiveLetter(true);
    }

    return (
        <>
            {hasSites ?
                <a href="#" onClick={() => hasSites && handleClick(letter)}><span className={hasSites ? styles.enabled : styles.disabled}>{letter}</span></a>
                :
                <span className={hasSites ? styles.enabled : styles.disabled}>{letter}</span>
            }
        </>
    );
}

export default SelectLetter;
