import { SpWhatChanged } from "./SpWhatChanged";

class AdwWhatChanged {
  public Description: string;
  public ImagePath: string;
  public ImageAltText: string;
  public ButtonLink: {
    Description:string;
    Url: string;
  };
  public DisplayPosition: number;
  public DisplayInApp: boolean;
  public id: number;
  public WebElementID: string;
  public StartDate:string;
  public EndDate:string;
  public Created:string
  
  constructor(spWhatChanged: SpWhatChanged) {
    if (spWhatChanged !== null) {
      this.id = spWhatChanged.fields.id; //check API call data
      this.Description = spWhatChanged.fields.Description;
      this.ImagePath = spWhatChanged.fields.ImagePath;
      this.ImageAltText = spWhatChanged.fields.ImageAltText;
      this.ButtonLink = spWhatChanged.fields.ButtonLink; 
      this.DisplayPosition = spWhatChanged.fields.DisplayPosition;
      this.DisplayInApp = spWhatChanged.fields.DisplayInApp;
      this.WebElementID = spWhatChanged.fields.WebElementID;
      this.StartDate= spWhatChanged.fields.StartDate;
      this.EndDate= spWhatChanged.fields.EndDate;
      this.Created= spWhatChanged.fields.Created;
    }
  }

}

export default AdwWhatChanged;
