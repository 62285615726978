'use client'

import React, { useContext, useEffect } from 'react';
import { AdwContext } from '../../../context/AdwContext';
import styles from "../../../App.module.scss";
import NoResults from '../../molecules/NoResults/NoResults';
import searchStyles from "../../molecules/SearchBar/MindbreezeAutoCompleteWithSearch.module.scss";
import Link from '../../atoms/Link/Link';

const ALERT_IMG: string = require("../../../assets/images/Alert.svg").default;
const NO_RESULTS_TEXT = 'Looks like you have no announcements left! Check back later.';

interface ISeeAllAnnouncementsViewProps {
  className?: string;
}

const SeeAllAnnouncementsView: React.FC<ISeeAllAnnouncementsViewProps> = () => {

  const [appState] = useContext(AdwContext);
  const announcements = [...(appState.lists.announcements ?? [])].sort(a => {
    if (a.pinned) return -1
  });

  useEffect(() => {
    document.title = 'InSide - All announcements';
    const searchBar = document.getElementById(searchStyles.search);

    if (searchBar) {
      searchBar.style.display = 'none';
    }

    return () => {
      if (searchBar) {
        searchBar.style.display = 'block';
      }
    }
  }, [])

  return (
    <div className={styles.announcements_container}>
      <h1 className={styles.announcements_title}>All recent announcements</h1>
      {announcements.length > 0 ?
        <ul className={styles.announcements_list}>
          {announcements.map(announcement => (
            <li key={announcement.id} className={styles.single_announcement}>
            {announcement.link ?
              <p>
                  <Link id={`announcement-${announcement.id}`} url={announcement.link.url} target="_blank" title={announcement.announcementText} linkText={announcement.announcementText} linkClasses={styles.announcement_link} />
              </p> :
              <p>
                {announcement.announcementText}
              </p>
            }
          </li>
        ))}
      </ul> :
      <NoResults 
          noResultsClasses={styles.announcements_noResults}
        imagePath={ALERT_IMG} 
        textForDisplay={NO_RESULTS_TEXT}
        id='Announcements-NoResults' 
      />
      }
    </div>
  )
}

export default SeeAllAnnouncementsView;