import React from "react";
import DesktopArrowButton from "../../atoms/DesktopArrowButton/DesktopArrowButton";
import styles from "./GoToLink.module.scss";
import { useNavigate } from "react-router-dom";

export interface IGoToLinkProps {
  linkText: string;
  url?: string;
  onClick?: (e: React.MouseEvent) => void;
  target?: string;
  id: string;
  linkColorString?: string;
}

const GoToLink: React.FC<IGoToLinkProps> = ({ linkText, url = "", target, id, linkColorString, onClick }) => {
  const navigate = useNavigate();
  if (!((url && target) || onClick)) return null;
  const linkColor: React.CSSProperties = linkColorString ? { color: linkColorString } : {};

  function isInternalLink(url: string) {
    return url.toLowerCase().indexOf("/") === 0;
  }

  const handleInternalLinkClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    navigate(url);
  };

  let linkAction;
  if (onClick) {
    linkAction = { onClick: e => onClick(e) }
  } else {
    linkAction = isInternalLink(url) ? { onClick: e => handleInternalLinkClick(e) } : { href: url, target: target };
  }

  return (
    <div className={styles.goToLink}>
      <a className={styles.link} id={id} {...linkAction} tabIndex={0}>
        <span id={`${id}-link-text`} style={linkColor}>
          {linkText}
        </span>{" "}
        <DesktopArrowButton desktopArrow={styles.moreArrow} arrowColor={linkColorString} />
      </a>
    </div>
  );
};

export default GoToLink;
